import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';

export function Timer({ goodUntilUtc, overCount, hideHours, onExpired }) {
    const refInterval = useRef(null);
    const [iteration, setIteration] = useState(0);
    const [running, setRunning] = useState(true);

    const twoDigits = n => n.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    const now = moment.utc();
    const ends = moment.utc(goodUntilUtc).utc();
    const duration = moment.duration(ends.diff(now));
    const expired = duration && !overCount && Math.round(duration.asSeconds()) <= 0;

    const runTicker = () => {
        if (!refInterval.current) {
            refInterval.current = setInterval(() => setIteration(iteration => iteration + 1), 1000);
        }
    }
    const clearTicker = () => {
        if (refInterval.current) clearInterval(refInterval.current);
    }

    useEffect(() => {
        if (!expired) runTicker();
        return () => clearTicker(); // eslint-disable-next-line
    }, []);

    useEffect(() => setRunning(true), [goodUntilUtc]);

    useEffect(() => {
        if (expired && running) {
            onExpired && onExpired();
            setRunning(false);
        }
    }, [iteration, expired, running, onExpired, setRunning]);

    const timePassed = duration.asSeconds() < 0;
    const lessThenFiveMins = !timePassed && duration.asMinutes() < 5;

    const hours = Math.abs(Math.trunc(duration.asHours()));
    duration.subtract(moment.duration(hours, 'hours'));

    const minutes = Math.abs(duration.minutes());
    duration.subtract(moment.duration(minutes, 'minutes'));

    const seconds = Math.abs(duration.seconds());

    const cssClasses = classNames({
        'time-counter': true,
        'time-passed': timePassed,
        'less-then-five-mins': lessThenFiveMins
    });

    if (!running || expired || isNaN(hours)) {
        return <span className={cssClasses}>{!hideHours && '00:'}00:00</span>;
    }

    return (
        <span className={cssClasses}>
            {timePassed && '+'}
            {!hideHours && <>{twoDigits(hours)}:</>}{twoDigits(minutes)}:{twoDigits(seconds)}
        </span>
    )
}

Timer.defaultProps = {
    onExpired: undefined,
    overCount: false,
    hideHours: false
}
