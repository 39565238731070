import * as React from 'react';
import { errorMessages } from '../../../../constants/error.messages';
import { BidConflict } from '../../../../types/bidding/BidConflict';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { OnHoverTooltip } from "../../../common/OnHoverTooltip";
import { isActiveTrade } from '../../../../types/trades/TradeStatus';
import { NotificationBody } from '../../../notifications/NotificationBody';
import { moneyUtils } from '../../../../utils';
import { formatUtils } from '../../../../utils/format.utils';
import { Trade } from '../../../../types/trades/Trade';
import { BwicProcessType, Process } from '../../../../types/models/Process';

interface Props {
    isSubmitting: boolean;
    isSuccess: boolean;
    isFailed: boolean;
    isPass: boolean;
    trade?: Trade;
    isTradedAway: boolean;
    ticker: string;
    conflict?: BidConflict;
    stageTransitionConflict?: {
        stagedBiddingStatus?: OpenBiddingStatus;
        canBidOnStage2?: boolean;
        hasBestLevelOnPosition?: boolean;
    };
    isColorDistribution?: boolean;
    validationError?: { title?: string, error?: string };
    isBidOverThemself: boolean;
    isLoggedOut: boolean;
    process: Process;
    liveBiddingStage2Expired?: boolean;
}

export function SubmitStatus({
    isSubmitting,
    isSuccess,
    isFailed,
    isPass,
    trade,
    isTradedAway,
    ticker,
    conflict,
    stageTransitionConflict,
    isColorDistribution,
    validationError,
    isBidOverThemself,
    isLoggedOut,
    process,
    liveBiddingStage2Expired
}: Props) {
    const renderFailed = () => {
        let title: string = 'Error';
        let tooltip: React.ReactNode = errorMessages.unexpectedError;

        if (isLoggedOut) {
            title = "Failed to save bid";
            tooltip = "An error occurred while submitting the bid. Please refresh the page and try again.";
        } else if (isColorDistribution) {
            title = "Bidding has been paused";
            tooltip = "BWIC suspended. Seller is distributing the color.";
        } else if (isPass && conflict != null) {
            title = "Bid submitted, you cannot pass";
            tooltip = `You can't pass, because initial bid for security ${ticker} has already been submitted`;
        } else if (conflict != null) {
            title = "Bid has been submitted earlier";
            tooltip = (
                <>
                    The bid was submitted by earlier:<br />
                    {ticker} {moneyUtils.money(conflict.size)} @ {formatUtils.formatBid(conflict.level)} (submitted by {conflict.bidderName})<br />
                    Please confirm your bid.
                </>
            );
        } else if (process.type === BwicProcessType.Live && stageTransitionConflict?.stagedBiddingStatus === OpenBiddingStatus.stage1Ended && liveBiddingStage2Expired) {
            title = "Bidding period has ended";
            tooltip = "Bidding is completed. No bids are accepted.";
        } else if (stageTransitionConflict?.stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
            title = process.type === BwicProcessType.BestFootForward ? "Bidding is over" : "Stage 1 has ended";
            tooltip = process.type === BwicProcessType.BestFootForward
                ? "You cannot confirm the bid because the bidding period is over."
                : "The bid cannot be accepted according to Stage 2 rules.";
        } else if (
            stageTransitionConflict?.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded ||
            process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded
        ) {
            title = "Stage 2 has ended";
            tooltip = "Bidding is completed. Bid is not accepted."
        } else if (isBidOverThemself) {
            title = "Bidding over yourself";

            const isJumpBallStage2 =
                process.type === BwicProcessType.JumpBall &&
                process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended

            tooltip = isJumpBallStage2
                ? "You were Best in Stage 1. Please confirm your bid."
                : "You are bidding over yourself. Please confirm.";
        } else if (isActiveTrade(trade)) {
            title = "Security has already traded to you";
            tooltip = `${ticker} cannot be traded. It is likely that this security was already traded to you.`;
        } else if (isTradedAway) {
            title = "Security has traded away";
            tooltip = `${ticker} cannot be traded. It is likely that this security was already traded away.`;
        } else if (validationError != null) {
            title = validationError.title || 'Error';
            tooltip = validationError.error ? <NotificationBody text={validationError.error} /> : errorMessages.unexpectedError;
        }

        return (
            <div className="flex-row">
                <OnHoverTooltip overlay="Unsuccess">
                    <span className="status status-rejected text-ellipsis">{title}</span>
                </OnHoverTooltip>
                <OnHoverTooltip placement="bottomRight" overlay={tooltip}>
                    <i className="icon icon-error"></i>
                </OnHoverTooltip>
            </div>
        );
    }

    if (isSubmitting) {
        return <div className="text-italic text-warm-grey submitting-bid">Submitting bid...</div>;
    }

    if (isSuccess) {
        return (
            <OnHoverTooltip overlay="Success">
                <span className="status status-confirmed">Bid saved</span>
            </OnHoverTooltip>
        )
    }

    if (isFailed) {
        return renderFailed();
    }

    return null;
}
