import * as React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { bidPlacementActions } from '../../../../actions';
import { formatUtils, numericUtils, stringUtils, biddingUtils } from '../../../../utils';
import { constants } from '../../../../constants';
import { BidLevelDelta } from '../../../common/bidding/BidLevelDelta';
import { RevertBidButton } from "../../../common/RevertBidButton";
import { AppState } from '../../../../types/state/AppState';
import { FormError } from '../../../controls';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export interface BidInputProps {
    enabled: boolean;
    readonly: boolean;
    position: BwicPosition;
    children?: (changed: boolean) => React.ReactNode;
}

export function Bid({ enabled, readonly, position, children }: BidInputProps) {
    const positionId = position.id;

    const dispatch = useAppDispatch();
    const [hasFocus, setHasFocus] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const editState = useSelector((s: AppState) => s.brokerDealerBidding.editBid[positionId]);    
    const currentBid = biddingUtils.getCurrentBid(position.bids);
    const editingValue = (editState?.pass && 'Pass') || editState?.value;
    const currentValue = currentBid?.pass ? 'Pass' : formatUtils.formatBid(currentBid?.value);
    const hasError = Boolean(editState?.errors?.bid);

    const isChanged = editState != null && (
        (editState.pass != null && Boolean(editState.pass) !== Boolean(currentBid?.pass)) ||
        (!stringUtils.isNullOrEmpty(editState.value) && numericUtils.numberOrDefault(editState.value) !== numericUtils.numberOrDefault(currentBid?.value))
    );

    const handleFocus = () => {
        if (inputRef?.current) {
            inputRef.current.select();
            setHasFocus(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const changedBidValue = rawValue ? rawValue.replace(/\s/g, '') : rawValue;

        if (changedBidValue && !(constants.bidNumber.test(changedBidValue))) {
            return null;
        }

        dispatch(bidPlacementActions.bidChange(positionId, changedBidValue))
    };

    const handleBlur = () => {
        dispatch(bidPlacementActions.formatBid(positionId));
        setHasFocus(false);
    };

    const handleRevert = (e: React.MouseEvent) => {
        dispatch(bidPlacementActions.revert(positionId));
        e.stopPropagation();
    };

    return (
        <>
            <div
                className={cn(
                    'display-inline', {
                    'form-control-changed': isChanged,
                    'form-control-error': hasError
                })}
            >
                {
                    readonly &&
                    <span className="input-placeholder-value text-ellipsis">
                        {currentValue ? currentValue : constants.emptyPlaceholder}
                    </span>
                }
                {
                    !readonly &&
                    <>
                        <input
                            ref={inputRef}
                            type="text"
                            className="form-control"
                            value={editingValue ?? currentValue}
                            disabled={!enabled}
                            maxLength={8}
                            placeholder="0.0000"
                            onFocus={handleFocus}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={e => e.stopPropagation()}
                        />
                        {hasError && <FormError message={editState?.errors?.bid} />}
                        {
                            hasFocus &&
                            isChanged &&
                            currentBid != null &&
                            Boolean(currentBid.value) &&
                            numericUtils.numberOrDefault(editState?.value) > 0 &&
                            <BidLevelDelta
                                currentLevel={currentBid.value}
                                updatedLevel={numericUtils.numberOrDefault(editState?.value)}
                                updatedLevelVisible={false}
                                tooltip={true}
                            />
                        }
                    </>
                }
            </div>
            {children?.(isChanged)}
            {isChanged && <RevertBidButton onRevert={handleRevert} />}
        </>
    );
}
