import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../../common';
import { isActiveTrade } from '../../../../../../types/trades/TradeStatus';

export const colorDistributionTradedStatus = {
    columnName: 'colorDistributionTradedStatus',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm">
                Status
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm">
                {isActiveTrade(position.trade) ? 'Traded' : 'DNT'}
            </SecurityListColumn>
        );
    }
};
