import { useHistory } from 'react-router';
import { tradeConfirmActions } from '../../../../actions';
import { LocationState, LocationStateBuilder, PopupType } from '../../../../types/state/ui/LocationState';
import { RejectReasonPopup } from '../../../common/RejectReasonPopup';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export type TRejectReasonPopupPayload = {
    title?: string;
    ticker?: string;
    positionId: number
    tradeReferenceId: string | number;
    textareaPlaceholder?: string;
    comfirmButtonText?: string;
}

export function TradeRejectReasonPopup() {
    const dispatch = useAppDispatch()
    const history = useHistory<LocationState<undefined, TRejectReasonPopupPayload>>();

    if (history.location?.state?.popup?.type === PopupType.TradeRejectReason) {
        const payload = history.location.state?.popup.payload;

        if (payload?.positionId && payload.ticker && payload.tradeReferenceId) {
            const title = <>Reject Reason for <span className="name-tag">{payload.ticker}</span></>

            return <RejectReasonPopup
                title={title}
                onReject={reason => dispatch(tradeConfirmActions.reject(payload.positionId, payload.tradeReferenceId, reason))}
                onClose={() => history.replace({
                    ...history.location,
                    state: new LocationStateBuilder(history.location.state).resetPopup().result()
                })}

            />
        }
    }

    return null;
}
