import { useEffect } from 'react';
import { bwicStatuses } from '../../../constants';
import { Scheduled, Bidding, Cancelled, Finished } from '.';
import { ColorDistribution } from './colorDistribution';
import {
    sellerBiddingActions,
    controlPanelActions,
} from '../../../actions';
import { Preloader } from '../../common';
import {
    DirectBiddingDisclaimerContentBlocker
} from '../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContentBlocker';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BiddingTabs } from '../../../constants/bidding';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const SellerView = () => {
    const dispatch = useAppDispatch();
    const { tab } = useParams<{ tab: BiddingTabs }>();
    const bwic = useAppSelector((s) => s.bidding.bwic);
    const isLoading = useAppSelector((s) => s.bidding.isLoading);

    const rightSidePanelVisible = useAppSelector((s) => (
        s.bwicHistory.visible ||
        s.sellerBidding.bidList.visible ||
        s.securityDetails.security ||
        s.rules.show ||
        s.pxTalkList.visible ||
        s.sellerBidding.autoFeedbackSettingsVisible ||
        s.dealerListPanel.panelVisible ||
        s.blastMessage.panelVisible ||
        s.bwicLog.security
    ))

    const activePositionId = useAppSelector((s) => (
        rightSidePanelVisible &&
        (
            (s.bwicHistory.visible && s.bwicHistory.positionId) ||
            (s.sellerBidding.bidList.visible && s.sellerBidding.bidList.positionId) ||
            (s.securityDetails.security && s.securityDetails.security.id) ||
            (s.bwicLog.security && s.bwicLog.security.id) ||
            (s.rules.show && s.rules.positionId) ||
            (s.pxTalkList.visible && s.pxTalkList.positionId)
        )
    ))

    const getActiveTab = () => bwic.isAllToAll ? undefined : tab

    useEffect(() => {
        return () => {
            dispatch(sellerBiddingActions.reset());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(controlPanelActions.hide());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    const renderTemplate = () => {
        switch (bwic.status) {
            case bwicStatuses.scheduled.key:
                return <Scheduled activePositionId={activePositionId} activeTab={getActiveTab()} />;

            case bwicStatuses.bidding.key:
                return bwic.isColorDistribution
                    ? <ColorDistribution />
                    : <Bidding
                        activePositionId={activePositionId}
                        rightSidePanelVisible={rightSidePanelVisible}
                        activeTab={getActiveTab()}
                    />;

            case bwicStatuses.cancelled.key:
                return <Cancelled activePositionId={activePositionId} activeTab={getActiveTab()} />;

            case bwicStatuses.finished.key:
                return <Finished
                    activePositionId={activePositionId}
                    rightSidePanelVisible={rightSidePanelVisible}
                    activeTab={getActiveTab()}
                />;

            default:
                return null;
        }
    };

    return (
        <DirectBiddingDisclaimerContentBlocker disabled={!bwic.isClearingBankParticipant}>
            <Preloader inProgress={isLoading}>
                {renderTemplate()}
            </Preloader>
        </DirectBiddingDisclaimerContentBlocker>
    )
}
