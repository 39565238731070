import classNames from "classnames";
import { brokerDealerAccessState } from "../../../constants/bidding";
import { SettlementAgreementStatus } from "../../../types/bid-as-dealer/SettlementAgreementStatus";
import { BwicProcessType } from "../../../types/models/Process";
import { user } from "../../../user";
import { useAgreement } from "../../seller-buyside/useAgreement";
import { Table } from "../common/table";
import { brokerDealerBiddingColumns } from "../common/table/columns";
import { TradeStatus } from "../../../types/trades/TradeStatus";
import { OpenBiddingStatus } from "../../../types/enums/OpenBiddingStatus";
import { biddingUtils } from "../../../utils";
import { liveBiddingUtils } from "../../../utils/live-bidding.utils";
import { jumpBallUtils } from "../../../utils/jump-ball.utils";
import { topXUtils } from "../../../utils/top-x.utils";
import moment from "moment";
import { BrokerDealerAccessState } from "../../../types/state/BrokerDealerBiddingState";
import { BwicPosition } from "../../../types/bwic/BwicPosition";
import { Bwic } from "../../../types/bwic/Bwic";
import { roles } from "../../../constants/roles";

interface BiddingTableProps {
    bwic: Bwic;
    securities: BwicPosition[];
    accessState: BrokerDealerAccessState;
    activePositionId?: number;
}

export const BiddingTable = ({ bwic, securities, accessState, activePositionId }: BiddingTableProps) => {
    const { agreement } = useAgreement();
    const isAllToAllAgreementSigned = agreement?.agreementStatus === SettlementAgreementStatus.confirmed;
    const canSubmitBid = accessState === brokerDealerAccessState.approved && user.hasRoles(roles.BrokerDealerTrader);

    const isProcessEnabled = (security: BwicPosition) => {
        const currentBid = biddingUtils.getCurrentBid(security.bids);
        switch (bwic.process.type) {
            case BwicProcessType.BestFootForward:
                return bwic.process.stagedBiddingStatus == null;
            case BwicProcessType.Live:
                return liveBiddingUtils.canBid(bwic.bidsDueUtc!, bwic.process, security.canBidOnStage2, security.latestBidSubmission)
            case BwicProcessType.JumpBall:
                return jumpBallUtils.canBid(bwic.process.stagedBiddingStatus, currentBid, security.isStage2Participant);
            case BwicProcessType.TopX:
                return topXUtils.canBid(bwic.process.stagedBiddingStatus, security.isStage2Participant);
            default: return true;
        }
    }

    const columns = brokerDealerBiddingColumns(
        bwic.isAllToAll,
        isAllToAllAgreementSigned,
        bwic.status,
        bwic.process.type,
        bwic.process.stagedBiddingStatus,
        bwic.isParsed,
        accessState
    );

    const createSecurityCustomClassName = (security: BwicPosition, context: { enabled: boolean }) => {
        const active = security.id === activePositionId;
        const tradedaway = !active && canSubmitBid && (security.isTradedAway || security.trade?.status === TradeStatus.rejected);
        const affirmed = !active && canSubmitBid && (security.trade?.status === TradeStatus.affirmed);
        const isTradePending = security.trade?.status === TradeStatus.pending;
        const inactive = !active && !tradedaway && !affirmed && !isTradePending && !context.enabled;

        return classNames('pointer', {
            active,
            tradedaway,
            affirmed,
            inactive
        });
    }

    const createSecurityCustomArgs = (security: BwicPosition) => {
        const isLiveBiddingStage2 =
            bwic.process.type === BwicProcessType.Live &&
            bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
        const liveBiddingStage2SubmissionDeadline = isLiveBiddingStage2
            ? liveBiddingUtils.calculateStage2SubmissionDeadline(bwic.bidsDueUtc!, bwic.process, security.latestBidSubmission)
            : undefined;
        const liveBiddingStage2Expired = isLiveBiddingStage2 && moment.utc().diff(liveBiddingStage2SubmissionDeadline) > 0;

        const currentBid = biddingUtils.getCurrentBid(security.bids);
        const hasBid = currentBid != null && !currentBid.pass;
        const biddingControlsVisible = !bwic.isColorDistribution && security.trade == null && !security.isTradedAway;
        const processEnabled = isProcessEnabled(security);

        const enabled = (
            bwic &&
            accessState === brokerDealerAccessState.approved &&
            biddingControlsVisible &&
            processEnabled
        );

        return {
            biddingControlsVisible,
            brokerDealerAccessState: accessState,
            enabled,
            processEnabled,
            hasBid,
            bwic,
            liveBiddingStage2SubmissionDeadline,
            liveBiddingStage2Expired
        };
    }

    return (
        <Table
            className="data-list-striped data-list-bd-bidding"
            dataItems={securities}
            columns={columns}
            createSecurityCustomArgs={bwic.isParsed ? undefined : createSecurityCustomArgs}
            createSecurityCustomClassName={createSecurityCustomClassName}
            createRowCustomKey={(position: BwicPosition) => position.id}
        />
    )
}