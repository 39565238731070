import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { AffirmTradeButtons } from '../../../../brokerDealer/trade-affirm';
import { tradeStatuses } from '../../../../../../constants/bidding';

export const affirmRejectButtons = {
    columnName: 'affirmRejectButtons',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-lg flex-row justify-content-end flex-item-right"
            >
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg flex-row justify-content-end flex-item-right">
                {
                    position.trade.status === tradeStatuses.pending.key &&
                    <AffirmTradeButtons
                        positionId={position.id}
                        ticker={position.ticker}
                        tradeReferenceId={position.trade.id}
                        buyerPseudoOrderNumber={position.trade.buyerPseudoOrderNumber}
                    />
                }
            </SecurityListColumn>
        );
    }
};
