import { isinCusip } from './isinCusip';
import { ticker } from './ticker';
import { tickerButton } from './ticker-button';
import { ccy } from './ccy';
import { size } from './size';
import { rating } from './rating';
import { pxTalk } from './pxTalk';
import { pxTalkChange } from './pxTalk-change';
import { targetLevel } from './target.level';
import { history } from './history';
import { color } from './color';
import { bid as bidView } from './bid';

import { colorDistributionTradedStatus } from './colorDistribution/colorDistributionTradedStatus';
import { colorDistributionType } from './colorDistribution/colorDistributionType';
import { colorDistributionLabel } from './colorDistribution/colorDistributionLabel';

import { axed } from './bid-placement/axed';
import { final } from './bid-placement/final';
import { bid } from './bid-placement/bid';
import { feedback } from './bid-placement/feedback';
import { brokerDealerActionButtons } from './bid-placement/broker-dealer.action.buttons';
import { commission } from './bid-placement/commission';

import { counterparty } from './trade-affirm/couterparty';
import { settleDate } from './trade-affirm/settle.date';
import { tradePrice } from './trade-affirm/trade.price';
import { tradeSize } from './trade-affirm/trade.size';
import { tradeComment } from './trade-affirm/trade-comment';
import { tradeStatus as tradeAffirmStatus } from './trade-affirm/trade.status'
import { affirmRejectButtons } from './trade-affirm/affirm-reject.buttons';
import { tradeCommission } from './trade-affirm/trade-commission';
import { tradeTotalPrice } from './trade-affirm/trade-total-price';
import { tradeRating } from './trade-affirm/trade-rating';
import { liveBiddingStage2Timer } from "./live-bidding-stage2-timer";

import { liveBiddingStage2Level } from "./open-bidding/liveBiddingStage2Level";
import { user } from '../../../../../user/user';
import { ListBuilder } from '../../../../../utils/ListBuilder';
import { biddingInfo } from './bid-placement/bidding-info';
import { BwicStatus } from '../../../../../types/enums/BwicStatus';
import { BwicProcessType } from '../../../../../types/models/Process';
import { OpenBiddingStatus } from '../../../../../types/enums/OpenBiddingStatus';
import { BrokerDealerAccessState } from '../../../../../types/state/BrokerDealerBiddingState';
import { roles } from '../../../../../constants';

const baseColumns = [tickerButton, size, rating];

export const sellerCanceledColumns = [tickerButton, size, rating, pxTalk, targetLevel];

export const sellerScheduledColumns = () => {
    const isBrokerDealerTrader = user.hasRoles(roles.BrokerDealerTrader);
    return new ListBuilder()
        .add(...baseColumns, pxTalk)
        .add(targetLevel)
        .addWhen(() => isBrokerDealerTrader, pxTalkChange) // Possible of using sellerScheduledColumns for BD as Seller in case of ALL-TO-ALL
        .result();
}

export const otherSellerColumns = [...baseColumns, pxTalk];
export const otherSellerBiddingColumns = [...baseColumns, pxTalk, history];
export const otherSellerFinishedColumns = [...baseColumns, pxTalk, color, history];

export const brokerDealerScheduledColumns = (canSavePriceTalk) => {
    return new ListBuilder()
        .add(...baseColumns)
        .add(pxTalk)
        .addWhen(() => canSavePriceTalk, pxTalkChange)
        .add(history)
        .result();
}

export const brokerDealerBiddingColumns = (
    isAllToAll,
    isAllToAllAgreementSigned,
    bwicStatus,
    bwicProcessType,
    stagedBiddingStatus,
    isParsedBwic,
    accessState) => {
    if (isParsedBwic) {
        return brokerDealerParsedBiddingColumns;
    }
    const isLiveBidding = bwicProcessType === BwicProcessType.Live;
    const isLiveBiddingStage2 = isLiveBidding && stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const isAllToAllConfirmedOrNotAllToAll = !isAllToAll || isAllToAllAgreementSigned;
    const isBFF = bwicProcessType === BwicProcessType.BestFootForward;
    const isStandard = bwicProcessType === BwicProcessType.Standard;
    const canSubmitBid = user.hasRoles(roles.BrokerDealerTrader);
    const showPriceTalkChange =
        user.hasRoles(roles.BrokerDealerTrader) &&
        isAllToAll &&
        bwicStatus === BwicStatus.scheduled;

    return new ListBuilder()
        .add(...baseColumns, pxTalk)
        .addWhen(() => showPriceTalkChange, pxTalkChange)
        .addWhen(() => canSubmitBid, bid)
        .addWhen(() => canSubmitBid && isAllToAll, commission)
        .addWhen(() => canSubmitBid && (isBFF || isStandard), axed, final)
        .addWhen(() => canSubmitBid && isLiveBiddingStage2 && isAllToAllConfirmedOrNotAllToAll, liveBiddingStage2Timer)
        .addWhen(() => canSubmitBid && !isLiveBidding && isAllToAllConfirmedOrNotAllToAll, feedback)
        .addWhen(() => canSubmitBid && isAllToAllConfirmedOrNotAllToAll && isLiveBiddingStage2 && accessState === BrokerDealerAccessState.Approved, liveBiddingStage2Level)
        .addWhen(() => canSubmitBid && isAllToAllConfirmedOrNotAllToAll, biddingInfo)
        .addWhen(() => canSubmitBid && isAllToAllConfirmedOrNotAllToAll, brokerDealerActionButtons)
        .add(history)
        .result();
}

const brokerDealerParsedBiddingColumns = [...baseColumns, pxTalk, history];
export const brokerDealerCanceledColumns = [...baseColumns, pxTalk, history];
export const brokerDealerFinishedColumns = (isAllToAll, isAllToAllAgreementSigned) => {
    const canSubmitBids = user.hasRoles(roles.BrokerDealerTrader);
    const isAllToAllConfirmedOrNotAllToAll = !isAllToAll || isAllToAllAgreementSigned;

    return new ListBuilder()
        .add(...baseColumns, pxTalk)
        .addWhen(() => canSubmitBids, bidView)
        .add(color)
        .addWhen(() => canSubmitBids && isAllToAllConfirmedOrNotAllToAll, feedback)
        .addWhen(() => canSubmitBids, brokerDealerActionButtons)
        .add(history)
        .result();
}
export const brokerDealerFinishedOpenBiddingColumns = () => {
    const canSubmitBids = user.hasRoles(roles.BrokerDealerTrader);

    return new ListBuilder()
        .add(...baseColumns, pxTalk)
        .addWhen(() => canSubmitBids, bidView)
        .add(color)
        .addWhen(() => canSubmitBids, liveBiddingStage2Level)
        .addWhen(() => canSubmitBids, brokerDealerActionButtons)
        .add(history)
        .result();
};
export const brokerDealerParsedFinishedColumns = [...baseColumns, pxTalk, color, history];
export const brokerDealerNotInvitedFinishedColumns = [...baseColumns, pxTalk, color, history];
export const brokerDealerNotInvitedFinishedOpenBiddingColumns = [...baseColumns, pxTalk, color, history];

export const colorDistributionColumns = [ticker, ccy, size, rating, colorDistributionTradedStatus, colorDistributionType, colorDistributionLabel];

export const directTradeAffirmColmns = [isinCusip, ticker, tradeRating, tradeAffirmStatus, settleDate, counterparty, tradePrice, tradeCommission, tradeTotalPrice, tradeSize, tradeComment, affirmRejectButtons];
