import * as React from 'react';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { getSecuritiesWithLatestBids } from '../../../../selectors';
import { biddingUtils } from '../../../../utils';
import { OnHoverTooltip } from '../../../common';

interface Props {
    position: BwicPosition;
}

export function TiedForBestWarningIcon({ position }: Props) {
    const [{ bids }] = getSecuritiesWithLatestBids({ securities: [position] });
    const [best = []] = biddingUtils.groupBidsByLevel(bids);

    if (best.length > 1) {
        const counterparties = best
            .map(b => b.buyerPseudoOrderNumber
                ? `${b.company.name}-${b.buyerPseudoOrderNumber} (${b.company.code}-${b.buyerPseudoOrderNumber})`
                : `${b.company.name} (${b.company.code})`)
            .join(', ');

        const message = `These participants are tied for the best price: ${counterparties}.`;

        return (
            <OnHoverTooltip overlay={message}>
                <i className="icon icon-warning" />
            </OnHoverTooltip>

        );
    }

    return null;
}
