import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../common';
import { BidLevel as BidLevelComponent } from '../../../seller/biddingSecurities';
import { BidOverThemSelf } from '../../../seller/biddingSecurities/BidOverThemSelf';
import { BidLevel } from '../../../../../types/bidding/BidLevel';

export const best = {
    columnName: 'best',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xl-xxl cell-bid-best">
                Best
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xl-xxl cell-bid-best overflow-visible">
                <BidLevelComponent
                    level={BidLevel.Best}
                    positionId={position.id}
                    bids={position.bids}
                    stage2Participants={context.stage2Participants}
                    trade={position.trade}
                    allowFeedback={context.allowFeedback}
                    bwicStatus={context.bwicStatus}
                    process={context.bwic?.process}
                >
                    <BidOverThemSelf
                        bwicStatus={context.bwic.status}
                        trade={position.trade}
                        positionId={position.id}
                    />
                </BidLevelComponent>
            </SecurityListColumn>
        );
    }
};

