import { memo } from 'react';
import { useAppSelector } from '../../../effects/useAppSelector';
import { TopPanel } from '../common';
import { Participants } from '../common/participants';
import SubHeader from '../common/SubHeader';
import { BiddingTabs } from '../../../constants/bidding';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { routes } from '../../../constants';

const AdminBiddingViewComponent = () => {
    const { tab } = useParams<{ referenceName: string, tab: string }>();
    const history = useHistory();

    const bwic = useAppSelector(s => s.bidding.bwic);
    const bwicReferenceName = bwic.referenceName;

    useEffect(() => {
        if (!tab || tab !== BiddingTabs.participants) {
            history.replace(routes.biddingUrl(bwicReferenceName, BiddingTabs.participants))
        }
    }, [bwicReferenceName, tab, history])

    if (!bwic) {
        return null;
    }

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic} />
                <SubHeader
                    bwic={bwic}
                    disabledTabs={[BiddingTabs.bidding]}
                    actionBarVisible={false}
                />
            </div>
            {tab === BiddingTabs.participants && <Participants />}
        </div>
    )
}

export const AdminBiddingView = memo(AdminBiddingViewComponent)
