import React, { Component } from 'react';
import { Popover } from '../../controls';
import cn from 'classnames';
export default class RejectedLink extends Component {
    render = () => {
        const { rejectReason, rejectedByCompanyCode, className = "" } = this.props;
        const title = rejectedByCompanyCode ? 'Rejected by ' + rejectedByCompanyCode : 'Rejected';

        return (
            <div className={cn('status status-rejected', className)}>
                <Popover
                    title="Reject Reason"
                    className="popover-md"
                    actionButton={<button className="pseudo-link">{title}</button>}
                >
                    <div className="status-message alert">
                        <i className="icon icon-warning"/>
                        <span className="status-message-cnt">{rejectReason}</span>
                    </div>
                </Popover>
            </div>
        );
    }
}
