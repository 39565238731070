import { brokerDealerAccessState } from "../../../constants/bidding";
import { SettlementAgreementStatus } from "../../../types/bid-as-dealer/SettlementAgreementStatus";
import { Bwic } from "../../../types/bwic/Bwic"
import { BwicPosition } from "../../../types/bwic/BwicPosition";
import { BwicProcessType } from "../../../types/models/Process";
import { BrokerDealerAccessState } from "../../../types/state/BrokerDealerBiddingState";
import { useAgreement } from "../../seller-buyside/useAgreement";
import { Table } from "../common/table";
import {
    brokerDealerFinishedColumns,
    brokerDealerFinishedOpenBiddingColumns,
    brokerDealerNotInvitedFinishedColumns,
    brokerDealerNotInvitedFinishedOpenBiddingColumns,
    brokerDealerParsedFinishedColumns
} from "../common/table/columns";

interface FinishedTableProps {
    bwic: Bwic;
    securities: BwicPosition[];
    accessState: BrokerDealerAccessState;
    createSecurityCustomArgs: (security: BwicPosition) => void;
    createSecurityCustomClassName: (security: BwicPosition) => string;
}

export const FinishedTable = ({ bwic, accessState, securities, createSecurityCustomArgs, createSecurityCustomClassName }: FinishedTableProps) => {
    const { agreement } = useAgreement();
    const isAllToAllAgreementSigned = agreement?.agreementStatus === SettlementAgreementStatus.confirmed;

    const { process } = bwic;

    let columns = brokerDealerFinishedColumns(bwic.isAllToAll, isAllToAllAgreementSigned);
    if (process.type === BwicProcessType.Live) {
        if (accessState === brokerDealerAccessState.notInvited) {
            columns = brokerDealerNotInvitedFinishedOpenBiddingColumns;
        } else {
            columns = brokerDealerFinishedOpenBiddingColumns();
        }
    } else if (bwic.isParsed) {
        columns = brokerDealerParsedFinishedColumns;
    } else if (accessState === brokerDealerAccessState.notInvited) {
        columns = brokerDealerNotInvitedFinishedColumns;
    }

    return <Table
        className="data-list-striped data-list-bd-finished"
        dataItems={securities}
        columns={columns}
        createSecurityCustomArgs={createSecurityCustomArgs}
        createSecurityCustomClassName={createSecurityCustomClassName}
    />
}