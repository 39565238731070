import { Component } from 'react';
import { connect } from 'react-redux';
import { TradedSelect } from './TradedSelect';
import { DntSelect } from './DntSelect';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';

class PublicColor extends Component {
    render = () => {
        return (
            <div className="public-color-options">
                <div className="public-color-options-text">Color will be sent to BWIC participants and published on the website.</div>
                <div className="public-color-options-form">
                    <div className="public-color-options-form-row">
                        <b>For Traded:</b>
                        <label htmlFor="forTradedType">Type:</label>
                        <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                            <TradedSelect />
                        </RoleActionBlocker>
                    </div>
                    <div className="public-color-options-form-row">
                        <b>For DNT:</b>
                        <label htmlFor="forDNTType">Type:</label>
                        <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                            <DntSelect />
                        </RoleActionBlocker>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ sellerBidding }) => ({
    colorDistribution: sellerBidding.colorDistribution
});

const connectedPublicColor = connect(mapStateToProps)(PublicColor);
export { connectedPublicColor as PublicColor };
