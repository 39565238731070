import React from 'react';
import { Bid, BidInputProps } from './Bid';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { topXUtils } from '../../../../utils/top-x.utils';

interface Props extends BidInputProps {
    process: Process;
}

export function TopXBid({ position, process, enabled, readonly }: Props) {
    if (process.type !== BwicProcessType.TopX || !process.topX) return null;

    const topXReadonly = process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;

    return <Bid
        enabled={enabled && topXUtils.canBid(process.stagedBiddingStatus, position.isStage2Participant)}
        readonly={readonly || topXReadonly} position={position}
    />
}
