import * as React from 'react';
import { FeedbackMenu } from '../FeedbackMenu';
import { OnHoverTooltip } from "../../../common";
import { constants } from '../../../../constants';
import { CompanySlim } from '../../../../types/company/CompanySlim';
import { BwicProcessType, Process } from '../../../../types/models/Process';

interface Props {
    positionId: number;
    company: CompanySlim;
    feedbackVisible: boolean;
    bidAttributesEnabled: boolean;
    process: Process;
    feedbackDisabled?: boolean
}

export function EmptyBid({ positionId, company, feedbackVisible, bidAttributesEnabled, process, feedbackDisabled = false }: Props) {
    const isFeedbackVisible = () => {
        if (!feedbackVisible) return false;
        if ((process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX || process.type === BwicProcessType.BestFootForward) &&
            process.stagedBiddingStatus != null) {
            return false;
        }

        return true;
    }

    return (
        <div className="bid-list-row flex-row">
            <div className="bid-list-cell bid-list-rank">{constants.emptyPlaceholder}</div>
            <div className="bid-list-cell bid-list-wrap">
                <div className="flex-row">
                    <OnHoverTooltip overlay={company.name}>
                        <div className="bid-list-cell bid-list-dealer">{company.code}</div>
                    </OnHoverTooltip>
                    <div className="bid-list-cell bid-list-bid">{constants.emptyPlaceholder}</div>
                    {
                        bidAttributesEnabled &&
                        <>
                            <div className="bid-list-cell bid-list-axed"></div>
                            <div className="bid-list-cell bid-list-final"></div>
                        </>
                    }
                    <div className="bid-list-cell bid-list-bid-on-behalf margin-l-a"></div>
                    <div className="bid-list-cell bid-list-feedback">
                        {
                            isFeedbackVisible() &&
                            <FeedbackMenu
                                positionId={positionId}
                                emptyBidCompany={company}
                                process={process}
                                disabled={feedbackDisabled}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
