import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { blastMessageActions } from '../../../../actions/blast-message.actions';
import { Bid } from '../../../../types/bidding/Bid';
import { AppState } from '../../../../types/state/AppState';
import { arrayUtils } from '../../../../utils/array.utils';
import { biddingUtils } from '../../../../utils/bidding.utils';
import { isRequesting } from '../../../../utils/request-state.utils';
import { BlastMessageRecipient } from './BlastMessageRecipient';
import { sortBrokerDealerCompanies } from '../../../../selectors/seller-companies.selector';
import { BidderCompanySlim } from '../../../../types/company/BidderCompanySlim';
import { useFavorites } from '../../../../effects/useFavorites';
import { useSettlementAgentAgreements } from '../../../../effects/useSettlementAgentAgreements';
import { Preloader } from '../../../common/Preloader';
import { EmptyPlaceholder } from '../../../common/EmptyPlaceholder';
import { CompanySlim } from '../../../../types/company/CompanySlim';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { BlastMessageRecipientsState } from '../../../../types/state/BlastMessageState';
import { RequestState } from '../../../../constants/request-state';
import { FavoriteCompanyState } from '../../../../types/state/FavoriteBrokerDealersState';
import { SettlementAgentAgreement } from '../../../../types/bid-as-dealer/SettlementAgentAgreement';
import { Checkbox } from '../../../controls/Checkbox';
import { Process } from '../../../../types/models/Process';

interface Props {
    disabled: boolean;
    bwicProcess: Process;
    isAllToAll: boolean;
}

export function BlastMessageRecipients({ disabled, bwicProcess, isAllToAll }: Props) {
    const dispatch = useDispatch();

    const participants = useSelector((s: AppState) => s.bidding.bwic?.companies);
    const securities = useSelector((s: AppState) => s.sellerBidding.securities);
    const isSelectAllRecipients = useSelector((s: AppState) => s.blastMessage.isSelectAllRecipients);
    const recipients = useSelector((s: AppState) => s.blastMessage.recipients);
    const favoritesState = useFavorites(isAllToAll);
    const agreementsState = useSettlementAgentAgreements();

    const handleSelectAllChange = (selectAll: boolean) => {
        dispatch(blastMessageActions.selectAllRecipientsChange(selectAll));
    };

    return <BlastMessageRecipientsContent
        disabled={disabled}
        participants={participants}
        securities={securities}
        isSelectAllRecipients={isSelectAllRecipients}
        recipients={recipients}
        favoritesState={favoritesState}
        agreementsState={agreementsState}
        onSelectAllChange={handleSelectAllChange}
    />;
}

interface ContentProps {
    disabled: boolean;
    participants?: CompanySlim[];
    securities: BwicPosition[];
    isSelectAllRecipients: boolean;
    recipients: BlastMessageRecipientsState;
    favoritesState: { requestState: RequestState, favorites: FavoriteCompanyState },
    agreementsState: { requestState: RequestState, items: SettlementAgentAgreement[] },
    onSelectAllChange: (selectAll: boolean) => void;
}

export function BlastMessageRecipientsContent({
    disabled,
    participants,
    securities,
    recipients,
    isSelectAllRecipients,
    favoritesState,
    agreementsState,
    onSelectAllChange }: ContentProps) {
    if (!participants || !securities.length) return null;

    const recipientsKeys = Object.keys(recipients);
    const hasRecipients = recipientsKeys.length > 0;
    const isLoading = isRequesting(agreementsState.requestState) || isRequesting(favoritesState.requestState);

    const allBids: Bid[] = securities.map(s => s.bids || []).flat();

    const biddingCompanies: BidderCompanySlim[] = arrayUtils.distinct(
        allBids.map(b => ({
            ...b.company,
            identifier: biddingUtils.getBidCompanyIdentifier(b),
            buyerPseudoOrderNumber: b.buyerPseudoOrderNumber
        })),
        (c: { identifier: string }) => c.identifier
    );

    const companies: BidderCompanySlim[] = [
        ...biddingCompanies,
        ...participants
            .map(p => ({ ...p, identifier: biddingUtils.getBidCompanyIdentifier({ company: p }) }))
            .filter(p => !biddingCompanies.some(c => c.identifier === p.identifier))
    ];

    const recipientCompanies = companies.filter(c => recipients[c.identifier] != null);

    return (
        <div
            data-testid="blast-message-settings-recipients"
            className={cn("blast-message-settings-recipients", {"no-recipients": !hasRecipients})}
        >
            {
                hasRecipients &&
                <div className="select-all-checkbox">
                    <Checkbox
                        className="text-bold"
                        partially={hasRecipients &&
                            recipientsKeys.some((key: string) => recipients[key]) &&
                            recipientsKeys.some((key: string) => !recipients[key])
                        }
                        checked={isSelectAllRecipients}
                        onChange={e => onSelectAllChange(e.target.checked)}
                        disabled={disabled}
                        label="All Recipients"
                    />
                </div>
            }
            <div className="blast-message-recipients">
                <Preloader inProgress={isLoading}>
                    {
                        hasRecipients &&
                        sortBrokerDealerCompanies(recipientCompanies, favoritesState.favorites, agreementsState.items).map(company => {
                            const c = company as BidderCompanySlim;

                            return (
                                <BlastMessageRecipient
                                    key={c.identifier}
                                    company={c}
                                    selected={recipients[c.identifier]}
                                    bidCount={allBids
                                        .filter(b => biddingUtils.getBidCompanyIdentifier(b) === c.identifier)
                                        .length
                                    }
                                    disabled={disabled}
                                />
                            );
                        })
                    }
                    {
                        !hasRecipients &&
                        <div className="blast-message-empty">
                            <EmptyPlaceholder textView={true} text="No one has placed bids on the current BWIC yet." />
                        </div>
                    }
                </Preloader>
            </div>
        </div>
    );
}