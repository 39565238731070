import React from 'react'
import { useHistory } from 'react-router';
import { LocationStateBuilder, PopupType } from '../../../../../types/state/ui/LocationState';
import { OnHoverTooltip } from "../../../../common/OnHoverTooltip";
import IconSVG from "../../../../../styles/svg-icons";
import { FeatureButton } from '../../../../access/FeatureButton';
import { RoleActionBlocker } from '../../../../access/RoleActionBlocker';
import { roles } from '../../../../../constants/roles';

interface Props {
    disabled?: boolean;
    disabledTooltip: React.ReactNode;
}

export function ShowBidOnBehalfPopupButton({ disabled = false, disabledTooltip }: Props) {
    const history = useHistory();

    return (
        <RoleActionBlocker roles={[roles.SellerTrader]}>
            {blocked =>
                <OnHoverTooltip disabled={!disabled || blocked} overlay={disabledTooltip}>
                    <FeatureButton
                        className="btn-link"
                        disabled={disabled}
                        onClick={() =>
                            history.replace({
                                ...history.location,
                                state: new LocationStateBuilder()
                                    .popup(PopupType.SaveBidOnBehalf)
                                    .result()
                            })}
                    >
                        <IconSVG name="upload" width={16} height={16} />
                        Upload Bids
                    </FeatureButton>
                </OnHoverTooltip>}
        </RoleActionBlocker>
    );
}
