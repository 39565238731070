import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../..';
import { Bwic } from '../../../../../../types/bwic/Bwic';
import { BwicStatus } from '../../../../../../types/enums/BwicStatus';
import { LiveBiddingBwicPosition } from '../../../../../../types/live-bidding/LiveBiddingBwicPosition';
import { biddingUtils } from '../../../../../../utils';
import { LiveBiddingStage2Level } from '../../../feedback/LiveBiddingStage2Level';

export const liveBiddingStage2Level = {
    columnName: 'open-bidding-feedback',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xl-flexible cell-open-bidding-feedback">
                Feedback
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position: LiveBiddingBwicPosition, context: { bwic: Bwic }) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xl-flexible cell-open-bidding-feedback">
                <LiveBiddingStage2Level
                    stagedBiddingStatus={context.bwic.process.stagedBiddingStatus}
                    levelSpecificationType={context.bwic.process.liveBidding!.levelSpecificationType}
                    currentBid={biddingUtils.getCurrentBid(position.bids)}
                    stage2Level={position.openBiddingStage2Level}
                    tiedToBest={position.tiedForBest}
                    animated={context.bwic.status === BwicStatus.bidding}
                />
            </SecurityListColumn>
        );
    }
};
