import React from 'react';
import moment from 'moment';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { constants, SORTING_TYPE } from '../../../../../../constants';


export const settleDate = {
    columnName: 'settleDate',
    renderHeader: function (sortBy, sortDirection, onClick) {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-sm"
                sorted={sortBy === this.columnName}
                sortDirection={sortDirection}
                onClick={onClick ? () => onClick(this.columnName, SORTING_TYPE.date) : null}
            >
                Settle Date
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm">
                {moment(position.settleDate).format(constants.dateShortFormat)}
            </SecurityListColumn>
        );
    }
};
