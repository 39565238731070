import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { user } from '../../user';
import { BwicTypeLabel, OnHoverTooltip, Preloader } from '../common';
import {
    blotterInit,
    blotterReset,
    blotterSearchRequest,
    blotterSearchRequestNextPage,
    blotterToggleBooked,
    blotterToggleVcon,
    blotterOrderByChange
} from '../../actions';
import { controlPanelActions, searchSecuritiesActions } from '../../actions';
import { dateTimeUtils, formatUtils, isRequesting, isRequestSuccess, moneyUtils } from '../../utils';
import { Table } from '../bidding/common/table';
import { constants, roles, SORTING_TYPE } from '../../constants';
import { CompanyDetailsButton } from '../company-details/CompanyDetailsButton';
import { AffirmTradeButtons } from '../bidding/brokerDealer/trade-affirm';
import { BlotterEmptyListPlaceholder } from './BlotterEmptyListPlaceholder';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { CompanyDetailsPanel } from '../company-details/CompanyDetailsPanel';
import { ListBuilder } from '../../utils/ListBuilder';
import { TradeType, tradeTypeTitle } from '../../types/trades/TradeType';
import { TradeSide } from '../../types/trades/TradeSide';
import { TradeStatusLabel } from '../trade/TradeStatusLabel';
import { TradeBookedCheckbox } from './TradeBookedCheckbox';
import { VconCheckbox } from './VconCheckbox';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';
import { TableColumnStickType } from '../bidding/common/table/types/TableColumnStickType';
import { SettlementStatusLabel } from '../settlement/SettlementStatusLabel';
import { SettlementStatusLabelCombined } from '../settlement/SettlementStatusLabelCombined';
import { StickyTableRow } from '../bidding/common/table/sticky/StickyTableRow';
import { StickyTableCollapsibleRow } from '../bidding/common/table/sticky/StickyTableCollapsibleRow';
import { TradeAllocationTableRows } from './TradeAllocationTableRows';
import { useSettlementAccounts } from '../../effects/data-accessors/useSettlementAccounts';
import { TradeAllocationPopup } from './settlement-account/TradeAllocationPopup';
import { TradeAllocationButton } from './settlement-account/TradeAllocationButton';
import { EmailLink } from '../bidding/common/EmailLink';
import { BidOnBehalfIcon } from '../bidding/seller/bid-on-behalf/BidOnBehalfIcon';
import { BlotterTradesFilter } from './BlotterTradesFilter';
import { BwicViewLink } from '../common/BwicViewLink';

export function BlotterTrades() {
    const dispatch = useDispatch();
    const currentUser = user.current();

    const isBrokerDealer = user.hasRoles(...roles.bd());
    const isSeller = user.hasRoles(...roles.seller());

    const isSellFilterApplied = useSelector(state => state.blotter.prevFilters.sellSide);
    const isBuyFilterApplied = useSelector(state => state.blotter.prevFilters.buySide);
    const securities = useSelector(state => state.blotter.securities);
    const requestStateSecurities = useSelector(state => state.blotter.requestStateSecurities);
    const ownTrades = useSelector(state => state.blotter.ownTrades);
    const page = useSelector(state => state.blotter.page);
    const pageSize = useSelector(state => state.blotter.pageSize);
    const totalRecordNumber = useSelector(state => state.blotter.totalRecordNumber);
    const orderByColumn = useSelector(state => state.blotter.orderByColumn);
    const orderByDirection = useSelector(state => state.blotter.orderByDirection);

    const companyDetailsRowKey = useSelector(state => state.companyDetails.rowKey);
    const { settlementAccounts, settlementAccountsRequestState } = useSettlementAccounts(false);

    useEffect(() => {
        dispatch(blotterInit());
        dispatch(blotterSearchRequest());

        return () => {
            dispatch(searchSecuritiesActions.reset());
            dispatch(controlPanelActions.hide());
            dispatch(blotterReset());
        };
    }, [dispatch]);

    const handleSortChange = field => {
        dispatch(blotterOrderByChange(field));
        dispatch(blotterSearchRequest());
    }

    const handleNextPage = () => {
        if (!isRequesting(requestStateSecurities)) {
            dispatch(blotterSearchRequestNextPage(page + 1));
        }
    }

    const getTradeRowHighlightedClassName = trade => {
        const active = getRowKey(trade) === companyDetailsRowKey;
        const pending = isAwaitingConfirmation(trade) || isAwaitingAllocation(trade);

        return cn({ active, 'pending-trade': pending });
    }

    const isAwaitingConfirmation = trade => {
        if (trade.status === TradeStatus.pending) {
            if (isDirectTrade(trade)) {
                return !isSettlementAgent(trade) && !isDirectTradeCreator(trade);
            }

            return isBrokerDealer;
        }
    }

    const isAwaitingAllocation = trade => {
        if (isDirectTrade(trade) && !trade.isAllToAll) { // Skip for All-to-All
            const statuses = [];
            if (isSeller) {
                trade.directBuyerCompany == null
                    ? statuses.push(trade.sellerSettlementStatus)
                    : statuses.push(trade.buyerSettlementStatus)
            } else {
                statuses.push(trade.buyerSettlementStatus);
                statuses.push(trade.sellerSettlementStatus);
            }

            return statuses.some(s => s === TradeSettlementStatus.PendingAllocation || s === TradeSettlementStatus.PendingProceeds);
        }

        return false;
    }

    const getRowKey = trade => trade.side ? `${trade.tradeId}-${trade.side}` : trade.tradeId;

    const getColumns = isDirectTradeDetails => {
        const tradeTypeIcon = {
            columnKey: 'tradeTypeIcon',
            renderColumnHeaderContent: () => '',
            renderColumnContent: trade => !isDirectTradeDetails &&
                <>
                    {!!trade.onBehalf && <BidOnBehalfIcon />}
                    <BwicTypeLabel
                        isParsed={false}
                        liveBidding={false}
                        isAllToAll={trade.isAllToAll}
                        directBidding={isDirectTrade(trade)}
                    />
                </>,
            className: 'data-list-cell-xs-sm'
        };
        const tradeDate = {
            columnKey: 'tradeDate',
            renderColumnHeaderContent: () => 'Trade Date',
            renderColumnContent: trade => isDirectTradeDetails
                ? isTradeAllocationExist(getTradeDetailsSettlementStatus(trade)) && !trade.isAllToAll && 'Allocation'
                : dateTimeUtils.utcToLocalString(trade.tradeDate, constants.dateTimeFormatShort),
            className: 'data-list-cell-lg',
            sortingField: 'tradeDate',
            sortingType: SORTING_TYPE.date
        };
        const settlementDate = {
            columnKey: 'settle-date',
            renderColumnContent: trade => !isDirectTradeDetails && trade.settlementDate
                ? moment(trade.settlementDate).format(constants.dateShortFormat)
                : '',
            renderColumnHeaderContent: () => 'Settl. Date',
            className: 'data-list-cell-sm',
            sortingField: 'settlementDate',
            sortingType: SORTING_TYPE.date
        };
        const settleType = {
            columnKey: 'settleType',
            renderColumnContent: trade => renderSettlementType(trade, isDirectTradeDetails),
            renderColumnHeaderContent: () => '',
            headerClassName: 'data-list-cell-xxs text-right text-medium',
            bodyClassName: 'data-list-cell-xxs text-right text-medium'
        };
        const settleStatus = {
            columnKey: 'settle-status',
            renderColumnContent: trade => renderSettlementStatus(trade, isDirectTradeDetails),
            renderColumnHeaderContent: () => 'Settl. Status',
            className: 'data-list-cell-lg'
        };
        const tradeStatus = {
            columnKey: 'trade-status',
            renderColumnContent: trade => isDirectTradeDetails
                ? ''
                : <TradeStatusLabel
                    status={trade.status}
                    rejectReason={trade.rejectReason}
                />,
            renderColumnHeaderContent: () => 'Trade Status',
            className: 'data-list-cell-sm',
            sortingField: 'tradeStatus',
            sortingType: SORTING_TYPE.number
        };
        const isinCusip = {
            columnKey: 'isinCusip',
            renderColumnContent: trade => isDirectTradeDetails ? '' : trade.isinCusip,
            renderColumnHeaderContent: () => 'ISIN/CUSIP',
            className: 'data-list-cell-sm'
        };
        const ticker = {
            columnKey: 'ticker',
            renderColumnContent: trade => isDirectTradeDetails ? '' : trade.ticker,
            renderColumnHeaderContent: () => 'Ticker',
            className: 'data-list-cell-lg'
        };
        const bwic = {
            columnKey: 'bwic',
            renderColumnContent: trade => renderViewBwicLink(trade, isDirectTradeDetails),
            renderColumnHeaderContent: () => 'BWIC',
            className: 'data-list-cell-xs'
        }
        const rating = {
            columnKey: 'rating',
            renderColumnContent: trade => isDirectTradeDetails ? '' : trade.rating,
            renderColumnHeaderContent: () => 'Rtg',
            className: 'data-list-cell-xs'
        };
        const side = {
            columnKey: 'side',
            renderColumnContent: trade => renderSide(trade, isDirectTradeDetails),
            renderColumnHeaderContent: () => 'Side',
            className: 'data-list-cell-xs'
        };
        const counterparty = {
            columnKey: 'counterparty',
            renderColumnContent: trade => isSettlementAgent(trade) && !isDirectTradeDetails
                ? constants.emptyPlaceholder
                : <CompanyDetailsButton
                    title={trade.counterparty.code}
                    isBrokerDealer={isBrokerDealer}
                    company={trade.counterparty}
                    rowKey={getRowKey(trade)}
                    onClick={() => dispatch(controlPanelActions.hide())}
                />,
            renderColumnHeaderContent: () => 'Counterparty',
            className: 'data-list-cell-sm'
        };
        const traderEmail = {
            columnKey: 'traderEmail',
            renderColumnContent: trade => renderTradeEmail(trade, isDirectTradeDetails),
            renderColumnHeaderContent: () => 'Trader Email',
            className: 'data-list-cell-lg-02'
        };

        const ccy = {
            columnKey: 'ccy',
            renderColumnContent: trade => trade.currency,
            renderColumnHeaderContent: () => 'Ccy',
            className: 'data-list-cell-xs'
        };
        const size = {
            columnKey: 'size',
            renderColumnContent: trade =>
                <OnHoverTooltip overlay={moneyUtils.money(trade.size)} placement="bottomRight">
                    <div className="text-ellipsis">{moneyUtils.money(trade.size)}</div>
                </OnHoverTooltip>,
            renderColumnHeaderContent: () => 'Size',
            className: 'data-list-cell-sm text-right',
            sortingField: 'size',
            sortingType: SORTING_TYPE.number
        };
        const price = {
            columnKey: 'price',
            renderColumnContent: trade => formatUtils.formatBid(trade.price),
            renderColumnHeaderContent: () => 'Price',
            className: 'text-right',
            sortingField: 'price',
            sortingType: SORTING_TYPE.number
        };
        const commission = {
            columnKey: 'commission',
            renderColumnContent: trade => renderCommission(isDirectTradeDetails, trade),
            renderColumnHeaderContent: () => 'Comm, %',
            className: 'text-right',
            sortingField: 'commission',
            sortingType: SORTING_TYPE.number
        };
        const totalPrice = {
            columnKey: 'totalPrice',
            renderColumnContent: (trade) => renderTotalPrice(isDirectTradeDetails, trade),
            renderColumnHeaderContent: () => 'Total Price',
            className: 'text-right'
        };
        const vcon = {
            columnKey: 'vcon',
            renderColumnContent: (trade) => isDirectTradeDetails && trade.status !== TradeStatus.rejected ? (
                <VconCheckbox
                    tradeId={trade.tradeId}
                    vconBuy={trade.vconBuy}
                    vconSell={trade.vconSell}
                    tradeSide={trade.side}
                    onClick={(tradeId, tradeSide, value) => dispatch(blotterToggleVcon(tradeId, tradeSide, value))}
                />
            ) : <>{constants.emptyPlaceholder}</>,
            renderColumnHeaderContent: () => 'VCON',
        };
        const booked = {
            columnKey: 'booked',
            renderColumnContent: (trade) => isDirectTradeDetails && trade.status !== TradeStatus.rejected ? (
                <TradeBookedCheckbox
                    tradeId={trade.tradeId}
                    bookedBuy={trade.bookedBuy}
                    bookedSell={trade.bookedSell}
                    tradeSide={trade.side}
                    onClick={(tradeId, tradeSide, value) =>
                        dispatch(blotterToggleBooked(tradeId, tradeSide, value))
                    }
                />
            ) : <>{constants.emptyPlaceholder}</>,
            renderColumnHeaderContent: () => 'Trade Booked',
            className: 'data-list-cell-sm'
        };
        const type = {
            columnKey: 'type',
            renderColumnContent: trade => isDirectTradeDetails ? '' : tradeTypeTitle[trade.type],
            renderColumnHeaderContent: () => 'Type',
            className: 'data-list-cell-xs',
            sortingField: 'tradeType',
            sortingType: SORTING_TYPE.number
        };
        const comment = {
            columnKey: 'comment',
            renderColumnContent: trade =>
                trade.comment && (!isDirectTradeDetails || trade.side === TradeSide.Buy)
                    ? <OnHoverTooltip wrapperClass="comment" overlay={trade.comment}>
                        <div className="text-ellipsis">{trade.comment}</div>
                    </OnHoverTooltip>
                    : constants.emptyPlaceholder,
            renderColumnHeaderContent: () => 'Comments',
            className: 'data-list-comment'
        };
        const emptyColumn = {
            columnKey: 'emptyColumn',
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-icon',
            stickRight: true
        };
        const actions = {
            columnKey: 'actions',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: trade =>
                <>
                    {
                        trade.status === TradeStatus.affirmed &&
                        isDirectTrade(trade) &&
                        !isDirectTradeDetails &&
                        !trade.isAllToAll &&
                        <TradeAllocationButton trade={trade} settlementAccounts={settlementAccounts} />
                    }
                    {renderPendingTradeButtons(trade)}
                </>,
            headerClassName: 'data-list-options data-list-cell-md flex-item-right',
            bodyClassName: 'data-list-options data-list-cell-md flex-row justify-content-end padding-l-0',
            stickRight: true
        };

        const columns = new ListBuilder()
            .add(tradeTypeIcon)
            .add(tradeDate)
            .add(tradeStatus)
            .addWhen(() => isBrokerDealer, type)
            .add(settlementDate, settleType, settleStatus, isinCusip, ticker, bwic,
                rating, side, counterparty, traderEmail, ccy, size, price, commission, totalPrice)
            .addWhen(() => isBrokerDealer && currentUser.isClearingBank, vcon)
            .addWhen(() => isBrokerDealer && currentUser.isClearingBank, booked)
            .add(comment, emptyColumn, actions)
            .result();
        return columns.map(c => new ColumnBuilder(c));
    };

    const isDirectTrade = trade => trade.type === TradeType.Client || trade.type === TradeType.Empty
    const isDirectTradeCreator = trade => isDirectTrade(trade) && trade.directBuyerCompany == null

    const isSettlementAgent = trade =>
        isBrokerDealer &&
        currentUser.isClearingBank &&
        isDirectTrade(trade) &&
        trade.counterparty &&
        trade.directBuyerCompany &&
        trade.counterparty.id !== currentUser.companyId &&
        trade.directBuyerCompany.id !== currentUser.companyId

    const isTradeAllocationExist = settlementStatus =>
        settlementStatus === TradeSettlementStatus.PendingSettlement ||
        settlementStatus === TradeSettlementStatus.PendingProceeds ||
        settlementStatus === TradeSettlementStatus.Settled;

    const getTradeDetailsSettlementStatus = trade =>
        trade.side === TradeSide.Sell ? trade.buyerSettlementStatus : trade.sellerSettlementStatus

    const getSellerSettlementStatus = trade => trade.directBuyerCompany == null ? trade.sellerSettlementStatus : trade.buyerSettlementStatus

    const renderSettlementType = (trade, isDirectTradeDetails) => {
        if (isDirectTrade(trade) && !trade.isAllToAll) { // Skip for All-to-All
            let isManual;

            if (isBrokerDealer && isDirectTradeDetails && isTradeAllocationExist(getTradeDetailsSettlementStatus(trade))) {
                isManual = trade.side === TradeSide.Sell ? trade.isBuyerAllocationManual : trade.isSellerAllocationManual;
            } else if (isSeller && isTradeAllocationExist(getSellerSettlementStatus(trade))) {
                isManual = isDirectTradeCreator(trade) ? trade.isSellerAllocationManual : trade.isBuyerAllocationManual;
            }

            if (isManual != null) {
                return isManual
                    ? <OnHoverTooltip overlay="Manual Allocation">M</OnHoverTooltip>
                    : <OnHoverTooltip overlay="Automated Allocation">A</OnHoverTooltip>
            }
        }

        return '';
    }

    const renderSettlementStatus = (trade, isDirectTradeDetails) => {
        if (isDirectTrade(trade)) {
            if (isDirectTradeDetails) {
                return <SettlementStatusLabel status={getTradeDetailsSettlementStatus(trade)} />;
            }
            if (isBrokerDealer) {
                return <SettlementStatusLabelCombined buyerStatus={trade.buyerSettlementStatus} sellerStatus={trade.sellerSettlementStatus} />
            }
            if (isSeller) {
                return <SettlementStatusLabel status={getSellerSettlementStatus(trade)} />;
            }
        }

        return <SettlementStatusLabel status={trade.sellerSettlementStatus} />
    }

    const renderViewBwicLink = (trade, isDirectTradeDetails) => {
        if (isDirectTradeDetails) return null;
        if (isDirectTrade(trade) && isSeller && !isDirectTradeCreator(trade)) return constants.emptyPlaceholder; // Seller - buyer

        const isMy = isDirectTrade(trade) ? isDirectTradeCreator(trade) : isSeller;

        return <BwicViewLink
            text="View"
            referenceName={trade.bwicReferenceName}
            isDirectBidding={trade.isClearingBankParticipant}
            isAllToAll={trade.isAllToAll}
            isMy={isMy}
            isParticipant={!isMy}
            isParsed={false}
        />
    };

    const renderTotalPrice = (isDirectTradeDetails, trade) => {
        if (trade.commission && (!isDirectTradeDetails || trade.side === TradeSide.Sell)) {
            return formatUtils.formatBid(trade.price + trade.commission);
        }
        return formatUtils.formatBid(trade.price);
    };

    const renderCommission = (isDirectTradeDetails, trade) => {
        if (isDirectTrade(trade) && trade.commission) {

            if (isDirectTradeCreator(trade)) return constants.emptyPlaceholder;

            const commission = formatUtils.formatDecimal(trade.commission, 4);

            if (isSettlementAgent(trade)) {
                if (isDirectTradeDetails && trade.side === TradeSide.Sell) return commission;
            } else if (!isDirectTradeDetails) {
                return commission;
            }
        }

        return constants.emptyPlaceholder;
    }

    const renderSide = (trade, isDirectTradeDetails) => {
        if (isDirectTrade(trade)) {
            if (isDirectTradeCreator(trade)) return 'Sell';
            if (isSettlementAgent(trade)) {
                if (!isDirectTradeDetails) return constants.emptyPlaceholder; // Settlement Agent summary row
                if (trade.side === TradeSide.Sell) return 'Sell'; // Settlement Agent 'sell' row
            }
            return 'Buy';
        }

        return isSeller ? 'Sell' : 'Buy' // Not direct trade
    };

    const renderTradeEmail = (trade, isDirectTradeDetails) => {
        const getEmail = () => {
            if (isDirectTrade(trade)) {
                if (isDirectTradeCreator(trade)) return trade.createdBy;
                if (isSettlementAgent(trade)) {
                    if (isDirectTradeDetails) {
                        return trade.side === TradeSide.Buy ? trade.createdBy : trade.affirmedRejectedBy;
                    }
                    return '';
                }
                return trade.affirmedRejectedBy;
            } else {
                return isSeller ? trade.createdBy : trade.affirmedRejectedBy; // Not direct trade
            }
        }

        const email = getEmail();
        return email ? <OnHoverTooltip overlay={email}><EmailLink email={email} /></OnHoverTooltip> : constants.emptyPlaceholder;
    }

    const renderPendingTradeButtons = trade => {
        if (isAwaitingConfirmation(trade)) {
            return (
                <div className="affirm-trade-buttons">
                    <AffirmTradeButtons
                        buyerPseudoOrderNumber={trade.buyerPseudoOrderNumber}
                        positionId={trade.positionId}
                        ticker={trade.ticker}
                        tradeReferenceId={trade.tradeId}
                        iconsView={true}
                    />
                </div>
            );
        }
        return null;
    };

    const renderTradeAllocationRows = (trade, columnType) =>
        <TradeAllocationTableRows
            tradeId={trade.tradeId}
            columnType={columnType}
            collapseLevel={1}
            settlementAccounts={settlementAccounts}
            side={trade.directBuyerCompany == null ? TradeSide.Buy : TradeSide.Sell}
        />

    const renderDirectTradeRows = (trade, columnType) => {
        const dataItems = [];
        const isSideFilterUnapplied = !isBuyFilterApplied && !isSellFilterApplied;

        if (isBuyFilterApplied || isSideFilterUnapplied) {
            dataItems.push({ ...trade, side: TradeSide.Buy });
        }
        if (isSellFilterApplied || isSideFilterUnapplied) {
            dataItems.push({ ...trade, side: TradeSide.Sell, counterparty: trade.directBuyerCompany });
        }

        let columns = columnType === TableColumnStickType.Middle
            ? getColumns(true).filter(c => !c.column.stickLeft && !c.column.stickRight)
            : getColumns(true).filter(c => c.column.stickRight);

        return dataItems.map(i => {
            const key = `${i.tradeId}-${i.side}`;

            if (trade.status === TradeStatus.affirmed &&
                !trade.isAllToAll &&
                isDirectTrade(trade) &&
                isTradeAllocationExist(getTradeDetailsSettlementStatus(i))) {
                return (
                    <StickyTableCollapsibleRow
                        key={key}
                        rowKey={key}
                        columns={columns}
                        dataItem={i}
                        className=""
                        context={{}}
                        collapseIconVisible={columnType === TableColumnStickType.Middle}
                        renderCollapsibleContent={trade =>
                            <TradeAllocationTableRows
                                tradeId={trade.tradeId}
                                columnType={columnType}
                                settlementAccounts={settlementAccounts}
                                collapseLevel={2}
                                side={i.side}
                            />
                        }
                    />
                );
            }

            return (
                <StickyTableRow
                    key={key}
                    rowKey={key}
                    columns={columns}
                    dataItem={i}
                    context={{}}
                    className={getTradeRowHighlightedClassName(i)}
                />
            );
        });
    }

    const shouldRenderCollapsibleRow = trade =>
        isDirectTrade(trade) && (
            isSettlementAgent(trade) || (
                !trade.isAllToAll &&
                isTradeAllocationExist(getSellerSettlementStatus(trade))
            )
        )

    const containerClasses = {
        'blotter-container flex-row flex-row-full-size': true,
        'blotter-container-sl': !isBrokerDealer,
        'blotter-container-bd': isBrokerDealer && !currentUser.isClearingBank,
        'blotter-container-cb': isBrokerDealer && currentUser.isClearingBank
    }

    return (
        <>
            <div className="sub-header-row type02 flex-row filters-row">
                <BlotterTradesFilter trades={securities} />
            </div>
            <div className={cn(containerClasses)}>
                <Preloader
                    inProgress={
                        totalRecordNumber === 0 && (
                            isRequesting(requestStateSecurities) || isRequesting(settlementAccountsRequestState)
                        )
                    }
                >
                    {
                        !!securities.length &&
                        <>
                            <Table
                                columns={getColumns(false)}
                                dataItems={securities}
                                collapsible={true}
                                sticky={true}
                                defaultSortBy={orderByColumn}
                                defaultSortByDirection={orderByDirection}
                                onNextPageRequest={handleNextPage}
                                infiniteScrollEnabled={totalRecordNumber > (page * pageSize)}
                                createRowCustomKey={trade => trade.tradeId}
                                createSecurityCustomClassName={getTradeRowHighlightedClassName}
                                shouldRenderCollapsibleRowCallback={shouldRenderCollapsibleRow}
                                renderCollapsibleItem={(trade, columnType) => isSettlementAgent(trade)
                                    ? renderDirectTradeRows(trade, columnType)
                                    : renderTradeAllocationRows(trade, columnType)
                                }
                                onSort={handleSortChange}
                            />
                            <CompanyDetailsPanel />
                            <TradeAllocationPopup settlementAccounts={settlementAccounts} />
                        </>
                    }
                    {
                        !securities.length && isRequestSuccess(ownTrades.requestState) &&
                        <BlotterEmptyListPlaceholder hasTrades={ownTrades.hasTrades} />
                    }
                </Preloader>
            </div>
        </>
    );
}
