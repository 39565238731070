import React from 'react';
import { Bid, BidInputProps } from './Bid';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { biddingUtils } from '../../../../utils';
import { jumpBallUtils } from '../../../../utils/jump-ball.utils';

interface Props extends BidInputProps {
    process: Process;
}

export function JumpBallBid({ position, process, enabled, readonly }: Props) {
    if (process.type !== BwicProcessType.JumpBall || !process.jumpBall) return null;

    const currentBid = biddingUtils.getCurrentBid(position.bids);

    const jumpBallCanBid = () => jumpBallUtils.canBid(
        process.stagedBiddingStatus,
        currentBid,
        position.isStage2Participant
    );
    const jumpBallReadonly = process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;

    return <Bid enabled={enabled && jumpBallCanBid()} readonly={readonly || jumpBallReadonly} position={position} />
}
