import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { PositionTrade } from '../../../../../../types/trades/PositionTrade';
import { OnHoverTooltip } from '../../../../../common/OnHoverTooltip';
import { formatUtils } from '../../../../../../utils/format.utils';

export const tradeTotalPrice = {
    columnName: 'tradeTotalPrice',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="text-right">
                Total Price
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (positionTrade: PositionTrade) {
        const totalPrice = formatUtils.formatBid(positionTrade.trade.price + (positionTrade.trade.commission ?? 0));

        return (
            <SecurityListColumn key={this.columnName} className="text-right">
                <OnHoverTooltip overlay={totalPrice}>
                    <span className="text-ellipsis">{totalPrice}</span>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
