import React from 'react';
import { useDispatch } from 'react-redux';
import {
    SecurityListHeaderColumn,
    SecurityListColumn, Timer
} from '../../../common';
import { BwicStatusLabel } from "../../../../common";
import { BwicStatus } from "../../../../../types/enums/BwicStatus";
import { isActiveTrade } from '../../../../../types/trades/TradeStatus';
import { biddingActions, bidPlacementActions } from '../../../../../actions';
import { biddingUtils } from '../../../../../utils';


const TimerContent = ({ position, expiresAtUtc }) => {
    const dispatch = useDispatch();

    const handleExpired = () => {
        dispatch(bidPlacementActions.revert(position.id));
        dispatch(biddingActions.liveBiddingStage2TimeoutEnd());
    };

    return <BwicStatusLabel
        status={BwicStatus.bidding}
        bwicTypeIconVisible={false}
        tooltip="Extra time for bidding."
        render={
            <div className="label-status-timer">
                <Timer goodUntilUtc={expiresAtUtc} onExpired={handleExpired} />
            </div>
        }
    />
};

export const liveBiddingStage2Timer = {
    columnName: 'timer',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} />
        );
    },
    renderBody: function (position, context) {
        const currentBid = biddingUtils.getCurrentBid(position.bids);

        const timerVisible =
            currentBid?.pass === false && // non pass current bid
            !position.isTradedAway &&
            !isActiveTrade(position.trade) &&
            !context.liveBiddingStage2Expired &&
            context.enabled;

        return (
            <SecurityListColumn key={this.columnName}>
                {Boolean(timerVisible) &&
                    <TimerContent
                        position={position}
                        expiresAtUtc={context.liveBiddingStage2SubmissionDeadline}
                    />
                }
            </SecurityListColumn>
        );
    }
};
