import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import IconSVG from '../../../../styles/svg-icons';
import { blastMessageActions, controlPanelActions } from '../../../../actions';
import { AppState } from '../../../../types/state/AppState';
import { FeatureButton } from '../../../access/FeatureButton';
import { OnHoverTooltip } from '../../../common';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    disabled?: boolean;
    disabledTooltip?: React.ReactNode;
}

export function BlastMessageButton({ disabled = false, disabledTooltip }: Props) {
    const dispatch = useAppDispatch();
    const panelVisible = useSelector((s: AppState) => s.blastMessage.panelVisible);

    React.useEffect(() => {
        if (disabled && panelVisible) {
            dispatch(blastMessageActions.hide());
        }
    },
        [disabled, panelVisible, dispatch]);

    const handleClick = () => {
        if (!panelVisible) {
            dispatch(controlPanelActions.hide());
            dispatch(blastMessageActions.show());
        } else {
            dispatch(blastMessageActions.hide());
        }
    }

    return (
        <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
            {
                (blocked: boolean) =>
                    <OnHoverTooltip
                        disabled={!disabled || blocked}
                        overlay={disabledTooltip}
                    >
                        <FeatureButton
                            disabled={disabled}
                            className={classNames('btn-link btn-blast-message', { 'btn-link-active': panelVisible })}
                            onClick={handleClick}
                        >
                            <IconSVG name="blast-message" width={16} height={16} />Blast Message
                        </FeatureButton>
                    </OnHoverTooltip>
            }
        </RoleActionBlocker>
    );
}
