import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { StatusMessageSection } from '../../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../../types/state/NotificationState';
import { BlastMessageTypes } from '../../../../types/state/BlastMessageState';

interface Props {
    bwicProcess: Process
    isAllToAll?: boolean
}

export function BlastMessageStatusMessage({ bwicProcess, isAllToAll }: Props) {
    const messageType = useSelector((s: AppState) => s.blastMessage.messageType);

    const blastMessageStatusMessage = isAllToAll
        ? "Message is sent to all bidders with bids."
        : messageType === BlastMessageTypes.bidOrPass
            ? "Messages are sent to participants (Broker-Dealers) who were invited to the BWIC."
            : "Messages are sent to participants (Broker-Dealers and Buy-Side) who were invited to the BWIC."

    if (bwicProcess.type !== BwicProcessType.JumpBall &&
        bwicProcess.type !== BwicProcessType.TopX) {
        return null
    }
    return (
            <StatusMessageSection type={StatusMessageSectionType.Info}>
                {blastMessageStatusMessage}
            </StatusMessageSection>
    );
}
