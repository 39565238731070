import React, { useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { BidOnBehalfParseResultItem, BulkSaveBidOnBehalfDataItem } from '../../../../../types/state/BidOnBehalfState';
import { GridColumn, GridDataItem, GridUploadStatus } from '../../../../../types/state/GridState';
import { isActiveTrade } from '../../../../../types/trades/TradeStatus';
import { apiUtils, numericUtils } from '../../../../../utils';
import { CompanySlim } from '../../../../../types/company/CompanySlim';
import { gridActions } from '../../../../../actions';
import { BwicPosition } from '../../../../../types/bwic/BwicPosition';
import { Grid } from '../../../../grid/Grid';
import { DeleteRowsWithErrorButton } from '../../../../grid/DeleteRowsWithErrorButton';
import { bidOnBehalfParseActions } from '../../../../../actions/bid-on-behalf-parse.actions';
import { Popup, PopupBody, PopupFooter } from '../../../../controls';
import { DownloadTemplateButton } from '../../../../common/download-template/DownloadTemplateButton';
import { SelectBdDropDown } from './SelectBdDropDown';
import { Bwic } from '../../../../../types/bwic/Bwic';
import { LocationState, PopupType, LocationStateBuilder } from '../../../../../types/state/ui/LocationState';
import { createCompanyBobColumn } from '../../../../../constants/grid.columns';
import { ParseErrors } from './ParseErrors';
import { entries } from 'lodash';
import { Confirm } from '../../../../alerts';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';

const selectBdKey = 'selectBd';

const hasCompanyBids = (dataItem: GridDataItem<BidOnBehalfParseResultItem>) =>
    entries(dataItem).some(([key, value]) => key !== selectBdKey && numericUtils.isNumber(value));

interface Props {
    bwic: Bwic;
    positions: BwicPosition[];
}
export function SaveBidOnBehalfPopup({ bwic, positions }: Props) {
    const locationState = useLocation<LocationState>().state;
    const offPlatformCompanies = useAppSelector(s => s.bidOnBehalf.companies);

    if (
        locationState?.popup?.type !== PopupType.SaveBidOnBehalf ||
        !positions?.length ||
        !offPlatformCompanies?.length ||
        !bwic) return null;

    return (
        <SaveBidOnBehalfPopupContent
            positions={positions}
            companies={offPlatformCompanies}
            bwicReferenceName={bwic.referenceName}
            bwicName={bwic.name}
        />
    );
}

interface ContentProps {
    bwicName: string;
    bwicReferenceName: string;
    positions: BwicPosition[];
    companies: CompanySlim[];
}

function SaveBidOnBehalfPopupContent({ positions, bwicName, bwicReferenceName }: ContentProps) {
    const [isCancelCofrimationShow, setIsCancelCofrimationShow] = useState(false);
    const dispatch = useAppDispatch();
    const selectCompanyShortCodesErrorText = 'For saving bids, please select BD shortcode in table header';

    const isGridUploading = useAppSelector(s => s.grid.upload.status === GridUploadStatus.Uploading);
    const tickers = useMemo(
        () => apiUtils.normalize(
            positions,
            p => p.ticker.trim().toUpperCase(),
            p => p.isTradedAway || p.trade != null),
        [positions]
    );

    useTradedPositionsReset(positions);

    React.useEffect(() => {
        const dataItems = positions.map(p => ({
            ticker: p.ticker,
            readonly: p.isTradedAway || isActiveTrade(p.trade)
        }))

        const ticker: GridColumn = {
            name: 'ticker',
            title: 'Ticker',
            required: true,
            length: 32,
            unique: true,
            initialValue: '',
            disabledSort: true,
            readonly: true,
            validate: ticker => tickers[ticker?.trim().toUpperCase() ?? ''] == null ? 'Ticker is not found' : ''
        };
        const selectBd: GridColumn = {
            ...createCompanyBobColumn(selectBdKey),
            title: '',
            canRemove: false,
            renderTitle: () => <SelectBdDropDown />,
            validateHeader: (nonDraftDataItems: GridDataItem<BulkSaveBidOnBehalfDataItem>[]) =>
                nonDraftDataItems.some((item: any) => item.selectBd) ? selectCompanyShortCodesErrorText : undefined,
        } as GridColumn;

        dispatch(gridActions.init(dataItems, [ticker, selectBd], 200, 20));
        dispatch(gridActions.setInitialPosition(0, 1)); // [first row, 'Select BD' column]

        return () => {
            dispatch(gridActions.reset());
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const handleSave = () => {
        dispatch(bidOnBehalfParseActions.saveBids());
    }

    const handleDeleteItemsWithErrors = () => {
        dispatch(bidOnBehalfParseActions.removeUnknownCompanyColumns());
        dispatch(bidOnBehalfParseActions.deleteItemsWithErrors());
    }

    const renderTitle = () => {
        return (
            <h1>
                Upload Bids <span className="name-tag">{bwicName}</span>
                <div className="flex-item-right">
                    <DownloadTemplateButton
                        templateType="UploadBobTemplate"
                        disabled={isGridUploading}
                        args={bwicReferenceName}
                    />
                    <DeleteRowsWithErrorButton onDelete={handleDeleteItemsWithErrors} />
                </div>
            </h1>
        );
    }

    return (
        <Popup
            modalClass="modal-full-size modal-upload-bids"
            renderTitle={renderTitle()}
            onClose={() => setIsCancelCofrimationShow(true)}
            renderInBody
        >
            <PopupBody>
                <ParseErrors tickers={tickers} />
                <Grid
                    addRowVisible={false}
                    moveRowVisible={false}
                    onDataItemsUpload={file => dispatch(bidOnBehalfParseActions.uploadFile(file))}
                    onDataItemsPaste={clipboardText => dispatch(bidOnBehalfParseActions.parseClipboard(clipboardText))}
                    onFileSelected={file => dispatch(bidOnBehalfParseActions.uploadFile(file))}
                />
            </PopupBody>
            <PopupFooter>
                <button
                    className="btn btn-ghost"
                    onClick={() => setIsCancelCofrimationShow(true)}
                >
                    Cancel
                </button>
                <SaveBidsButton onClick={handleSave} />
            </PopupFooter>
            <CancelConfirmationModal
                isCancelCofrimationShow={isCancelCofrimationShow}
                setIsCancelCofrimationShow={setIsCancelCofrimationShow} />
        </Popup>
    )
}

interface SaveBidsButtonProps {
    onClick: () => void;
}

interface CancelConfirmationModalProps {
    isCancelCofrimationShow: boolean;
    setIsCancelCofrimationShow: (isCancelCofrimationShow: boolean) => void
}

function CancelConfirmationModal({ isCancelCofrimationShow, setIsCancelCofrimationShow }: CancelConfirmationModalProps) {
    const history = useHistory();
    const destinationRedirect = { ...history.location, state: new LocationStateBuilder().resetPopup().result() };
    const hasBids = useAppSelector(s => s.grid.dataItems.some((i: GridDataItem<BidOnBehalfParseResultItem>) =>
        !i.draft && hasCompanyBids(i))
    );
    if (!isCancelCofrimationShow) return null;
    if (!hasBids) {
        return <Redirect to={destinationRedirect} />
    }

    const handleClose = () => {
        history.replace(destinationRedirect);
    }

    return (
        <Confirm
            renderInBody
            title='Unsubmitted Bids'
            text='A have unsubmitted bids. Are you sure you want to leave?'
            onConfirm={handleClose}
            onCancel={() => setIsCancelCofrimationShow(false)}
        />
    )
}

function SaveBidsButton({ onClick }: SaveBidsButtonProps) {
    const hasBids = useAppSelector(s => s.grid.dataItems.some((i: GridDataItem<BidOnBehalfParseResultItem>) =>
        !i.draft && hasCompanyBids(i))
    );
    const hasErrors = useAppSelector(s =>
        s.grid.headers?.some(h => h.headerError) ||
        s.grid.dataItems.some((i: GridDataItem<BidOnBehalfParseResultItem>) => !i.draft && i.errors?.length)
    );

    return (
        <button
            className="btn btn-main"
            disabled={!hasBids || hasErrors}
            onClick={onClick}
        >
            Save Bids
        </button>
    );
}

function useTradedPositionsReset(positions: BwicPosition[]) {
    const dispatch = useAppDispatch();
    const dataItems: GridDataItem<BidOnBehalfParseResultItem>[] = useAppSelector(s => s.grid.dataItems);

    React.useEffect(() => {
        positions
            .filter(p => p.trade)
            .forEach(p => {
                const item = dataItems.find(i => i.ticker === p.ticker);
                if (item != null && !item?.readonly) {
                    // reset position bids and make row readonly
                    const updated = ({
                        ticker: item.ticker,
                        readonly: true
                    });
                    dispatch(gridActions.replaceDataItem(updated, dataItems.indexOf(item)));
                }
            })

        // eslint-disable-next-line
    }, [positions, dispatch]);
}
