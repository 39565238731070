import React, { useState } from 'react';
import { Popup } from "../controls";
import contentImageStageFirst from '../../images/ob-explanation-stage-1.png';
import contentImageStageSecond from '../../images/ob-explanation-stage-2.png';
import { Checkbox } from '../controls/Checkbox';

export function OpenBiddingExplanation({ onClickGotIt, onClose }) {
    const [dontShowAgain, setDontShowAgain] = useState(false);

    function handleClickGotIt() {
        onClickGotIt && onClickGotIt(dontShowAgain);
    }

    return (
        <Popup
            title="LiveBidding&#8482;"
            modalClass="ob-explanation-modal"
            onClose={onClose}>
            <div className="modal-body">
                <div className="ob-explanation-section flex-row">
                    <div className="ob-explanation-column"><img src={contentImageStageFirst} alt=""/></div>
                    <div className="ob-explanation-column"><img src={contentImageStageSecond} alt=""/></div>
                </div>
                <div className="ob-explanation-section">
                    <p>LiveBidding&#8482; is a unique feature that enables live exchange in two stages:</p>
                </div>
                <div className="ob-explanation-section flex-row">
                    <div className="ob-explanation-column">
                        <h3>Stage 1:</h3>
                        <p>Participating bidders must submit bids in a &#8243;blind&#8243; manner similar to traditional BWICs.</p>
                    </div>
                    <div className="ob-explanation-column">
                        <h3>Stage 2:</h3>
                        <p>A specified number of top bidders from Stage 1 can see live Best bid levels and can improve their bids during the pre-set interval of time. Bidding continues till either no improvement interval or BWIC ends.</p>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <Checkbox
                    label="Don't show me again"
                    checked={dontShowAgain}
                    onChange={() => setDontShowAgain(!dontShowAgain)}
                />
                <button type="button" className="btn btn-main" onClick={handleClickGotIt}>
                    Got it
                </button>
            </div>
        </Popup>
    )
}
