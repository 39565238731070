import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { dateTimeUtils, biddingUtils, formatUtils } from '../../../../utils';
import { RejectedLink } from '../../common';
import { FeedbackLabel } from '../../common/feedback/FeedbackLabel';
import { TradeStatus } from '../../../../types/trades/TradeStatus';
import IconSVG from "../../../../styles/svg-icons";
import { BidOnBehalfIcon } from '../bid-on-behalf/BidOnBehalfIcon';
import { BwicProcessType } from '../../../../types/models/Process';

function Events({ bwic, position }) {
    const { trade, bids } = position;
    const bidsByCreatedDate = (bids && [...bids].sort((a, b) => b.createdUtc.localeCompare(a.createdUtc))) || [];
    const [lastSubmittedBid] = bidsByCreatedDate;

    const getCompanyCode = (company, buyerPseudoOrderNumber) => {
        const code = company && company.code && company.code.toUpperCase();

        return buyerPseudoOrderNumber ? `${code}-${buyerPseudoOrderNumber}` : code;
    }

    const renderTradeEvent = () => {
        if (trade && moment(trade.tradeDate).isAfter(lastSubmittedBid.createdUtc)) {
            const companyCode = getCompanyCode(trade.buyerCompany, trade.buyerPseudoOrderNumber);

            let tradeContent = null;
            if (trade.status === TradeStatus.rejected) {
                tradeContent = <RejectedLink rejectReason={trade.rejectReason} rejectedByCompanyCode={companyCode} />
            } else if (trade.status === TradeStatus.canceled) {
                tradeContent = <FeedbackLabel text={`Trade @ ${formatUtils.formatBid(trade.price)} canceled automatically`} />;
            } else {
                const text = `Traded to ${companyCode} @ ${formatUtils.formatBid(trade.price)} ${dateTimeUtils.utcToLocalString(trade.tradeDate)}.`;
                tradeContent = <FeedbackLabel text={text} />;
            }

            return (
                <>
                    {Boolean(trade.onBehalf) && <BidOnBehalfIcon />}
                    {tradeContent}
                </>
            );
        }

        return null;
    }

    const renderLiveBiddingEvent = () => {
        const { process } = bwic;
        if (process.type === BwicProcessType.Live && process.liveBidding && process.stagedBiddingStatus) { // after stage 1
            const notPassedBids = bidsByCreatedDate.filter(b => !b.pass);
            const [lastSubmittedNotPassedBid] = notPassedBids;
            const now = moment.utc();
            const stage2Start = moment.utc(bwic.bidsDueUtc).add(process.liveBidding.stage1Deadline, 'minute');
            const stage2Deadline = moment(stage2Start).add(process.liveBidding.improvementRound, 'minute');
            const bidSubmissionDeadline = lastSubmittedNotPassedBid && moment.utc(lastSubmittedNotPassedBid.createdUtc).add(process.liveBidding.improvementRound, 'minute');
            const isBiddingTimeEnded = now.isAfter(stage2Deadline) && now.isAfter(bidSubmissionDeadline);
            const hasStage2Bids = lastSubmittedNotPassedBid && moment.utc(lastSubmittedNotPassedBid.createdUtc).isAfter(stage2Start);

            if (lastSubmittedNotPassedBid && isBiddingTimeEnded) {
                return <FeedbackLabel text="Bidding is finished. Please submit your trades" />
            } else if (!hasStage2Bids && !isBiddingTimeEnded) {
                const message = notPassedBids.length ? 'Stage 2 started' : 'No bids were placed in Stage 1';
                return <FeedbackLabel text={message} />
            }
        }

        return null;
    }

    const getAxedFinalIcons = (axed, final) => {
        return (
            <>
                {(axed || final) && <span>&nbsp;</span>}
                {axed && <IconSVG name="axed" width={16} height={16} />}
                {final && <IconSVG name="final" width={16} height={16} />}
            </>
        )
    }

    const renderBidEvent = () => {
        if (lastSubmittedBid) {
            const companyCode = getCompanyCode(lastSubmittedBid.company, lastSubmittedBid.buyerPseudoOrderNumber) || '';
            if (lastSubmittedBid.pass) {
                return <FeedbackLabel text={`${companyCode} - PASS`} />
            } else {
                const bidLevel = biddingUtils.getBidLevel(lastSubmittedBid, position.bids);
                const levelBids = biddingUtils.getBidsByLevel(position.bids, bidLevel);
                const hasManySameLevelBids = levelBids && levelBids.length > 1;
                const process = bwic.process;
                const improversCount = process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX
                    ? process.topX?.improverCount ?? process.jumpBall?.improverCount
                    : 3;
                const levelString = formatUtils.formatBidLevel(bidLevel, hasManySameLevelBids, improversCount);

                return <FeedbackLabel text={
                    <>
                        {`${companyCode}`} bids {`${formatUtils.formatBid(lastSubmittedBid.value)}`}
                        {getAxedFinalIcons(lastSubmittedBid.axed, lastSubmittedBid.final)}{'. ' + levelString}
                    </>
                } />
            }
        }
        return null;
    }

    return renderTradeEvent() || renderLiveBiddingEvent() || renderBidEvent();
}

const mapStateToProps = ({ bidding }) => ({
    bwic: bidding.bwic,
});

const connectedEvents = connect(mapStateToProps)(Events);
export { connectedEvents as Events };
