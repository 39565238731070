import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { moneyUtils } from '../../../../../../utils';
import { OnHoverTooltip } from "../../../../../common";

export const tradeSize = {
    columnName: 'tradeSize',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="text-right"
            >
                Size
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const size = moneyUtils.money(position.trade.size);

        return (
            <SecurityListColumn key={this.columnName} className="text-right">
                <OnHoverTooltip overlay={size} placement="bottomRight">
                    <div className="text-ellipsis">{size}</div>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
