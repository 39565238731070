import { useEffect, useRef } from "react";
import { constants } from '../../../constants';
import { useDispatch } from "react-redux";
import { bidOnBehalfActions } from "../../../actions";


export const useBobAutoSave = (disabled: boolean) => {
    const dispatch = useDispatch();
    const autoSave = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        if (!disabled && !autoSave.current) {
            autoSave.current = setInterval(
                () => dispatch(bidOnBehalfActions.submit()),
                constants.bobAutoSaveIntervalSeconds * 1000
            );
        }
        return () => {
            if (autoSave.current) {
                clearInterval(autoSave.current);
            }
        }
    }, [dispatch, disabled])
}