import * as React from 'react';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import IconSVG from '../../../styles/svg-icons';

export function FinalIcon() {
    return (
        <OnHoverTooltip wrapperClass="final-icon-wrap" overlay="Final">
            <IconSVG name="final" width={16} height={16} />
        </OnHoverTooltip>
    );
}
