import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { dealerListPanelActions, controlPanelActions } from '../../../../actions';
import { AppState } from '../../../../types/state/AppState';
import IconSVG from '../../../../styles/svg-icons';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export function DealerListButton() {
    const dispatch = useAppDispatch();
    const { panelVisible } = useSelector((state: AppState) => state.dealerListPanel);

    const handleClick = () => {
        if (panelVisible) {
            dispatch(dealerListPanelActions.hide())
        } else {
            dispatch(controlPanelActions.hide());
            dispatch(dealerListPanelActions.show());
        }
    }

    return (
        <button className={classNames('btn-link btn-show-dealers', { 'btn-link-active': panelVisible })} onClick={handleClick}>
            <IconSVG name="hidden" width={16} height={16} />Bidders
        </button>
    );
}
