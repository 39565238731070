import * as React from 'react';
import { BwicBase } from '../../../../../../types/bwic/BwicBase';
import { BwicPosition } from '../../../../../../types/bwic/BwicPosition';
import { BwicProcessType } from '../../../../../../types/models/Process';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { Bid } from '../../../bid-placement/Bid';
import { LiveBid } from '../../../bid-placement/LiveBid';
import { JumpBallBid } from '../../../bid-placement/JumpBallBid';
import { TopXBid } from '../../../bid-placement/TopXBid';
import { BFFBid } from '../../../bid-placement/BFFBid';
import { AllToAllArgeementRequiredActionBlocker } from '../../../../../agreements/AllToAllArgeementRequiredActionBlocker';

export const bid = {
    columnName: 'bid',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm cell-bid-value cell-input text-right">
                Bid
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (
        position: BwicPosition,
        context: { biddingControlsVisible: boolean, enabled: boolean, bwic: BwicBase }) {
        const props = {
            position,
            readonly: !context.biddingControlsVisible
        };

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm cell-bid-value cell-input text-right">
                <AllToAllArgeementRequiredActionBlocker disabled={!context.bwic.isAllToAll}>
                    {
                        blocked => (
                            <>
                                {context.bwic.process.type === BwicProcessType.Standard && <Bid {...props} enabled={context.enabled && !blocked} />}
                                {context.bwic.process.type === BwicProcessType.Live && <LiveBid {...props} process={context.bwic.process} enabled={context.enabled && !blocked} />}
                                {context.bwic.process.type === BwicProcessType.JumpBall && <JumpBallBid {...props} process={context.bwic.process} enabled={context.enabled && !blocked} />}
                                {context.bwic.process.type === BwicProcessType.TopX && <TopXBid {...props} process={context.bwic.process} enabled={context.enabled && !blocked} />}
                                {context.bwic.process.type === BwicProcessType.BestFootForward && <BFFBid {...props} process={context.bwic.process} enabled={context.enabled && !blocked} />}
                            </>
                        )
                    }
                </AllToAllArgeementRequiredActionBlocker>
            </SecurityListColumn>
        );
    }
};
