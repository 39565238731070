import React, { useState } from 'react';
import { Form } from '../../forms';
import { Popup } from '../../controls';

interface CancelReasonPopupProps {
    onCancel: (reason: string) => void
    onClose: () => void
}

export const CancelReasonPopup = ({ onCancel, onClose }: CancelReasonPopupProps) => {
    const [reason, setReason] = useState('');

    return (
        <Popup title="BWIC Cancelation" onClose={onClose}>
            <Form onSubmit={() => onCancel(reason)}>
                <div className="modal-body">
                    <p className="modal-cancelation-reason-text">
                        Please confirm you would like to cancel the BWIC
                    </p>
                    <div className="form-row">
                        <label className="form-label">Reason for Cancelation (optional)</label>
                        <textarea
                            className="form-control"
                            maxLength={255}
                            value={reason}
                            placeholder="Enter the reason for cancelation here"
                            onChange={e => setReason(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-ghost" onClick={onClose}>No, Don’t Cancel</button>
                    <button type="submit" className="btn btn-main btn-danger">Yes, Cancel BWIC</button>
                </div>
            </Form>
        </Popup>
    );
}
