import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompanySlim } from '../../../../types/company/CompanySlim';
import { AppState } from '../../../../types/state/AppState';
import { RightSidePanel, Checkbox } from '../../../controls';
import { Preloader, Pluralize, FavoriteButton, SearchInput, OnHoverTooltip, EmptyPlaceholder } from '../../../common';
import { DealerListSection } from './DealerListSection';
import { dealerListPanelActions } from '../../../../actions';
import { biddingUtils } from '../../../../utils';
import { CompanyStatus } from '../../../../types/company/CompanyStatus';
import { BidderCompanySlim } from '../../../../types/company/BidderCompanySlim';
import { BidOnlyFilter } from '../BidOnlyFilter';
import { Stage2ParticipantsFilter } from '../Stage2ParticipantsFilter';
import { BwicProcessType } from '../../../../types/models/Process';
import { useDealerListAggregatedData } from '../useDealerListAggregatedData';

interface Props {
    bwicParticipants: CompanySlim[];
    offPlatformVisible: boolean;
    isStage2Mode: boolean;
    isClearingBankParticipant: boolean;
    isAllToAll: boolean;
}

export function DealerListPanel(props: Props) {
    const dispatch = useDispatch();
    const panelVisible = useSelector((state: AppState) => state.dealerListPanel.panelVisible);
    const isDefaultVisibilityApplied = useSelector((state: AppState) => state.dealerListPanel.isDefaultVisibilityApplied);
    const isRequesting = useSelector((state: AppState) => state.bidOnBehalf.isRequesting);
    const offPlatformCompanies = useSelector((state: AppState) => state.bidOnBehalf.companies);

    React.useEffect(() => {
        if (!isRequesting && !isDefaultVisibilityApplied && props.bwicParticipants.length) {
            if (props.isStage2Mode) {
                dispatch(dealerListPanelActions.stage2ParticipantsFlagChange(true));
            } else {
                dispatch(dealerListPanelActions.showBwicParticipantsOnly());
            }
        }
    }, [isRequesting, isDefaultVisibilityApplied, props.bwicParticipants, props.isStage2Mode, offPlatformCompanies, dispatch]);

    return panelVisible ? <DealerListPanelContent {...props} /> : null;
}

function DealerListPanelContent({ offPlatformVisible = false, isClearingBankParticipant }: Props) {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState('');
    const {
        companyHiddenState,
        allFavoriteCompaniesVisible,
        allPlatformCompaniesVisible,
        allOffPlatformCompaniesVisible,
        allSettlmentsClientsVisible,
    } = useSelector((state: AppState) => state.dealerListPanel);

    const {
        bidsByCompanyIdentifier,
        onPlatformCompanies,
        offPlatformCompanies,
        buyers
    } = useDealerListAggregatedData();

    const disabledCompanies = useSelector((state: AppState) => state.dealerListPanel.disabledCompanies);
    const isRequesting = useSelector((state: AppState) => state.bidOnBehalf.isRequesting);
    const favorites = useSelector((state: AppState) => state.favoriteBrokerDealers.favorites);
    const proccesType = useSelector((state: AppState) => state.bidding.bwic?.process.type);
    const isAllToAll = useSelector((state: AppState) => state.bidding.bwic?.isAllToAll);

    const favoriteCompanies: BidderCompanySlim[] = React.useMemo(() => {
        const result = [...onPlatformCompanies];
        if (offPlatformVisible) {
            result.push(...offPlatformCompanies);
        }

        return result.filter(c => favorites[c.id])
            .map(company => ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) }));
    }, [offPlatformCompanies, onPlatformCompanies, favorites, offPlatformVisible]);

    const applySearchTerm = React.useCallback((companies: BidderCompanySlim[]) => {
        const searchTermLowerCase = searchTerm?.toLowerCase();
        return companies.filter(c =>
            !searchTermLowerCase ||
            biddingUtils.getBidderName(c)?.toLowerCase().includes(searchTermLowerCase) ||
            biddingUtils.getBidderShortcode(c)?.toLowerCase().includes(searchTermLowerCase)
        );
    }, [searchTerm]);

    const filteredOnPlatformCompanies =
        React.useMemo(() => applySearchTerm(onPlatformCompanies), [onPlatformCompanies, applySearchTerm]);
    const filteredOffPlatformCompanies =
        React.useMemo(() => applySearchTerm(offPlatformCompanies), [offPlatformCompanies, applySearchTerm]);
    const filteredFavoriteCompanies =
        React.useMemo(() => applySearchTerm(favoriteCompanies), [favoriteCompanies, applySearchTerm]);
    const filteredBuyers =
        React.useMemo(() => applySearchTerm(buyers), [buyers, applySearchTerm]);

    React.useEffect(() => {
        const shouldForceAllState = (allToggleFlag: boolean, companies: BidderCompanySlim[]): boolean =>
            allToggleFlag !== companies.every(c => !companyHiddenState[c.identifier]);

        if (shouldForceAllState(allFavoriteCompaniesVisible, filteredFavoriteCompanies)) {
            dispatch(dealerListPanelActions.toggleAllFavoriteVisibleFlagOnly())
        }
        if (shouldForceAllState(allPlatformCompaniesVisible, filteredOnPlatformCompanies)) {
            dispatch(dealerListPanelActions.toggleAllPlatformVisibleFlagOnly())
        }
        if (shouldForceAllState(allOffPlatformCompaniesVisible, filteredOffPlatformCompanies)) {
            dispatch(dealerListPanelActions.toggleAllOffPlatformVisibleFlagOnly())
        }
        if (shouldForceAllState(allSettlmentsClientsVisible, buyers)) {
            dispatch(dealerListPanelActions.toggleAllSettlmentsClientsFlagOnly())
        }
    }, [
        dispatch,
        allFavoriteCompaniesVisible,
        filteredFavoriteCompanies,
        companyHiddenState,
        allPlatformCompaniesVisible,
        filteredOnPlatformCompanies,
        allOffPlatformCompaniesVisible,
        filteredOffPlatformCompanies,
        buyers,
        allSettlmentsClientsVisible
    ]);

    const renderSearch = () => {
        return (
            <div className="dealers-list-panel-search">
                <SearchInput
                    placeholder="Search broker-dealer"
                    value={searchTerm}
                    onChange={setSearchTerm}
                    onClear={() => setSearchTerm('')}
                    maxLength={128}
                />
            </div>
        );
    };

    const renderCheckbox = (company: BidderCompanySlim, onPlatform: boolean, favoritedDisabled: boolean = false) => {
        const companyBids = bidsByCompanyIdentifier.get(company.identifier) || [];
        const disabledCompanyCheckbox = disabledCompanies[company.identifier];

        const getText = (noBidsSubmitted?: boolean) => {
            if (noBidsSubmitted) {
                return <>This Broker-Dealer did not submit any bids at Stage&nbsp;1</>;
            }
            let text = biddingUtils.getBidderName(company);

            if (biddingUtils.getBidderShortcode(company) && !company.buyerPseudoOrderNumber) {
                text = text + ` (${biddingUtils.getBidderShortcode(company)})`;
            }

            return text;
        };

        const renderLabel = () => <span className="align-middle text-ellipsis">{getText()}</span>;

        return (
            <div
                key={company.identifier}
                className="dealer-checkbox-row"
            >
                <OnHoverTooltip overlay={getText(disabledCompanyCheckbox)}>
                    <Checkbox
                        checked={!companyHiddenState[company.identifier]}
                        disabled={disabledCompanyCheckbox}
                        onChange={() => {
                            dispatch(dealerListPanelActions.toggleCompanyVisible(company.identifier, !onPlatform));
                            dispatch(dealerListPanelActions.stage2ParticipantsFlagChange(false));
                        }}
                        label={onPlatform ? renderLabel() : getText()}
                    />
                </OnHoverTooltip>
                {
                    !!companyBids.length &&
                    <div className="bids-counter">
                        <Pluralize singular="bid" count={companyBids.length} />
                    </div>
                }
                {
                    favoritedDisabled ? null : (
                        <div className="favorite-action-button">
                            <FavoriteButton companyId={company.id} />
                        </div>
                    )
                }
            </div>
        );
    };

    return (
        <RightSidePanel title="Bidders" onClose={() => dispatch(dealerListPanelActions.hide())}>
            <div className="dealers-list-panel">
                {!isAllToAll && (
                    <>
                        {renderSearch()}
                        <div className="dealers-list-panel-with-bids flex-row">
                            <BidOnlyFilter />
                        </div>
                    </>
                )}
                {
                    (proccesType === BwicProcessType.JumpBall ||
                        proccesType === BwicProcessType.TopX ||
                        proccesType === BwicProcessType.Live) &&
                    <div className="dealers-list-panel-with-bids flex-row">
                        <Stage2ParticipantsFilter />
                    </div>
                }
                {
                    !isAllToAll &&
                    <DealerListSection
                        title="Favorite broker-dealers"
                        defaultExpanded={false}
                        toggleState={allFavoriteCompaniesVisible}
                        onToggleAll={() => dispatch(dealerListPanelActions.toggleAllFavoriteCompaniesVisible(filteredFavoriteCompanies))}
                        toggleEnabled={!!filteredFavoriteCompanies.length}
                        totalCount={filteredFavoriteCompanies.length}
                        checkedCount={filteredFavoriteCompanies.filter(company => !companyHiddenState[company.identifier]).length}
                    >
                        {filteredFavoriteCompanies.map(c => renderCheckbox(c, c.status === CompanyStatus.active))}
                        {
                            !filteredFavoriteCompanies.length && !searchTerm &&
                            <EmptyPlaceholder
                                textView={true}
                                text="Your favorite broker-dealer companies will appear here."
                            />
                        }
                        {
                            !filteredFavoriteCompanies.length && !!searchTerm &&
                            <EmptyPlaceholder textView={true} />
                        }
                    </DealerListSection>
                }
                {
                    !isAllToAll &&
                    <DealerListSection
                        title="Platform broker-dealers"
                        defaultExpanded={!offPlatformVisible}
                        toggleState={allPlatformCompaniesVisible}
                        onToggleAll={() => dispatch(dealerListPanelActions.toggleAllPlatformVisible(filteredOnPlatformCompanies))}
                        toggleEnabled={!!filteredOnPlatformCompanies.length}
                        totalCount={filteredOnPlatformCompanies.length}
                        checkedCount={filteredOnPlatformCompanies.filter(company => !companyHiddenState[company.identifier]).length}
                    >
                        {filteredOnPlatformCompanies.map(c => renderCheckbox(c, true))}
                        {
                            !filteredOnPlatformCompanies.length && !searchTerm &&
                            <EmptyPlaceholder textView={true} text="There are no broker-dealer companies." />
                        }
                        {
                            !filteredOnPlatformCompanies.length && !!searchTerm &&
                            <EmptyPlaceholder textView={true} />
                        }
                    </DealerListSection>
                }
                {
                    offPlatformVisible &&
                    !isRequesting &&
                    !!offPlatformCompanies.length &&
                    !isAllToAll &&
                    <DealerListSection
                        title="Off-platform broker-dealers"
                        toggleState={allOffPlatformCompaniesVisible}
                        onToggleAll={() => dispatch(dealerListPanelActions.toggleAllOffPlatformVisible(filteredOffPlatformCompanies))}
                        toggleEnabled={!!filteredOffPlatformCompanies.length}
                        totalCount={filteredOffPlatformCompanies.length}
                        checkedCount={filteredOffPlatformCompanies.filter(company => !companyHiddenState[company.identifier]).length}
                    >
                        <Preloader inProgress={isRequesting}>
                            {filteredOffPlatformCompanies.map(c => renderCheckbox(c, false))}
                            {
                                !filteredOffPlatformCompanies.length && !!searchTerm &&
                                <EmptyPlaceholder textView={true} />
                            }
                        </Preloader>
                    </DealerListSection>
                }
                {
                    isClearingBankParticipant &&
                    <DealerListSection
                        title={isAllToAll ? 'All bidders' : 'Settlement Agent’s clients'}
                        checkboxTooltip={`There are no ${isAllToAll ? "bidder" : "Buy-Side"} companies to show`}
                        defaultExpanded={true}
                        toggleState={allSettlmentsClientsVisible}
                        onToggleAll={() => dispatch(dealerListPanelActions.toggleAllSettlmentsClientsVisible(filteredBuyers))}
                        toggleEnabled={!!filteredBuyers.length}
                        totalCount={filteredBuyers.length}
                        checkedCount={filteredBuyers.filter(company => !companyHiddenState[company.identifier]).length}
                    >
                        {filteredBuyers.map(c => renderCheckbox(c, true, true))}
                        {
                            !filteredBuyers.length && !searchTerm &&
                            <EmptyPlaceholder
                                textView={true}
                                text={isAllToAll ? 'Bidders will appear here.' : 'Buy-Side companies will appear here.'}
                            />
                        }
                        {
                            !filteredBuyers.length && !!searchTerm &&
                            <EmptyPlaceholder textView={true} />
                        }
                    </DealerListSection>
                }
            </div>
        </RightSidePanel>
    );
}
