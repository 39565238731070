import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { TopPanel, ActionBar, CompletionTime, BiddingTimer } from '../common';
import {
    otherSellerBiddingActions as actions,
    securityDetailsActions,
    bwicHistoryActions,
    rulesActions
} from '../../../actions';
import { bwicStatuses } from '../../../constants';
import { Table } from '../common/table';
import { otherSellerColumns, otherSellerFinishedColumns, otherSellerBiddingColumns } from '../common/table/columns';
import { ControlPanel } from '../common/ControlPanel';

class OtherSellerView extends Component {
    componentWillUnmount = () => this.props.reset();

    getColumns = status => {
        switch (status) {
            case bwicStatuses.bidding.key: return otherSellerBiddingColumns;
            case bwicStatuses.finished.key: return otherSellerFinishedColumns;
            default: return otherSellerColumns;
        }
    }

    createSecurityCustomClassName = (hasHistoryColumn, security) => {
        return classNames({
            pointer: hasHistoryColumn,
            active: security.id === this.props.activePositionId
        });
    }

    handleRowClick = position => this.props.toggleHistory(position.id, position.ticker);

    render = () => {
        const { bwic, securities } = this.props;

        if (!bwic || !securities) return null;

        const columns = this.getColumns(bwic.status);
        const hasHistoryColumn = columns.some(c => c.columnName === 'history');
        const isBidding = bwic.status === bwicStatuses.bidding.key;
        const isFinished = bwic.status === bwicStatuses.finished.key;
        const completedDateUtc = bwic.completedDateUtc || bwic.goodUntilUtc;

        return (
            <div className="container bidding-cnt">
                <div className="sub-header">
                    <TopPanel bwic={bwic}>
                        <div className="flex-item-right controls">
                            <ActionBar>
                                {
                                    isBidding &&
                                    <div className="ends-in">
                                        <BiddingTimer
                                            bidsDueUtc={bwic.bidsDueUtc}
                                            goodUntilUtc={bwic.goodUntilUtc}
                                            process={bwic.process}
                                        />
                                    </div>
                                }
                                {
                                    isFinished &&
                                    <CompletionTime
                                        completedDateUtc={completedDateUtc}
                                    />
                                }
                            </ActionBar>
                        </div>
                    </TopPanel>
                </div>
                <div className="flex-row flex-row-full-size">
                    <Table
                        className="data-list-striped"
                        dataItems={securities}
                        columns={columns}
                        createSecurityCustomClassName={this.createSecurityCustomClassName.bind(this, hasHistoryColumn)}
                        onRowClick={hasHistoryColumn ? this.handleRowClick : undefined}
                    />
                    <ControlPanel
                        bwic={bwic}
                        historyEnabled={isBidding || isFinished}
                        positions={securities}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bidding, otherSellerBidding, securityDetails, bwicHistory, rules, pxTalkList, bwicLog }) => ({
    bwic: bidding.bwic,
    securities: otherSellerBidding.securities,
    activePositionId:
        (bwicHistory.visible && bwicHistory.positionId) ||
        (securityDetails.security && securityDetails.security.id) ||
        (bwicLog.security && bwicLog.security.id) ||
        (rules.show && rules.positionId) ||
        (pxTalkList.visible && pxTalkList.positionId)
});

const mapDispatchToProps = {
    init: actions.init,
    reset: actions.reset,
    toggleHistory: bwicHistoryActions.toggleHistory,
    hideSecurityDetails: securityDetailsActions.securityDetailsReset,
    hideRules: rulesActions.hide
}

const connectedOtherSellerView = connect(mapStateToProps, mapDispatchToProps)(OtherSellerView);
export { connectedOtherSellerView as OtherSellerView };
