import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bwicStatuses } from '../../../constants';
import { brokerDealerBiddingActions as actions } from '../../../actions';
import { Scheduled, Bidding, Cancelled, Finished } from '.';
import { AppState } from '../../../types/state/AppState';

export const BrokerDealerView = () => {
    const bwic = useSelector((s: AppState) => s.bidding.bwic);
    const activePositionId: number | undefined = useSelector((s: AppState) =>
        (s.bwicHistory.visible ? s.bwicHistory.positionId : undefined) ||
        (s.securityDetails.security ? s.securityDetails.security.id : undefined) ||
        (s.bwicLog.security ? s.bwicLog.security.id : undefined) ||
        (s.rules.show ? s.rules.positionId : undefined) ||
        (s.pxTalkList.visible ? s.pxTalkList.positionId : undefined)
    )

    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(actions.reset())
    }, [dispatch])

    switch (bwic.status) {
        case bwicStatuses.scheduled.key:
            return bwic.isAllToAll
                ? <Bidding activePositionId={activePositionId} />
                : <Scheduled activePositionId={activePositionId} />;
        case bwicStatuses.bidding.key:
            return <Bidding activePositionId={activePositionId} />;
        case bwicStatuses.cancelled.key:
            return <Cancelled activePositionId={activePositionId} />;
        case bwicStatuses.finished.key:
            return <Finished activePositionId={activePositionId} />;
        default: return null;
    }
}
