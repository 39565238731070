import { Component } from 'react';
import { connect } from 'react-redux';
import { colorDistributionActions as actions } from '../../../../actions';
import { TopPanel } from '../../common';
import { Table } from '../../common/table';
import { Confirm } from '../../../alerts';
import { PublicColor } from '.';
import { colorDistributionColumns } from '../../common/table/columns';
import { FeatureButton } from '../../../access/FeatureButton';
import { ColorDistributionBlocked } from './ColorDistributionBlocked';
import { getSecuritiesWithLatestBids } from '../../../../selectors';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';
import { RequiredRole } from '../../../access/RequiredRole';

class ColorDistribution extends Component {
    state = {
        confirmationVisible: false
    }

    handleProceed = () => this.setState({ confirmationVisible: true });

    handleConfirm = () => {
        this.setState({ confirmationVisible: false });
        this.props.completeBwic();
    }

    render = () => {
        return (
            <div className="container bidding-cnt">
                <div className="sub-header">
                    <TopPanel bwic={this.props.bwic}>
                        <div className="flex-item-right controls">
                            <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                                <FeatureButton
                                    className="btn btn-ghost"
                                    onClick={() => this.props.cancelColorDistribution(this.props.bwic.referenceName)}
                                >
                                    Cancel
                                </FeatureButton>
                            </RoleActionBlocker>
                            <RoleActionBlocker className="margin-l-8" roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                                <FeatureButton className="btn btn-main" onClick={this.handleProceed}>
                                    Proceed and Complete
                                </FeatureButton>
                            </RoleActionBlocker>
                        </div>
                    </TopPanel>
                </div>
                <div className="color-distribution">
                    <RequiredRole
                        requiredRoles={[roles.SellerTrader, roles.BrokerDealerTrader]}
                        renderBlockedContent={() => <ColorDistributionBlocked />}
                    >
                        <h2 className="color-distribution-title color-distribution-title-main">Color Distribution</h2>
                        <div className="color-distribution-content">
                            <div className="color-distribution-public">
                                <PublicColor />
                            </div>
                            <div className="color-distribution-preview">
                                <h2 className="color-distribution-title">Preview</h2>
                                <Table
                                    className="data-list-striped"
                                    dataItems={this.props.securities}
                                    columns={colorDistributionColumns}
                                />
                            </div>
                        </div>
                        {
                            this.state.confirmationVisible &&
                            <Confirm
                                title="Confirmation"
                                confirmButtonText="Complete BWIC"
                                TextComponent={() => <>Do you want to complete the BWIC?<br />This operation is irreversible.</>}
                                onCancel={() => this.setState({ confirmationVisible: false })}
                                onConfirm={this.handleConfirm}
                            />
                        }
                    </RequiredRole>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bidding, sellerBidding }) => ({
    bwic: bidding.bwic,
    securities: getSecuritiesWithLatestBids({ securities: sellerBidding.securities })
});

const mapDispatchToProps = {
    cancelColorDistribution: actions.cancelColorDistribution,
    completeBwic: actions.completeBwic

};

const connectedColorDistribution = connect(mapStateToProps, mapDispatchToProps)(ColorDistribution);
export { connectedColorDistribution as ColorDistribution };
