import React from 'react';
import { Bid, BidInputProps } from './Bid';
import { BwicProcessType, Process } from '../../../../types/models/Process';

interface Props extends BidInputProps {
    process: Process;
}

export function BFFBid({ position, process, enabled, readonly }: Props) {
    if (process.type !== BwicProcessType.BestFootForward) return null;

    return <Bid
        enabled={enabled}
        readonly={readonly || process.stagedBiddingStatus != null}
        position={position}
    />
}
