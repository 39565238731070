import React from 'react';
import { Checkbox } from '../../controls';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { useDispatch } from 'react-redux';
import { TooltipPlacement } from '../../common/Tooltip';
import { dealerListPanelActions } from '../../../actions/dealer-list-panel.actions';
import { useDealerListAggregatedData } from './useDealerListAggregatedData';

export function BidOnlyFilter() {
    const dispatch = useDispatch();

    const {
        allBids,
        companyHiddenState,
        bidOnlyFlag,
        bidsByCompanyIdentifier,
        onPlatformCompanies,
        offPlatformCompanies,
        buyers
    } = useDealerListAggregatedData()

    const handleBidOnlyFlagChange = () => {
        const enabled = !bidOnlyFlag;
        dispatch(dealerListPanelActions.setBidOnlyFlag(enabled));
        dispatch(dealerListPanelActions.stage2ParticipantsFlagChange(false));

        const allDealers = [...onPlatformCompanies, ...offPlatformCompanies, ...buyers];

        if (enabled) {
            allDealers.forEach(c => {
                const bids = bidsByCompanyIdentifier.get(c.identifier);
                const hasBids = bids != null && bids.length > 0;
                const hidden = companyHiddenState[c.identifier];

                if ((hasBids && hidden) || (!hasBids && !hidden)) {
                    dispatch(dealerListPanelActions.toggleCompanyVisible(c.identifier));
                }
            });
        } else {
            dispatch(dealerListPanelActions.toggleAllPlatformVisible(onPlatformCompanies, true));
            dispatch(dealerListPanelActions.toggleAllSettlmentsClientsVisible(buyers, false));
        }
    };

    React.useEffect(() => {
        const allBrokerDealers = [...onPlatformCompanies, ...offPlatformCompanies, ...buyers];

        const isBrokerDealerWithoutBidsVisible = allBrokerDealers.some(b => {
            const isVisible = !companyHiddenState[b.identifier];
            const hasBids = bidsByCompanyIdentifier.get(b.identifier)?.length;
            return isVisible && !hasBids;
        });

        const allBrokerDealersWithBidsVisible = !allBrokerDealers.some(b =>
            companyHiddenState[b.identifier] &&
            bidsByCompanyIdentifier.get(b.identifier)?.length
        );

        if ((bidOnlyFlag && isBrokerDealerWithoutBidsVisible) || // BD without bids column added
            (bidOnlyFlag && !allBrokerDealersWithBidsVisible) || // BD with bids column removed
            (!bidOnlyFlag && !isBrokerDealerWithoutBidsVisible && allBrokerDealersWithBidsVisible)) { // Auto-check bid only flag
            dispatch(dealerListPanelActions.setBidOnlyFlag(!bidOnlyFlag));
        }
        // eslint-disable-next-line
    }, [companyHiddenState, dispatch]);

    return (
        <>
            <OnHoverTooltip
                overlay={!!allBids.length ? "Click to view columns of all bidders with bids." : "There are no bids submitted"}
                placement={TooltipPlacement.BottomRight}
            >
                <Checkbox
                    checked={bidOnlyFlag && Boolean(allBids.length)}
                    disabled={!allBids.length}
                    onChange={handleBidOnlyFlagChange}
                    label="Bidders With Bids"
                    className="text-medium"
                />
            </OnHoverTooltip>
        </>
    );
};
