import { BwicPosition } from "../../../../types/bwic/BwicPosition";
import { useAgreement } from "../../../seller-buyside/useAgreement";
import { Commission as CommissionInput } from '../../../bid-as-dealer/submit-bid/Commission';
import { useAppSelector } from "../../../../effects/useAppSelector";
import { biddingUtils } from "../../../../utils/bidding.utils";
import { formatUtils } from "../../../../utils/format.utils";
import { constants } from "../../../../constants/constants";
import { errorMessages } from "../../../../constants/error.messages";
import { bidPlacementActions } from "../../../../actions";
import { useAppDispatch } from "../../../../effects/useAppDispatch";

interface Props {
    enabled: boolean;
    readonly: boolean;
    position: BwicPosition;
}

export function Commission({ enabled, readonly, position }: Props) {
    const dispatch = useAppDispatch();
    const { agreement } = useAgreement();
    const commission = useAppSelector(s => s.brokerDealerBidding.editBid[position.id])?.commission;
    const commissionError = useAppSelector(s => s.brokerDealerBidding.editBid[position.id])?.commissionError;
    const currentBid = biddingUtils.getCurrentBid(position.bids)

    const currentCommission = currentBid?.commission ?? agreement?.commissions[position.rating];
    const currentCommissionFormatted = formatUtils.formatBid(currentCommission == null ? currentCommission : Number(currentCommission));

    const validate = (commission: number) => {
        const minValue = currentBid?.commission ?? agreement?.commissions[position.rating] ?? constants.commissionRange.min;
        const maxValue = constants.commissionRange.max;

        if (commission < minValue || commission > maxValue) {
            return errorMessages.invalidRange(minValue, maxValue);
        }

        return '';
    };

    const handleCommissionChange = (value: string) => {
        const error = commissionError
            ? validate(Number(commission))
            : '';

        dispatch(bidPlacementActions.commissionChange(position.id, value, error));
    };

    const handleOnLeave = () => {
        // Reset to default if 0 or empty
        if (!Number(commission)) {
            dispatch(bidPlacementActions.commissionChange(position.id, currentCommissionFormatted, ''));
        } else {
            const error = validate(Number(commission));
            const value = formatUtils.formatBid(Number(commission))

            dispatch(bidPlacementActions.commissionChange(position.id, value, error));
        }
    };

    return readonly
        ? <>{currentCommissionFormatted}</>
        : <CommissionInput
            originalCommission={Number(currentCommission ?? 0)}
            commission={commission ?? currentCommissionFormatted ?? ''}
            error={commissionError ?? ''}
            disabled={!enabled}
            onCommissionChange={handleCommissionChange}
            onLeave={handleOnLeave}
        />
}