import { useSelector } from 'react-redux';
import { bidPlacementActions } from '../../../../actions/bid-placement.actions';
import { AppState } from '../../../../types/state/AppState';
import { Checkbox } from '../../../controls/Checkbox';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    disabled: boolean;
    currentFinal: boolean;
    currentPass: boolean;
}

export function Final({ positionId, disabled, currentFinal, currentPass }: Props) {
    const dispatch = useAppDispatch();
    const bid = useSelector((s: AppState) => s.brokerDealerBidding.editBid[positionId])
    const final = bid?.final ?? currentFinal ?? false;
    const pass = bid?.pass == null ? currentPass : bid.pass;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        dispatch(bidPlacementActions.finalChange(positionId, e.target.checked))
    };

    return <Checkbox onChange={handleChange} checked={final} disabled={disabled || pass} />
}
