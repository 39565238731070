import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../common';
import { TickerButton } from "../../../../common";

export const tickerButton = {
    columnName: 'ticker',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-lg"
            >
                Ticker
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg" security={position}>
                <TickerButton security={position} />
            </SecurityListColumn>
        );
    }
};
