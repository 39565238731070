import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'; 
import { useHistory } from 'react-router';
import { RequestState } from '../../../../constants/request-state';
import { ApiOperationType } from '../../../../types/api-operation/ApiOperationType';
import { Bid } from '../../../../types/bidding/Bid';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { AppState } from '../../../../types/state/AppState';
import { parseKey } from '../../../../types/state/BidOnBehalfState';
import { bidOnBehalfUtils } from '../../../../utils';
import { RouteLeavingGuard } from '../../../routing';
import IconSVG from '../../../../styles/svg-icons';

interface Props {
    positions: BwicPosition[] // positions with latest bid
}

export function BidOnBehalfSavingLabel({ positions }: Props) {
    const history = useHistory();

    const [saveOccurred, setSaveOccurred] = useState(false);
    const [saveTimeoutEnded, setSaveTimeoutEnded] = useState(false);
    const editState = useSelector((state: AppState) => state.bidOnBehalf.editState);
    const isSubmitting = useSelector((state: AppState) => state.bidOnBehalf.isSubmitting);
    const hasPending = useSelector((state: AppState) =>
        state.apiOperation.requests.some(r =>
            r.event === ApiOperationType.submitBidsOnBehalf &&
            r.state === RequestState.request
        ));

    const hasChanges = useMemo(() =>
        Object.keys(editState).some((key: string) => {
            const edit = editState[key];
            if (edit.error) {
                return true;
            }

            const { positionId, companyId } = parseKey(key);
            const position = positions.find(p => p.id === positionId);

            if (position) {
                const latestBid: Bid | undefined = position.bids?.find(b => b.company.id === companyId);
                return bidOnBehalfUtils.isBidChanged(edit, latestBid);
            }

            return false;
        })
        , [editState, positions]);

    const isSaved = saveOccurred && !isSubmitting && !hasPending;


    useEffect(() => {
        if (isSubmitting && !saveOccurred) {
            setSaveOccurred(true);
        }
    }, [isSubmitting, saveOccurred]);

    useEffect(() => {
        if (saveTimeoutEnded && (isSubmitting || hasPending)) {
            setSaveTimeoutEnded(false);
        }
    }, [isSubmitting, hasPending, saveTimeoutEnded])

    useEffect(() => {
        if (isSaved && !saveTimeoutEnded) {
            const timeout = 5000; //5 seconds
            setTimeout(() => setSaveTimeoutEnded(true), timeout);
        }
    }, [isSaved, saveTimeoutEnded]);

    const renderLabel = () => {
        if (isSaved && !saveTimeoutEnded) {
            return <div className="bob-saving-label"><IconSVG name="saved" width={16} height={16} />Bids saved</div>
        } else if (isSubmitting || hasPending) {
            return <div className="bob-saving-label"><IconSVG name="saving" width={16} height={16} />Saving bids…</div>;
        }

        return null;
    }

    return (
        <>
            {renderLabel()}
            <RouteLeavingGuard
                title="Unsubmitted Bids"
                text="You have unsubmitted bids. Are you sure you want to leave?"
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    );

}
