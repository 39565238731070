import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { moneyUtils } from '../../../../../utils';
import { OnHoverTooltip } from "../../../../common";

export const size = {
    columnName: 'size',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="text-right">
                Size
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const size = moneyUtils.money(position.size);

        return (
            <SecurityListColumn key={this.columnName} className="text-right">
                <OnHoverTooltip overlay={size}>
                    <span className="text-ellipsis">{size}</span>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
