import React from 'react';
import { RulesButton } from './RulesButton';
import { HistoryButton } from '../../bwic-history/HistoryButton';

interface Props {
    children: React.ReactNode;
}

export function ActionBar({ children }: Props) {
    return (
        <div className="flex-item-right controls flex-row">
            {children}
            <RulesButton />
            <HistoryButton text="BWIC Log" />
        </div>
    );
}
