import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { constants, onBoardingTooltipConst } from '../../constants';
import { biddingActions as actions, onBoardingTooltipGotIt, onBoardingTooltipSetCountRequest } from '../../actions';
import { SellerView } from './seller';
import { OtherSellerView } from './otherSeller';
import { BrokerDealerView } from './brokerDealer';
import { Preloader } from '../common';
import { OpenBiddingExplanation } from "./OpenBiddingExplanation";
import { useRouteMatch } from "react-router";
import { user } from '../../user';
import { useCacheOnBoardingState } from '../../effects';
import { AdminBiddingView } from './admin/AdminBiddingView';
import { BwicProcessType } from '../../types/models/Process';
import { pushHistoryActions } from "../../actions/entities/push-history.actions";
import { BwicVisitorType } from '../../types/state/BiddingState';

export function BiddingView() {
    const routeMatch = useRouteMatch();
    const dispatch = useDispatch();
    const { bwic, visitorType, isLoading } = useSelector(state => state.bidding);
    const stateBoardingTooltip = useSelector((s) => s.onBoardingTooltip);
    const onBoardingKey = onBoardingTooltipConst.pages.bwicOpenBidding;
    const [showExplanation, setShowExplanation] = useState(false);

    useCacheOnBoardingState(1, stateBoardingTooltip);

    useEffect(() => {
        dispatch(actions.init(routeMatch.params.referenceName));
        return () => {
            dispatch(actions.reset());
            dispatch(pushHistoryActions.reset());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const explanationStatus = user.getOnBoardingTooltipCounters().BwicOpenBidding;
        let showExplanation = explanationStatus !== onBoardingTooltipConst.maxCountLimit;
        if (stateBoardingTooltip && stateBoardingTooltip.pages[onBoardingKey]) {
            showExplanation = !stateBoardingTooltip.pages[onBoardingKey].gotIt
        }
        setShowExplanation(showExplanation);  // eslint-disable-next-line
    }, [stateBoardingTooltip]);

    const saveDontShowExplanation = (dontShowExplanation) => {
        if (dontShowExplanation) {
            dispatch(onBoardingTooltipSetCountRequest([{
                page: onBoardingKey,
                count: onBoardingTooltipConst.maxCountLimit
            }]));
            dispatch(onBoardingTooltipGotIt(onBoardingKey, true))
        }
    };

    const handleCloneExplanation = () => setShowExplanation(false);

    const handleCloseOpenBiddingExplanation = (dontShowAgain) => {
        handleCloneExplanation();
        saveDontShowExplanation(dontShowAgain);
    };

    const renderBiddingViewByRole = () => {
        switch (visitorType) {
            case BwicVisitorType.Admin: return <AdminBiddingView />;
            case BwicVisitorType.Seller: return <SellerView />;
            case BwicVisitorType.OtherSeller: return <OtherSellerView />;
            case BwicVisitorType.BrokerDealer:
            case BwicVisitorType.Buyer:
                return <BrokerDealerView />;
            default: return null;
        }
    };

    const getDocumentTitle = () => {
        const bidDueLocal =
            moment
                .utc(bwic.bidsDueUtc)
                .local()
                .format(constants.dateTimeFormat);
        switch (visitorType) {
            case BwicVisitorType.Seller:
                return `Sell: ${bwic.name} - KTX ATS Platform`;
            case BwicVisitorType.BrokerDealer:
            case BwicVisitorType.Buyer:
                return `Buy: ${bwic.name} - KTX ATS Platform`;
            default:
                return `${bidDueLocal} ${bwic.name} - KTX ATS Platform`;
        }
    }

    if (bwic) {
        document.title = getDocumentTitle();
    }
    return (
        <Preloader inProgress={isLoading}>
            {renderBiddingViewByRole()}
            {
                showExplanation && bwic?.process?.type === BwicProcessType.Live &&
                <OpenBiddingExplanation
                    onClose={handleCloneExplanation}
                    onClickGotIt={handleCloseOpenBiddingExplanation}
                />
            }
        </Preloader>
    );
}
