import * as React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { CompanySlim } from '../../../../types/company/CompanySlim';
import { getEditKey } from '../../../../types/state/BidOnBehalfState';
import { Bid } from '../../../../types/bidding/Bid';
import { biddingUtils, bidOnBehalfUtils } from '../../../../utils';
import { BorderHighlightAnimation } from '../../../common/BorderHighlightAnimation';
import { EditBidValue } from './EditBidValue';
import { FeedbackMenu } from '../FeedbackMenu';
import { AppState } from '../../../../types/state/AppState';
import { LazyLoad } from '../../../common/LazyLoad';
import IconSVG from '../../../../styles/svg-icons';
import { ContextMenu } from '../../../controls';
import { bidOnBehalfActions } from '../../../../actions';
import { ListBuilder } from '../../../../utils/ListBuilder';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { roles } from '../../../../constants/roles';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';

interface Props {
    company: CompanySlim;
    position: BwicPosition;
    disabled?: boolean;
    bidAttributesVisibe: boolean; // true if axed/final options are available
    process: Process;
    columnAnimationEnabled?: boolean;
}

export const EditBidOnBehalf = React.memo(EditBidOnBehalfContent, (prev, next) => {
    return (
        prev.company === next.company &&
        prev.disabled === next.disabled &&
        prev.position.id === next.position.id &&
        prev.position.bids === next.position.bids &&
        prev.process.stagedBiddingStatus === next.process.stagedBiddingStatus &&
        prev.columnAnimationEnabled === next.columnAnimationEnabled
    );
});

function EditBidOnBehalfContent({ company, position, bidAttributesVisibe, disabled = false, process, columnAnimationEnabled = false }: Props) {
    const dispatch = useDispatch();
    const bids = position.bids || []
    const editState = useSelector((state: AppState) => state.bidOnBehalf.editState[getEditKey(position.id, company.id)]);
    const hasFocus = useSelector((state: AppState) =>
        state.bidOnBehalf.focusedBid != null &&
        state.bidOnBehalf.focusedBid.positionId === position.id &&
        state.bidOnBehalf.focusedBid.companyId === company.id
    );
    const latestBid: Bid | undefined = bids.find(b => b.company.id === company.id);
    const pass = editState?.pass ?? latestBid?.pass;
    const axed = editState?.axed ?? latestBid?.axed ?? false;
    const final = editState?.final ?? latestBid?.final ?? false;
    const finalChanged = !pass && editState?.final != null && !!editState?.final !== !!latestBid?.final;
    const axedChanged = !pass && editState?.axed != null && !!editState?.axed !== !!latestBid?.axed;
    const hasBid = !pass && (latestBid?.value || editState?.value);

    const hasSameLevelBids = () => {
        if (!bids.length || !latestBid || latestBid.pass) {
            return false;
        }

        return bids.some(b =>
            b.company.id !== latestBid.company.id &&
            b.value === latestBid.value &&
            !bids.some(bb => bb.id > b.id && bb.company.id === b.company.id) // latest bid
        );
    }

    const renderHiddenActionsButton = () => {
        return (
            <button>
                <IconSVG name="more" width={16} height={16} />
            </button>
        );
    }

    const getMenuItems = () => {
        const axedMenuItem = {
            requiredRoles: [roles.SellerTrader, roles.BrokerDealerTrader],
            textComponent: () => {
                return (
                    <>
                        <IconSVG name="axed" className={cn({ disabled: disabled || pass })} width={16} height={16} />
                        <label>Axed</label>
                    </>
                );
            },
            action: () => dispatch(bidOnBehalfActions.axed(position.id, company.id, !axed)),
            disabled: disabled || pass || !hasBid
        };
        const finalMenuItem = {
            requiredRoles: [roles.SellerTrader, roles.BrokerDealerTrader],
            textComponent: () => {
                return (
                    <>
                        <IconSVG name="final" className={cn({ disabled: disabled || pass })} width={16} height={16} />
                        <label>Final</label>
                    </>
                );
            },
            action: () => dispatch(bidOnBehalfActions.final(position.id, company.id, !final)),
            disabled: disabled || pass || !hasBid
        };
        const passMenuItem = {
            requiredRoles: [roles.SellerTrader, roles.BrokerDealerTrader],
            text: 'Pass',
            action: () => dispatch(bidOnBehalfActions.pass(position.id, company.id)),
            disabled: disabled || latestBid != null || !!editState?.pass
        };

        return new ListBuilder()
            .addWhen(() => bidAttributesVisibe, axedMenuItem, finalMenuItem)
            .add(passMenuItem)
            .result();
    }

    return (
        <LazyLoad disabled={hasFocus}>
            <div className="bid-on-behalf-form" onClick={e => e.stopPropagation()}>
                <div className="bid-on-behalf-form-value">
                    <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                        <BorderHighlightAnimation disabled={!columnAnimationEnabled}>
                            <EditBidValue
                                companyId={company.id}
                                positionId={position.id}
                                latestBid={latestBid}
                                editState={editState}
                                disabled={disabled || (
                                    process.type === BwicProcessType.JumpBall &&
                                    latestBid?.stagedBiddingStatus === OpenBiddingStatus.stage1Ended
                                )}
                                hasMoveNextFocus={hasFocus}
                                isBidDecreaseDisabled={bidOnBehalfUtils.isBidDecreaseDisabled(process)}
                            />
                        </BorderHighlightAnimation>
                    </RoleActionBlocker>
                </div>
                <div className="bid-on-behalf-form-icons">
                    <span className="icon-wrap">{!pass && axed && <IconSVG name="axed" className={cn({ changed: axedChanged })} width={16} height={16} />}</span>
                    <span className="icon-wrap">{!pass && final && <IconSVG name="final" className={cn({ changed: finalChanged })} width={16} height={16} />}</span>
                </div>
                <div className="bid-on-behalf-form-actions">
                    <div className="bid-on-behalf-context-menu">
                        <ContextMenu
                            menuItems={getMenuItems()}
                            customTargetComponent={renderHiddenActionsButton()}
                            disabled={disabled}
                        />
                    </div>
                    <div className="bid-on-behalf-feedback">
                        <FeedbackMenu
                            getLevelCallback={() => latestBid ? biddingUtils.getBidLevel(latestBid, bids) : undefined}
                            positionId={position.id}
                            bids={bids}
                            levelBids={latestBid ? [latestBid] : undefined}
                            hasManyBids={hasSameLevelBids()}
                            disabled={disabled}
                            emptyBidCompany={latestBid ? undefined : company}
                            process={process}
                        />
                    </div>
                </div>
            </div>
        </LazyLoad>
    );
}
