import { useSelector } from "react-redux";
import { keys } from 'lodash';
import { brokerDealerBiddingActions } from '../../../../actions/brokerDealer.bidding.actions';
import { ShowFor } from '../../../access';
import { AppState } from '../../../../types/state/AppState';
import { DirectBiddingDisclaimerActionBlocker } from '../../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { FeatureButton } from '../../../access/FeatureButton';
import { roles } from '../../../../constants/roles';
import { useAppDispatch } from "../../../../effects/useAppDispatch";

interface BidAllProps {
    title?: string;
    showCount: boolean;
}

export function BidAll({ title = 'Bid All', showCount }: BidAllProps) {
    const dispatch = useAppDispatch();
    const count = useSelector((s: AppState) =>
        keys(s.brokerDealerBidding.editBid).filter(key => s.brokerDealerBidding.editBid[+key].canSubmit).length
    );
    const hasErrors = useSelector((s: AppState) =>
        keys(s.brokerDealerBidding.editBid).some(key => 
            s.brokerDealerBidding.editBid[+key].errors?.bid ||
            s.brokerDealerBidding.editBid[+key].commissionError
        )
    );

    const handleBidAll = () => dispatch(brokerDealerBiddingActions.bidAll());

    return (
        <div className="bid-all-btn-wrapper">
            <ShowFor role={roles.BrokerDealerTrader}>
                <DirectBiddingDisclaimerActionBlocker onConfirm={handleBidAll}>
                    <FeatureButton
                        className="btn btn-main"
                        disabled={!count || hasErrors}
                        onClick={handleBidAll}
                    >
                        {title}{showCount && !!count && ` (${count})`}
                    </FeatureButton>
                </DirectBiddingDisclaimerActionBlocker>
            </ShowFor>
        </div>
    );
}
