import { useSelector } from 'react-redux';
import { RequestState } from '../../../../constants/request-state';
import { AppState } from '../../../../types/state/AppState';
import { isRequesting } from '../../../../utils/request-state.utils';

export function usePositionSubmitResult(positionId: number) {
    const submitInProgress = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.submitInProgress);
    const submitInProgressPositionId = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.submitInProgressPositionId);
    const submitResult = useSelector((s: AppState) =>
        s.brokerDealerBidding.submitBidsConfirmation.submitResult[positionId]) ?? { requestState: RequestState.none };

    const isSubmitting = isRequesting(submitResult.requestState) || (
        submitInProgress &&
        (submitInProgressPositionId == null || submitInProgressPositionId === positionId)
    );

    return { isSubmitting, submitResult };
}
