import React, { ReactNode } from 'react';
import moment, { Moment } from 'moment';
import { Timer } from "./Timer";
import { constants } from '../../../constants';
import { dateTimeUtils } from '../../../utils';
import { OnHoverTooltip } from '../../common';
import { OpenBiddingStatus } from '../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { TopXSettings } from '../../../types/bwic-process/TopXSettings';
import { JumpBallSettings } from '../../../types/bwic-process/JumpBallSettings';

type TRenderFunc = (timer?: React.ReactNode, tooltip?: string) => JSX.Element;

export interface BiddingTimerProps {
    bidsDueUtc: Date;
    goodUntilUtc: Date;
    process: Process;
    render?: TRenderFunc;
    onExpired?: () => void;
}

export function BiddingTimer({ bidsDueUtc, goodUntilUtc, process, render, onExpired }: BiddingTimerProps) {
    return (
        <>
            {process.type === BwicProcessType.Standard && <StandardTimer goodUntilUtc={goodUntilUtc} render={render} />}
            {process.type === BwicProcessType.Live && <LiveBiddingTimer bidsDueUtc={bidsDueUtc} goodUntilUtc={goodUntilUtc} process={process} render={render} onExpired={onExpired} />}
            {process.type === BwicProcessType.BestFootForward && <BestFF bidsDueUtc={bidsDueUtc} goodUntilUtc={goodUntilUtc} render={render} process={process} />}
            {
                (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
                <JumpBallAndTopXTimer
                    bidsDueUtc={bidsDueUtc}
                    goodUntilUtc={goodUntilUtc}
                    stagedBiddingStatus={process.stagedBiddingStatus}
                    settings={process.type === BwicProcessType.JumpBall ? process.jumpBall! : process.topX!}
                    render={render}
                />
            }
        </>
    );
}

interface BwicEndsAtProps {
    goodUntilUtc: Date;
    text?: React.ReactNode;

}
function BwicEndsAt({ goodUntilUtc, text = 'BWIC completed at:' }: BwicEndsAtProps) {
    const endsAt = dateTimeUtils.utcToLocalString(goodUntilUtc, constants.timeFormat);
    return <span className="bwic-ends-time text-medium">{text} <span className="text-xl">{endsAt}</span></span>;
}

interface StandardTimerProps {
    goodUntilUtc: Date;
    render?: TRenderFunc;
}
function StandardTimer({ goodUntilUtc, render }: StandardTimerProps) {
    const timer = <Timer goodUntilUtc={goodUntilUtc} overCount />;

    return render?.(timer) ?? (
        <span className="bwic-ends-time text-medium">BWIC Ends in: <span className="text-xl">{timer}</span></span>
    );
}


interface StagedTimerProps {
    stageEnd: Moment;
    title: ReactNode;
    tooltip?: string;
    onExpired?: () => void;
}
function StagedTimer({ stageEnd, title, tooltip, onExpired }: StagedTimerProps) {
    return (
        <OnHoverTooltip
            overlay={tooltip}
            disabled={!tooltip}
            placement="bottomRight"
        >
            <div className="bidding-timer text-medium">
                <div className="bidding-timer-label">
                    {title}
                </div>
                <div className="bidding-timer-value text-xl">
                    <Timer goodUntilUtc={stageEnd} onExpired={onExpired?.()} />
                </div>
            </div>
        </OnHoverTooltip>
    );
}


function LiveBiddingTimer({ bidsDueUtc, goodUntilUtc, process, render, onExpired }: BiddingTimerProps) {
    if (process.stagedBiddingStatus == null && process.liveBidding?.stage1Deadline != null) { //stage 1 in progress
        const stageGoodUntil = moment.utc(bidsDueUtc).add(process.liveBidding.stage1Deadline, 'minutes');
        const tooltip = "The time cut off for Stage 1 of bidding; the selected number of top bidders will be allowed to proceed to Stage 2";

        return render?.(<Timer goodUntilUtc={stageGoodUntil} />, tooltip) ?? <StagedTimer
            stageEnd={stageGoodUntil}
            title="Stage 1 Ends in:"
            tooltip="The time cut off for Stage 1 of bidding; the selected number of top bidders will be allowed to proceed to Stage 2"
            onExpired={onExpired}
        />
    } else if (process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
        return render?.() ?? <BwicEndsAt goodUntilUtc={goodUntilUtc} text="Stage 2 Ends in:" />;
    }

    return render?.() ?? <BwicEndsAt goodUntilUtc={goodUntilUtc} text="Stage 2 is completed. BWIC Ends at:" />;
}

interface JumpBallAndTopXTimerProps {
    bidsDueUtc: Date;
    goodUntilUtc: Date;
    stagedBiddingStatus?: OpenBiddingStatus | null;
    settings: JumpBallSettings | TopXSettings;
    render?: TRenderFunc;
}

function JumpBallAndTopXTimer({ bidsDueUtc, goodUntilUtc, stagedBiddingStatus, settings, render }: JumpBallAndTopXTimerProps) {
    if (stagedBiddingStatus == null && settings.automaticStage1Deadline != null) { // stage 1 auto
        const automaticStage1DeadlineMinutes = dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes;
        const stage1End = moment.utc(bidsDueUtc).add(automaticStage1DeadlineMinutes, 'minutes');
        const tooltip = `The time cut off for Stage 1 of bidding; the Top ${settings.improverCount} bidders will be allowed to proceed to Stage 2.`;

        return render?.(<Timer goodUntilUtc={stage1End} />, tooltip) ?? <StagedTimer
            stageEnd={stage1End}
            title="Stage 1 Ends in:"
            tooltip={tooltip}
        />;
    } else if (stagedBiddingStatus == null && settings.automaticStage1Deadline == null) { // stage 1 manual
        return render?.() ?? <span className="text-medium">{`Stage 1 ${constants.emptyPlaceholder} Seller controls finish time`}</span>;
    } else if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended && settings.improvementRound != null) { // stage 2 preset
        const automaticStage1DeadlineMinutes = settings.automaticStage1Deadline
            ? dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes
            : undefined;

        const stage1End = automaticStage1DeadlineMinutes
            ? moment.utc(bidsDueUtc).add(automaticStage1DeadlineMinutes, 'minutes')
            : moment.utc(settings.stage1EndDateUtc);
        const improvementRoundMinutes = dateTimeUtils.parseTimeSpan(settings.improvementRound).totalMinutes;
        const stage2End = stage1End.add(improvementRoundMinutes, 'minutes');

        return render?.(<Timer goodUntilUtc={stage2End} />) ?? <StagedTimer stageEnd={stage2End} title="Stage 2 Ends in:" />;
    } else if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended && settings.improvementRound == null) { // stage 2 unspecified
        return render?.() ?? <span className="text-medium">{`Stage 2 ${constants.emptyPlaceholder} Seller controls finish time`}</span>;
    }

    return render?.() ?? <BwicEndsAt goodUntilUtc={goodUntilUtc} text="Stage 2 is completed. BWIC Ends at:" />;
}

interface BestFFProps {
    bidsDueUtc: Date;
    goodUntilUtc: Date;
    process: Process;
    render?: TRenderFunc;
}
function BestFF({ bidsDueUtc, goodUntilUtc, process, render }: BestFFProps) {
    if (process.stagedBiddingStatus == null && process.bff?.stage1Deadline != null) {
        const stage1DeadlineMinutes = dateTimeUtils.parseTimeSpan(process.bff.stage1Deadline).totalMinutes;
        const stage1End = moment.utc(bidsDueUtc).add(stage1DeadlineMinutes, 'minutes');
        const tooltip = "Remaining time until the end of the bidding period.";

        return render?.(<Timer goodUntilUtc={stage1End} />, tooltip) ?? <StagedTimer
            stageEnd={stage1End}
            title={"Bidding period ends in:"}
            tooltip={tooltip}
        />;
    }

    return render?.() ?? <BwicEndsAt goodUntilUtc={goodUntilUtc} text="Bidding period is completed. BWIC Ends at:" />
}
