import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { RightSidePanel, Toggle } from '../../../controls';
import { autoFeedbackSettingsActions as actions } from '../../../../actions/auto-feedback-settings-actions';
import { AutoFeedbackSettings } from '../../../../types/models/AutoFeedbackSettings';
import { AppState } from '../../../../types/state/AppState';
import { OnHoverTooltip } from '../../../common';
import { StatusMessageSection } from '../../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../../types/state/NotificationState';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BwicProcessType } from '../../../../types/models/Process';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { TopXStage2Participants } from '../../../../types/bwic-process/TopXSettings';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    scheduled?: boolean;
}

export function AutoFeedbackSettingsPanel(props: Props) {
    const autoFeedbackSettingsVisible = useSelector((s: AppState) => s.sellerBidding.autoFeedbackSettingsVisible);

    if (!autoFeedbackSettingsVisible) return null;

    return <AutoFeedbackSettingsPanelContent {...props} />;
}

function AutoFeedbackSettingsPanelContent({ scheduled }: Props) {
    const dispatch = useAppDispatch();
    const settings: AutoFeedbackSettings = useSelector((s: AppState) => s.bidding.bwic && s.bidding.bwic?.autoFeedbackConfiguration);
    const autoFeedbackSettingsSaving = useSelector((s: AppState) => s.sellerBidding.autoFeedbackSettingsSaving);
    const isAutoFeedbackBlastMessageSending = useSelector((s: AppState) => s.sellerBidding.isAutoFeedbackBlastMessageSending);
    const process = useAppSelector(s => s.bidding.bwic?.process)

    if (!settings || !process) return null;

    const enabled = !scheduled && settings.onPlatform && settings.offPlatform;
    const isTopXStage2 = process.type === BwicProcessType.TopX && process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const isThirdTextVisible = isTopXStage2 && process.topX!.improverCount >= TopXStage2Participants.Top3;
    const isFourthTextVisible = isTopXStage2 && process.topX!.improverCount >= TopXStage2Participants.Top4;
    const isFifthTextVisible = isTopXStage2 && process.topX!.improverCount === TopXStage2Participants.Top5;

    return (
        <RightSidePanel
            title="Auto-Feedback"
            onClose={() => dispatch(actions.showAutoFeedbackSettingsPanel(false))}
        >
            <div className="auto-feedback-settings">
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    <p>The system provides the following feedback:</p>
                    <ol className="list-alfa">
                        <li>to best: "<em>Best</em>"</li>
                        <li>in case if multiple dealers are best, to all of them: "<em>Tied for the best level</em>"</li>
                        <li>to cover: "<em>Cover</em>"</li>
                        {isThirdTextVisible && <li>to third: "<em>Third</em>"</li>}
                        {isFourthTextVisible && <li>to fourth: "<em>Fourth</em>"</li>}
                        {isFifthTextVisible && <li>to fifth: "<em>Fifth</em>"</li>}
                        {
                            !isTopXStage2 &&
                            <li>to participants with no bid: "<em>Please submit your bid or indicate that you pass</em>"</li>
                        }
                    </ol>
                </StatusMessageSection>

                <div className={classNames('auto-feedback-settings-options', {'toggle-disabled': scheduled})}>
                    <OnHoverTooltip
                        disabled={!scheduled}
                        overlay="The Auto-feedback feature will be available after the start of Trading."
                    >
                        <span>
                            <Toggle
                                value={enabled}
                                label={enabled ? 'Turn auto-feedback off' : 'Turn auto-feedback on'}
                                onClick={() => dispatch(actions.toggleAutofeedback())}
                                disabled={autoFeedbackSettingsSaving || isAutoFeedbackBlastMessageSending || scheduled}
                            />
                        </span>
                    </OnHoverTooltip>
                </div>
            </div>
        </RightSidePanel>
    );
}
