import React, { Component } from 'react';
import { connect } from 'react-redux';
import { brokerDealerAccessState } from '../../../../constants/bidding';
import { brokerDealerBiddingActions as actions } from '../../../../actions';
import { RequestInvitationDialog } from '.';

class RequestInvitationPanel extends Component {
    state = {
        requestInviteDisalogVisible: false
    }

    handleRequestInviteDialog = () => this.setInviteDialogVisibility(true);

    handleRequestInviteDialogClose = () => this.setInviteDialogVisibility(false);

    handleInvitationSend = comment => {
        this.setInviteDialogVisibility(false);
        this.props.dispatch(actions.sendInviteRequest(comment));
    }

    setInviteDialogVisibility = visible => this.setState({ requestInviteDisalogVisible: visible });

    renderNotInvited = () => {
        return (
            <>
                <div className="invitation-panel flex-row">
                    <div className="invitation flex-row">
                        <div className="flex-none">
                            <i className="icon icon-lock"></i>
                            <b>You need an invite from the seller</b>
                        </div>
                    </div>
                </div>
                {
                    this.state.requestInviteDisalogVisible &&
                    <RequestInvitationDialog
                        onSend={this.handleInvitationSend}
                        onClose={this.handleRequestInviteDialogClose}
                    />
                }
             </>
        );
    }

    renderAccessRequested = () => {
        return (
            <div className="flex-row">
                <div className="flex-none">
                    <b>The invite request has been sent</b>
                    <span>You will be able to bid once approved</span>
                </div>
            </div>
        );
    }

    render = () => {
        switch (this.props.accessState) {
            case brokerDealerAccessState.notInvited: return this.renderNotInvited();
            case brokerDealerAccessState.accessRequested: return this.renderAccessRequested();
            default: return null;
        }
    }
}

const mapStateToProps = ({ bidding, brokerDealerBidding }) => ({
    bwic: bidding.bwic,
    accessState: brokerDealerBidding.accessState
});

const connectedRequestInvitationPanel = connect(mapStateToProps)(RequestInvitationPanel);
export { connectedRequestInvitationPanel as RequestInvitationPanel }; 
