import * as React from 'react';
import cn from 'classnames';
import { values } from 'lodash';
import { ScrollSyncPane } from 'react-scroll-sync';
import { StickyTableCollapsibleRow } from './StickyTableCollapsibleRow';
import { SecurityListContent } from '../../../../common/security-list';
import { TableColumnStickType } from '../types/TableColumnStickType';
import { ColumnBuilder } from '../columns/column-builder/ColumnBuilder';
import { useDispatch } from 'react-redux';
import { tableActions } from '../../../../../actions/table.actions';
import { apiUtils } from '../../../../../utils';
import { StickyTableRow } from './StickyTableRow';
import { TableGroupingRow } from './TableGroupingRow';

interface Props<T> {
    tableKey?: string;
    dataItems: T[];
    columns: ColumnBuilder<T>[][];
    infiniteScrollEnabled?: boolean;
    isNextPageRequesting?: boolean;
    renderFooterRow?: (columns: ColumnBuilder<T>[]) => React.ReactNode;
    onNextPageRequest?: () => void;
    createSecurityCustomClassName?: (dataItem: T, context: { [key: string]: any }) => string | undefined;
    createSecurityCustomArgs?: (dataItem: T, index: number) => { [key: string]: any };
    createRowCustomKey?: (dataItem: T, index: number) => string | undefined;
    expandAll?: boolean;
    collapsible: boolean;
    headerMiddleScrollRef: React.RefObject<HTMLDivElement>,
    headerMiddleContentRef: React.RefObject<HTMLDivElement>,
    onExpandChange: () => void;
    onRowClick?: (dataItem: T) => void;
    onRowHover?: (dataItem: T) => void;
    shouldRenderCollapsibleRowCallback?: (dataItem: T) => boolean;
    renderCollapsibleContent: (dataItem: T, type?: TableColumnStickType) => React.ReactNode | React.ReactNode[];
    renderGroupByRow?: (current: T, prev?: T, columnType?: TableColumnStickType) => React.ReactNode;
    renderLastRow?: () => React.ReactNode;
}

export function StickyTableContent<T>({
    tableKey,
    dataItems,
    columns,
    infiniteScrollEnabled = false,
    renderFooterRow,
    onNextPageRequest,
    isNextPageRequesting,
    createSecurityCustomClassName,
    createSecurityCustomArgs,
    createRowCustomKey,
    expandAll = false,
    collapsible = false,
    headerMiddleScrollRef,
    headerMiddleContentRef,
    onRowClick,
    onRowHover,
    onExpandChange,
    shouldRenderCollapsibleRowCallback,
    renderCollapsibleContent,
    renderGroupByRow,
    renderLastRow,
}: Props<T>) {
    const dispatch = useDispatch();

    const leftColumnRef = React.useRef<HTMLDivElement>(null);
    const rightColumnRef = React.useRef<HTMLDivElement>(null);
    const middleColumnRef = React.useRef<HTMLDivElement>(null);
    const middleColumnContentRef = React.useRef<HTMLDivElement>(null);
    const fakeScrollRef = React.useRef<HTMLDivElement>(null);
    const fakeScrollContentRef = React.useRef<HTMLDivElement>(null);

    const setFakeScrollDimensions = () => {
        if (
            middleColumnRef.current &&
            middleColumnContentRef.current &&
            fakeScrollRef.current &&
            fakeScrollContentRef.current &&
            headerMiddleScrollRef.current &&
            headerMiddleContentRef.current &&
            (leftColumnRef.current || rightColumnRef.current)
        ) {
            fakeScrollContentRef.current.style.width = `${headerMiddleContentRef.current.offsetWidth}px`;
            middleColumnContentRef.current.style.width = `${headerMiddleContentRef.current.offsetWidth}px`;
            fakeScrollRef.current.style.width = `${headerMiddleScrollRef.current.offsetWidth}px`;
            if (leftColumnRef.current) {
                fakeScrollRef.current.style.left = `${leftColumnRef.current.offsetWidth}px`;
            }
        }
    }

    React.useEffect(() => {
        return () => {
            if (!tableKey) {
                dispatch(tableActions.reset());
            }
        }
    }, [tableKey, dispatch]);

    React.useLayoutEffect(() => {
        const observer = new ResizeObserver(setFakeScrollDimensions);
        const ref = middleColumnRef.current;
        if (ref && observer) {
            observer.observe(ref);
        }
        return () => {
            if (ref && observer) {
                observer.unobserve(ref);
            }
        }
    });

    const rowsData = apiUtils.normalize(
        dataItems ?? [],
        (item, index) => (createRowCustomKey && createRowCustomKey(item, index)) || index,
        (item, index) => {
            const context = { rowIndex: index, ...createSecurityCustomArgs?.(item, index) };
            return {
                context,
                collapsible: shouldRenderCollapsibleRowCallback ? shouldRenderCollapsibleRowCallback(item) : collapsible,
                className: createSecurityCustomClassName?.(item, context)
            };
        }
    );

    if (!dataItems) return null;

    const [leftColumns = [], middleColumns = [], rightColumns = []] = columns;
    const hasCollapsibleRows = collapsible || values(rowsData).some(data => data.collapsible);

    const renderRowPart = (
        dataItem: T,
        columns: ColumnBuilder<T>[],
        index: number,
        columnType: TableColumnStickType,
        collapseIconVisible: boolean = false) => {
        const key = (createRowCustomKey && createRowCustomKey(dataItem, index)) || index;
        const context = rowsData[key].context;
        const shouldRenderCollapsibleRow = rowsData[key].collapsible
        const className = rowsData[key].className;

        if (shouldRenderCollapsibleRow) {
            return (
                <StickyTableCollapsibleRow
                    key={key}
                    rowKey={String(key)}
                    dataItem={dataItem}
                    columns={columns}
                    columnType={columnType}
                    className={className}
                    collapseIconVisible={collapseIconVisible}
                    expandAll={expandAll}
                    context={context}
                    renderCollapsibleContent={renderCollapsibleContent}
                    onExpandChange={onExpandChange}
                    onHover={onRowHover}
                />
            );
        }

        return (
            <TableGroupingRow
                key={key}
                renderGroupByRow={renderGroupByRow?.(dataItem, dataItems[index - 1], columnType)}
            >
                <StickyTableRow
                    rowKey={String(key)}
                    className={cn(className, { 'collapsible-list-shift': hasCollapsibleRows })}
                    columns={columns}
                    dataItem={dataItem}
                    onClick={onRowClick}
                    onHover={onRowHover}
                    context={context}
                />
            </TableGroupingRow>
        );
    };

    return (
        <>
            <SecurityListContent
                isNextPageRequesting={isNextPageRequesting}
                infiniteScrollEnabled={infiniteScrollEnabled}
                onNextPageRequest={onNextPageRequest}
                sticky={true}
            >
                <div className="data-list-columns">
                    {
                        !!leftColumns.length &&
                        <ScrollSyncPane group="vertical" innerRef={leftColumnRef}>
                            <div className="data-column-sticky data-column-sticky-left">
                                {dataItems.map((item, index) => renderRowPart(item, leftColumns, index, TableColumnStickType.Left, true))}
                            </div>
                        </ScrollSyncPane>
                    }
                    <ScrollSyncPane group={['horizontal', 'vertical'] as unknown as string} innerRef={middleColumnRef}>
                        <div className="data-column-sticky data-column-sticky-middle">
                            <div
                                ref={middleColumnContentRef}
                                className="middle-sticky-content"
                            >
                                {dataItems.map((item, index) => renderRowPart(item, middleColumns, index, TableColumnStickType.Middle, !leftColumns.length))}
                            </div>
                        </div>
                    </ScrollSyncPane>
                    {
                        !!rightColumns.length &&
                        <ScrollSyncPane group="vertical" innerRef={rightColumnRef} >
                            <div className="data-column-sticky data-column-sticky-right">
                                {dataItems.map((item, index) => renderRowPart(item, rightColumns, index, TableColumnStickType.Right))}
                            </div>
                        </ScrollSyncPane>
                    }
                </div>
                {renderLastRow && renderLastRow()}
                {renderFooterRow && renderFooterRow(columns.flat())}
            </SecurityListContent>
            <ScrollSyncPane group="horizontal" innerRef={fakeScrollRef}>
                <div className="fake-horizontal-scroll">
                    <div ref={fakeScrollContentRef} className="fake-element" />
                </div>
            </ScrollSyncPane>
        </>
    );
}
