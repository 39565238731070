import * as React from 'react';
import IconSVG from '../../../../../../styles/svg-icons';
import { Axed } from '../../../bid-placement/Axed';
import { OnHoverTooltip } from '../../../../../common';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { biddingUtils } from '../../../../../../utils';
import { BwicPosition } from '../../../../../../types/bwic/BwicPosition';
import { BwicBase } from '../../../../../../types/bwic/BwicBase';
import { AllToAllArgeementRequiredActionBlocker } from '../../../../../agreements/AllToAllArgeementRequiredActionBlocker';

const ColumnHeader = () => {
    return (
        <SecurityListHeaderColumn className='data-list-cell-xxs padding-l-0 text-center'>
            <OnHoverTooltip overlay="Axed">
                <IconSVG name="axed" width={16} height={16} />
            </OnHoverTooltip>
        </SecurityListHeaderColumn>
    );
}

export const axed = {
    columnName: 'axed',
    renderHeader: function () {
        return <ColumnHeader key={this.columnName} />;
    },
    renderBody: function (position: BwicPosition, context: { biddingControlsVisible: boolean, enabled: boolean, bwic: BwicBase }) {
        const { biddingControlsVisible, enabled } = context;
        const currentBid = biddingUtils.getCurrentBid(position.bids);

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xxs padding-l-0 text-center">
                {biddingControlsVisible &&
                    <AllToAllArgeementRequiredActionBlocker disabled={!context.bwic.isAllToAll}>
                        {
                            blocked => (
                                <Axed
                                    currentAxed={Boolean(currentBid?.axed)}
                                    currentPass={Boolean(currentBid?.pass)}
                                    positionId={position.id}
                                    disabled={!enabled || blocked}
                                />
                            )
                        }
                    </AllToAllArgeementRequiredActionBlocker>}
                {!biddingControlsVisible && Boolean(currentBid?.axed) && <IconSVG name="axed" width={16} height={16} />}
            </SecurityListColumn>
        );
    }
};
