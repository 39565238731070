import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colorDistributionActions as actions } from '../../../../actions';
import { colorDistributionTypes } from '../../../../constants';
import { formatUtils, biddingUtils } from '../../../../utils';
import IconSVG from '../../../../styles/svg-icons';
import { BidLevel } from '../../../../types/bidding/BidLevel';

class ColorLabel extends Component {
    renderRevertLink = predefinedType => {
        const { position, dispatch } = this.props;

        return (
            <button
                className="btn-link flex-item-right"
                onClick={() => dispatch(actions.positionColorTypeChange(position.id, predefinedType))}
            >
                <IconSVG name="undo" width={16} height={16} />
                Revert
            </button>
        );
    }

    renderTraded = () => {
        const { position } = this.props;
        const { forTradedType } = this.props.colorDistribution;
        const colorDistribution
            = position.colorDistribution == null
                ? forTradedType
                : position.colorDistribution;
        const canRevert = position.colorDistribution != null && position.colorDistribution !== forTradedType;

        let label = '';

        if (colorDistribution === colorDistributionTypes.status.key) {
            label = 'Traded';
        } else if (colorDistribution === colorDistributionTypes.coverHandle.key) {
            const cover = biddingUtils.getBidsByLevel(position.bids, BidLevel.Cover);
            if (cover && cover.length) {
                label = cover[0].valueHandle;
            }
        } else if (colorDistribution === colorDistributionTypes.coverExact.key) {
            const cover = biddingUtils.getBidsByLevel(position.bids, BidLevel.Cover);
            if (cover && cover.length) {
                label = formatUtils.formatBid(cover[0].value);
            }
        }

        return (
            <>
                {
                    !!label &&
                    <>
                        <span className="status active"></span>
                        <label>{label}</label>
                    </>
                }
                {canRevert && this.renderRevertLink(forTradedType)}
            </>
        );
    }

    renderDNT = () => {
        const { position } = this.props;
        const { forDNTType } = this.props.colorDistribution;
        const colorDistribution = position.colorDistribution == null
            ? forDNTType
            : position.colorDistribution;
        const canRevert = position.colorDistribution != null && position.colorDistribution !== forDNTType;

        let label = '';

        if (colorDistribution === colorDistributionTypes.status.key || !position?.bids?.length) {
            label = 'DNT';
        } else {
            const best = biddingUtils.getBidsByLevel(position.bids, BidLevel.Best);
            if (best && best.length) {
                if (colorDistribution === colorDistributionTypes.bestHandle.key) {
                    label = `DNT ${best[0].valueHandle}`;
                } else if (colorDistribution === colorDistributionTypes.bestExact.key) {
                    label = `DNT ${formatUtils.formatBid(best[0].value)}`;
                }
            }
        }

        return (
            <>
                {
                    !!label &&
                    <>
                        <span className="status blocked"></span>
                        <label>{label}</label>
                    </>
                }
                {canRevert && this.renderRevertLink(forDNTType)}
            </>
        );
    }

    render = () => this.props.traded ? this.renderTraded() : this.renderDNT();
}

const mapStateToProps = ({ sellerBidding }) => ({
    colorDistribution: sellerBidding.colorDistribution
});

const connectedColorLabel = connect(mapStateToProps)(ColorLabel);
export { connectedColorLabel as ColorLabel };
