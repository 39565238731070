import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../common';
import { PxTalkChange } from '../../../brokerDealer/scheduledSecurities/PxTalkChange';

export const pxTalkChange = {
    columnName: 'pxTalkChange',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm cell-input text-right">
                My Px Talk, Price
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm cell-input text-right overflow-visible">
                <PxTalkChange
                    bwicReferenceName={context.bwic.referenceName}
                    position={position}
                />
            </SecurityListColumn>
        );
    }
};
