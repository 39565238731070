import React from 'react';
import { routes } from '../../../constants';
import { BwicStatusLabel } from "../../common";
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import { SameDayBwicIcon } from '../../common/SameDayBwicIcon';
import { BwicProcessType } from '../../../types/models/Process';
import { dateTimeUtils } from '../../../utils';
import { BwicBase } from '../../../types/bwic/BwicBase';

interface TopPanelProps {
    children?: React.ReactNode
    bwic: BwicBase
}

const TopPanel = ({ children, bwic }: TopPanelProps) => {
    const {
        status, name, isParsed, bidsDueUtc, isClearingBankParticipant,
        process, isSameDayBwic, isAllToAll
    } = bwic;

    return (
        <div className="sub-header-row type01 flex-row">
            <Breadcrumbs>
                <BreadcrumbsItem route={routes.allBWICs} text='BWIC Monitor' />
            </Breadcrumbs>
            <BwicStatusLabel
                status={status}
                liveBidding={process?.type === BwicProcessType.Live}
                isParsed={isParsed}
                directBidding={isClearingBankParticipant}
                isAllToAll={isAllToAll}
            />

            {!!bidsDueUtc &&
                <h1 className="bwic-due flex-row">
                    {isSameDayBwic && <SameDayBwicIcon />}
                    {dateTimeUtils.utcToLocalString(bidsDueUtc)}
                </h1>
            }
            <div className="header-bwic-rules text-xl">{name}</div>
            {children}
        </div>
    );
}

export default TopPanel
