import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '../common';
import { isRequesting } from '../../utils';
import { blotterExportRequest } from '../../actions/blotter.actions';
import { AppState } from '../../types/state/AppState'
import { RequestState } from '../../constants/request-state';
import IconSVG from '../../styles/svg-icons';

export function BlotterExportButton() {
    const dispatch = useDispatch();
    const { securities } = useSelector((s: AppState) => s.blotter);
    const { requestStateSecurities } = useSelector((s: AppState) => s.blotter);
    const { requestStateExport } = useSelector((s: AppState) => s.blotter);

    return (
        <Preloader small={true} fullScreen={false} inProgress={isRequesting(requestStateExport)} text="Exporting…">
            <button
                disabled={!securities.length || requestStateSecurities === RequestState.request}
                onClick={() => dispatch(blotterExportRequest())}
                className="btn btn-link"
            >
                <IconSVG name="export" width={16} height={16} /> Export
        </button>
        </Preloader>
    );
}
