import * as React from 'react';
import cn from 'classnames';
import { IColumnDefinition } from '../../types/ColumnDefinition';
import { SecurityListColumn } from '../../../index';
import { ColumnHeaderWrapper } from './ColumnHeaderWrapper';

export class ColumnBuilder<TEntity> {
    constructor(column: IColumnDefinition<TEntity>) {
        this.column = column;
    }

    column: IColumnDefinition<TEntity>;

    renderHeader(
        sortBy?: string,
        sortDirection?: string,
        onClick?: (fieldName: string) => void,
        context?: any,
    ) {
        const columnHeaderContent = this.column.renderColumnHeaderContent(context);

        const headerClassName = cn(this.column.className ?? this.column.headerClassName, {
            'hide-column-stick-right': this.column.stickRight
        })

        return (
            <ColumnHeaderWrapper
                key={this.column.columnKey}
                columnTitle={columnHeaderContent}
                headerOnHoverText={this.column.headerOnHoverText}
                onClick={onClick}
                headerClassName={headerClassName}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sortingType={this.column.sortingType}
                sortingField={this.column.sortingField}
            />
        );
    }

    renderBody(entity: TEntity, context: any) {
        const columnContent = this.column.renderColumnContent(entity, context);
        return (
            <SecurityListColumn
                dataCellId={this.column.columnKey}
                key={this.column.columnKey}
                className={this.column.className ?? this.column.bodyClassName}
                onClick={() => {}}
            >
                {columnContent}
            </SecurityListColumn>
        );
    }
}
