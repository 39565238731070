import React from 'react';
import { useSelector } from 'react-redux';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { brokerDealerAccessState } from '../../../../../../constants/bidding';
import { arrayUtils, biddingUtils } from '../../../../../../utils';
import { OpenBiddingStatus } from '../../../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType } from '../../../../../../types/models/Process';
import { BiddingInfo } from '../../../../brokerDealer/biddingSecurities/BiddingInfo';
import { BwicHistoryActionType } from '../../../../../../types/bwic-history/BwicHisoryActionType';

function BiddingInfoBody({ position, args }) {
    const { bwic } = args;
    const currentBid = biddingUtils.getCurrentBid(position.bids);

    // Find 'Please submit your final level' or other requests
    const bidRequestAction = useSelector(state =>
        currentBid
            ? undefined
            : arrayUtils.findLast(
                state.bwicHistory.actions,
                action =>
                    action.positionId === position.id &&
                    action.actionType === BwicHistoryActionType.QuickFeedback
            )
    );

    if (args.brokerDealerAccessState === brokerDealerAccessState.notInvited) return null;

    if (
        bwic.process.type === BwicProcessType.JumpBall &&
        bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
        bwic.process.jumpBall?.stage1EndDateUtc &&
        bidRequestAction?.sentDateUtc) {
    }

    return <BiddingInfo
        process={bwic.process}
        canBidOnStage2={
            (bwic.process.type === BwicProcessType.Live && position.canBidOnStage2) ||
            (bwic.process.type === BwicProcessType.JumpBall && position.isStage2Participant) ||
            (bwic.process.type === BwicProcessType.TopX && position.isStage2Participant)
        }
        isColorDistribution={bwic.isColorDistribution}
        bwicStatus={bwic.status}
        expired={args.liveBiddingStage2Expired}
        currentBid={currentBid}
    />
}

export const biddingInfo = {
    columnName: 'biddingInfo',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-lg-02 cell-bidding-info">
                Bidding Info
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, args) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg-02 cell-bidding-info">
                <BiddingInfoBody position={position} args={args} />
            </SecurityListColumn>
        );
    }
};
