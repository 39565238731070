import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colorDistributionActions as actions } from '../../../../actions';
import { colorDistributionTypes } from '../../../../constants';
import { BlockedFeatureContext } from '../../../access/BlockedFeatureContext';

class ColorType extends Component {
    render = () => {
        const { position, traded, dispatch } = this.props;
        const isAnyBidExist = !!position?.bids?.length;
        const { forTradedType, forDNTType } = this.props.colorDistribution;
        const predefinedDistributionColor = traded ? forTradedType : forDNTType;
        const sourceValues = traded
            ? colorDistributionTypes.traded()
            : colorDistributionTypes.dnt();
        const selectedDistributionColor = position.colorDistribution == null
            ? predefinedDistributionColor
            : position.colorDistribution;
        const typeOptionsToDisplay = isAnyBidExist ? sourceValues: [colorDistributionTypes.status];

        return (
            <BlockedFeatureContext.Consumer>
                {
                    isBlocked => 
                        <select
                            className="form-control form-control-sm form-select"
                            value={isAnyBidExist ? selectedDistributionColor: colorDistributionTypes.status.key}
                            disabled={isBlocked || !isAnyBidExist}
                            onChange={e => dispatch(actions.positionColorTypeChange(position.id, e.target.value))}
                        >
                            {typeOptionsToDisplay.map(t => <option key={t.key} value={t.key}>{t.title}</option>) }
                        </select>
                }
            </BlockedFeatureContext.Consumer>
        );
    }
}

const mapStateToProps = ({ sellerBidding }) => ({
    colorDistribution: sellerBidding.colorDistribution
});

const connectedColorType = connect(mapStateToProps)(ColorType);
export { connectedColorType as ColorType };
