import React, { Component } from 'react';
import { biddingUtils, numericUtils, moneyUtils } from '../../../../utils';
import { OnHoverTooltip } from "../../../common";
import { constants } from '../../../../constants';
import { BidLevel } from '../../../../types/bidding/BidLevel';

export default class PnL extends Component {
    render = () => {
        const { targetLevel, bids, size } = this.props;
        const [best] = biddingUtils.getBidsByLevel(bids, BidLevel.Best);

        if (!best || !numericUtils.isNumber(targetLevel)) {
            return constants.emptyPlaceholder;
        }

        const pnl = numericUtils.round(Number(best.value) - Number(targetLevel), 4) * size / 100;
        const money = moneyUtils.money(pnl, true);

        return (
            <OnHoverTooltip overlay={money}>
                <span className="text-ellipsis">{money}</span>
            </OnHoverTooltip>
        )
    }
}
