import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { HistoryButton } from '../../../../bwic-history/HistoryButton';

export const history = {
    columnName: 'history',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-lg-02 cell-button-history overflow-visible text-right">
                Actions
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xxs cell-button-history overflow-visible text-right">
                <HistoryButton positionId={position.id} ticker={position.ticker} />
            </SecurityListColumn>
        );
    }
};
