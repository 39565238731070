import React from 'react';
import cn from 'classnames';
import IconSVG from "../../../styles/svg-icons";

interface Props {
    phone: string;
    className?: string;
    icon?: boolean;
}

export function PhoneNumberLink({ phone, className, icon }: Props) {
    return (
        <a
            href={"tel:" + phone.replace(/\D/g, '')}
            className={cn(className)}
        >
            {icon && <IconSVG name="phone" width="16" height="16" />}
            <span>{phone}</span>
        </a>
    );
}
