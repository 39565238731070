import React from 'react';
import { SecurityListHeaderColumn } from '../../../../../common/security-list';
import { OnHoverTooltip, Relative } from '../../../../../common';

interface ColumnHeaderWrapperProps {
    columnTitle: string | React.ReactNode;
    onClick?: (columnName: string, sortingType?: string) => void;
    headerOnHoverText?: string;
    headerClassName?: string;
    sortBy?: string;
    sortDirection?: string;
    sortingField?: string;
    sortingType?: string;
}

export function ColumnHeaderWrapper(
    {
        columnTitle,
        headerOnHoverText,
        onClick,
        headerClassName,
        sortBy,
        sortDirection,
        sortingField,
        sortingType,
    }: ColumnHeaderWrapperProps
) {
    const handleClick = () => {
        if (sortingField && onClick) {
            onClick(sortingField, sortingType)
        }
    };

    return (
        <SecurityListHeaderColumn
            className={headerClassName}
            onClick={handleClick}
            sortDirection={sortDirection}
            sorted={sortingField ? sortBy === sortingField : undefined}
        >
            <Relative className="align-top" inline={true}>
                <OnHoverTooltip overlay={headerOnHoverText}>
                    {columnTitle}
                </OnHoverTooltip>
            </Relative>
        </SecurityListHeaderColumn>
    )
}
