import * as React from 'react';
import { OnHoverTooltip } from "../../../common/OnHoverTooltip";

interface FeedbackLabelProps {
    text: React.ReactNode;
    onHoverText?: React.ReactNode;
    italic?: boolean;
    pending?: boolean;
}

export function FeedbackLabel({ text, onHoverText, italic, pending }: FeedbackLabelProps) {
    if (!onHoverText) {
        onHoverText = text;
    }
    if (pending) {
        return (
            <div className="position-relative">
                <OnHoverTooltip overlay={onHoverText}>
                    <span className="status status-color status-pending">{text}</span>
                </OnHoverTooltip>
            </div>
        )
    }
    if (italic) {
        return (
            <div className="position-relative text-ellipsis">
                <OnHoverTooltip overlay={onHoverText}>
                    <i>{text}</i>
                </OnHoverTooltip>
            </div>
        )
    }
    return (
        <div className="position-relative text-ellipsis">
            <OnHoverTooltip overlay={onHoverText}>
                <span> {text}</span>
            </OnHoverTooltip>
        </div>
    )
}
