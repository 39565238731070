import * as React from 'react';
import { useSelector } from 'react-redux';
import { bidPlacementActions } from '../../../../actions/bid-placement.actions';
import { AppState } from '../../../../types/state/AppState';
import { Checkbox } from '../../../controls/Checkbox';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    disabled: boolean;
    currentAxed: boolean;
    currentPass: boolean;
}

export function Axed({ positionId, disabled, currentAxed, currentPass }: Props) {
    const dispatch = useAppDispatch();
    const bid = useSelector((s: AppState) => s.brokerDealerBidding.editBid[positionId])
    const axed = bid?.axed ?? currentAxed ?? false;
    const pass = bid?.pass == null ? currentPass : bid.pass;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        dispatch(bidPlacementActions.axedChange(positionId, e.target.checked))
    };

    return <Checkbox onChange={handleChange} checked={axed} disabled={disabled || pass} />
}
