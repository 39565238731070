import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';

export const ccy = {
    columnName: 'currency',
    renderHeader: function (sortBy, sortDirection, onClick) {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-xs"
                sorted={sortBy === 'currency'}
                sortDirection={sortDirection}
                onClick={onClick ? () => onClick(this.columnName) : null}
            >
                Ccy
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xs">
                {position.currency}
            </SecurityListColumn>
        );
    }
};
