import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BlockedFeatureContext } from '../../../access/BlockedFeatureContext';
import { OnHoverTooltip } from '../../../common';

export function TabItem({
    active,
    title,
    path = '',
    tooltip = '',
    disabled = false,
    disabledTooltip = "",
    notificationLabel = 'NEW',
    notificationCount = 0,
    counter = 0,
    onClick
}) {
    const isBlocked = useContext(BlockedFeatureContext);

    const renderNotifications = () => {
        if (notificationCount) {
            return <span className="badge">{notificationCount} {notificationLabel}</span>
        }
        return null;
    }

    const renderDisabled = () => {
        const content = <button className="btn btn-link tabs-item-link" disabled={true}>{title}{renderNotifications()}</button>;

        if (disabledTooltip) {
            return (
                <OnHoverTooltip overlay={disabledTooltip}>
                    {content}
                </OnHoverTooltip>
            );
        }

        return content;
    }

    const formatTitle = () => counter ? `${title} (${counter})` : title


    return (
        <li className={classNames("tabs-item", { active })}>
            {
                disabled || isBlocked
                    ? renderDisabled()
                    : (
                        <OnHoverTooltip overlay={tooltip}>
                            {path
                                ? <Link className="tabs-item-link" to={path}>{formatTitle()} {renderNotifications()}</Link>
                                : <button className="btn btn-link tabs-item-link" onClick={onClick}>{formatTitle()} {renderNotifications()}</button>}
                        </OnHoverTooltip>)
            }
        </li>
    );
}

TabItem.defaultProps = {
    onClick: null,
    state: undefined
}
