import classNames from 'classnames';
import { BiddingTabs, tradeStatuses } from '../../../constants/bidding';
import { CompletionTime, TopPanel } from '../common';
import { Participants } from '../common/participants';
import SubHeader from '../common/SubHeader';
import { Table } from '../common/table';
import { ControlPanel } from '../common/ControlPanel';
import { getSecuritiesWithLatestBids, getSellerFinishedCompanies } from '../../../selectors';
import { getSellerFinishedColumns } from './columns';
import { BwicProcessType } from '../../../types/models/Process';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicPosition } from '../../../types/bwic/BwicPosition';
import { BidderCompanySlim } from '../../../types/company/BidderCompanySlim';

interface FinishedProps {
    activeTab?: BiddingTabs;
    activePositionId: number;
    rightSidePanelVisible: boolean;
}

export const Finished = ({ activeTab, activePositionId, rightSidePanelVisible }: FinishedProps) => {

    const bwic = useAppSelector((s) => s.bidding.bwic);
    const settlementAgentAgreements = useAppSelector((s) => s.entities.settlementAgentAgreements.items);
    const securities = useAppSelector((s) => getSecuritiesWithLatestBids({ securities: s.sellerBidding.securities }));
    const visibleCompanies = useAppSelector((s) => (
        getSellerFinishedCompanies({
            participants: s.bidding.bwic.companies,
            offPlatformCompanies: s.bidOnBehalf.companies,
            favorites: s.favoriteBrokerDealers.favorites,
            securities,
            agreements: s.entities.settlementAgentAgreements.items,
            isAllToAll: s.bidding.bwic?.isAllToAll
        })
    )) as BidderCompanySlim[]

    const createSecurityCustomArgs = () => {
        return {
            bwic,
            allowFeedback: false,
            targetLevelReadonly: true,
            bwicStatus: bwic.status
        };
    };

    const createSecurityCustomClassName = (security: BwicPosition) => {
        const tradeStatus = security.trade && security.trade.status;
        const pending = tradeStatus === tradeStatuses.pending.key;
        const affirmed = tradeStatus === tradeStatuses.affirmed.key;
        const rejected = tradeStatus === tradeStatuses.rejected.key;

        return classNames('pointer', {
            active: security.id === activePositionId,
            pending,
            affirmed,
            rejected
        });
    }
    const completedDateUtc = bwic.completedDateUtc || bwic.goodUntilUtc;

    const dataListClasses = classNames({
        'data-list-seller-finished': true,
        'data-list-short': rightSidePanelVisible
    });

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    <div className="flex-item-right controls">
                        {completedDateUtc && <CompletionTime
                            completedDateUtc={completedDateUtc}
                        />}
                    </div>
                </TopPanel>
                <SubHeader bwic={bwic} />
            </div>
            <div className="flex-row flex-row-full-size">
                {activeTab === BiddingTabs.participants
                    ? <Participants />
                    : (
                        <Table
                            sticky={true}
                            className={dataListClasses}
                            dataItems={securities}
                            columns={getSellerFinishedColumns(
                                rightSidePanelVisible,
                                visibleCompanies,
                                bwic.process.type !== BwicProcessType.Live,
                                settlementAgentAgreements,
                                bwic.isAllToAll)
                            }
                            createSecurityCustomArgs={createSecurityCustomArgs}
                            createSecurityCustomClassName={createSecurityCustomClassName}
                            createRowCustomKey={(position: BwicPosition) => position.id}
                        />
                    )
                }
                <ControlPanel
                    bwic={bwic}
                    bidListEnabled={true}
                    positions={securities}
                />
            </div>
        </div>
    );
}
