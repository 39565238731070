import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { blotterCustomDateFilterChange, blotterDateFilterChange, blotterFiltersReset, blotterResetCurrency, blotterSearchRequest, blotterSelectAllCurrencies, blotterSetCurrency, blotterSettlementCustomDateFilterChange, blotterSettlementDateFilterChange, blotterToggleAllocationStatusPendingAllocation, blotterToggleAllocationStatusPendingProceeds, blotterToggleAllocationStatusPendingSettlement, blotterToggleAllocationStatusSettled, blotterToggleAllocationStatusUnsettled, blotterToggleConfirmed, blotterToggleFilterBuy, blotterToggleFilterClient, blotterToggleFilterDesc, blotterToggleFilterSell, blotterTogglePending, blotterToggleRejected, controlPanelActions } from '../../actions';
import { bwicDateFilterOptions, roles } from '../../constants';
import { RequestState } from '../../constants/request-state';
import { BlotterSearchResult } from '../../types/blotter/BlotterSearchResult';
import { Currencies, Currency } from '../../types/enums/Currency';
import { TradeSettlementStatus, tradeSettlementStatusTitle } from '../../types/settlement/TradeSettlementStatus';
import { AppState } from '../../types/state/AppState';
import { TradeSide } from '../../types/trades/TradeSide';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { TradeType, tradeTypeTitle } from '../../types/trades/TradeType';
import { isRequesting, isRequestNone } from '../../utils';
import { ShowFor } from '../access';
import { DateRangeSelector } from '../common';
import { FilterSelect } from '../common/filters/FilterSelect';
import { tradeStatuses } from '../../constants/bidding/trade.statuses';

import { FilterButton, FilterClear, FilterPanel, FilterSection, SecuritiesSearchInput } from '../filters';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    trades: BlotterSearchResult[];
}

export function BlotterTradesFilter({ trades }: Props) {
    const dispatch = useAppDispatch();

    const filters = useSelector((s: AppState) => s.blotter.filters);
    const initialFilters = useSelector((s: AppState) => s.blotter.initialFilters);
    const prevFilters = useSelector((s: AppState) => s.blotter.prevFilters);
    const requestStateSecurities = useSelector((s: AppState) => s.blotter.requestStateSecurities);
    const searchSecuritiesItems = useSelector((s: AppState) => s.searchSecurities.searchTermItems) ?? [];
    const hasTrades = useSelector((s: AppState) => s.blotter.ownTrades.hasTrades);

    const filtersDisabled = isRequestNone(requestStateSecurities) || isRequesting(requestStateSecurities) || !hasTrades;
    const isClearFilterShown = !filtersDisabled && !isEqual(filters, initialFilters);
    const filtersChanged = !isEqual(filters, prevFilters);

    const search = () => dispatch(blotterSearchRequest());

    const resetFilters = () => {
        dispatch(controlPanelActions.hide());
        dispatch(blotterFiltersReset());
        search();
    };


    return (
        <FilterPanel>
            <FilterSection>
                <SecuritiesSearchInput
                    className="small-size"
                    compactView={true}
                    onSearch={() => dispatch(blotterSearchRequest())}
                    disabled={
                        requestStateSecurities === RequestState.request ||
                        (!trades.length && !searchSecuritiesItems.length) ||
                        !hasTrades
                    }
                />
            </FilterSection>
            <FilterSection>
                <span className="vertical-divider" />
            </FilterSection>
            <FilterSection>
                <DateRangeSelector
                    restyled={true}
                    title="Trade Date"
                    isApplied={prevFilters.selectedDateOption === filters.selectedDateOption}
                    onReset={() => dispatch(blotterDateFilterChange(bwicDateFilterOptions.unspecified))}
                    disabled={filtersDisabled}
                    selectedDateOption={filters.selectedDateOption}
                    customDateRange={filters.customDateRange}
                    onSelectedDateChange={(date: Date) => dispatch(blotterDateFilterChange(date))}
                    onCustomDateChange={(date: Date) => dispatch(blotterCustomDateFilterChange(date))}
                    acceptedOptions={[
                        bwicDateFilterOptions.unspecified,
                        bwicDateFilterOptions.today,
                        bwicDateFilterOptions.thisWeek,
                        bwicDateFilterOptions.lastWeek,
                        bwicDateFilterOptions.lastMonth,
                        bwicDateFilterOptions.lastYear,
                        bwicDateFilterOptions.custom
                    ]}
                    disabledDays={{ after: new Date() }}
                />
            </FilterSection>
            <FilterSection>
                <FilterSelect
                    title="Trade Status"
                    multiply={true}
                    disabled={filtersDisabled}
                    isApplied={
                        prevFilters.confirmed === filters.confirmed &&
                        prevFilters.pending === filters.pending &&
                        prevFilters.rejected === filters.rejected
                    }
                    options={[
                        TradeStatus.pending,
                        TradeStatus.affirmed,
                        TradeStatus.rejected
                    ].map(x => ({
                        text: tradeStatuses.getByKey(x)!.title,
                        value: x,
                        selected: (
                            (filters.pending && x === TradeStatus.pending) ||
                            (filters.confirmed && x === TradeStatus.affirmed) ||
                            (filters.rejected && x === TradeStatus.rejected)
                        ),
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={item => {
                        (item.value === TradeStatus.pending && dispatch(blotterTogglePending()));
                        (item.value === TradeStatus.affirmed && dispatch(blotterToggleConfirmed()));
                        (item.value === TradeStatus.rejected && dispatch(blotterToggleRejected()));
                    }}
                    onClearAll={() => {
                        filters.pending && dispatch(blotterTogglePending());
                        filters.confirmed && dispatch(blotterToggleConfirmed());
                        filters.rejected && dispatch(blotterToggleRejected());
                    }}
                    onSelectAll={() => {
                        !filters.pending && dispatch(blotterTogglePending());
                        !filters.confirmed && dispatch(blotterToggleConfirmed());
                        !filters.rejected && dispatch(blotterToggleRejected());
                    }}
                />
            </FilterSection>
            <FilterSection>
                <DateRangeSelector
                    restyled={true}
                    title="Settl. Date"
                    isApplied={prevFilters.settlementSelectedDateOption === filters.settlementSelectedDateOption}
                    disabled={filtersDisabled}
                    selectedDateOption={filters.settlementSelectedDateOption}
                    customDateRange={filters.settlementCustomDateRange}
                    onReset={() => dispatch(blotterSettlementDateFilterChange(bwicDateFilterOptions.unspecified))}
                    onSelectedDateChange={(date: Date) => dispatch(blotterSettlementDateFilterChange(date))}
                    onCustomDateChange={(date: Date) => dispatch(blotterSettlementCustomDateFilterChange(date))}
                    acceptedOptions={[
                        bwicDateFilterOptions.unspecified,
                        bwicDateFilterOptions.today,
                        bwicDateFilterOptions.thisWeek,
                        bwicDateFilterOptions.lastWeek,
                        bwicDateFilterOptions.lastMonth,
                        bwicDateFilterOptions.lastYear,
                        bwicDateFilterOptions.custom
                    ]}
                    disabledDays={{ after: new Date() }}
                />
            </FilterSection>
            <FilterSection>
                <FilterSelect
                    title="Settl. Status"
                    multiply={true}
                    disabled={filtersDisabled}
                    isApplied={
                        prevFilters.allocationStatus.unsettled === filters.allocationStatus.unsettled &&
                        prevFilters.allocationStatus.pendingAllocation === filters.allocationStatus.pendingAllocation &&
                        prevFilters.allocationStatus.pendingSettlement === filters.allocationStatus.pendingSettlement &&
                        prevFilters.allocationStatus.pendingProceeds === filters.allocationStatus.pendingProceeds &&
                        prevFilters.allocationStatus.settled === filters.allocationStatus.settled
                    }
                    options={[
                        TradeSettlementStatus.Unsettled,
                        TradeSettlementStatus.PendingAllocation,
                        TradeSettlementStatus.PendingProceeds,
                        TradeSettlementStatus.PendingSettlement,
                        TradeSettlementStatus.Settled,
                    ].map(x => ({
                        text: tradeSettlementStatusTitle[x],
                        value: x,
                        selected: (
                            (filters.allocationStatus.unsettled && x === TradeSettlementStatus.Unsettled) ||
                            (filters.allocationStatus.pendingAllocation && x === TradeSettlementStatus.PendingAllocation) ||
                            (filters.allocationStatus.pendingSettlement && x === TradeSettlementStatus.PendingSettlement) ||
                            (filters.allocationStatus.pendingProceeds && x === TradeSettlementStatus.PendingProceeds) ||
                            (filters.allocationStatus.settled && x === TradeSettlementStatus.Settled)
                        ),
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={item => {
                        (item.value === TradeSettlementStatus.Unsettled && dispatch(blotterToggleAllocationStatusUnsettled()));
                        (item.value === TradeSettlementStatus.PendingSettlement && dispatch(blotterToggleAllocationStatusPendingSettlement()));
                        (item.value === TradeSettlementStatus.PendingAllocation && dispatch(blotterToggleAllocationStatusPendingAllocation()));
                        (item.value === TradeSettlementStatus.PendingProceeds && dispatch(blotterToggleAllocationStatusPendingProceeds()));
                        (item.value === TradeSettlementStatus.Settled && dispatch(blotterToggleAllocationStatusSettled()));
                    }}
                    onClearAll={() => {
                        filters.allocationStatus.unsettled && dispatch(blotterToggleAllocationStatusUnsettled());
                        filters.allocationStatus.pendingAllocation && dispatch(blotterToggleAllocationStatusPendingAllocation());
                        filters.allocationStatus.pendingSettlement && dispatch(blotterToggleAllocationStatusPendingSettlement());
                        filters.allocationStatus.pendingProceeds && dispatch(blotterToggleAllocationStatusPendingProceeds());
                        filters.allocationStatus.settled && dispatch(blotterToggleAllocationStatusSettled());
                    }}
                    onSelectAll={() => {
                        !filters.allocationStatus.unsettled && dispatch(blotterToggleAllocationStatusUnsettled());
                        !filters.allocationStatus.pendingAllocation && dispatch(blotterToggleAllocationStatusPendingSettlement());
                        !filters.allocationStatus.pendingSettlement && dispatch(blotterToggleAllocationStatusPendingAllocation());
                        !filters.allocationStatus.pendingProceeds && dispatch(blotterToggleAllocationStatusPendingProceeds());
                        !filters.allocationStatus.settled && dispatch(blotterToggleAllocationStatusSettled());
                    }}
                />
            </FilterSection>
            <FilterSection>
                <FilterSelect
                    title="Currency"
                    disabled={filtersDisabled}
                    isApplied={prevFilters.currency.length === filters.currency.length}
                    options={Currencies.map(c => ({
                        text: c,
                        value: c,
                        selected: filters.currency.some((fc: Currency) => fc === c),
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={item => dispatch(blotterSetCurrency(item.value))}
                    onClearAll={() => dispatch(blotterResetCurrency())}
                    onSelectAll={() => dispatch(blotterSelectAllCurrencies())}
                />
            </FilterSection>
            <FilterSection>
                <FilterSelect
                    title="Side"
                    disabled={filtersDisabled}
                    isApplied={
                        prevFilters.buySide === filters.buySide &&
                        prevFilters.sellSide === filters.sellSide
                    }
                    options={[TradeSide.Buy, TradeSide.Sell].map(x => ({
                        text: x,
                        value: x,
                        selected: (x === TradeSide.Buy && filters.buySide) || (x === TradeSide.Sell && filters.sellSide),
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={item => {
                        item.value === TradeSide.Buy && dispatch(blotterToggleFilterBuy());
                        item.value === TradeSide.Sell && dispatch(blotterToggleFilterSell());
                    }}
                    onClearAll={() => {
                        filters.buySide && dispatch(blotterToggleFilterBuy());
                        filters.sellSide && dispatch(blotterToggleFilterSell());
                    }}
                    onSelectAll={() => {
                        !filters.buySide && dispatch(blotterToggleFilterBuy());
                        !filters.sellSide && dispatch(blotterToggleFilterSell());
                    }}
                />
            </FilterSection>
            <ShowFor roles={roles.bd()}>
                <FilterSection>
                    <FilterSelect
                        title="Type"
                        disabled={filtersDisabled}
                        isApplied={
                            prevFilters.client === filters.client &&
                            prevFilters.desc === filters.desc
                        }
                        options={[TradeType.Client, TradeType.Desk].map(x => ({
                            text: tradeTypeTitle[x],
                            value: x,
                            selected: (x === TradeType.Client && filters.client) || (x === TradeType.Desk && filters.desc),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            item.value === TradeType.Client && dispatch(blotterToggleFilterClient());
                            item.value === TradeType.Desk && dispatch(blotterToggleFilterDesc());
                        }}
                        onClearAll={() => {
                            filters.client && dispatch(blotterToggleFilterClient());
                            filters.desc && dispatch(blotterToggleFilterDesc());
                        }}
                        onSelectAll={() => {
                            !filters.client && dispatch(blotterToggleFilterClient());
                            !filters.desc && dispatch(blotterToggleFilterDesc());
                        }}
                    />
                </FilterSection>
            </ShowFor>
            <FilterSection>
                <FilterButton
                    caption="Apply"
                    disabled={!filtersChanged}
                    onClick={search}
                />
            </FilterSection>
            <FilterSection>
                <FilterClear isShown={isClearFilterShown} onClick={resetFilters} />
            </FilterSection>
        </FilterPanel>
    );
}
