import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';

export const ticker = {
    columnName: 'ticker',
    renderHeader: function (sortBy, sortDirection, onClick) {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-lg"
                sorted={sortBy === this.columnName}
                sortDirection={sortDirection}
                onClick={onClick ? () => onClick(this.columnName) : null}
            >
                Ticker
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (entry) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg">
                {entry.ticker}
            </SecurityListColumn>
        );
    }
};
