import React from 'react';
import cn from 'classnames';
import { sellerBiddingActions } from '../../../../actions/seller.bidding.actions';
import { tradeStatuses } from '../../../../constants/bidding/trade.statuses';
import { isActiveTrade } from '../../../../types/trades/TradeStatus';
import { FeatureButton } from '../../../access/FeatureButton';
import { Trade } from '../../../../types/trades/Trade';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { TimeSpan } from '../../../../types/primitives/TimeSpan';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface TradeButtonStyleProps {
    process: Process;
    children: (className: string) => React.ReactElement;
}

export function TradeButtonStyle({ process, children }: TradeButtonStyleProps) {
    let className = "btn-main";

    if (process.type === BwicProcessType.Live ||
        process.type === BwicProcessType.TopX ||
        process.type === BwicProcessType.JumpBall) {

        let automaticStage1Deadline: TimeSpan | undefined;
        if (process.type === BwicProcessType.TopX) automaticStage1Deadline = process.topX?.automaticStage1Deadline;
        if (process.type === BwicProcessType.JumpBall) automaticStage1Deadline = process.jumpBall?.automaticStage1Deadline;

        const isStage1 = process.stagedBiddingStatus == null && (
            process.type === BwicProcessType.Live || automaticStage1Deadline == null
        );

        if (isStage1) {
            className = 'btn-ghost';
        }
    }

    return children(className);
}


interface Props {
    positionId: number;
    process: Process;
    enabled?: boolean;
    trade?: Trade;
}

export function TradeButton({ positionId, trade, process, enabled = true }: Props) {
    const dispatch = useAppDispatch();

    const handleClick = (e: React.MouseEvent) => {
        dispatch(sellerBiddingActions.trade(positionId));
        e.stopPropagation();
    }

    if (isActiveTrade(trade)) {
        return <span className="status">{tradeStatuses.getByKey(trade!.status)!.title}</span>
    }


    return (
        <TradeButtonStyle process={process}>
            {
                (className: string) =>
                    <FeatureButton
                        className={cn('btn', 'btn-sm', className)}
                        disabled={!enabled}
                        onClick={handleClick}
                    >
                        trade
                    </FeatureButton>
            }
        </TradeButtonStyle>
    );
}
