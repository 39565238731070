import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { TradeStatusLabel } from '../../../../../trade/TradeStatusLabel';

export const tradeStatus = {
    columnName: 'tradeStatus',
    title: 'Status',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
            >
                {this.title}
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function ({ trade }) {
        return (
            <SecurityListColumn key={this.columnName}>
                {trade && <TradeStatusLabel status={trade.status} rejectReason={trade.rejectReason} />}
            </SecurityListColumn>
        );
    }
};
