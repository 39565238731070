import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { formatUtils } from '../../../../../../utils';
import { OnHoverTooltip } from "../../../../../common";

export const tradePrice = {
    columnName: 'tradePrice',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="text-right"
            >
                Price
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function ({ trade }) {
        const price = formatUtils.formatBid(trade.price);

        return (
            <SecurityListColumn key={this.columnName} className="text-right">
                <OnHoverTooltip overlay={price} placement="bottomRight">
                    <div className="text-ellipsis">{price}</div>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
