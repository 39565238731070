import * as React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { biddingUtils, formatUtils } from '../../../../../utils';
import { OnHoverTooltip } from "../../../../common";
import { constants } from '../../../../../constants';
import { BwicPosition } from '../../../../../types/bwic/BwicPosition';
import { AxedIcon } from "../../AxedIcon";
import { FinalIcon } from "../../FinalIcon";

export const bid = {
    columnName: 'bid',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm cell-bid-value text-right">
                Bid
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position: BwicPosition) {
        const bid = biddingUtils.getCurrentBid(position.bids);
        let formattedBid, axed, final;

        if (bid) {
            formattedBid = bid.pass ? 'Pass' : formatUtils.formatBid(bid.value);
            axed = bid.axed;
            final = bid.final;
        }

        return (
            <SecurityListColumn
                key={this.columnName}
                className="data-list-cell-sm cell-bid-value text-right"
            >
                <div className="flex-row justify-content-end">
                    <OnHoverTooltip overlay={formattedBid}>
                        <span className="input-placeholder-value text-ellipsis">{formattedBid || constants.emptyPlaceholder}</span>
                    </OnHoverTooltip>
                    <div className="axed-final-icons">
                        {axed && <AxedIcon />}
                        {final && <FinalIcon />}
                    </div>
                </div>
            </SecurityListColumn>
        );
    }
};
