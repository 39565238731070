import React from 'react';
import { errorMessages } from '../../../../../constants';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { BidOnBehalfParseResultItem } from '../../../../../types/state/BidOnBehalfState';
import { GridDataItem } from '../../../../../types/state/GridState';
import { StatusMessageSectionType } from '../../../../../types/state/NotificationState';
import { StatusMessageSection } from '../../../../status-message/StatusMessageSection';

interface Props {
    tickers: { [ticker: string]: boolean }
}

export function ParseErrors({ tickers }: Props) {
    const gridHeaders = useAppSelector(s => s.grid.headers) ?? [];
    const dataItems: GridDataItem<BidOnBehalfParseResultItem>[] = useAppSelector(s => s.grid.dataItems);

    if (!gridHeaders.length) return null;

    const valueCannotBeLess = dataItems.some(i => i?.errors?.find(i => i.message === errorMessages.valueCannotBeLess));

    const selectBdError = gridHeaders.find(h => h.name === 'selectBd')?.headerError;

    const unknownCompanyShortCodes = gridHeaders
        .filter(h => h.headerError && h.name !== 'selectBd')
        .map(h => h.name);
    const unknownTickers = dataItems
        .filter(i => !i.draft && i.ticker?.trim() && tickers[i.ticker?.trim().toUpperCase()] == null)
        .map(i => i.ticker!);

    const renderError = (title: string, items: string[]) => <p>{title}: <span className="text-medium">{items.join(', ')}.</span></p>;

    return (
        <>
            {
                selectBdError ?
                    <StatusMessageSection type={StatusMessageSectionType.Error}>
                        {selectBdError}
                    </StatusMessageSection> : null
            }
            {(unknownCompanyShortCodes.length || unknownTickers.length || valueCannotBeLess) ?
                <StatusMessageSection type={StatusMessageSectionType.Alert}>
                    {!!unknownCompanyShortCodes.length && renderError('One or more Broker-Dealers were not found in the system', unknownCompanyShortCodes)}
                    {!!unknownTickers.length && renderError('One or more Tickers were not found in the BWIC', unknownTickers)}
                    {valueCannotBeLess && <p>{errorMessages.valueCannotBeLess}</p>}
                </StatusMessageSection> : null
            }
        </>
    );

}
