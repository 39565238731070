import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { brokerDealerAccessState } from '../../../../../../constants/bidding';
import { biddingUtils } from '../../../../../../utils/bidding.utils';
import { BidFeedback } from '../../../../brokerDealer/biddingSecurities/BidFeedback';

function FeedbackBody({ position, bwic, accessState }) {
    if (accessState === brokerDealerAccessState.notInvited) return null;

    const currentBid = biddingUtils.getCurrentBid(position.bids);

    return <BidFeedback
        feedback={currentBid?.feedback}
        feedbackDate={currentBid?.feedbackCreatedUtc}
        bidRequest={position.bidRequest}
        bwicStatus={bwic.status}
        currentBid={currentBid}
        process={bwic.process}
        bidsDueUtc={bwic.bidsDueUtc}
        positionId={position.id}
        bwicReferenceName={bwic.bwicReferenceName}
    />
}

export const feedback = {
    columnName: 'feedback',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-md-flexible cell-flexible-text cell-feedback">
                Feedback
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-md-flexible cell-flexible-text cell-feedback">
                <FeedbackBody
                    position={position}
                    bwic={context.bwic}
                    accessState={context.brokerDealerAccessState}
                />
            </SecurityListColumn>
        );
    }
};
