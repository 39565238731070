import { useState } from 'react';
import classNames from 'classnames';
import { sellerBiddingActions as actions } from '../../../actions';
import { TopPanel } from '../common';
import { Participants } from '../common/participants';
import { BiddingTabs } from '../../../constants/bidding';
import { ContextMenu } from '../../controls';
import { Table } from '../common/table/Table';
import { sellerScheduledColumns } from '../common/table/columns';
import { CancelReasonPopup } from './CancelReasonPopup';
import SubHeader from '../common/SubHeader';
import { AutoFeedbackSettingsButton } from './auto-feedback/AutoFeedbackSettingsButton';
import { AutoFeedbackSettingsPanel } from './auto-feedback/AutoFeedbackSettingsPanel';
import { ControlPanel } from '../common/ControlPanel';
import { BwicProcessType } from '../../../types/models/Process';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicPosition } from '../../../types/bwic/BwicPosition';
import { roles } from '../../../constants';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface ScheduledProps {
    activeTab?: BiddingTabs;
    activePositionId: number;
}

export const Scheduled = ({ activeTab, activePositionId }: ScheduledProps) => {
    const [cancelBwicDialogVisible, setCancelBwicDialogVisible] = useState(false);
    const dispatch = useAppDispatch();
    const bwic = useAppSelector((s) => s.bidding.bwic);
    const securities = useAppSelector((s) => s.sellerBidding.securities);

    const handleBwicCancel = (reason: string) => {
        dispatch(actions.cancelBwic(bwic.referenceName, reason));
        setCancelBwicDialogVisible(false);
    }

    const createSecurityCustomClassName = (security: BwicPosition) => {
        return classNames('pointer', {
            active: security.id === activePositionId
        });
    }

    const isStandard = bwic.process.type === BwicProcessType.Standard;
    const isJumpBall = bwic.process.type === BwicProcessType.JumpBall;
    const isTopX = bwic.process.type === BwicProcessType.TopX;
    const optionsMenuItems = [
        {
            text: 'Cancel',
            requiredRoles: [roles.SellerTrader, roles.BrokerDealerTrader],
            action: () => setCancelBwicDialogVisible(true)
        }
    ];

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    <div className="flex-item-right controls">
                        <ContextMenu menuItems={optionsMenuItems} text="Actions" />
                    </div>
                </TopPanel>
                <SubHeader bwic={bwic}>
                    {
                        (isStandard || isTopX || isJumpBall) &&
                        <AutoFeedbackSettingsButton
                            disabled={true}
                            disabledTooltip={bwic.isClearingBankParticipant
                                ? `Auto-feedback is automatically turned on for ${bwic.isAllToAll ? "All-to-All" : "direct"} BWICs.`
                                : 'The Auto-feedback feature will be automatically turned on after the start of Bidding.'
                            }
                        />
                    }
                </SubHeader>
            </div>
            <div className="flex-row flex-row-full-size">
                {activeTab === BiddingTabs.participants
                    ? <Participants />
                    : (
                        <Table
                            className="data-list-striped"
                            dataItems={securities}
                            columns={sellerScheduledColumns()}
                            createSecurityCustomClassName={createSecurityCustomClassName}
                            createSecurityCustomArgs={() => ({ bwic })}
                        />
                    )
                }
                {
                    cancelBwicDialogVisible &&
                    <CancelReasonPopup
                        onClose={() => setCancelBwicDialogVisible(false)}
                        onCancel={handleBwicCancel}
                    />
                }
                <ControlPanel
                    bwic={bwic}
                    bidListEnabled={false}
                    historyEnabled={false} positions={securities}
                />
                {bwic.process.type !== BwicProcessType.Live && <AutoFeedbackSettingsPanel scheduled={true} />}
            </div>
        </div>
    );
}
