import React from 'react';
import cn from 'classnames';

interface EmailLinkProps {
    email: string;
    className?: string;
    children?: React.ReactNode;
}

export function EmailLink({ email, children, className }: EmailLinkProps) {
    return (
        <a className={cn('email text-ellipsis', className)} href={`mailto:${email}`}>
            {children ?? email}
        </a>
    )
}
