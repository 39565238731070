import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { OnHoverTooltip } from "../../../../../common";
import { constants } from '../../../../../../constants';

export const tradeComment = {
    columnName: 'tradaComment',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn
                key={this.columnName}
                className="data-list-cell-md"
            >
                Comments
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-md">
                {
                    position.trade.comment
                        ? <OnHoverTooltip wrapperClass="comment" overlay={position.trade.comment}>
                            <div className="text-ellipsis">{position.trade.comment}</div>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                }
            </SecurityListColumn>
        );
    }
}
