import * as React from 'react'
import { BidConfirmationState } from '../../../../types/state/BrokerDealerBiddingState';
import { useDispatch } from 'react-redux';
import { isRequestFailed } from '../../../../utils/request-state.utils';
import { brokerDealerBiddingActions } from '../../../../actions';
import { ReSubmitButton } from './ReSubmitButton';
import { usePositionSubmitResult } from './usePositionSubmitResult';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';

interface Props {
    position: BwicPosition;
    bid: BidConfirmationState;
    isColorDistribution: boolean;
    isOpenBiddingEnded: boolean;
}

export function ReSubmitBidButton({ position, bid, isOpenBiddingEnded, isColorDistribution }: Props) {
    const dispatch = useDispatch();
    const { isSubmitting, submitResult } = usePositionSubmitResult(position.id);

    return (
        <ReSubmitButton
            isTraded={position.trade != null}
            isTradedAway={Boolean(position.isTradedAway)}
            isColorDistribution={isColorDistribution}
            isOpenBiddingEnded={isOpenBiddingEnded}
            isSubmitting={isSubmitting}
            isFailed={isRequestFailed(submitResult.requestState)}
            isValidationError={submitResult.validationError != null}
            hasStageTransitionConflict={submitResult.stageTransitionConflict != null}
            isBidOverThemself={Boolean(submitResult.isBidOverThemself)}
            hasConflict={submitResult.conflict != null}
            onClick={() => dispatch(brokerDealerBiddingActions.submitBids(position.id, { [position.id]: bid }))}
        />
    );
}
