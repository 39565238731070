import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../common';
import { TargetLevel } from '../../../common';
import { constants, roles } from '../../../../../constants';
import { OpenBiddingStatus } from '../../../../../types/enums/OpenBiddingStatus';
import { isActiveTrade } from '../../../../../types/trades/TradeStatus';
import { BwicProcessType } from '../../../../../types/models/Process';
import { RoleActionBlocker } from '../../../../access/RoleActionBlocker';

export const targetLevel = {
    columnName: 'targetLevel',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm cell-input text-right">
                Target Level
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        const isBiddingEnded = () =>
            context.bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded || (
                context.bwic.process.type === BwicProcessType.BestFootForward &&
                context.bwic.process.stagedBiddingStatus != null
            )

        const stage2NoBids = () =>
            (context.bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended ||
                context.bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded) &&
            !position.bids?.some(b => !b.pass)

        if (
            context.targetLevelReadonly ||
            isActiveTrade(position.trade) ||
            isBiddingEnded() ||
            stage2NoBids() ||
            context.liveBiddingStage2Expired) {
            return (
                <SecurityListColumn key={this.columnName} className="data-list-cell-sm cell-input text-right">
                    {position.targetLevel || constants.emptyPlaceholder}
                </SecurityListColumn>
            );
        }

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm cell-input text-right overflow-visible">
                <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    <TargetLevel
                        positionId={position.id}
                        targetLevel={position.targetLevel}
                    />
                </RoleActionBlocker>
            </SecurityListColumn>
        );
    }
};
