import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { biddingUtils, formatUtils } from '../../../../utils';
import { FeedbackMenu } from '../FeedbackMenu';
import IconSVG from '../../../../styles/svg-icons';
import { OnHoverTooltip } from '../../../common';
import { AnimatedValueUpdate } from '../../../controls';
import { sellerBiddingActions } from '../../../../actions';
import { isActiveTrade, TradeStatus } from '../../../../types/trades/TradeStatus';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { BwicProcessType } from '../../../../types/models/Process';
import { jumpBallUtils } from '../../../../utils/jump-ball.utils';
import { topXUtils } from '../../../../utils/top-x.utils';
import { constants } from '../../../../constants';
import { compareDates } from '../../../../utils/compare.utils';

export function BidLevel({ bwicReferenceName, positionId, level, trade, bwicStatus, bids, stage2Participants = {},  allowFeedback = true, process, children = null }) {
    const dispatch = useDispatch();
    const levelBids = useMemo(() =>
        biddingUtils.getBidsByLevel(bids, level).sort((a, b) => compareDates(a.createdUtc, b.createdUtc)),
        [bids, level]
    );
    const activeTrade = isActiveTrade(trade);
    const feedbackVisible = allowFeedback && !activeTrade;
    const newBids = levelBids.filter(b => b.isNew);
    const isAffirmedTrade = trade != null && trade.status === TradeStatus.affirmed;
    const tradeCompanyIdentifier =
        trade &&
        biddingUtils.getBidCompanyIdentifier({
            buyerPseudoOrderNumber: trade.buyerPseudoOrderNumber,
            company: trade.buyerCompany
        });
    const tradeBid = trade && levelBids.find(b =>
        biddingUtils.getBidCompanyIdentifier(b) === tradeCompanyIdentifier &&
        Number(trade.price) === Number(b.value)
    );

    React.useEffect(() => {
        if (newBids.length) {
            dispatch(sellerBiddingActions.markBidsShown(
                newBids,
                positionId,
                bwicReferenceName
            ));
        }
    }, [newBids, positionId, bwicReferenceName, dispatch]);

    const getCompanyCode = company => company && company.code && company.code.toUpperCase();

    const isFeedbackEnabled = () => {
        if (process.type === BwicProcessType.JumpBall) {
            return jumpBallUtils.canSendFeedback(stage2Participants, process.stagedBiddingStatus, levelBids);
        } else if (process.type === BwicProcessType.TopX) {
            return topXUtils.canSendFeedback(stage2Participants, process.stagedBiddingStatus, levelBids);
        } else if (process.type === BwicProcessType.BestFootForward) {
            return process.stagedBiddingStatus == null;
        }

        return true
    }

    const renderBidTooltip = () => {
        const companies = levelBids.map((b, i) =>
            <div key={i}>
                <span>
                    {isAffirmedTrade && tradeBid && biddingUtils.getUniqueBidId(tradeBid) === biddingUtils.getUniqueBidId(b) && 'Traded to '}
                    {trade && trade.status === TradeStatus.rejected && tradeBid && biddingUtils.getUniqueBidId(tradeBid) === biddingUtils.getUniqueBidId(b) && 'Rejected by '}
                    {b.buyerPseudoOrderNumber ? `${b.company.name}-${b.buyerPseudoOrderNumber}` : b.company.name}
                </span>
                {b.axed && <IconSVG name="axed" width={16} height={16} />}
                {b.final && <IconSVG name="final" width={16} height={16} />}
            </div>
        );

        return <div>{companies}</div>;
    };

    const renderContent = () => {
        const bids = activeTrade && tradeBid ? [tradeBid] : levelBids;
        const hasFeedback = bids.length && bids.every(b => !!b.feedback);
        const hasNewBid = newBids.length > 0;

        const className = classNames({
            "level-bids-data": true,
            'no-feedback-bid':  process.type !== BwicProcessType.Live && !activeTrade && !hasFeedback,
            'traded': tradeBid && isAffirmedTrade,
            'on-behalf': bids.every((b) => b.onBehalf),
            'finished': bwicStatus === BwicStatus.finished,
        });

        const value = formatUtils.formatBid(bids[0].value);
        const axed = bids.some(b => b.axed);
        const final = bids.some(b => b.final);
        let companyText = '';
        if (bids.length === 1) {
            const [bid] = bids;
            companyText = bid.buyerPseudoOrderNumber
                ? `${getCompanyCode(bid.company)}-${bid.buyerPseudoOrderNumber}`
                : getCompanyCode(bid.company);
        } else {
            companyText = `${bids.length} bids`;
        }

        return (
            <OnHoverTooltip overlay={renderBidTooltip()}>
                <AnimatedValueUpdate
                    value={`${bids.length}-${value}-${companyText}-${axed}-${final}`}
                    className={className}
                    backgroundClassName="new-bid"
                    disabled={!hasNewBid}
                >
                    <span>{companyText}</span>
                    <b>{value}</b>
                    {axed && <IconSVG name="axed" width={16} height={16} />}
                    {final && <IconSVG name="final" width={16} height={16} />}
                </AnimatedValueUpdate>
            </OnHoverTooltip>
        );
    };

    if (levelBids.length) {
        return (
            <div className="flex-row">
                <div className="level-bids">{renderContent(levelBids)}</div>
                {children}
                <div className="feedback-menu">
                    {
                        allowFeedback &&
                        !activeTrade &&
                        <FeedbackMenu
                            level={level}
                            positionId={positionId}
                            bids={bids}
                            levelBids={levelBids}
                            process={process}
                            disabled={!isFeedbackEnabled()}
                        />
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="flex-row">
            <span className="bid-level-dash">{constants.emptyPlaceholder}</span>
            {
                feedbackVisible &&
                <div className="company-bid-value-empty flex-item-right">
                    <IconSVG name="chat" className={classNames({ disabled: true })} width={16} height={16} />
                </div>
            }
        </div>
    );


}
