import React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import {OnHoverTooltip} from "../../../../../common";

export const counterparty = {
    columnName: 'counterparty',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName}>
                Counterparty
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName}>
                <OnHoverTooltip overlay={position.trade.sellerCompany.name}>
                    <div className="text-ellipsis">{position.trade.sellerCompany.code}</div>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
