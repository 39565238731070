import * as React from 'react';
import { useSelector } from 'react-redux';
import { brokerDealerBiddingActions } from '../../../../actions/brokerDealer.bidding.actions';
import { AppState } from '../../../../types/state/AppState';
import { DirectBiddingDisclaimerActionBlocker } from '../../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { FeatureButton } from '../../../access/FeatureButton';
import { Bid } from '../../../../types/bidding/Bid';
import { useAgreement } from '../../../seller-buyside/useAgreement';
import { Rating } from '../../../../types/enums/Rating';
import { OnHoverTooltip } from '../../../common';
import { values } from 'lodash';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    disabled: boolean;
    isAllToAll: boolean;
    currentBid?: Bid;
    rating: Rating;
}

export function BidButton({ positionId, rating, disabled, isAllToAll, currentBid }: Props) {
    const dispatch = useAppDispatch();

    const { agreement } = useAgreement();
    const editState = useSelector((s: AppState) => s.brokerDealerBidding.editBid[positionId]);
    const canSubmit = editState?.canSubmit ?? false;
    const hasErrors = values(editState?.errors).some(e => !!e) || editState?.commissionError;

    const getText = () => {
        const defaultText = { text: 'bid', tooltip: undefined };
        if (hasErrors) return defaultText; // invalid bid or commission
        if(editState?.pass || (currentBid?.pass && !canSubmit)) return defaultText; // Pass
        if (!currentBid && !editState?.value) return defaultText; // no bid
        if(editState?.value === '' || (isAllToAll && editState?.commission === '')) return defaultText;

        const commission = isAllToAll
            ? Number(
                editState?.commission ??
                currentBid?.commission ??
                agreement?.commissions[rating])
            : undefined;

        return commission
            ? {
                text: "Bid",
                tooltip: canSubmit ? "Click to submit bid includes commission." : ""
            } : { text: "Bid", tooltip: undefined };
    }

    const handleClick = (e?: React.MouseEvent) => {
        dispatch(brokerDealerBiddingActions.bidSingle(positionId));
        e && e.stopPropagation();
    }

    const { text, tooltip } = getText();

    return (
        <DirectBiddingDisclaimerActionBlocker onConfirm={handleClick} disabled={!isAllToAll}>
            <OnHoverTooltip overlay={tooltip}>
                <FeatureButton
                    type="button"
                    className="btn btn-main btn-sm"
                    disabled={disabled || !canSubmit}
                    onClick={handleClick}
                >
                    {text}
                </FeatureButton>
            </OnHoverTooltip>
        </DirectBiddingDisclaimerActionBlocker>
    );
}
