import { useAppSelector } from "../../../../../effects/useAppSelector";
import { calculateStage2Participants } from "../../../../../utils/stage2-participants-calculator";
import { OnHoverTooltip } from "../../../../common";
import { constants } from '../../../../../constants';
import { BwicPosition } from "../../../../../types/bwic/BwicPosition";
import { Bwic } from "../../../../../types/bwic/Bwic";
import { OpenBiddingStatus } from "../../../../../types/enums/OpenBiddingStatus";
import { TradeStatus } from "../../../../../types/trades/TradeStatus";
import { SecurityListColumn, SecurityListHeaderColumn } from "../../../../common/security-list";

export const biddersCounter = {
    columnName: 'biddersCounter',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xxs padding-l-0" />
        )
    },
    renderBody: function (position: BwicPosition, context: { bwic: Bwic }) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xxs padding-l-0">
                <Counter position={position} bwic={context.bwic} />
            </SecurityListColumn>
        );
    }
};

function Counter({ position, bwic }: { position: BwicPosition, bwic: Bwic }) {

    const positionWithAllBids = useAppSelector((s) => position.id ? s.sellerBidding.securities.find(s => s.id === position.id) : undefined);
    const stage2Participants = Object.keys(calculateStage2Participants(positionWithAllBids?.bids ?? [], bwic.process)).length;
    const participantsWithImprovedBids = position?.bids.filter(b => b.stagedBiddingStatus === OpenBiddingStatus.stage1Ended).length

    if (bwic.process.stagedBiddingStatus !== OpenBiddingStatus.stage1Ended) {
        return null
    }

    if (position.trade?.status === TradeStatus.affirmed || stage2Participants === 0) {
        return <div className="counter-placeholder">{constants.emptyPlaceholder}</div>
    }

    return (
        <OnHoverTooltip overlay={`${participantsWithImprovedBids} of ${stage2Participants} bidders improved their bids.`}>
            <div className="counter">
                {participantsWithImprovedBids}/{stage2Participants}
            </div>
        </OnHoverTooltip>
    )
} 
