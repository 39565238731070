import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../../common';
import { ColorLabel } from '../../../../seller/colorDistribution';
import { isActiveTrade } from '../../../../../../types/trades/TradeStatus';

export const colorDistributionLabel = {
    columnName: 'colorDistributionLabel',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xl-flexible flex-row">
                Color
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const isTraded = isActiveTrade(position.trade);

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xl-flexible flex-row">
                <ColorLabel position={position} traded={isTraded} />
            </SecurityListColumn>
        );
    }
};
