import classNames from 'classnames';
import { BiddingTabs } from '../../../constants/bidding';
import { TopPanel } from '../common';
import { Participants } from '../common/participants';
import SubHeader from '../common/SubHeader';
import { Table } from '../common/table';
import { sellerCanceledColumns } from '../common/table/columns';
import { ControlPanel } from '../common/ControlPanel';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicPosition } from '../../../types/bwic/BwicPosition';

interface CancelledProps {
    activeTab?: BiddingTabs;
    activePositionId: number;
}

export const Cancelled = ({ activeTab, activePositionId }: CancelledProps) => {
    const bwic = useAppSelector((s) => s.bidding.bwic);
    const securities = useAppSelector((s) => s.sellerBidding.securities);

    const createSecurityCustomClassName = (security: BwicPosition) => {
        return classNames('pointer', {
            active: security.id === activePositionId
        });
    }

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic} />
                <SubHeader bwic={bwic} />
            </div>
            <div className="flex-row flex-row-full-size">
                {activeTab === BiddingTabs.participants
                    ? <Participants />
                    : (
                        <Table
                            className="data-list-striped"
                            dataItems={securities}
                            columns={sellerCanceledColumns}
                            createSecurityCustomClassName={createSecurityCustomClassName}
                            createSecurityCustomArgs={() => ({
                                targetLevelReadonly: true,
                                bwic
                            })}
                        />
                    )
                }
                <ControlPanel bwic={bwic} positions={securities} />
            </div>
        </div>
    );
}
