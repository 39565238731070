import * as React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Checkbox } from '../../../controls';
import { OnHoverTooltip } from '../../../common';
import { TooltipPlacement } from '../../../common/Tooltip';
import IconSVG from '../../../../styles/svg-icons';
import { dealerListPanelActions } from '../../../../actions';


interface Props {
    title: string;
    checkboxTooltip?: string;
    defaultExpanded?: boolean;
    toggleState: boolean;
    toggleEnabled?: boolean;
    children: React.ReactNode;
    onToggleAll: () => void;
    totalCount: number;
    checkedCount: number;
}

export function DealerListSection({ title, checkboxTooltip, children, defaultExpanded = true, toggleState, toggleEnabled = true, onToggleAll, totalCount, checkedCount }: Props) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(defaultExpanded);
    const isPartiallySelected = checkedCount > 0 && totalCount !== checkedCount;

    const handleToggleAll = () => {
        if (toggleEnabled) {
            onToggleAll();
            dispatch(dealerListPanelActions.stage2ParticipantsFlagChange(false));
        }
    }

    return (
        <div className={classNames('dealers-list-panel-section', { 'section-is-open': expanded })}>
            <div className="dealers-list-panel-group">
                <div className="collapsable-item-header">
                    <div className="flex-row" onClick={() => setExpanded(!expanded)}>
                        <IconSVG name="icon-expand" className={classNames({ 'collapse': expanded })} width={16} height={16} />
                        {
                            <OnHoverTooltip
                                overlay={checkboxTooltip ? checkboxTooltip : "There are no broker-dealers to show"}
                                disabled={toggleEnabled}
                                placement={TooltipPlacement.BottomRight}
                            >
                                <Checkbox
                                    partially={isPartiallySelected}
                                    checked={toggleState && toggleEnabled}
                                    disabled={!toggleEnabled}
                                    onChange={handleToggleAll} />
                            </OnHoverTooltip>
                        }
                        <h4 className="row-text text-sm text-bold">{title}</h4>
                        <span className='text-warm-grey'>({checkedCount}/{totalCount} selected)</span>
                    </div>
                </div>
                {
                    expanded &&
                    <div className="collapsable-item-content">
                        {children}
                    </div>
                }
            </div>
        </div>
    );
}
