import React from 'react';
import { DropDownList, DropDownListItem } from '../../../../controls/DropDownList';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { CompanySlim } from '../../../../../types/company/CompanySlim';
import { bidOnBehalfParseActions } from '../../../../../actions/bid-on-behalf-parse.actions';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';

export function SelectBdDropDown() {
    const dispatch = useAppDispatch();
    const offPlatformCompanies = useAppSelector(s => s.bidOnBehalf.companies);
    const headers = useAppSelector(s => s.grid.headers);

    const items: DropDownListItem[] = React.useMemo(() =>
        offPlatformCompanies
            .filter(c => !headers.some(h => h.name === c.code))
            .map(c => ({
                text: '',
                value: c.code,
                payload: c,
                disabled: headers.some(h => h.name.localeCompare(c.code, undefined, { sensitivity: 'accent' }) === 0)
            })),
        [offPlatformCompanies, headers]);

    const renderDropDownItem = (item: DropDownListItem) =>
        <div className="select-bd-item flex-row">
            {!!item.text && item.text}
            {
                !item.text &&
                <>
                    <div className="select-bd-name">{(item.payload as CompanySlim).name}</div>
                    <div className="select-bd-code flex-item-right">{(item.payload as CompanySlim).code}</div>
                </>
            }
        </div>

    return (
        <DropDownList
            className="select-bd form-control-sm"
            placeholder="Select BD"
            items={items}
            renderItemContentCallback={renderDropDownItem}
            onChange={item => dispatch(bidOnBehalfParseActions.addCompany(item.value as string))}
        />
    );
}
