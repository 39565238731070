import { Link } from 'react-router-dom';
import { EmptyPlaceholder} from '../../../common';
import { routes } from '../../../../constants';
import { BlockedPlatformAccessText } from "../../../access/BlockedPlatformAccessText";

export function ColorDistributionBlocked() {
    return (
        <EmptyPlaceholder
            title='Color Distribution is in progress…'
            text={<BlockedPlatformAccessText />}
        >
            <Link className="btn btn-main" to={routes.allBWICs}>view bwic monitor</Link>
        </EmptyPlaceholder>
    );
}
