import * as React from 'react'

interface Props {
    isTradedAway?: boolean;
    isTraded?: boolean;
    isSubmitting: boolean;
    isFailed: boolean;
    isColorDistribution?: boolean;
    isOpenBiddingEnded?: boolean;
    hasStageTransitionConflict?: boolean;
    isBidOverThemself: boolean;
    hasConflict: boolean;
    isValidationError: boolean;
    onClick: () => void;
}

export function ReSubmitButton({
    isTradedAway,
    isTraded,
    isSubmitting,
    isFailed,
    isOpenBiddingEnded = false,
    isColorDistribution,
    hasStageTransitionConflict = false,
    isBidOverThemself,
    hasConflict,
    isValidationError,
    onClick }: Props) {
    if (
        isSubmitting ||
        isColorDistribution ||
        isOpenBiddingEnded ||
        isTradedAway ||
        isTraded ||
        isValidationError ||
        !isFailed ||
        hasStageTransitionConflict) {
        return null;
    }

    const text = hasConflict || isBidOverThemself ? 'Confirm' : 'Retry';

    return (
        <button className="btn btn-sm btn-ghost" onClick={onClick}>
            {text}
        </button>
    );
}
