import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { formatUtils } from '../../../../../utils';
import { OnHoverTooltip } from "../../../../common";
import { constants } from '../../../../../constants';

export const color = {
    columnName: 'color',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-lg cell-color-value">
                Color
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const colorValue = formatUtils.formatColor(position.color) || constants.emptyPlaceholder;

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg cell-color-value">
                <OnHoverTooltip overlay={colorValue}>
                    <span>{colorValue}</span>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
