import { RequestState } from '../../../../constants/request-state';
import { LiveBiddingPosition } from '../../../../types/live-bidding/LiveBiddingPosition';
import { useLiveBiddingData } from '../../../../effects/data-accessors/useLiveBiddingData';

interface LiveBiddingStage2LevelDataProps {
    positionId: number;
    children: (requestState: RequestState, data?: LiveBiddingPosition) => JSX.Element
}
export function LiveBiddingStrage2LevelData({ positionId, children }: LiveBiddingStage2LevelDataProps) {
    const { requestState, data } = useLiveBiddingData(positionId);

    return children(requestState, data);
}