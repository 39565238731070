import * as React from 'react';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import IconSVG from '../../../../styles/svg-icons';

export function BidOnBehalfIcon() {
    return (
        <OnHoverTooltip overlay="Bid on behalf">
            <IconSVG name="bid-on-behalf" width={16} height={16} />
        </OnHoverTooltip>
    );
}
