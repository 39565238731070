import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { biddingActions } from '../../../../actions';
import { Preloader } from '../../../common';
import { DealerList } from '../../../dealer-list/DealerList';
import { arrayUtils } from '../../../../utils/array.utils';
import { sortDealersByConfirmedAgreement } from '../../../../utils/direct-bidding.utils';
import { AppState } from '../../../../types/state/AppState';
import { Bid } from '../../../../types/bidding/Bid';
import { isRequestSuccess } from '../../../../utils';
import { BwicProcessType } from '../../../../types/models/Process';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export function Participants() {
    const dispatch = useAppDispatch();

    const bwicDealers = useSelector((state: AppState) => state.bidding.bwicDealers);
    const bwicDealerUsers = useSelector((state: AppState) => state.bidding.bwicDealerUsers);
    const bwicDealerContacts = useSelector((state: AppState) => state.bidding.bwicDealerContacts);
    const participantsLoadingState = useSelector((state: AppState) => state.bidding.participantsLoadingState);
    const bwic = useSelector((state: AppState) => state.bidding.bwic);
    const positions = useSelector((state: AppState) => state.sellerBidding.securities);
    const settlementAgentAgreementsState = useSelector((state: AppState) => state.entities.settlementAgentAgreements);

    useEffect(() => {
        if (bwic) {
            dispatch(biddingActions.loadBwicParticipants());
        }
    }, [dispatch, settlementAgentAgreementsState, bwic]);

    const sortedDealers = useMemo(
        () => sortDealersByConfirmedAgreement(bwicDealers, settlementAgentAgreementsState.items),
        [bwicDealers, settlementAgentAgreementsState.items]
    );

    const bidsByCompany = useMemo(() => {
        const allBids = positions
            .map(p => p.bids || [])
            .flat()
            .filter(b => !b.buyerPseudoOrderNumber);
        return arrayUtils.groupBy(allBids, (bid: Bid) => bid.company.id)
    }, [positions]);

    return (
        <div className="bidding-participants">
            <Preloader inProgress={!isRequestSuccess(participantsLoadingState)}>
                <div className="bidding-participants-content container-flex">
                    {
                        isRequestSuccess(participantsLoadingState) &&
                        <DealerList
                            dealers={sortedDealers}
                            users={bwicDealerUsers}
                            contacts={bwicDealerContacts}
                            bidsByCompany={bidsByCompany}
                            favoriteButtonDisabled={true}
                            agreements={settlementAgentAgreementsState.items}
                            liveBidding={bwic.process.type === BwicProcessType.Live}
                        />
                    }
                </div>
            </Preloader>
        </div>
    )
}
