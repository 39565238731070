import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../../common';
import { BidListButton } from '../../../../seller/bid-list/BidListButton';

export const bidListButton = {
    columnName: 'bidListButton',
    renderHeader: function () {
        return <SecurityListHeaderColumn key={this.columnName} className="cell-bidlist-action" />;
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="cell-bidlist-action">
                <BidListButton position={position} />
            </SecurityListColumn>
        );
    }
};
