import { useAppSelector } from "../../../../effects/useAppSelector";
import { BwicStatus } from "../../../../types/enums/BwicStatus";
import { Trade } from "../../../../types/trades/Trade";
import { BidOverThemselfIcon } from "./BidOverThemselfIcon";

interface Props {
    positionId?: number;
    bwicStatus?: BwicStatus;
    trade?: Trade;
    onHoverTooltipMessage?: React.ReactNode;
}

export function BidOverThemSelf({ positionId, bwicStatus, trade, onHoverTooltipMessage }: Props) {
    const securities = useAppSelector(s => s.sellerBidding.securities);
    const allBids = securities.find(s => s.id === positionId)?.bids;

    return <BidOverThemselfIcon
        bids={allBids ?? []}
        bwicStatus={bwicStatus}
        trade={trade}
        onHoverTooltipMessage={onHoverTooltipMessage}
    />
}
