import * as React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsibleListItemContent } from "../../../../common/collapsible-list/CollapsibleListItemContent";
import { CollapsibleList } from "../../../../common/collapsible-list/CollapsibleList";
import { CollapsibleListItem } from "../../../../common/collapsible-list/CollapsibleListItem";
import { SecurityListRow } from "../../../../common/security-list";
import { ColumnBuilder } from '../columns/column-builder/ColumnBuilder';
import { TableColumnStickType } from '../types/TableColumnStickType';
import { AppState } from '../../../../../types/state/AppState';
import IconSVG from '../../../../../styles/svg-icons';
import { tableActions } from '../../../../../actions/table.actions';

interface Props<T> {
    rowKey: string;
    dataItem: T;
    columns: ColumnBuilder<T>[];
    columnType: TableColumnStickType;
    collapseIconVisible: boolean;
    expandAll: boolean;
    className?: string;
    context: { [key: string]: any };
    renderCollapsibleContent?: (dataItem: T, columnType?: TableColumnStickType) => React.ReactNode | React.ReactNode[];
    onExpandChange?: (rowKey: string) => void;
    onHover?: (dataItem: T) => void;
}

export function StickyTableCollapsibleRow<T>({
    rowKey,
    columns,
    columnType,
    dataItem,
    collapseIconVisible,
    expandAll,
    className,
    context,
    renderCollapsibleContent,
    onExpandChange,
    onHover }: Props<T>) {
    const dispatch = useDispatch();

    const expandedState = useSelector((s: AppState) => s.ui.table.expand[rowKey]);
    const expanded = expandedState ?? expandAll;
    const hover = useSelector((s: AppState) => s.ui.table.hover[rowKey]) ?? false;

    const handleExpandedChange = () => {
        onExpandChange?.(rowKey);
        dispatch(tableActions.expandedChange(rowKey, !expanded));
    }

    const handleRowHover = (hover: boolean) => {
        if (hover) {
            onHover?.(dataItem);
        }

        dispatch(tableActions.hoverChange(rowKey, hover));
    }

    return (
        <CollapsibleList className={expanded ? "expand" : ""}>
            <CollapsibleListItem expanded={expanded}>
                <SecurityListRow
                    className={cn("component-collapsible-list-item-header", className, { hover })}
                    onClick={handleExpandedChange}
                    onHover={() => handleRowHover(true)}
                    onMouseLeave={() => handleRowHover(false)}
                >
                    {
                        collapseIconVisible &&
                        <div className="data-list-cell-collapse-action">
                            <span className="btn-link">
                                <IconSVG name="icon-expand" className={cn({ 'collapse': expanded })} width={16} height={16} />
                            </span>
                        </div>
                    }
                    {columns.map((c) => c.renderBody(dataItem, context))}
                </SecurityListRow>
                <CollapsibleListItemContent expanded={expanded}>
                    {expanded && renderCollapsibleContent?.(dataItem, columnType)}
                </CollapsibleListItemContent>
            </CollapsibleListItem>
        </CollapsibleList>
    );
}
