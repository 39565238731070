import * as React from 'react';
import { BlotterExportButton } from './BlotterExportButton';
import { BlotterTrades } from './BlotterTrades';

export function BlotterTradesView() {
    return (
        <>
            <div className="sub-header-row type01 flex-row">
                <h1>BWIC Blotter</h1>
                <div className="flex-item-right controls">
                    <BlotterExportButton />
                </div>
            </div>
            <BlotterTrades />
        </>
    );
}
