import React from 'react'
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../effects/useAppSelector'
import { Checkbox } from '../../controls/Checkbox';
import { dealerListPanelActions } from '../../../actions/dealer-list-panel.actions';

export function Stage2ParticipantsFilter() {
    const dispatch = useDispatch();
    const isStage2ParticipantsOnly = useAppSelector(s => s.dealerListPanel.isStage2ParticipantsOnly);

    return (
        <Checkbox
            className="text-medium"
            checked={isStage2ParticipantsOnly}
            label="Stage 2 Participants"
            onChange={() => dispatch(dealerListPanelActions.stage2ParticipantsFlagChange(!isStage2ParticipantsOnly, true))}
        />
    )
}
