import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { BidConflictsWarning } from '../../../common/bidding/BidConflictsWarning';
import { bidOnBehalfActions } from '../../../../actions/bid-on-behalf.actions';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';

export function BidOnBehalfConflictPopup() {
    const dispatch = useDispatch();
    const conflicts = useSelector((state: AppState) => state.bidOnBehalf.conflicts);
    const isConflictsPpopupVisible = useSelector((state: AppState) => state.bidOnBehalf.isConflictsPpopupVisible);
    const securities: BwicPosition[] = useSelector((state: AppState) => state.sellerBidding.securities);

    if (!conflicts.length || !securities.length || !isConflictsPpopupVisible) {
        return null;
    }

    return (
        <Popup 
            title="Bid Conflict" 
            modalClass="modal-bob-conflicts"
            onClose={() => dispatch(bidOnBehalfActions.resetConflicts())}
        >
            <PopupBody>
                <BidConflictsWarning conflicts={conflicts} positions={securities} />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={() => dispatch(bidOnBehalfActions.resetConflicts())}>Cancel</button>
                <button className="btn btn-main" onClick={() => dispatch(bidOnBehalfActions.submit(true))}>Confirm</button>
            </PopupFooter>
         </Popup>
    );
}
