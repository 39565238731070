import React from 'react';
import { useSelector } from 'react-redux';
import { tradeConfirmActions } from '../../../../actions';
import { TRejectReasonPopupPayload } from './TradeRejectReasonPopup';
import { Preloader, OnHoverTooltip } from '../../../common';
import { FeatureButton } from '../../../access/FeatureButton';
import IconSVG from '../../../../styles/svg-icons';
import { DirectBiddingDisclaimerActionBlocker } from '../../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { useHistory } from 'react-router';
import { LocationState, LocationStateBuilder, PopupType } from '../../../../types/state/ui/LocationState';
import { AppState } from '../../../../types/state/AppState';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    buyerPseudoOrderNumber?: number;
    positionId: number;
    tradeReferenceId: string | number;
    ticker: string;
    iconsView?: boolean;
}

export function AffirmTradeButtons({ positionId, tradeReferenceId, ticker, iconsView = false, buyerPseudoOrderNumber }: Props) {
    const dispatch = useAppDispatch();
    const history = useHistory<LocationState<undefined, TRejectReasonPopupPayload>>();
    const isInProgress = useSelector((s: AppState) => s.tradeConfirm.operationsProgress.some(o => o.positionId === positionId && o.isInProgress));

    const handleAffirm = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        dispatch(tradeConfirmActions.affirm(positionId, tradeReferenceId));
    };

    const handleReject = () => {
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state)
                .popup(PopupType.TradeRejectReason, { positionId, tradeReferenceId, ticker })
                .result()
        });
    };

    const iconAffirm = <IconSVG name="saved" width={16} height={16} />
    const iconReject = <IconSVG name="close" width={16} height={16} />;

    return (
        <Preloader small inProgress={isInProgress} text="Processing…" fullScreen={false}>
            <RoleActionBlocker roles={[roles.BrokerDealerTrader, roles.SellerTrader]}>
                <DirectBiddingDisclaimerActionBlocker onConfirm={handleAffirm} disabled={!buyerPseudoOrderNumber}>
                    <FeatureButton className="btn-link btn-affirm" onClick={handleAffirm}>
                        {
                            iconsView
                                ? <OnHoverTooltip overlay="Affirm">{iconAffirm}</OnHoverTooltip>
                                : <>{iconAffirm}<span>Affirm</span></>
                        }
                    </FeatureButton>
                </DirectBiddingDisclaimerActionBlocker>
            </RoleActionBlocker>
            <RoleActionBlocker roles={[roles.BrokerDealerTrader, roles.SellerTrader]}>
                <DirectBiddingDisclaimerActionBlocker onConfirm={handleReject} disabled={!buyerPseudoOrderNumber}>
                    <FeatureButton className="btn-link btn-reject btn-danger" onClick={handleReject}>
                        {
                            iconsView
                                ? <OnHoverTooltip overlay="Reject">{iconReject}</OnHoverTooltip>
                                : <>{iconReject} <span>Reject</span></>
                        }
                    </FeatureButton>
                </DirectBiddingDisclaimerActionBlocker>
            </RoleActionBlocker>
        </Preloader>
    );
}
