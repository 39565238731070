import React from 'react';
import { SecurityListRow } from '../../../../common/security-list';

interface Props {
    children: React.ReactNode,
    renderGroupByRow?: React.ReactNode
}

export function TableGroupingRow({ children, renderGroupByRow }: Props) {
    return (
        <>
            {renderGroupByRow && <SecurityListRow className="row-grouping">{renderGroupByRow}</SecurityListRow>}
            {children}
        </>)
}
