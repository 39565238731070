import React from 'react';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { Bid, BidInputProps } from './Bid';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';

interface Props extends BidInputProps {
    process: Process;
}

export function LiveBid({ position, process, enabled, readonly }: Props) {
    if (process.type !== BwicProcessType.Live || !process.liveBidding) return null;

    const liveBiddingReadonly = process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;

    return <Bid position={position} enabled={enabled} readonly={liveBiddingReadonly || readonly} />
}