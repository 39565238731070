import { useEffect, useRef } from 'react';
import cn from 'classnames';
import moment from "moment";
import { sellerBiddingActions as actions } from '../../../actions';
import { BiddingTabs } from '../../../constants/bidding';
import { biddingUtils } from '../../../utils/bidding.utils';
import { liveBiddingUtils } from '../../../utils/live-bidding.utils';
import { TopPanel, BiddingTimer } from '../common';
import { Participants } from '../common/participants';
import { TradePopup } from './trade/TradePopup';
import SubHeader from '../common/SubHeader';
import { FlexRow, Preloader } from '../../common';
import { Table } from '../common/table';
import { ControlPanel } from '../common/ControlPanel';
import { AutoFeedbackSettingsButton } from './auto-feedback/AutoFeedbackSettingsButton';
import { AutoFeedbackSettingsPanel } from './auto-feedback/AutoFeedbackSettingsPanel';
import { ShowFor } from '../../access';
import { FeatureButton } from '../../access/FeatureButton';
import { getSecuritiesWithLatestBids, getSellerBiddingCompanies } from '../../../selectors';
import { DealerListPanel } from './dealer-list-panel/DealerListPanel';
import { DealerListButton } from './dealer-list-panel/DealerListButton';
import { getSellerBiddingColumns } from './columns';
import { BidOnBehalfConflictPopup } from './bid-on-behalf/BidOnBehalfConflictPopup';
import { BidOnBehalfSavingLabel } from './bid-on-behalf/BidOnBehalfSavingLabel';
import { BlastMessageButton } from './blast-message/BlastMessageButton';
import { BlastMessagePanel } from './blast-message/BlastMessagePanel';
import { TradeStatus } from '../../../types/trades/TradeStatus';
import { OpenBiddingStatus } from '../../../types/enums/OpenBiddingStatus';
import { BwicProcessType } from '../../../types/models/Process';
import { SwitchStageButton } from './jump-ball/SwitchStageButton';
import { Stage2ParticipantsFilter } from './Stage2ParticipantsFilter';
import { ShowBidOnBehalfPopupButton } from './bid-on-behalf/bulk-save/ShowBidOnBehalfPopupButton'
import { SaveBidOnBehalfPopup } from './bid-on-behalf/bulk-save/SaveBidOnBehalfPopup';
import { TradeButtonStyle } from "./trade/TradeButton";
import { calculateStage2Participants } from '../../../utils/stage2-participants-calculator';
import { BidOnlyFilter } from './BidOnlyFilter';
import { Notifications as NotificationEffects } from './Notifications';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicPosition } from '../../../types/bwic/BwicPosition';
import { BidderCompanySlim } from '../../../types/company/BidderCompanySlim';
import { useBobAutoSave } from './useBobAutoSave';
import { roles } from '../../../constants/roles';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface BiddingProps {
    activeTab?: BiddingTabs;
    activePositionId: number;
    rightSidePanelVisible: boolean;
}

export const Bidding = ({ activeTab, activePositionId, rightSidePanelVisible }: BiddingProps) => {
    const dispatch = useAppDispatch();

    const columnAnimationEnabled = useRef(false);
    const bwic = useAppSelector((s) => s.bidding.bwic);
    const securities = useAppSelector((s) => getSecuritiesWithLatestBids(s.sellerBidding));
    const securitesWithAllBids = useAppSelector((s) => s.sellerBidding.securities);
    const settlementAgentAgreements = useAppSelector((s) => s.entities.settlementAgentAgreements.items);
    const isStage2ParticipantsOnly = useAppSelector((s) => s.dealerListPanel.isStage2ParticipantsOnly);
    const isLoading = useAppSelector((s) =>
        s.bidding.bwic.process.type === BwicProcessType.Live && (s.bidOnBehalf.isRequesting || !s.dealerListPanel.isDefaultVisibilityApplied)
    )
    const visibleCompanies = useAppSelector((s) => (
        getSellerBiddingCompanies({
            participants: s.bidding.bwic.companies,
            offPlatformCompanies: s.bidOnBehalf.companies,
            hiddenState: s.dealerListPanel.companyHiddenState,
            favorites: s.favoriteBrokerDealers.favorites,
            processType: s.bidding.bwic.process.type,
            agreements: s.entities.settlementAgentAgreements.items,
            securities: s.sellerBidding.securities,
            isAllToAll: s.bidding.bwic.isAllToAll
        })
    )) as BidderCompanySlim[];

    useEffect(() => {
        setTimeout(() => columnAnimationEnabled.current = true, 1000);
    }, [bwic])

    useBobAutoSave(bwic === null || bwic.process.type === BwicProcessType.Live);

    const canTradeAll = () => securities.some(s => biddingUtils.canTradePosition(s));

    const createSecurityCustomClassName = (security: BwicPosition) => {
        const tradeStatus = security.trade && security.trade.status;
        const tradeStatusFlags = getTradeStatusFlags(tradeStatus);
        const isStage2 = bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
        const isStage2Ended = bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
        const isBFFBiddingEnded =
            bwic.process.type === BwicProcessType.BestFootForward &&
            bwic.process.stagedBiddingStatus != null;

        return cn('pointer', {
            ...tradeStatusFlags,
            active: security.id === activePositionId,
            inactive: (isStage2 || isStage2Ended || isBFFBiddingEnded) && !security.bids?.some(b => !b.pass),
        });
    }

    const createSecurityCustomArgs = (security: BwicPosition) => {
        const isLiveBidding = bwic.process.type === BwicProcessType.Live;
        const isLiveBiddingStage2 = isLiveBidding && bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
        const isLiveBiddingEnded = isLiveBidding && bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
        const liveBiddingStage2SubmissionDeadline = isLiveBiddingStage2 && bwic.bidsDueUtc
            ? liveBiddingUtils.calculateStage2SubmissionDeadline(bwic.bidsDueUtc, bwic.process, security.latestBidSubmission)
            : undefined;
        const liveBiddingStage2Expired = isLiveBiddingStage2 && moment.utc().diff(liveBiddingStage2SubmissionDeadline) > 0;
        let stage2Participants = {};
        if (bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
            const allBids = securitesWithAllBids.find(s => s.id === security.id)?.bids ?? [];
            stage2Participants = calculateStage2Participants(allBids, bwic.process);
        }

        return {
            bwic,
            stage2Participants,
            allowFeedback: !isLiveBidding,
            liveBiddingStage2SubmissionDeadline,
            liveBiddingStage2Expired,
            enabled: !isLiveBiddingEnded && !liveBiddingStage2Expired, // hide live bidding timer
            columnAnimationEnabled: columnAnimationEnabled.current
        };
    }

    const createHeaderCustomArgs = () => ({ columnScrollEnabled: columnAnimationEnabled.current })

    const getTradeStatusFlags = (tradeStatus?: TradeStatus) => {
        const pending = tradeStatus === TradeStatus.pending;
        const affirmed = tradeStatus === TradeStatus.affirmed;
        const rejected = tradeStatus === TradeStatus.rejected;
        const canceled = tradeStatus === TradeStatus.rejected;

        return { pending, affirmed, rejected, canceled };
    }


    const isAllPositionsTraded = !securities.some(s => s.trade == null || s.trade.status === TradeStatus.rejected);
    const isLiveBidding = bwic.process.type === BwicProcessType.Live;
    const isTopX = bwic.process.type === BwicProcessType.TopX;
    const isJumpBall = bwic.process.type === BwicProcessType.JumpBall;
    const isBestFF = bwic.process.type === BwicProcessType.BestFootForward;
    const isStandard = bwic.process.type === BwicProcessType.Standard;
    const isJumpBallBiddingCompleted = isJumpBall && bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
    const isTopXBiddingCompleted = isTopX && bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
    const isBestFFBiddingCompleted = isBestFF && bwic.process.stagedBiddingStatus != null;
    const isButtonDisabled = isAllPositionsTraded || isJumpBallBiddingCompleted || isTopXBiddingCompleted || isBestFFBiddingCompleted;
    const isSatge2Process = isTopX || isJumpBall || isLiveBidding;
    const isStage2 = bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const isStage2Ended = bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
    const disabledButtonTooltip =
        isButtonDisabled && (
            (isAllPositionsTraded && 'All securities in the BWIC have been traded') ||
            ((isJumpBallBiddingCompleted || isTopXBiddingCompleted || isBestFFBiddingCompleted) && 'Bidding is completed')
        );

    let isAutoFeedbacksDisabled = false;
    if (isAllPositionsTraded || isJumpBall || isBestFF || isLiveBidding || isStandard) {
        isAutoFeedbacksDisabled = true;
    } else if (isTopX) {
        isAutoFeedbacksDisabled = bwic.process.stagedBiddingStatus !== OpenBiddingStatus.stage1Ended;
    } else {
        isAutoFeedbacksDisabled = bwic.isClearingBankParticipant;
    }

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    <div className="flex-item-right controls flex-row">
                        <BidOnBehalfSavingLabel positions={securities} />
                        {bwic.bidsDueUtc && bwic.goodUntilUtc && (
                            <div className="ends-in">
                                <BiddingTimer
                                    bidsDueUtc={bwic.bidsDueUtc}
                                    goodUntilUtc={bwic.goodUntilUtc}
                                    process={bwic.process}
                                />
                            </div>
                        )}
                        <SwitchStageButton process={bwic.process} bwicReferenceName={bwic.referenceName} />
                        <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                            <TradeButtonStyle process={bwic.process}>
                                {
                                    className =>
                                        <FeatureButton
                                            className={cn('btn', className)}
                                            disabled={!canTradeAll()}
                                            onClick={() => dispatch(actions.tradeAll())}
                                        >
                                            trade all
                                        </FeatureButton>
                                }
                            </TradeButtonStyle>
                        </RoleActionBlocker>
                        <RoleActionBlocker className="margin-l-8" roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                            <FeatureButton
                                className="btn btn-ghost btn-complete"
                                onClick={() => dispatch(actions.completeBwic(bwic.referenceName))}
                            >
                                Send Color
                            </FeatureButton>
                        </RoleActionBlocker>
                    </div>
                </TopPanel>
                <SubHeader bwic={bwic}>
                    {
                        !isLoading &&
                        <FlexRow>
                            {!bwic.isAllToAll && (isBestFF || (isSatge2Process && !isStage2Ended)) && <BidOnlyFilter />}
                            {isSatge2Process && !isStage2Ended && <Stage2ParticipantsFilter />}
                        </FlexRow>
                    }
                    {
                        !isLiveBidding && !bwic.isAllToAll &&
                        <ShowBidOnBehalfPopupButton
                            disabled={isButtonDisabled}
                            disabledTooltip={disabledButtonTooltip}
                        />
                    }
                    <DealerListButton />
                    {
                        !isLiveBidding &&
                        !isBestFF &&
                        <AutoFeedbackSettingsButton
                            disabled={isAutoFeedbacksDisabled}
                            disabledTooltip={
                                isJumpBallBiddingCompleted || isTopXBiddingCompleted || isBestFFBiddingCompleted
                                    ? 'Bidding is completed'
                                    : bwic.isClearingBankParticipant && !(isTopX && isAllPositionsTraded)
                                        ? `Auto-feedback is automatically turned on for ${bwic.isAllToAll ? "All-to-All" : "direct"} BWICs.`
                                        : isAllPositionsTraded ? 'All securities in the BWIC have been traded' : 'Auto-feedback is automatically turned on.'
                            }
                        />
                    }
                    {
                        !isLiveBidding &&
                        <BlastMessageButton disabled={isButtonDisabled} disabledTooltip={disabledButtonTooltip} />
                    }
                </SubHeader>
            </div>
            <div className="flex-row flex-row-full-size">
                <NotificationEffects bwic={bwic} />
                {activeTab === BiddingTabs.participants
                    ? <Participants />
                    : (
                        <Preloader inProgress={isLoading}>
                            {
                                !isLoading &&
                                <Table
                                    sticky={true}
                                    className={cn('data-list-seller-bidding', {
                                        'data-list-short': rightSidePanelVisible
                                    })}
                                    columns={getSellerBiddingColumns(
                                        rightSidePanelVisible,
                                        bwic.process,
                                        visibleCompanies,
                                        settlementAgentAgreements,
                                        isStage2ParticipantsOnly,
                                        bwic.isAllToAll
                                    )}
                                    dataItems={securities}
                                    createSecurityCustomClassName={createSecurityCustomClassName}
                                    createHeaderCustomArgs={bwic.isParsed ? undefined : createHeaderCustomArgs}
                                    createSecurityCustomArgs={createSecurityCustomArgs}
                                    createRowCustomKey={(position: BwicPosition) => position.id}
                                />
                            }
                        </Preloader>
                    )
                }
                <ShowFor roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    <TradePopup />
                </ShowFor>
                <ControlPanel
                    bwic={bwic}
                    bidListEnabled={true}
                    positions={securities}
                />
                {
                    !isAutoFeedbacksDisabled &&
                    <ShowFor roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                        <AutoFeedbackSettingsPanel />
                    </ShowFor>
                }
                {
                    !isLiveBidding &&
                    <ShowFor roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                        <BlastMessagePanel bwicProcess={bwic.process} isAllToAll={bwic.isAllToAll} />
                    </ShowFor>
                }
                <DealerListPanel
                    isClearingBankParticipant={bwic.isClearingBankParticipant}
                    isStage2Mode={(isTopX || isJumpBall || isLiveBidding) && (isStage2 || isStage2Ended)}
                    isAllToAll={bwic.isAllToAll}
                    bwicParticipants={bwic.companies}
                    offPlatformVisible={!isLiveBidding} />
                <ShowFor roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    {!isLoading && !bwic.isAllToAll && <BidOnBehalfConflictPopup />}
                    {
                        !isLiveBidding && !bwic.isAllToAll &&
                        <SaveBidOnBehalfPopup bwic={bwic} positions={securities} />
                    }
                </ShowFor>
            </div>
        </div>
    );
};