import * as React from 'react';
import classNames from 'classnames';
import { Bid } from '../../../../types/bidding/Bid';
import { EditBidOnBehalfState } from '../../../../types/state/BidOnBehalfState';
import { formatUtils, numericUtils } from '../../../../utils';
import { BidLevelDelta } from '../../../common/bidding/BidLevelDelta';
import { useDispatch } from 'react-redux';
import { bidOnBehalfActions } from '../../../../actions';
import { constants, errorMessages } from '../../../../constants';
import { FormError } from '../../../controls';
import { BlockedFeatureContext } from '../../../access/BlockedFeatureContext';

interface Props {
    companyId: number;
    positionId: number;
    latestBid?: Bid;
    editState?: EditBidOnBehalfState;
    disabled: boolean;
    hasMoveNextFocus: boolean;
    isBidDecreaseDisabled: boolean
}

export function EditBidValue({ companyId, positionId, latestBid, editState = undefined, hasMoveNextFocus, disabled = false, isBidDecreaseDisabled }: Props) {
    const dispatch = useDispatch();
    const pass = editState == null ? !!latestBid?.pass : !!editState.pass;
    const value = pass ? 'Pass' : editState?.value ?? formatUtils.formatBid(latestBid?.value);
    const isChanged = editState && (!!editState.pass !== !!latestBid?.pass || (editState.value && +editState.value !== latestBid?.value));
    const [hasFocus, setHasFocus] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const isBlocked = React.useContext(BlockedFeatureContext);

    React.useEffect(() => {
        if (hasMoveNextFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [hasMoveNextFocus, dispatch]);

    const validateBid = (value: number) => {
        if (!numericUtils.isNumber(value)) {
            return errorMessages.invalidValue;
        }
        if (value < constants.bidRange.min || value > constants.bidRange.max) {
            return errorMessages.bidShouldBeBetween;
        }

        if (isBidDecreaseDisabled && latestBid && value < latestBid?.value) {
            return errorMessages.valueCannotBeLess;
        }

        return undefined;
    }

    const handleBlur = () => {
        setHasFocus(false);
        if (hasMoveNextFocus) {
            dispatch(bidOnBehalfActions.resetNextBidIdentifiers());
        }
        if (editState?.value != null) {
            if (editState.value === '' || +editState.value === latestBid?.value) {
                dispatch(bidOnBehalfActions.resetBidEdit(positionId, companyId));
            } else {
                const editingLevel = Number(editState.value);
                const formatted = formatUtils.formatBid(editingLevel);
                const error = editState.error ?? validateBid(editingLevel);
                dispatch(bidOnBehalfActions.editBid(positionId, companyId, formatted, true, error));
            }
        }
    }

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
        setHasFocus(true);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const input = e.target as HTMLInputElement;
            input.blur();
            dispatch(bidOnBehalfActions.moveNextBid(companyId, positionId))
        }
    }

    const handleChange = (e: any) => {
        const rawValue = e.target.value;
        const value = rawValue ? rawValue.replace(/\s/g, '') : rawValue;

        if (!value || constants.bidNumber.test(value)) {
            const error = editState?.error ? validateBid(+value) : undefined;
            dispatch(bidOnBehalfActions.editBid(positionId, companyId, value, false, error));
        }
    };

    return (
        <div className={classNames('display-inline', { 'form-control-changed': isChanged, 'form-control-error': editState?.error })}>
            <input
                ref={inputRef}
                type="text"
                className="form-control"
                value={value}
                disabled={disabled || isBlocked}
                maxLength={8}
                placeholder="0.0000"
                onFocus={handleOnFocus}
                onMouseUp={() => false}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onClick={e => e.stopPropagation()}
            />
            <FormError message={editState?.error} />
            {
                hasFocus &&
                !disabled &&
                !!isChanged &&
                latestBid != null &&
                latestBid.value > 0 &&
                editState != null &&
                !!editState.value &&
                +editState.value > 0 &&
                <BidLevelDelta
                    currentLevel={latestBid.value}
                    updatedLevel={+editState.value}
                    updatedLevelVisible={false}
                    tooltip={true}
                />
            }
        </div>
    );
}
