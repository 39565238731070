import { useSelector } from 'react-redux';
import { bidPlacementActions } from '../../../../actions/bid-placement.actions';
import { AppState } from '../../../../types/state/AppState';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    disabled: boolean;
    currentPass: boolean;
}

export function PassButton({ positionId, disabled, currentPass }: Props) {
    const dispatch = useAppDispatch();
    const pass = useSelector((s: AppState) => s.brokerDealerBidding.editBid[positionId]?.pass) ?? false;

    const handleClick = (e: React.MouseEvent) => {
        dispatch(bidPlacementActions.passChange(positionId, true));
        e.stopPropagation();
    }

    return (
        <button
            type="button"
            className="btn btn-ghost btn-sm"
            disabled={disabled || pass || currentPass}
            onClick={handleClick}
        >
            pass
        </button>
    );
}
