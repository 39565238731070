import { BwicBase } from "../../../../../../types/bwic/BwicBase";
import { BwicPosition } from "../../../../../../types/bwic/BwicPosition";
import { AllToAllArgeementRequiredActionBlocker } from "../../../../../agreements/AllToAllArgeementRequiredActionBlocker";
import { SecurityListColumn } from "../../../../../common/security-list/SecurityListColumn";
import { SecurityListHeaderColumn } from "../../../../../common/security-list/SecurityListHeaderColumn";
import { Commission } from "../../../bid-placement/Commission";

export const commission = {
    columnName: 'commission',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="cell-input-commision">
                Comm, %
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (
        position: BwicPosition,
        context: { enabled: boolean, biddingControlsVisible: boolean, processEnabled: boolean, bwic: BwicBase }) {
        return (
            <SecurityListColumn key={this.columnName} className="cell-input-commision overflow-visible">
                <AllToAllArgeementRequiredActionBlocker disabled={!context.bwic.isAllToAll}>
                    {
                        blocked => (
                            <Commission
                                position={position}
                                enabled={context.enabled && !blocked}
                                readonly={!context.biddingControlsVisible || !context.processEnabled}
                            />
                        )
                    }
                </AllToAllArgeementRequiredActionBlocker>
            </SecurityListColumn>
        );
    }
}
