import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealerListPanelActions } from '../../../../actions';
import { bidOnBehalfActions } from '../../../../actions';
import { useAppSelector } from '../../../../effects/useAppSelector';
import IconSVG from '../../../../styles/svg-icons';
import { AppState } from '../../../../types/state/AppState';
import { OnHoverTooltip } from '../../../common';
import { SettlementAgentAgreement } from '../../../../types/bid-as-dealer/SettlementAgentAgreement';
import { SettlementAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { CompanyStatus } from '../../../../types/company/CompanyStatus';
import { BidderCompanySlim } from '../../../../types/company/BidderCompanySlim';
import { biddingUtils } from '../../../../utils';
import { ScrollIntoView } from '../../../common/ScrollIntoView';

interface Props {
    company: BidderCompanySlim;
    canHide?: boolean;
    agreement?: SettlementAgentAgreement;
    columnScrollEnabled?: boolean;
}

function DealerColumnHeaderContent({ company, canHide = true, agreement, columnScrollEnabled }: Props) {
    const dispatch = useDispatch();
    const isFavorite = useSelector((state: AppState) => state.favoriteBrokerDealers.favorites[company.id]);
    const lastSelectedCompanyIdentifier = useAppSelector(s => s.dealerListPanel.lastSelectedCompanyIdentifier);
    const isOnPlatform = company.status === CompanyStatus.active;
    const isConfirmedClearingBank =
        isOnPlatform &&
        company.isClearingBank &&
        !company.buyerPseudoOrderNumber &&
        agreement?.agreementStatus === SettlementAgreementStatus.confirmed;

    const handleHideClick = () => {
        dispatch(dealerListPanelActions.toggleCompanyVisible(company.identifier));
        if (!company.buyerPseudoOrderNumber) {
            dispatch(bidOnBehalfActions.resetCompanyBidsEdit(company.id));
        }
    };

    const enabledScroll = columnScrollEnabled && !!lastSelectedCompanyIdentifier && lastSelectedCompanyIdentifier === company.identifier;

    return (
        <ScrollIntoView className="flex-row padding-r-0 padding-l-0" disabled={!enabledScroll}>
            {!company.buyerPseudoOrderNumber ? <ul className="column-header-icons flex-row flex-none">
                {
                    isFavorite &&
                    <li>
                        <IconSVG name="favorite-fill" width={16} height={16} />
                    </li>
                }
                {
                    isConfirmedClearingBank &&
                    <li>
                        <OnHoverTooltip overlay="Settlement Agent. Submit bids directly to the Seller once approved by the Settlement Agent.">
                            <span className="icon-wrap">
                                <IconSVG name="clearing" width={16} height={16} />
                            </span>
                        </OnHoverTooltip>
                    </li>
                }
                {
                    (isOnPlatform && !isConfirmedClearingBank) &&
                    <li>
                        <OnHoverTooltip overlay="Platform broker-dealer. Bids will come automatically">
                            <span className="icon-wrap">
                                <IconSVG name="on-platform" width={16} height={16} />
                            </span>
                        </OnHoverTooltip>
                    </li>
                }
            </ul> : null}
            <OnHoverTooltip overlay={biddingUtils.getBidderName(company)}>
                <span>{biddingUtils.getBidderShortcode(company)}</span>
            </OnHoverTooltip>
            <div className="remove-button">
                {
                    canHide &&
                    <button onClick={handleHideClick}>
                        <IconSVG name="close" width={16} height={16} />
                    </button>
                }
            </div>
        </ScrollIntoView>
    );
}

export const DealerColumnHeaderContentMemoized = React.memo(DealerColumnHeaderContent);
