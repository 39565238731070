import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { roles, routes } from '../../constants';
import { ShowFor } from '../access';
import { BlotterTrades } from './BlotterTrades';
import { BlotterTradesView } from './BlotterTradesView';
import { TabItem, TabList } from '../bidding/common/tab-list';
import { BlotterSettlementAccount } from './settlement-account/BlotterSettlementAccount';
import { useTradeAllocationTemplates } from '../../effects/data-accessors/useTradeAllocationTemplates';
import { useLocationStateReset } from '../../effects/useLocationStateReset';
import { user } from '../../user';
import { TradeAllocationTemplateButton } from './settlement-account/template/TradeAllocationTemplateButton';
import { useSettlementAccounts } from '../../effects/data-accessors/useSettlementAccounts';
import { BlotterExportButton } from './BlotterExportButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { isRequesting } from '../../utils';
import { settlementAccountActions } from '../../actions/settlement-account.actions';

enum BlotterTabType {
    Trades,
    Settlement
}

export function BlotterView() {
    const history = useHistory();
    const dispatch = useDispatch();

    useLocationStateReset(); // Reset all popups and panels on the first load
    useSettlementAccounts();
    const { tradeAllocationTemplatesRequestState, templates } = useTradeAllocationTemplates(!user.hasRoles(...roles.seller()));
    const requestStateSecurities = useSelector((s: AppState) => s.blotter.requestStateSecurities);

    const renderTabs = (activeTab: BlotterTabType) =>
        <div className="sub-header">
            <div className="sub-header-row type01 flex-row">
                <TabList className="tabs-large">
                    <TabItem
                        active={activeTab === BlotterTabType.Trades}
                        title="Trades"
                        path={routes.blotterTardes}
                    />
                    <TabItem
                        active={activeTab === BlotterTabType.Settlement}
                        title="Settlement Accounts"
                        onClick={() => {
                            dispatch(settlementAccountActions.reset()); // TO DO: Remove after Settlement Account push will be implemented
                            history.push(routes.blotterSettlementAccountDetailsUrl(""));
                        }}
                    />
                </TabList>
                <div className="flex-item-right controls flex-row">
                    <Switch>
                        <Route exact path={[routes.blotterTardes, routes.blotter]}>
                            <BlotterExportButton />
                            <TradeAllocationTemplateButton
                                templates={templates}
                                requestState={tradeAllocationTemplatesRequestState}
                                onShow={nextState => history.replace(routes.blotterSettlementAccountDetailsUrl(""), nextState)}
                                disabled={isRequesting(requestStateSecurities)}
                            />
                        </Route>
                        <Route exact path={routes.blotterSettlementAccounts}>
                            <TradeAllocationTemplateButton
                                templates={templates}
                                requestState={tradeAllocationTemplatesRequestState}
                                onShow={nextState => history.replace(history.location.pathname, nextState)}
                                disabled={isRequesting(requestStateSecurities)}
                            />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>

    return (
        <div className="container">
            <ShowFor roles={roles.seller()}>
                <Switch>
                    <Route exact path={[routes.blotterTardes, routes.blotter]} render={() => renderTabs(BlotterTabType.Trades)} />
                    <Route exact path={routes.blotterSettlementAccounts} render={() => renderTabs(BlotterTabType.Settlement)} />
                </Switch>
                <Switch>
                    <Route exact path={[routes.blotterTardes, routes.blotter]} component={BlotterTrades} />
                    <Route exact path={routes.blotterSettlementAccounts}>
                        <BlotterSettlementAccount templates={templates} />
                    </Route>
                    <Redirect to={routes.notFound} />
                </Switch>
            </ShowFor>
            <ShowFor roles={roles.bd()}>
                <Switch>
                    <Route exact path={routes.blotter} component={BlotterTradesView} />
                    <Redirect to={routes.notFound} />
                </Switch>
            </ShowFor>
        </div>
    );
}
