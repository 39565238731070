import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { MessageHistoryPanel } from '../../bwic-history/MessageHistoryPanel';
import { MessageHistory } from '../../bwic-history/MessageHistory';
import { BidList } from '../seller/bid-list/BidList';
import {
    sellerBiddingActions,
    bwicHistoryActions,
    securityDetailsActions,
    rulesActions,
    pxTalkListActions
} from '../../../actions';
import { SecurityDetails } from '../../security-details';
import { RightSideTabPanel } from '../../controls/RightSideTabPanel';
import { BwicRules } from '../../bwic-rules/BwicRules';
import { BwicRulesPanel } from '../../bwic-rules/BwicRulesPanel';
import { PxTalkList } from '../../common';
import SecurityDetailsOptions from '../../security-details/SecurityDetailsOptions';
import { ControlPanelOptions } from './ControlPanelOptions';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { BWICLog } from '../../security-details/bwic-log/BWICLog';
import { bwicLogActions } from '../../../actions/bwic-log.actions';

export function ControlPanel(
    {
        bwic,
        position,
        positions,
        bidListEnabled = false,
        historyEnabled = true,
        showEvalValues = false,
        accessDealDocumentsFeature,
        onHistoryActive,
        onClose
    }
) {
    const dispatch = useDispatch();
    const bidList = useSelector(store => store.sellerBidding.bidList);
    const securityDetails = useSelector(store => store.securityDetails);
    const bwicLog = useSelector(store => store.bwicLog);
    const history = useSelector(store => store.bwicHistory);
    const rules = useSelector(store => store.rules);
    const pxTalkList = useSelector(store => store.pxTalkList);

    if (!bwic) {
        return null;
    }

    const panelClassNames = cn({
        'right-panel-tabs': true,
        'right-panel-history': history.visible,
        'right-panel-bids': bidList.visible
    });

    const getBidsDueDate = () => bwic.bidsDueUtc || bwic.bidsDueDateUtc;
    const getGoodUntilDate = () => bwic.goodUntilUtc || bwic.goodUntilDateUtc;
    const isMyBwic = bwic.isMy ?? bwic.seller?.id === user.current()?.companyId;

    if (history.visible && !history.positionId) {
        return <MessageHistoryPanel process={bwic.process} isAllToAll={bwic.isAllToAll} />;
    } else if (rules.show && rules.separatePanel) {
        return <BwicRulesPanel
            onClose={onClose}
            isParsed={bwic.isParsed}
            bidsDueDate={getBidsDueDate()}
            goodUntilDate={getGoodUntilDate()}
            process={bwic.process}
            parsedProcess={bwic.parsedProcess}
            isSameDayBwic={bwic.isSameDayBwic}
            daysToSettle={bwic.daysToSettle}
            isMy={isMyBwic}
            isAllToAll={bwic.isAllToAll}
            isDirectBidding={bwic.isClearingBankParticipant}
        />;
    }

    const positionId =
        history.positionId ||
        bidList.positionId ||
        rules.positionId ||
        pxTalkList.positionId ||
        (securityDetails.security && securityDetails.security.id) ||
        (bwicLog.security && bwicLog.security.id);


    if (!position) {
        position = positions == null
            ? bwic.securities.find(s => s.id === positionId)
            : positions.find(s => s.id === positionId);
    }

    if (!position) {
        return null;
    }

    const handleHistoryActivate = () => {
        dispatch(bwicHistoryActions.showHistory(true, positionId, position.ticker));

        if (onHistoryActive) {
            onHistoryActive();
        }
    };

    const tabs = [];

    if (bidListEnabled) {
        const bidListTab = {
            title: 'All Bids',
            active: bidList.visible,
            renderContent: () =>
                <BidList
                    bwicStatus={bwic.status}
                    process={bwic.process}
                    participants={bwic.companies}
                    positions={positions}
                    isAllToAll={bwic.isAllToAll}
                />,
            onActivate: () => dispatch(sellerBiddingActions.showBidList(true, positionId)),
            onDeactivate: () => dispatch(sellerBiddingActions.showBidList(false))
        };

        tabs.push(bidListTab);
    }

    const pxTalkTab = {
        title: 'Px Talk',
        active: pxTalkList.visible,
        renderContent: () =>
            <PxTalkList
                evalPriceTalk={position.evalPriceTalk}
                evalPriceTalkVisible={showEvalValues}
                bwicReferenceName={bwic.referenceName}
                normalizedEvalPriceTalk={position.normalizedLevelEvalPriceTalk}
                isParsed={bwic.isParsed}
            />,
        onActivate: () => dispatch(pxTalkListActions.show(positionId)),
        onDeactivate: () => dispatch(pxTalkListActions.hide())
    };

    tabs.push(pxTalkTab);

    const securityDetailsTab = {
        title: 'Security',
        active: !!securityDetails.security,
        renderContent: () => <SecurityDetails accessDealDocumentsFeature={accessDealDocumentsFeature} />,
        onActivate: () => dispatch(securityDetailsActions.securityDetailsInitRequest(position, false)),
        onDeactivate: () => dispatch(securityDetailsActions.securityDetailsReset())
    };

    tabs.push(securityDetailsTab);

    const bwicLogTab = {
        title: 'BWIC Log',
        active: !!bwicLog.security,
        renderContent: () => <BWICLog evalPriceTalkVisible={showEvalValues} />,
        onActivate: () => dispatch(bwicLogActions.bwicLogInitRequest(position)),
        onDeactivate: () => dispatch(bwicLogActions.bwicLogReset())
    };

    tabs.push(bwicLogTab);

    const rulesTab = {
        title: 'Rules',
        active: rules.show,
        renderContent: () =>
            <BwicRules
                isParsed={bwic.isParsed}
                bidsDueDate={getBidsDueDate()}
                process={bwic.process}
                parsedProcess={bwic.parsedProcess}
                goodUntilDate={getGoodUntilDate()}
                isSameDayBwic={bwic.isSameDayBwic}
                daysToSettle={bwic.daysToSettle}
                isMy={isMyBwic}
                isAllToAll={bwic.isAllToAll}
                isDirectBidding={bwic.isClearingBankParticipant}
            />,
        onActivate: () => dispatch(rulesActions.show(positionId)),
        onDeactivate: () => dispatch(rulesActions.hide())
    };

    tabs.push(rulesTab);

    if (historyEnabled) {
        const historyTab = {
            title: 'Bid Log',
            active: history.visible,
            renderContent: () => <MessageHistory process={bwic.process} isAllToAll={bwic.isAllToAll} />,
            onActivate: handleHistoryActivate,
            onDeactivate: () => dispatch(bwicHistoryActions.showHistory(false))
        };

        tabs.push(historyTab);
    }

    return (
        <RightSideTabPanel
            className={panelClassNames}
            title={
                <>
                    {position.ticker}
                    {
                        !user.hasSingleRole(roles.SellerAdministrator) &&
                        <SecurityDetailsOptions
                            optionsExclude={[ControlPanelOptions.viewBwic]}
                            position={position}
                        />
                    }
                </>
            }
            items={tabs}
            onClose={onClose}
        />
    );
}

ControlPanel.defaultProps = {
    onHistoryActive: undefined,
    onClose: undefined,
    position: undefined,
    positions: null,
    accessDealDocumentsFeature: undefined
}
