import React from 'react';
import { constants } from '../../../constants/constants';
import { BwicStatus } from '../../../types/enums/BwicStatus';
import { OpenBiddingStatus } from '../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { liveBiddingUtils } from '../../../utils/live-bidding.utils';
import { BwicStatusLabel } from '../../common';
import { Timer } from './Timer';

interface Props {
    bidsDueDateUtc: Date;
    process: Process;
    latestBidSubmission?: Date;
    canBidOnStage2: boolean;
    renderEmpty?: React.ReactNode;
    onExpired: () => void;
}

export function LiveBiddingStage2Timer({
    bidsDueDateUtc,
    process,
    latestBidSubmission,
    canBidOnStage2,
    renderEmpty,
    onExpired }: Props) {
    const isLiveBiddingStage2 =
        process.type === BwicProcessType.Live &&
        process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const canBid = liveBiddingUtils.canBid(bidsDueDateUtc, process, canBidOnStage2, latestBidSubmission);

    if (isLiveBiddingStage2 && canBid) {
        const stage2SubmissionDeadline =
            liveBiddingUtils.calculateStage2SubmissionDeadline(
                bidsDueDateUtc,
                process,
                latestBidSubmission
            );

        return (
            <BwicStatusLabel
                status={BwicStatus.bidding}
                tooltip="Extra time for bidding."
                render={
                    <div className="label-status-timer">
                        <Timer goodUntilUtc={stage2SubmissionDeadline} onExpired={onExpired} />
                    </div>
                }
            />
        )
    }

    return <>{renderEmpty ?? constants.emptyPlaceholder}</>;
}