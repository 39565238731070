import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colorDistributionActions } from '../../../../actions';
import { BlockedFeatureContext } from '../../../access/BlockedFeatureContext';
import { colorDistributionTypes } from '../../../../constants';
import { AppState } from '../../../../types/state/AppState';

export function DntSelect() {
    const dispatch = useDispatch();
    const forDNTType: number = useSelector((state: AppState) => state.sellerBidding.colorDistribution.forDNTType);
    const isBlocked = React.useContext(BlockedFeatureContext);

    return (
        <select
            id="forDNTType"
            className="form-control form-select"
            value={forDNTType}
            disabled={isBlocked}
            onChange={e => dispatch(colorDistributionActions.dntTypeChange(e.target.value))}
        >
            {colorDistributionTypes.dnt().map(t => <option key={t.key} value={t.key}>{t.title}</option>)}
        </select>
    );
}
