import * as React from 'react';
import { Bid } from '../../../../types/bidding/Bid';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { arrayUtils, biddingUtils, formatUtils } from '../../../../utils';
import { FeedbackMenu } from '../FeedbackMenu';
import { OnHoverTooltip } from "../../../common";
import { constants } from '../../../../constants';
import { Process } from '../../../../types/models/Process';
import { BidderCompanySlim } from '../../../../types/company/BidderCompanySlim';
import { AxedIcon } from "../../common/AxedIcon";
import { FinalIcon } from "../../common/FinalIcon";

interface Props {
    company: BidderCompanySlim;
    position: BwicPosition;
    feedbackDisabled?: boolean;
    feedbackVisible?: boolean;
    showEmptyDash?: boolean;
    process?: Process;
}

function CompanyBidComponent({
    company,
    position,
    feedbackDisabled = false,
    feedbackVisible = true,
    process }: Props) {
    const bids = position.bids || [];
    const companyBids = bids.filter(b => biddingUtils.getBidCompanyIdentifier(b) === company.identifier);
    const latestBid: Bid | undefined = arrayUtils.max(companyBids, bid => bid.id);

    const hasSameLevelBids = () => {
        if (!bids.length || !latestBid || latestBid.pass) {
            return false;
        }

        return bids.some(b =>
            biddingUtils.getBidCompanyIdentifier(b) !== biddingUtils.getBidCompanyIdentifier(latestBid) &&
            b.value === latestBid.value &&
            !bids.some(bb => bb.id > b.id && biddingUtils.getBidCompanyIdentifier(bb) === biddingUtils.getBidCompanyIdentifier(b)) // latest bid
        );
    }

    const renderContent = () => {
        if (latestBid == null) {
            return <div className="company-bid-empty">{constants.emptyPlaceholder}</div>
        } else if (latestBid.pass) {
            return <div className="company-bid-pass">Pass</div>;
        }

        const value = formatUtils.formatBid(latestBid.value);

        return (
            <div className="company-bid-value-content">
                <OnHoverTooltip overlay={value}>
                    <span className="input-placeholder-value text-ellipsis">{value}</span>
                </OnHoverTooltip>
                <span className="icon-wrap">{latestBid.axed && <AxedIcon />}</span>
                <span className="icon-wrap">{latestBid.final && <FinalIcon />}</span>
            </div>
        );
    }

    return (
        <div className="company-bid-value">
            {renderContent()}
            {
                feedbackVisible && process != null &&
                <FeedbackMenu
                    level={latestBid ? biddingUtils.getBidLevel(latestBid, position.bids) : undefined}
                    positionId={position.id}
                    bids={position.bids}
                    levelBids={latestBid ? [latestBid] : undefined}
                    disabled={feedbackDisabled}
                    hasManyBids={hasSameLevelBids()}
                    emptyBidCompany={latestBid ? undefined : company}
                    process={process}
                />
            }
        </div>
    );
}

export const CompanyBid = React.memo(CompanyBidComponent, (prev, next) => {
    return (
        prev.company === next.company &&
        prev.feedbackDisabled === next.feedbackDisabled &&
        prev.feedbackVisible === next.feedbackVisible &&
        prev.position.id === next.position.id &&
        prev.position.bids === next.position.bids &&
        prev.position.trade === next.position.trade &&
        prev.showEmptyDash === next.showEmptyDash &&
        prev.process?.stagedBiddingStatus === next.process?.stagedBiddingStatus
    );
});
