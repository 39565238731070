import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blastMessageActions } from '../../../../actions/blast-message.actions';
import { RequestState } from '../../../../constants/request-state';
import { getSecuritiesWithLatestBids } from '../../../../selectors';
import { ApiOperationType } from '../../../../types/api-operation/ApiOperationType';
import { AppState } from '../../../../types/state/AppState';

interface Props {
    disabled: boolean;
}

export function BlastMessageButtons({ disabled }: Props) {
    const dispatch = useDispatch();
    const hasPendingBidOnBehalfOperations = useSelector((state: AppState) =>
        state.bidOnBehalf.isSubmitting ||
        state.apiOperation.requests.some(r =>
            r.event === ApiOperationType.submitBidsOnBehalf &&
            r.state === RequestState.request
        ));
    const recipients = useSelector((s: AppState) => s.blastMessage.recipients);
    const error = useSelector((s: AppState) => s.blastMessage.error);
    const selectedRecipientCount = Object
        .keys(recipients)
        .filter((key: string) => recipients[key])
        .length;
    const securities = useSelector((s: AppState) => s.sellerBidding.securities);
    const bwicParticipants = useSelector((s: AppState) => s.bidding.bwic?.companies);

    return (
        <div className="blast-message-settings-buttons">
            <button
                className="btn btn-ghost"
                disabled={disabled}
                onClick={() => dispatch(blastMessageActions.hide())}
            >
                Cancel
            </button>
            <button
                className="btn btn-main"
                disabled={
                    disabled ||
                    !selectedRecipientCount ||
                    hasPendingBidOnBehalfOperations ||
                    !!error
                }
                onClick={() => dispatch(blastMessageActions.send(
                    recipients,
                    bwicParticipants,
                    getSecuritiesWithLatestBids({ securities })))
                }
            >
                Send {!!selectedRecipientCount && `(${selectedRecipientCount})`}
            </button>
        </div>
    );
}
