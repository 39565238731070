import { useSelector } from 'react-redux';
import { brokerDealerAccessState } from '../../../constants/bidding';
import { TopPanel, BiddingTimer, ActionBar, ExportButton } from '../common';
import { RequestInvitationPanel } from './invitation';
import { BwicCreator } from '.';
import { BidAll } from '../common/bid-placement/BidAll';
import { ControlPanel } from '../common/ControlPanel';
import { CompanyDetailsPanel } from '../../company-details/CompanyDetailsPanel';
import { ConfirmBidsPopup } from './confirm-bids/ConfirmBidsPopup';
import { BrokerDealerBiddingRouteLeaveGuard } from './BrokerDealerBiddingRouteLeaveGuard';
import { RequestSettlementAgentAgreementBanner } from '../../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { BwicStatus } from "../../../types/enums/BwicStatus";
import { BiddingTable } from './BiddingTable';
import { AppState } from '../../../types/state/AppState';
import { RecipientType, useBiddingCompletedNotification } from '../../../effects/useBiddingCompletedNotification';

interface BiddingProps {
    activePositionId?: number;
}

export const Bidding = ({ activePositionId }: BiddingProps) => {
    const bwic = useSelector((s: AppState) => s.bidding.bwic);
    const securities = useSelector((s: AppState) => s.brokerDealerBidding.securities);
    const accessState = useSelector((s: AppState) => s.brokerDealerBidding.accessState);

    useBiddingCompletedNotification(RecipientType.Bidder, bwic);

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    {bwic.seller && <BwicCreator seller={bwic.seller} />}
                    <div className="flex-item-right controls flex-row">
                        <div className="ends-in">
                            {bwic.status !== BwicStatus.scheduled && bwic.bidsDueUtc && bwic.goodUntilUtc && (
                                <BiddingTimer
                                    bidsDueUtc={bwic.bidsDueUtc}
                                    goodUntilUtc={bwic.goodUntilUtc}
                                    process={bwic.process}
                                />
                            )}
                        </div>
                        {
                            !bwic.isParsed &&
                            <BidAll
                                showCount={
                                    accessState === brokerDealerAccessState.approved &&
                                    !bwic.isColorDistribution
                                }
                            />
                        }
                    </div>
                </TopPanel>
                <div className="sub-header-row type03 flex-row">
                    {!bwic.isParsed && <RequestInvitationPanel />}
                    <ActionBar>
                        {accessState === brokerDealerAccessState.approved && <ExportButton bwicReferenceName={bwic.referenceName} />}
                    </ActionBar>
                </div>
            </div>
            {bwic.isAllToAll && <RequestSettlementAgentAgreementBanner />}
            <div className="flex-row flex-row-full-size">
                <div className="container-flex container-flex-bid-all">
                    <BiddingTable
                        bwic={bwic}
                        securities={securities}
                        accessState={accessState}
                        activePositionId={activePositionId}
                    />
                </div>
                <ControlPanel bwic={bwic} positions={securities} />
                <CompanyDetailsPanel />
            </div>
            <BrokerDealerBiddingRouteLeaveGuard accessState={accessState} />
            <ConfirmBidsPopup />
        </div>
    );
}
