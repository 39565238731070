import * as React from 'react'
import { isRequestSuccess, isRequestFailed } from '../../../../utils/request-state.utils';
import { SubmitStatus } from './SubmitStatus';
import { isActiveTrade } from '../../../../types/trades/TradeStatus';
import { usePositionSubmitResult } from './usePositionSubmitResult';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { liveBiddingUtils } from '../../../../utils/live-bidding.utils';

interface Props {
    position: BwicPosition;
    isColorDistribution: boolean;
    isOpenBiddingEnded: boolean;
    isPass: boolean
}

export function SubmitBidStatus({ position, isColorDistribution, isOpenBiddingEnded, isPass }: Props) {
    const { isSubmitting, submitResult } = usePositionSubmitResult(position.id);
    const process = useAppSelector(s => s.bidding.bwic?.process);
    const bidsDueDateUtc = useAppSelector(s => s.bidding.bwic?.bidsDueDateUtc || s.bidding.bwic?.bidsDueUtc);
    const liveBiddingStage2Expired = liveBiddingUtils.checkLiveBiddingStage2Expired(process, bidsDueDateUtc, position.latestBidSubmission);

    if (process == null) return null;

    return (
        <SubmitStatus
            ticker={position.ticker}
            trade={position.trade}
            isTradedAway={position.isTradedAway}
            isSubmitting={isSubmitting}
            isSuccess={isRequestSuccess(submitResult.requestState)}
            isFailed={
                isRequestFailed(submitResult.requestState) ||
                isColorDistribution ||
                isOpenBiddingEnded ||
                isActiveTrade(position.trade) ||
                Boolean(position.isTradedAway)
            }
            isPass={isPass}
            conflict={submitResult.conflict}
            stageTransitionConflict={submitResult.stageTransitionConflict}
            isColorDistribution={isColorDistribution}
            validationError={submitResult.validationError}
            isBidOverThemself={Boolean(submitResult.isBidOverThemself)}
            isLoggedOut={Boolean(submitResult.isLoggedOut)}
            process={process}
            liveBiddingStage2Expired={liveBiddingStage2Expired}
        />
    );
}
