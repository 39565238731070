import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { ColumnBuilder } from '../columns/column-builder/ColumnBuilder';
import { SecurityListRow } from "../../../../common/security-list";
import { AppState } from '../../../../../types/state/AppState';
import { tableActions } from '../../../../../actions/table.actions';


interface Props<T> {
    rowKey: string;
    className?: string;
    columns: ColumnBuilder<T>[];
    dataItem: T;
    context: { [key: string]: any };
    onClick?: (dataItem: T) => void;
    onHover?: (dataItem: T) => void;
}

export function StickyTableRow<T>({ className, columns, dataItem, context, rowKey, onClick, onHover }: Props<T>) {
    const dispatch = useDispatch();
    const hover = useSelector((s: AppState) => s.ui.table.hover[rowKey]) ?? false;

    const handleRowHover = (hover: boolean) => {
        dispatch(tableActions.hoverChange(rowKey, hover));
        onHover?.(dataItem);
    }

    return (
        <SecurityListRow
            className={cn(className, { hover })}
            onClick={onClick ? () => onClick(dataItem) : undefined}
            onHover={() => handleRowHover(true)}
            onMouseLeave={() => handleRowHover(false)}
        >
            {columns.map(c => c.renderBody(dataItem, context))}
        </SecurityListRow>
    );
}
