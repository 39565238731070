import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import IconSVG from '../../../../styles/svg-icons';
import { autoFeedbackSettingsActions } from '../../../../actions/auto-feedback-settings-actions';
import { FeatureButton } from '../../../access/FeatureButton';
import { AppState } from '../../../../types/state/AppState';
import { OnHoverTooltip } from '../../../common';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    disabled?: boolean;
    disabledTooltip?: string;
}

export function AutoFeedbackSettingsButton({ disabled = false, disabledTooltip }: Props) {
    const dispatch = useAppDispatch();
    const autoFeedbackSettingsVisible = useSelector((s: AppState) => s.sellerBidding.autoFeedbackSettingsVisible);
    const bwic = useSelector((s: AppState) => s.bidding.bwic)

    React.useEffect(() => {
        if (disabled && autoFeedbackSettingsVisible) {
            dispatch(autoFeedbackSettingsActions.showAutoFeedbackSettingsPanel(false));
        }
    },
        [disabled, autoFeedbackSettingsVisible, dispatch]);

    if (!bwic) {
        return null;
    }

    const isActive = bwic.autoFeedbackConfiguration.onPlatform || bwic.autoFeedbackConfiguration.offPlatform;

    return (
        <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
            {
                blocked =>
                    <OnHoverTooltip
                        disabled={!disabled || blocked}
                        overlay={disabledTooltip}
                    >
                        <FeatureButton
                            disabled={disabled}
                            className={classNames('btn-link btn-auto-feedback', { 'btn-link-active': autoFeedbackSettingsVisible && !disabled })}
                            onClick={() => dispatch(autoFeedbackSettingsActions.toggleAutoFeedbackSettingsPanel())}
                        >
                            <IconSVG name="settings" width={16} height={16} />
                            <span>Auto-Feedback: </span>
                            <span className={classNames('auto-feedback-status', { 'auto-feedback-on': isActive && !disabled })}>{isActive ? 'on' : 'off'}</span>
                        </FeatureButton>
                    </OnHoverTooltip>
            }
        </RoleActionBlocker>
    );
}
