import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../common';
import { BidLevel as BidLevelComponent } from '../../../seller/biddingSecurities';
import { BidLevel } from '../../../../../types/bidding/BidLevel';

export const third = {
    columnName: 'third',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xl-xxl cell-bid-on-top">
                Third
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        const { id, trade } = position;

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xl-xxl cell-bid-on-top overflow-visible">
                <BidLevelComponent
                    level={BidLevel.Third}
                    positionId={id}
                    bids={position.bids}
                    stage2Participants={context.stage2Participants}
                    trade={trade}
                    allowFeedback={context.allowFeedback}
                    bwicStatus={context.bwicStatus}
                    process={context.bwic?.process}
                />
            </SecurityListColumn>
        );
    }
};
