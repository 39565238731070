import moment from 'moment';
import { constants } from '../../../../constants/constants';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BidRequest, bidRequestText } from '../../../../types/bidding/BidRequest';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { PushHistoryStateItemType } from '../../../../types/state/PushHistoryState';
import dateTimeUtils from '../../../../utils/dateTime.utils';
import { AnimatedValueUpdate } from '../../../controls/AnimatedValueUpdate';
import { FeedbackLabel } from '../../common/feedback/FeedbackLabel';

export interface FeedbackCurrentBid {
    pass: boolean;
    stagedBiddingStatus?: OpenBiddingStatus;
    createdUtc?: Date;
    feedbackCreatedUtc?: Date;
}

interface Props {
    bidsDueUtc: Date;
    feedback?: string;
    feedbackDate?: Date;
    bidRequest?: BidRequest;
    bwicStatus: BwicStatus;
    process: Process;
    currentBid?: FeedbackCurrentBid;
    positionId: number;
    bwicReferenceName: string;
}

const newBidAnimationTimeInSeconds = 5;

export function BidFeedback({
    bidsDueUtc,
    feedback,
    feedbackDate,
    bidRequest,
    bwicStatus,
    currentBid,
    process,
    positionId,
    bwicReferenceName
}: Props) {
    const hasBid = currentBid != null && !currentBid.pass;

    const pushNewBid = useAppSelector(state => state.pushHistory.history[PushHistoryStateItemType.NewBid]?.find(item => item.positionId === positionId));
    const pushFeedback = useAppSelector(state => state.pushHistory.history[PushHistoryStateItemType.Feedback]?.find(item => item.positionId === positionId));
    const pushBwicStatus = useAppSelector(state => state.pushHistory.history[PushHistoryStateItemType.BwicStatusChanged]?.find(item => item.bwicReferenceName === bwicReferenceName));
    const dates = [pushNewBid?.receivedDate, pushFeedback?.receivedDate, pushBwicStatus?.receivedDate].filter(d => d)
    const lastUpdateDate = dateTimeUtils.getMaxDate(dates);

    if (bwicStatus === BwicStatus.scheduled && hasBid) {
        if (process.type === BwicProcessType.BestFootForward || process.type === BwicProcessType.Live) {
            return <FeedbackLabel text={constants.emptyPlaceholder} />;
        }
        return <AnimatedFeedback date={lastUpdateDate} text={'Feedback will be provided when the BWIC starts'} />;
    }

    if (bwicStatus === BwicStatus.bidding &&
        hasBid &&
        !feedback && (
            // Hide `Waiting` on JB and BestFootForward in Stage 2
            process.type === BwicProcessType.Standard ||
            (process.type === BwicProcessType.BestFootForward && process.stagedBiddingStatus == null) || (
                (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
                process.stagedBiddingStatus !== OpenBiddingStatus.improvementRoundEnded))) {
        return <AnimatedFeedback date={lastUpdateDate} text="Waiting" />
    }

    if (feedback) {
        return <AnimatedFeedback text={addStagePrefix(bidsDueUtc, feedback, feedbackDate, process)} date={lastUpdateDate} />
    } else if (bwicStatus === BwicStatus.bidding && bidRequest != null && currentBid == null) {
        const text = bidRequestText[bidRequest.bidRequestType];
        return <AnimatedFeedback text={addStagePrefix(bidsDueUtc, text, bidRequest.created, process)} date={lastUpdateDate} />
    }

    return <FeedbackLabel text={constants.emptyPlaceholder} />;
}

interface AnimatedFeedbackProps {
    date?: Date;
    text: string;
}

function AnimatedFeedback({ date, text }: AnimatedFeedbackProps) {
    const diffSeconds = date
        ? moment().diff(moment(date), 'seconds')
        : newBidAnimationTimeInSeconds; // no animation if the date is not provided

    if (diffSeconds >= newBidAnimationTimeInSeconds) {
        return <FeedbackLabel text={text} />
    }
    return <AnimatedValueUpdate value={text}><FeedbackLabel text={text} /></AnimatedValueUpdate>;
}

function addStagePrefix(
    bidsDueUtc: Date,
    feedback: string,
    feedbackDate: Date | undefined,
    process: Process) {
    const stagePrefixVisible =
        process.type === BwicProcessType.JumpBall ||
        process.type === BwicProcessType.TopX;


    if (!stagePrefixVisible) return feedback;

    let stageText = "Stage 1";

    if (process.stagedBiddingStatus != null && feedbackDate) { // Stage 2 or later
        const settings = process.type === BwicProcessType.JumpBall
            ? process.jumpBall
            : process.topX;

        if (!settings) throw Error("Process settings");

        const automaticStage1DeadlineMinutes = settings.automaticStage1Deadline
            ? dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes
            : undefined;

        const stage1End = automaticStage1DeadlineMinutes
            ? moment.utc(bidsDueUtc).add(automaticStage1DeadlineMinutes, 'minutes')
            : settings.stage1EndDateUtc && moment.utc(settings.stage1EndDateUtc);

        if (stage1End && moment.utc(feedbackDate).isAfter(stage1End)) {
            stageText = "Stage 2";
        }
    }

    return `${stageText}: ${feedback}`;
}