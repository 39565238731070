import { useSelector } from 'react-redux';
import { RouteLeavingGuard } from '../../routing';
import { brokerDealerAccessState } from '../../../constants/bidding';
import { AppState } from '../../../types/state/AppState';
import { keys } from 'lodash';
import { useHistory } from 'react-router';

interface Props {
    accessState: number;
}

export function BrokerDealerBiddingRouteLeaveGuard({ accessState }: Props) {
    const history = useHistory();

    const canSubmit = useSelector((s: AppState) =>
        keys(s.brokerDealerBidding.editBid).some(key => s.brokerDealerBidding.editBid[+key].canSubmit)
    );

    const handleBlockNavigation = () => accessState === brokerDealerAccessState.approved && canSubmit;

    return (
        <RouteLeavingGuard
            title="Unsubmitted Bids"
            text="You have unsubmitted bids. Are you sure you want to leave?"
            navigate={(pathname: string) => history.push(pathname)}
            shouldBlockNavigation={handleBlockNavigation}
        />
    );
}
