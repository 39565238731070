import React from 'react';
import { BidCompanyIdentifier } from '../../../../types/bidding/BidCompanyIdentifier';
import { Bid } from '../../../../types/bidding/Bid';
import { biddingUtils } from '../../../../utils/bidding.utils';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { Trade } from '../../../../types/trades/Trade';
import { isActiveTrade } from '../../../../types/trades/TradeStatus';

interface Props {
    companyIdentifier?: BidCompanyIdentifier;
    bids?: Bid[];
    bwicStatus?: BwicStatus;
    trade?: Trade;
    onHoverTooltipMessage?: React.ReactNode;
}

export const bidOverThemselfMessage = 'Two best bids are placed by the same bidder. Consider trading at the lower level to prevent “bidding over themselves”.'

export function BidOverThemselfIcon({ companyIdentifier, bids, bwicStatus, trade, onHoverTooltipMessage = bidOverThemselfMessage }: Props) {
    const [best, cover] = biddingUtils.groupBidsByLevel(bids ?? []);
    const companyIdentifierOrBest = companyIdentifier ?? (best?.[0] ? biddingUtils.getBidCompanyIdentifier(best?.[0]) : '');

    if (
        !isActiveTrade(trade) &&
        bwicStatus !== BwicStatus.finished &&
        best &&
        best.length === 1 &&
        cover &&
        cover.length === 1 &&
        companyIdentifierOrBest &&
        biddingUtils.getBidCompanyIdentifier(best[0]) === companyIdentifierOrBest &&
        biddingUtils.getBidCompanyIdentifier(cover[0]) === companyIdentifierOrBest) {

        return (
            <OnHoverTooltip overlay={onHoverTooltipMessage}>
                <i className="icon icon-warning" />
            </OnHoverTooltip>
        );
    }

    return null;
}
