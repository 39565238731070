import * as React from 'react';
import { BwicBase } from '../../../../../../types/bwic/BwicBase';
import { BwicPosition } from '../../../../../../types/bwic/BwicPosition';
import { TradeStatus } from '../../../../../../types/trades/TradeStatus';
import { biddingUtils } from '../../../../../../utils/bidding.utils';
import { AllToAllArgeementRequiredActionBlocker } from '../../../../../agreements/AllToAllArgeementRequiredActionBlocker';
import { SecurityListColumn, SecurityListHeaderColumn } from '../../../../../common/security-list';
import { TradedToMe } from '../../../../../trade/TradedToMe';
import { AffirmTradeButtons } from '../../../../brokerDealer/trade-affirm';
import { BidButton } from '../../../bid-placement/BidButton';
import { PassButton } from '../../../bid-placement/PassButton';
import RejectedLink from '../../../RejectedLink';


export const brokerDealerActionButtons = {
    columnName: 'brokerDealerActionButtons',
    renderHeader: function () {
        return <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xxs" />;
    },
    renderBody: function (
        position: BwicPosition,
        context: { biddingControlsVisible: boolean, enabled: boolean, hasBid: boolean, bwic: BwicBase }) {
        const { biddingControlsVisible, enabled, hasBid } = context;
        const currentBid = biddingUtils.getCurrentBid(position.bids);

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg-02 cell-bid-actions" onClick={(e: React.MouseEvent) => { e.stopPropagation() }}>
                {biddingControlsVisible && !context.bwic.isAllToAll && <PassButton positionId={position.id} disabled={!enabled || hasBid} currentPass={Boolean(currentBid?.pass)} />}
                {biddingControlsVisible && <BidButton positionId={position.id} currentBid={currentBid} rating={position.rating} disabled={!enabled} isAllToAll={context.bwic.isAllToAll}/>}

                <AllToAllArgeementRequiredActionBlocker disabled={!context.bwic.isAllToAll}>
                    {(blocked) => blocked
                        ? null
                        : (<>
                            {
                                !biddingControlsVisible &&
                                !position.isTradedAway &&
                                position.trade?.status === TradeStatus.affirmed &&
                                <TradedToMe price={position.trade.price} />
                            }
                            {
                                !biddingControlsVisible &&
                                !position.isTradedAway &&
                                position.trade?.status === TradeStatus.rejected &&
                                <RejectedLink rejectReason={position.trade.rejectReason} />
                            }
                            {
                                !biddingControlsVisible &&
                                !position.isTradedAway &&
                                position.trade?.status === TradeStatus.pending &&
                                <AffirmTradeButtons
                                    positionId={position.id}
                                    ticker={position.ticker}
                                    tradeReferenceId={position.trade.id}
                                    buyerPseudoOrderNumber={position.trade.buyerPseudoOrderNumber}
                                />
                            }
                            {
                                !biddingControlsVisible &&
                                position.isTradedAway &&
                                <>Traded away</>
                            }
                        </>)
                    }
                </AllToAllArgeementRequiredActionBlocker>
            </SecurityListColumn>
        );
    }
};
