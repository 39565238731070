import React from 'react';
import { EmptyPlaceholder } from '../common';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { blotterFiltersReset, blotterSearchRequest, controlPanelActions } from '../../actions';

export function BlotterEmptyListPlaceholder({ hasTrades }) {
    const dispatch = useAppDispatch();

    const handleResetFilter = () => {
        dispatch(controlPanelActions.hide());
        dispatch(blotterFiltersReset());
        dispatch(blotterSearchRequest());
    };

    return hasTrades ? <EmptyPlaceholder onResetClick={handleResetFilter}/> : <EmptyPlaceholder text="You do not have any traded securities yet." />;
}
