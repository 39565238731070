import { useBiddingCompletedSoonNotification } from "../../../effects/bidding/useBiddingCompletedSoonNotification";
import { RecipientType, useBiddingCompletedNotification } from "../../../effects/useBiddingCompletedNotification";
import { Bwic } from "../../../types/bwic/Bwic";

interface Props {
    bwic: Bwic;
}
export function Notifications({ bwic }: Props){
    useBiddingCompletedSoonNotification(bwic);
    useBiddingCompletedNotification(RecipientType.Seller, bwic);

    return null;
}