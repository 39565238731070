import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../common';
import { PnL } from '../../../seller/biddingSecurities';

export const pnl = {
    columnName: 'pnl',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="text-right">
                PnL
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const { targetLevel, bids, size } = position;

        return (
            <SecurityListColumn key={this.columnName} className="text-right">
                <PnL targetLevel={targetLevel} bids={bids} size={size} />
            </SecurityListColumn>
        );
    }
};
