import React from 'react';
import classNames from 'classnames';
import { rulesActions } from '../../../actions/rules.actions';
import { connect } from 'react-redux';
import { BwicRulesPseudoLink } from '../../common/BwicRulesPseudoLink';

class RulesButton extends React.Component {
    handleClick = () => {
        const { show, positionId, dispatch } = this.props;
        if (show && !positionId) {
            dispatch(rulesActions.hide());
        } else {
            dispatch(rulesActions.show(positionId, true));
        }
    }

    render() {
        const { bwic } = this.props;

        return (
            <BwicRulesPseudoLink
                className={classNames({ 'btn-link-active': this.props.show })}
                isParsed={bwic.isParsed}
                process={bwic.process}
                parsedProcess={bwic.parsedProcess}
                onClick={this.handleClick}
                withIcon={true}
            />
        )
    }
}

const mapStateToProps = ({ bidding, rules }) => ({
    show: rules.show,
    positionId: rules.positionId,
    bwic: bidding.bwic
});

const ConnectedRulesButton = connect(mapStateToProps)(RulesButton);

export { ConnectedRulesButton as RulesButton };
