import React from 'react';
import cn from 'classnames';
import { BwicPositionBase } from '../../../../types/bwic/BwicPositionBase';
import { User } from '../../../../types/account/User';
import { user } from '../../../../user';
import { FormError } from '../../../controls/FormError';
import { RequestState } from '../../../../constants/request-state';
import { pxTalkService } from '../../../../services/pxTalk.service';
import { LevelType } from '../../../../types/bwic/LevelType';
import { jsonUtils } from '../../../../utils/json.utils';
import { errorMessages } from '../../../../constants/error.messages';
import { isRequesting } from '../../../../utils';

interface Props {
    bwicReferenceName: string;
    position: BwicPositionBase;
    disabled: boolean;
}

export function PxTalkChange({ bwicReferenceName, position, disabled }: Props) {
    const currentUser: User = user.current() as unknown as User;
    const currentPriceTalk = position.pxTalks?.find(p => p.company.id === currentUser?.companyId);
    const [level, setLevel] = React.useState('');
    const isChanged = (level && !currentPriceTalk) || level !== (currentPriceTalk?.talk ?? '');
    const { requestState, submit, error } = usePriceTalkSubmit(bwicReferenceName, position.id, level);

    React.useEffect(() => {
        if (currentPriceTalk) {
            setLevel(currentPriceTalk?.talk ?? '');
        }
        // eslint-disable-next-line
    }, [currentPriceTalk])

    const className = cn('display-inline', {
        'form-control-changed': isChanged,
        'form-control-error': error
    });

    const handleBlur = () => {
        if (isChanged && !isRequesting(requestState)) {
            submit();
        }
    }

    return (
        <div className={className}>
            <input
                type="text"
                className="form-control form-control-sm"
                value={level}
                autoComplete="off"
                disabled={disabled}
                placeholder="0.00"
                onChange={e => setLevel(e.target.value)}
                onBlur={handleBlur}
                onFocus={e => e.target.select()}
                onClick={e => e.stopPropagation()}
                maxLength={8}
            />
            <FormError message={error} />
        </div>

    );
}

type TNormalizeResult = { parsedLevel: number, level: string };

function usePriceTalkSubmit(bwicReferenceName: string, positionId: number, value: string) {
    const [requestState, setRequestState] = React.useState(RequestState.none);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        if (error) {
            setError('');
        }
        // eslint-disable-next-line
    }, [value])

    const level = value.trim();

    const submit = async () => {
        setRequestState(RequestState.request);

        const normalizeResult = level ? await normalize() : { error: '' };

        if (normalizeResult.error) {
            setError(normalizeResult.error);
            setRequestState(RequestState.failure);
            return;
        }

        await doSubmit(normalizeResult.normalized);
    }

    const normalize = async () => {
        try {
            const normalized: TNormalizeResult = await pxTalkService.normalize(value, LevelType.price);
            return { normalized };
        } catch (e) {
            const responseMessage: { error?: string } | undefined = e?.message && jsonUtils.tryParse(e.message);
            const error = responseMessage?.error || errorMessages.invalidValue;
            return { error };
        }
    }

    const doSubmit = async (normalized?: TNormalizeResult) => {
        try {
            const model = {
                positionId,
                talk: normalized?.level ?? '',
                normalizedLevel: normalized?.parsedLevel,
                levelType: LevelType.price
            };

            await pxTalkService.bulkSave(bwicReferenceName, [model]);
            setError('');
            setRequestState(RequestState.success);
        } catch (e) {
            setError('Failed to save');
            setRequestState(RequestState.failure);
        }
    }

    return { requestState, submit, error };
}
