import {
    SecurityListHeaderColumn,
    SecurityListColumn,
} from '../../../../common';
import { TradeButton } from '../../../../seller/trade/TradeButton';
import { biddingUtils } from '../../../../../../utils/bidding.utils';
import { roles } from '../../../../../../constants/roles';
import { RoleActionBlocker } from '../../../../../access/RoleActionBlocker';

export const tradeButton = {
    columnName: 'tradeButton',
    renderHeader: function () {
        return <SecurityListHeaderColumn key={this.columnName} className="cell-trade-action" />;
    },
    renderBody: function (position, context) {
        return (
            <SecurityListColumn key={this.columnName} className="cell-trade-action">
                <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    <TradeButton
                        positionId={position.id}
                        enabled={biddingUtils.canTradePosition(position)}
                        trade={position.trade}
                        process={context.bwic.process}
                    />
                </RoleActionBlocker>
            </SecurityListColumn>
        );
    }
};
