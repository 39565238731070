import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
    SecurityListColumn,
    SecurityListContainer,
    SecurityListContent,
    SecurityListHeader,
} from '../../common';
import { SORT } from '../../../../constants';
import { useSortedList } from '../../../../effects';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { StickyTableContent } from './sticky/StickyTableContent';
import { TableContent } from './TableContent';
import IconSVG from '../../../../styles/svg-icons';
import { isMac } from "../../../../utils/isMac.utils";
import { TableColumnStickType } from './types/TableColumnStickType';
import { useDispatch } from 'react-redux';
import { tableActions } from '../../../../actions/table.actions';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { SecurityListRow } from "../../../common/security-list";

export function Table({
    tableKey,
    className = '',
    dataItems = [],
    columns = [],
    headerVisible = true,
    isNextPageRequesting,
    createSecurityCustomClassName,
    createSecurityCustomArgs,
    createHeaderCustomArgs,
    createRowCustomKey,
    shouldRenderCollapsibleRowCallback,
    infiniteScrollEnabled = false,
    sticky = false,
    defaultSortBy,
    defaultSortByDirection = SORT.ASC,
    onRowClick,
    onRowHover,
    onSort,
    customSortingCompareCallback,
    onNextPageRequest,
    renderHeaderRow,
    renderLastRow,
    renderFooterRow,
    collapsible = false,
    renderCollapsibleItem,
    defaultExpandAll = false,
    onExpandAll,
    renderGroupByRow,
    createRowRef,
}) {
    const dispatch = useDispatch();

    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortByDirection);
    const defaultSortingType = (
        defaultSortBy &&
        columns && (
            columns.find(c =>
                c.column &&
                c.column.columnKey === defaultSortBy) ?? { column: {} }).column.sortingType) || undefined;

    const [sortingType, setSortingType] = useState(defaultSortingType);
    const [expandAll, setExpandAll] = useState(defaultExpandAll);
    const expandedState = useRef({});

    const sortState = useAppSelector(s => tableKey && s.ui.table.sort[tableKey]);

    const [sortedDataItems] = useSortedList(
        dataItems,
        sortState?.sortBy ?? sortBy,
        sortState?.sortDirection ?? sortDirection,
        sortState?.sortingType ?? sortingType,
        !onSort,
        customSortingCompareCallback
    );

    const leftColumns = sticky ? columns.filter(c => c.column && c.column.stickLeft) : [];
    const middleColumns = sticky ? columns.filter(c => !c.column || (!c.column.stickLeft && !c.column.stickRight)) : [];
    const rightColumns = sticky ? columns.filter(c => c.column && c.column.stickRight) : [];
    const isOptionsColumnCollapsed = createHeaderCustomArgs?.().isOptionsColumnCollapsed;

    const tableRef = useRef(null);
    const headerMiddleScrollRef = useRef(null); // Sticky table middle section header sroll ref
    const headerMiddleContentRef = useRef(null); // Sticky table middle section header content ref


    const renderSecurityLastRow = () => {
        if (!renderLastRow || infiniteScrollEnabled) {
            return null;
        }

        const rendered = renderLastRow();

        if (!rendered) {
            return null;
        }

        if (sticky) {
            return rendered;
        }

        return (
            <SecurityListRow>
                <SecurityListColumn className="data-list-cell-auto">
                    {rendered}
                </SecurityListColumn>
            </SecurityListRow>
        );
    }

    useEffect(() => {
        if (tableKey) {
            dispatch(tableActions.sortByChange(tableKey, defaultSortBy, defaultSortByDirection, defaultSortingType));
        }
    }, [tableKey, defaultSortBy, defaultSortByDirection, defaultSortingType, dispatch])

    useEffect(() => {
        setExpandAll(defaultExpandAll);
    }, [defaultExpandAll]);

    if (!dataItems.length) return null;

    const handleHeaderClick = (field, sortingType) => {
        setSortingType(sortingType);
        if (onSort && typeof onSort === 'function') {
            onSort(
                field,
                field === sortBy
                    ? (sortDirection === SORT.ASC ? SORT.DESC : SORT.ASC)
                    : SORT.DESC
            );
        }
        if (field === sortBy) {
            const direction = sortDirection === SORT.ASC ? SORT.DESC : SORT.ASC;
            setSortDirection(direction);
            dispatch(tableActions.sortByChange(tableKey, field, direction, sortingType));
        } else {
            setSortBy(field);
            setSortDirection(SORT.DESC);
            dispatch(tableActions.sortByChange(tableKey, field, SORT.DESC, sortingType));
        }
    };

    const handleExpandedChange = itemKey => {
        const expanded = expandedState.current[itemKey] === undefined ? expandAll : expandedState.current[itemKey];
        expandedState.current = {
            ...expandedState.current,
            [itemKey]: !expanded
        };

        const keys = Object.keys(expandedState.current);
        if (keys.length === dataItems.length) {
            if (
                (expandAll && keys.every(key => !expandedState.current[key])) ||
                (!expandAll && keys.every(key => expandedState.current[key]))
            ) {
                handleExpandAll();
            }
        }
    }

    const handleExpandAll = () => {
        expandedState.current = {};
        setExpandAll(!expandAll);
        dispatch(tableActions.resetExpanded());

        if (onExpandAll) {
            onExpandAll(!expandAll);
        }
    }

    const renderCollapsibleHeader = () => {
        if (collapsible) {
            return (
                <span className="btn-link data-list-cell-collapse-action" onClick={handleExpandAll}>
                    <IconSVG className={classNames({ collapse: expandAll })} name="icon-double-arrows" width={16} height={16} />
                </span>
            );
        }

        return null;
    }

    const onCollapseRightColumn = (collapsed) => {
        if (tableRef.current) {
            const setIsOptionsColumnCollapsed = createHeaderCustomArgs?.().setIsOptionsColumnCollapsed;
            if (setIsOptionsColumnCollapsed) {
                setIsOptionsColumnCollapsed(collapsed);
            }

            if (collapsed) {
                tableRef.current.classList.add('right-sticky-collapsed')
            } else {
                tableRef.current.classList.remove('right-sticky-collapsed')
            }
        }
    }

    const renderStickyTable = () => {
        const headerContext = { ...createHeaderCustomArgs?.(), onCollapseRightColumn };

        return (
            <ScrollSync proportional={false}>
                <>
                    {
                        headerVisible &&
                        <SecurityListHeader>
                            {!!leftColumns.length && (
                                <div className="header-sticky-col data-column-sticky-left">
                                    {renderCollapsibleHeader()}
                                    {renderHeaderRow
                                        ? renderHeaderRow(TableColumnStickType.Left, headerContext)
                                        : leftColumns.map(c => c.renderHeader(sortBy, sortDirection, handleHeaderClick, headerContext))
                                    }
                                </div>
                            )}
                            <ScrollSyncPane group="horizontal" innerRef={headerMiddleScrollRef}>
                                <div className="header-sticky-col data-column-sticky-middle">
                                    <div className="header-sticky-content" >
                                        <div className="flex-row" ref={headerMiddleContentRef}>
                                            {!leftColumns.length && renderCollapsibleHeader()}
                                            {renderHeaderRow
                                                ? renderHeaderRow(TableColumnStickType.Middle, headerContext)
                                                : middleColumns.map(c => c.renderHeader(sortBy, sortDirection, handleHeaderClick, headerContext))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ScrollSyncPane>
                            {!!rightColumns.length && (
                                <div className="header-sticky-col data-column-sticky-right">
                                    <div className="data-column-sticky-right-content">
                                        {renderHeaderRow
                                            ? renderHeaderRow(TableColumnStickType.Right, headerContext)
                                            : rightColumns.map(c => c.renderHeader(sortBy, sortDirection, handleHeaderClick, headerContext))
                                        }
                                    </div>
                                </div>
                            )}
                        </SecurityListHeader>
                    }
                    <StickyTableContent
                        tableKey={tableKey}
                        dataItems={sortedDataItems}
                        columns={[leftColumns, middleColumns, rightColumns]}
                        createSecurityCustomArgs={createSecurityCustomArgs}
                        createSecurityCustomClassName={createSecurityCustomClassName}
                        createRowCustomKey={createRowCustomKey}
                        expandAll={expandAll}
                        headerMiddleScrollRef={headerMiddleScrollRef}
                        headerMiddleContentRef={headerMiddleContentRef}
                        onExpandChange={handleExpandedChange}
                        onRowClick={onRowClick}
                        onRowHover={onRowHover}
                        infiniteScrollEnabled={infiniteScrollEnabled}
                        onNextPageRequest={onNextPageRequest}
                        renderFooterRow={renderFooterRow}
                        collapsible={collapsible}
                        renderCollapsibleContent={renderCollapsibleItem}
                        shouldRenderCollapsibleRowCallback={shouldRenderCollapsibleRowCallback}
                        isNextPageRequesting={isNextPageRequesting}
                        renderGroupByRow={renderGroupByRow}
                        renderLastRow={renderSecurityLastRow}
                    />
                </>
            </ScrollSync>
        );
    }

    const renderTable = () => {
        const headerContext = { ...createHeaderCustomArgs?.(), onCollapseRightColumn };
        const headers = columns.map(c => headerVisible && c.renderHeader(sortBy, sortDirection, handleHeaderClick, headerContext));

        return (
            <>
                {
                    headerVisible &&
                    <SecurityListHeader>
                        {renderCollapsibleHeader()}
                        {headers}
                    </SecurityListHeader>
                }
                <SecurityListContent
                    isNextPageRequesting={isNextPageRequesting}
                    infiniteScrollEnabled={infiniteScrollEnabled}
                    onNextPageRequest={onNextPageRequest}
                >
                    <TableContent
                        dataItems={sortedDataItems}
                        columns={columns}
                        createSecurityCustomArgs={createSecurityCustomArgs}
                        createSecurityCustomClassName={createSecurityCustomClassName}
                        createRowCustomKey={createRowCustomKey}
                        collapsible={collapsible}
                        expandAll={expandAll}
                        onRowClick={onRowClick}
                        onRowHover={onRowHover}
                        createRowRef={createRowRef}
                        shouldRenderCollapsibleRowCallback={shouldRenderCollapsibleRowCallback}
                        renderCollapsibleItem={renderCollapsibleItem}
                        onExpandChange={handleExpandedChange}
                    />
                    {renderSecurityLastRow()}
                </SecurityListContent>
                {renderFooterRow && renderFooterRow(columns)}
            </>
        );
    };

    return (
        <div ref={tableRef} className={classNames(
            "data-list", className, {
            'data-list-mac': isMac(),
            'data-list-striped data-list-freeze': sticky,
            'right-sticky-col': sticky && !!rightColumns.length,
            'right-sticky-collapsed': isOptionsColumnCollapsed,
            'sticky-with-last-row': sticky && !!renderSecurityLastRow(),
        })}>
            <SecurityListContainer className="data-list-container">
                {sticky ? renderStickyTable() : renderTable()}
            </SecurityListContainer>
        </div>
    );
}

Table.defaultProps = {
    tableKey: null,
    createSecurityCustomClassName: null,
    createSecurityCustomArgs: null,
    createHeaderCustomArgs: null,
    createRowCustomKey: null,
    shouldRenderCollapsibleRowCallback: null,
    onSort: null,
    customSortingCompareCallback: null,
    defaultSortBy: null,
    onNextPageRequest: null,
    onRowClick: null,
    onRowHover: null,
    onExpandAll: null,
    renderCollapsibleItem: null,
    renderHeaderRow: null,
    renderLastRow: null,
    isNextPageRequesting: null,
    renderFooterRow: null,
    renderGroupByRow: null,
    createRowRef: null,
}
