import React from 'react'
import { CollapsibleListRow } from "../../../common/collapsible-list/CollapsibleListRow";
import { SecurityListRow } from "../../../common/security-list";

export function TableContent({
    dataItems,
    columns,
    createSecurityCustomClassName,
    createSecurityCustomArgs,
    createRowCustomKey,
    shouldRenderCollapsibleRowCallback,
    onRowClick,
    onRowHover,
    collapsible = false,
    renderCollapsibleItem = null,
    expandAll = false,
    createRowRef,
    onExpandChange,
}) {
    if (!dataItems || !columns) return null;

    return dataItems.map((s, i) => {
        const context = { rowIndex: i, ...createSecurityCustomArgs?.(s, i) };
        const className = createSecurityCustomClassName?.(s, context);
        const key = createRowCustomKey == null ? i : createRowCustomKey(s, i);
        const shouldRenderCollapsibleRow = shouldRenderCollapsibleRowCallback ? shouldRenderCollapsibleRowCallback(s) : collapsible;

        return shouldRenderCollapsibleRow ? (
            <CollapsibleListRow
                key={key}
                className={className}
                expandAll={expandAll}
                dataItem={s}
                context={context}
                columns={columns}
                onExpandChange={() => onExpandChange(key)}
            >
                {renderCollapsibleItem && renderCollapsibleItem(s)}
            </CollapsibleListRow>
        ) : (
            <SecurityListRow
                key={key}
                ref={createRowRef ? createRowRef(s, i) : null}
                className={className}
                onClick={onRowClick ? onRowClick.bind(this, s) : undefined}
                onHover={onRowHover ? onRowHover.bind(this, s) : undefined}
            >
                {columns.map((c) => c.renderBody(s, context))}
            </SecurityListRow>
        );
    });
}

