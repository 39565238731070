import * as React from 'react';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import IconSVG from '../../../styles/svg-icons';
import { errorActions } from '../../../actions';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { FeatureButton } from '../../access/FeatureButton';
import { OnHoverTooltip, Preloader } from '../../common';
import { RequestState } from '../../../constants/request-state';
import { bwicService } from '../../../services/bwic.service';
import { isRequesting } from '../../../utils/request-state.utils';

interface Props {
    bwicReferenceName: string;
    textVisible?: boolean;
    tooltipText?: string;
}

export function ExportButton({ bwicReferenceName, tooltipText, textVisible = true }: Props) {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = React.useState(RequestState.none);

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        setRequestState(RequestState.request);

        try {
            const file: { name: string; blob: Blob; } = await bwicService.exportBwic(bwicReferenceName);
            saveAs(file.blob, file.name);
            setRequestState(RequestState.success);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestState(RequestState.failure);
        }
    };

    return (
        <ActionBlocker feature={SubscriptionFeature.ExportIndividualBwic}>
            {featureBlocked =>
                <Preloader
                    small={true}
                    fullScreen={false}
                    inProgress={isRequesting(requestState)}
                    text={textVisible ? "Exporting…" : undefined}
                >
                    <OnHoverTooltip overlay={!featureBlocked && tooltipText}>
                        <FeatureButton
                            className="btn-link btn-export-bwic"
                            onClick={handleClick}
                        >
                            <IconSVG name="export" width={16} height={16} />
                            {textVisible && "Export"}
                        </FeatureButton>
                    </OnHoverTooltip>
                </Preloader>}
        </ActionBlocker>
    );
}
