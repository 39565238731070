import React from "react";
import { useAppSelector } from "../../../effects/useAppSelector";
import { getBuyers } from "../../../selectors";
import { Bid } from "../../../types/bidding/Bid";
import { BidderCompanySlim } from "../../../types/company/BidderCompanySlim";
import { CompanySlim } from "../../../types/company/CompanySlim";
import { CompanyStatus } from "../../../types/company/CompanyStatus";
import { arrayUtils } from "../../../utils/array.utils";
import { biddingUtils } from "../../../utils/bidding.utils";

export function useDealerListAggregatedData() {
    const positions = useAppSelector(s => s.sellerBidding.securities);
    const offPlatformCompaniesSlim = useAppSelector(s => s.bidOnBehalf.companies);
    const bwicParticipants = useAppSelector(s => s.bidding.bwic?.companies ?? []);
    const isAllToAll = useAppSelector(s => s.bidding.bwic?.isAllToAll) ?? false;
    const companyHiddenState = useAppSelector(s => s.dealerListPanel.companyHiddenState);
    const bidOnlyFlag = useAppSelector(s => s.dealerListPanel.bidOnlyFlag);

    const allBids: Bid[] = React.useMemo(() =>
        positions
            .map(p => p.bids || [])
            .flat(),
        [positions]);

    const bidsByCompanyIdentifier = React.useMemo(
        () => arrayUtils.groupBy(allBids, bid => biddingUtils.getBidCompanyIdentifier(bid)),
        [allBids]
    );

    const addIdentifier = (company: CompanySlim) =>
        ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) })

    const onPlatformCompanies: BidderCompanySlim[] = React.useMemo(
        () => bwicParticipants
            .filter(c => c.status === CompanyStatus.active)
            .filter(c => !c.isClearingBank || !isAllToAll)
            .map(addIdentifier),
        [bwicParticipants, isAllToAll]
    );

    const offPlatformCompanies: BidderCompanySlim[] = React.useMemo(
        () => offPlatformCompaniesSlim.map(addIdentifier),
        [offPlatformCompaniesSlim]
    );

    const buyers = getBuyers(positions);

    return {
        allBids,
        companyHiddenState,
        bidOnlyFlag,
        bidsByCompanyIdentifier,
        onPlatformCompanies,
        offPlatformCompanies,
        buyers
    }
}