import cn from 'classnames';
import { sellerBiddingActions } from '../../../actions/seller.bidding.actions';
import { feedbackTypes } from '../../../constants/bidding';
import IconSVG from '../../../styles/svg-icons';
import { biddingUtils } from '../../../utils/bidding.utils';
import { formatUtils } from '../../../utils/format.utils';
import ContextMenu from '../../controls/ContextMenu';
import { ContextMenuItem } from '../../controls/ContextMenuItem';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { OpenBiddingStatus } from '../../../types/enums/OpenBiddingStatus';
import { Bid } from '../../../types/bidding/Bid';
import { BidLevel } from '../../../types/bidding/BidLevel';
import { CompanySlim } from '../../../types/company/CompanySlim';
import { JumpBallStage2Participants } from '../../../types/bwic-process/JumpBallSettings';
import { TopXStage2Participants } from '../../../types/bwic-process/TopXSettings';
import { bidRequestText, BidRequestType } from '../../../types/bidding/BidRequest';
import { roles } from '../../../constants/roles';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    process: Process;
    bids?: Bid[];
    levelBids?: Bid[];
    hasManyBids?: boolean;
    level?: BidLevel;
    disabled?: boolean;
    emptyBidCompany?: CompanySlim;
    getLevelCallback?: () => BidLevel | undefined;
}

export function FeedbackMenu({ positionId, process, bids, levelBids = [], hasManyBids, level, disabled = false, emptyBidCompany, getLevelCallback }: Props) {
    const best = biddingUtils.getBidsByLevel(bids ?? [], BidLevel.Best);
    level = level ?? getLevelCallback?.();

    const isSameHandle = (best: number, bid: number) => (Math.trunc(best) - Math.trunc(bid)) === 0;
    const isStandard = process.type === BwicProcessType.Standard;
    const isTopX = process.type === BwicProcessType.TopX;
    const isJumpBall = process.type === BwicProcessType.JumpBall;
    const isBFF = process.type === BwicProcessType.BestFootForward;
    const isJumpBallStage2 = isJumpBall && process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended

    return (
        <ContextMenu
            className="quick-feedback-menu"
            customTargetComponent={<IconSVG name="chat" className={cn({ disabled })} width={16} height={16} />}
            disabled={disabled}
        >
            {
                !disabled &&
                <>
                    {
                        isJumpBallStage2 && level != null &&
                        <SubmitFinal positionId={positionId} bids={levelBids} />
                    }
                    {
                        !isJumpBallStage2 &&
                        <>
                            {
                                // Companies without bids
                                level == null && emptyBidCompany != null &&
                                <BidOrPassRequest positionId={positionId} companies={[emptyBidCompany]} />
                            }
                            {
                                // PASS
                                level == null && Boolean(levelBids?.some(b => b.pass)) &&
                                <SubmitFinal positionId={positionId} bids={levelBids} />
                            }
                            {
                                level != null &&
                                <>
                                    {level === BidLevel.Best && !isBFF && (levelBids.length > 1 || Boolean(hasManyBids)) && <TiedForBest positionId={positionId} bids={levelBids} />}
                                    {level === BidLevel.Best && !isBFF && levelBids.length === 1 && !Boolean(hasManyBids) && <Best positionId={positionId} bids={levelBids} />}
                                    {
                                        level === BidLevel.Cover && !isBFF &&
                                        <>
                                            <Cover positionId={positionId} bids={levelBids} />
                                            {isSameHandle(best[0]!.value, levelBids[0]!.value) && <CoverSameHandle positionId={positionId} bids={levelBids} />}
                                            {!isSameHandle(best[0]!.value, levelBids[0]!.value) && <CoverDifferentHandle positionId={positionId} bids={levelBids} />}
                                            <CoverExact positionId={positionId} bids={levelBids} best={best[0]!} />
                                            <CoverHandle positionId={positionId} bids={levelBids} best={best[0]!} />
                                        </>
                                    }
                                    {
                                        level === BidLevel.Third && (
                                            isStandard ||
                                            (isJumpBall && process.jumpBall?.improverCount! >= JumpBallStage2Participants.Top3) ||
                                            (isTopX && process.topX?.improverCount! >= TopXStage2Participants.Top3)) &&
                                        <>
                                            <Third positionId={positionId} bids={levelBids} />
                                            {isSameHandle(best[0]!.value, levelBids[0]!.value) && <ThirdSameHandle positionId={positionId} bids={levelBids} />}
                                            {!isSameHandle(best[0]!.value, levelBids[0]!.value) && <ThirdDifferentHandle positionId={positionId} bids={levelBids} />}
                                            <ThirdExact positionId={positionId} bids={levelBids} best={best[0]!} />
                                            <ThirdHandle positionId={positionId} bids={levelBids} best={best[0]!} />
                                        </>
                                    }
                                    {
                                        (
                                            (isJumpBall && level === BidLevel.Fourth && process.jumpBall?.improverCount! >= JumpBallStage2Participants.Top4) ||
                                            (isTopX && level === BidLevel.Fourth && process.topX?.improverCount! >= TopXStage2Participants.Top4)
                                        ) &&
                                        <Fourth positionId={positionId} bids={levelBids} />
                                    }
                                    {
                                        (
                                            (isJumpBall && level === BidLevel.Fifth && process.jumpBall?.improverCount! >= JumpBallStage2Participants.Top5) ||
                                            (isTopX && level === BidLevel.Fifth && process.topX?.improverCount! >= TopXStage2Participants.Top5)
                                        ) && <Fifth positionId={positionId} bids={levelBids} />
                                    }
                                    {
                                        (
                                            (isJumpBall && (level + 1) > process.jumpBall?.improverCount!) ||
                                            (isTopX && (level + 1) > process.topX?.improverCount!)
                                        ) && <NotInTopX positionId={positionId} bids={levelBids} level={isTopX ? process.topX?.improverCount! : process.jumpBall?.improverCount!} />
                                    }
                                    {isStandard && level > BidLevel.Third && <NotInTopX positionId={positionId} bids={levelBids} level={3} />}
                                    <SubmitFinal positionId={positionId} bids={levelBids} />
                                </>
                            }
                        </>
                    }

                </>
            }
        </ContextMenu>
    );
}

interface FeedbackProps {
    positionId: number;
    bids: Bid[];
}

interface FeedbackMenuItemProps extends FeedbackProps {
    text: string;
    feedbackType: number;
}

function FeedbackMenuItem({ positionId, bids, feedbackType, text }: FeedbackMenuItemProps) {
    const dispatch = useAppDispatch();

    return (
        <ContextMenuItem
            requiredRoles={[roles.BrokerDealerTrader, roles.SellerTrader]}
            onClick={() => dispatch(sellerBiddingActions.sendQuickFeedback(positionId, bids, feedbackType))}
        >
            {text}
        </ContextMenuItem>
    );
}

interface BidRequestProps {
    positionId: number;
    companies: CompanySlim[];
}
interface BidRequestMenuItemProps extends BidRequestProps {
    text: string;
    bidRequestType: number;
}

function BidRequestMenuItem({ positionId, companies, text, bidRequestType }: BidRequestMenuItemProps) {
    const dispatch = useAppDispatch();

    return (
        <ContextMenuItem
            requiredRoles={[roles.BrokerDealerTrader, roles.SellerTrader]}
            onClick={() => dispatch(sellerBiddingActions.sendBidRequest(positionId, companies, bidRequestType))}
        >
            {text}
        </ContextMenuItem>
    );
}

const Best = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.best} text="Best" />
const TiedForBest = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.tiedForBest} text="Tied for the best level" />
const Cover = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.cover} text="Cover" />
const CoverSameHandle = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.coverSameHandle} text="Cover, best in the same handle" />
const CoverDifferentHandle = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.coverDifferentHandle} text="Cover, best in the different handle" />
const CoverExact = ({ best, ...props }: FeedbackProps & { best: Bid }) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.coverExact} text={`Cover, best: ${formatUtils.formatBid(best.value)}`} />
const CoverHandle = ({ best, ...props }: FeedbackProps & { best: Bid }) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.coverHandle} text={`Cover, best: ${best.valueHandle}`} />
const Third = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.third} text="Third" />
const ThirdSameHandle = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.thirdSameHandle} text="Third, best in the same handle" />
const ThirdDifferentHandle = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.thirdDifferentHandle} text="Third, best in the different handle" />
const ThirdExact = ({ best, ...props }: FeedbackProps & { best: Bid }) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.thirdExact} text={`Third, best: ${formatUtils.formatBid(best.value)}`} />
const ThirdHandle = ({ best, ...props }: FeedbackProps & { best: Bid }) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.thirdHandle} text={`Third, best: ${best.valueHandle}`} />
const Fourth = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.fourth} text="Fourth" />
const Fifth = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.fifth} text="Fifth" />
const NotInTopX = ({ level, ...props }: FeedbackProps & { level: number }) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.outOfTop3} text={`Not in top ${level}`} />
const SubmitFinal = (props: FeedbackProps) => <FeedbackMenuItem {...props} feedbackType={feedbackTypes.submitFinal} text="Please submit your final level" />
const BidOrPassRequest = (props: BidRequestProps) => <BidRequestMenuItem {...props} bidRequestType={BidRequestType.BidOrPass} text={bidRequestText[BidRequestType.BidOrPass]} />
