import * as React from 'react';
import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { PositionTrade } from '../../../../../../types/trades/PositionTrade';

export const tradeRating = {
    columnName: 'tradeRating',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xxs">
                Rtg
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (positionTrade: PositionTrade) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xxs">
                {positionTrade.trade.rating}
            </SecurityListColumn>
        );
    }
};
