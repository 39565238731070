import React from 'react';
import { useHistory } from 'react-router';
import { BiddingTabs } from '../../../constants/bidding';
import { ActionBar, ExportButton } from '../common';
import { TabList, TabItem } from '../common/tab-list';
import { Bwic } from '../../../types/bwic/Bwic';
import { routes } from '../../../constants';
import { useParams } from 'react-router-dom';

interface Props {
    bwic: Bwic;
    children?: React.ReactNode;
    disabledTabs?: BiddingTabs[];
    actionBarVisible?: boolean;
}

export default function SubHeader({ bwic, children, actionBarVisible = true, disabledTabs }: Props) {
    return (
        <div className="sub-header-row type03 flex-row">
            {!bwic.isAllToAll && <BiddingTabList bwic={bwic} disabledTabs={disabledTabs ?? []} />}
            {actionBarVisible && (
                <ActionBar>
                    {children}
                    <ExportButton bwicReferenceName={bwic.referenceName} />
                </ActionBar>
            )}
        </div>
    );
}


interface TabListProps {
    bwic: Bwic;
    disabledTabs: BiddingTabs[];
}

function BiddingTabList({ bwic, disabledTabs }: TabListProps) {
    const history = useHistory();
    const { tab } = useParams<{ tab: string }>();

    return (
        <TabList>
            <TabItem
                active={!tab || tab === BiddingTabs.bidding}
                title='Bidding'
                onClick={() => history.push(routes.biddingUrl(bwic.referenceName, BiddingTabs.bidding))}
                disabled={disabledTabs.indexOf(BiddingTabs.bidding) > -1}
            />
            <TabItem
                active={tab === BiddingTabs.participants}
                title='Dealers'
                onClick={() => history.push(routes.biddingUrl(bwic.referenceName, BiddingTabs.participants))}
                disabled={disabledTabs.indexOf(BiddingTabs.participants) > -1}
            />
        </TabList>
    );
}
