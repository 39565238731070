import { regexps, roles } from '../../../constants';
import { CompanySlim } from '../../../types/company/CompanySlim';
import { CompanyDetailsButton } from '../../company-details/CompanyDetailsButton';
import { controlPanelActions } from '../../../actions';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    seller: CompanySlim
    rowKey?: number;
}

export function BwicCreator({ seller, rowKey }: Props) {
    const dispatch = useAppDispatch();
    if (!seller) {
        return null;
    }

    const creator = seller.name
        .replace(regexps.selectLeadingAndEndingCommas, '')
        .trim();

    return (
        <div className="created-by">
            <RoleActionBlocker roles={[roles.BrokerDealerTrader]}>
                {(disabled: boolean) => (
                    <CompanyDetailsButton
                        rowKey={rowKey ?? seller.id}
                        title={`Created by: ${creator}`}
                        company={seller}
                        disabled={disabled}
                        onClick={() => dispatch(controlPanelActions.hide())}
                    />
                )}
            </RoleActionBlocker>
        </div>
    );
}
