import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blastMessageActions } from '../../../../actions/blast-message.actions';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { AppState } from '../../../../types/state/AppState';
import { BlastMessageRecipientLevelState, BlastMessageTypes } from '../../../../types/state/BlastMessageState';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import { RadioButton } from '../../../controls/RadioButton';
import { Stage1AboutToEndTimeoutRadioButton } from './Stage1AboutToEndTimeoutRadioButton';

interface Props {
    disabled: boolean;
    bwicProcess: Process;
    isAllToAll: boolean;
}

export function BlastMessageType({ disabled, bwicProcess, isAllToAll }: Props) {
    const dispatch = useDispatch();
    const messageType = useSelector((s: AppState) => s.blastMessage.messageType);
    const recipientLevel = useSelector((s: AppState) => s.blastMessage.recipientLevel);
    const isSubmitFinal = messageType === BlastMessageTypes.submitFinal;
    const isBidOrPass = messageType === BlastMessageTypes.bidOrPass;
    const isStage1AboutToEndTimeout = messageType === BlastMessageTypes.stage1AboutToEndTimeout;
    const isJumpBallTopXProcess = bwicProcess.type === BwicProcessType.JumpBall || bwicProcess.type === BwicProcessType.TopX;
    const isBidOrPassEnabled = !isAllToAll && (!isJumpBallTopXProcess || bwicProcess.stagedBiddingStatus == null);
    const isSubmitFinalEnabled = !isJumpBallTopXProcess || bwicProcess.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const isStage1AboutToEndTimeoutVisible = isJumpBallTopXProcess && bwicProcess.stagedBiddingStatus === null;
    const isJumpBallTopXStage1Ended = isJumpBallTopXProcess && bwicProcess.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

    const submitFinalTooltipText = bwicProcess.stagedBiddingStatus === OpenBiddingStatus.stage1Ended
        ? "Message will be sent to participants who can but did not bid in Stage 2"
        : "This option does not apply to Stage 1"
    const bidOrPassTooltipText = bwicProcess.stagedBiddingStatus == null
        ? "Message will be sent to participants who did not bid in Stage 1"
        : "This option does not apply to Stage 2"
    const recipientLevelDisabledTooltip = bwicProcess.stagedBiddingStatus === OpenBiddingStatus.stage1Ended
        ? "This option does not apply to Stage 2"
        : "This option does not apply to Stage 1"

    React.useEffect(() => {
        if (!isSubmitFinalEnabled && isSubmitFinal) {
            if(isBidOrPassEnabled) {
                dispatch(blastMessageActions.messageTypeChange(BlastMessageTypes.bidOrPass));
            } else if(isStage1AboutToEndTimeoutVisible) {
                dispatch(blastMessageActions.messageTypeChange(BlastMessageTypes.stage1AboutToEndTimeout));
            }
        } else if (!isBidOrPassEnabled && isBidOrPass) {
            dispatch(blastMessageActions.messageTypeChange(BlastMessageTypes.submitFinal));
        } else if (isJumpBallTopXStage1Ended && isSubmitFinal) {
            dispatch(blastMessageActions.recipientLevelChange(BlastMessageRecipientLevelState.All));
        } else if (!isStage1AboutToEndTimeoutVisible && isStage1AboutToEndTimeout) {
            dispatch(blastMessageActions.messageTypeChange(BlastMessageTypes.submitFinal));
            dispatch(blastMessageActions.recipientLevelChange(BlastMessageRecipientLevelState.All));
        }

    }, [
        isAllToAll,
        isSubmitFinalEnabled,
        isBidOrPassEnabled,
        isSubmitFinal,
        isBidOrPass,
        isJumpBallTopXStage1Ended,
        isStage1AboutToEndTimeout,
        isStage1AboutToEndTimeoutVisible,
        dispatch
    ]);

    const handleMessageTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(blastMessageActions.messageTypeChange(+e.target.value));
    };

    const handleRecipientLevelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(blastMessageActions.recipientLevelChange(e.target.value as BlastMessageRecipientLevelState));
    };

    return (
        <div className="blast-message-settings-types">
            <div className="blast-message-types-list">
                <OnHoverTooltip overlay={isJumpBallTopXProcess && submitFinalTooltipText}>
                    <RadioButton
                        dataTestId='submitFinal'
                        label='"Please submit your final level"'
                        name="messageType"
                        checked={isSubmitFinal}
                        disabled={disabled || !isSubmitFinalEnabled}
                        value={BlastMessageTypes.submitFinal}
                        onChange={handleMessageTypeChange}
                        data-testid="ok-button"
                    />
                </OnHoverTooltip>
                <div className="sub-group-radios">
                    <OnHoverTooltip disabled={!isJumpBallTopXProcess} overlay={recipientLevelDisabledTooltip}>
                        <RadioButton
                            label="Top 3"
                            name="recipientLevel"
                            disabled={disabled || (!isSubmitFinalEnabled || isJumpBallTopXStage1Ended)}
                            checked={isSubmitFinal && recipientLevel === BlastMessageRecipientLevelState.Top3}
                            value={BlastMessageRecipientLevelState.Top3}
                            onChange={handleRecipientLevelChange}
                        />
                    </OnHoverTooltip>
                    <OnHoverTooltip disabled={!isJumpBallTopXProcess} overlay={recipientLevelDisabledTooltip}>
                        <RadioButton
                            label="Top 5"
                            name="recipientLevel"
                            disabled={disabled || (!isSubmitFinalEnabled || isJumpBallTopXStage1Ended)}
                            checked={isSubmitFinal && recipientLevel === BlastMessageRecipientLevelState.Top5}
                            value={BlastMessageRecipientLevelState.Top5}
                            onChange={handleRecipientLevelChange}
                        />
                    </OnHoverTooltip>
                    <OnHoverTooltip overlay={isJumpBallTopXProcess && submitFinalTooltipText}>
                        <RadioButton
                            label={isAllToAll ? "All bidders with bids" : "All participants with bids"}
                            name="recipientLevel"
                            disabled={disabled || !isSubmitFinalEnabled}
                            checked={isSubmitFinal && recipientLevel === BlastMessageRecipientLevelState.All}
                            value={BlastMessageRecipientLevelState.All}
                            onChange={handleRecipientLevelChange}
                        />
                    </OnHoverTooltip>
                </div>
                {
                    !isAllToAll &&
                    <OnHoverTooltip overlay={isJumpBallTopXProcess && bidOrPassTooltipText}>
                        <RadioButton
                            dataTestId='bidOrPass'
                            label='"Please submit your bid or indicate that you pass"'
                            name="messageType"
                            checked={isBidOrPass}
                            disabled={disabled || !isBidOrPassEnabled}
                            value={BlastMessageTypes.bidOrPass}
                            onChange={handleMessageTypeChange}
                        />
                    </OnHoverTooltip>
                }
                <Stage1AboutToEndTimeoutRadioButton
                    isStage1AboutToEndTimeout={isStage1AboutToEndTimeout}
                    disabled={disabled}
                    handleOnChange={handleMessageTypeChange}
                    visible={isStage1AboutToEndTimeoutVisible}
                />
            </div>
        </div>
    );
}

