import * as React from 'react';
import { useSelector } from 'react-redux';
import { biddingUtils } from '../../../../utils/bidding.utils';
import { EmptyBid } from './EmptyBid';
import { BidGroup } from './BidGroup';
import { EmptyPlaceholder, OnHoverTooltip } from '../../../common';
import IconSVG from '../../../../styles/svg-icons';
import { AppState } from '../../../../types/state/AppState';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { CompanySlim } from '../../../../types/company/CompanySlim';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { isActiveTrade } from '../../../../types/trades/TradeStatus';
import { Bid } from '../../../../types/bidding/Bid';
import { Toggle } from '../../../controls';
import { calculateStage2Participants } from '../../../../utils/stage2-participants-calculator';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';

interface Props {
    participants: CompanySlim[];
    bwicStatus: BwicStatus;
    process: Process;
    positions: BwicPosition[];
    isAllToAll: boolean;
}

export function BidList({ participants, bwicStatus, process, positions, isAllToAll }: Props) {
    const [showAllBidders, setShowAllBidders] = React.useState(false);
    const { positionId, visible } = useSelector((s: AppState) => s.sellerBidding.bidList);
    const positionWithAllBids = useSelector((s: AppState) => positionId ? s.sellerBidding.securities.find(s => s.id === positionId) : undefined);
    const position = visible && positionId ? positions.find(s => s.id === positionId) : undefined;
    const bidsByLevel = React.useMemo(() => {
        const bidsByLevel = biddingUtils.groupBidsByLevel(position?.bids ?? []);
        const pass = position?.bids?.filter(b => b.pass);

        return pass?.length ? [...bidsByLevel, pass] : bidsByLevel;
    }, [position]);

    if (!visible || !position || !positionWithAllBids) return null;

    const hasActiveTrade = isActiveTrade(position.trade);
    const isBidding = bwicStatus === BwicStatus.bidding;
    const isFinished = bwicStatus === BwicStatus.finished;
    const hasStage2 = process.type === BwicProcessType.Live || process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX;
    const feedbackEnabled = isBidding && process.type !== BwicProcessType.Live && !hasActiveTrade;
    const bidAttributesEnabled = process.type === BwicProcessType.Standard || process.type === BwicProcessType.BestFootForward;

    const renderBiddingSubHeader = () =>
        <div className="bid-list-controls flex-row">
            <h3>All Participants</h3>
            <div className="bid-list-toggle">
                <OnHoverTooltip
                    disabled={showAllBidders}
                    overlay="Toggle on to see all invited broker-dealers, even those with no bids"
                >
                    <Toggle value={showAllBidders} onClick={() => setShowAllBidders(!showAllBidders)} />
                </OnHoverTooltip>
            </div>
        </div>;

    const renderStage2GroupSplitter = () =>
        <div key="bid-list-splitter" className="bid-list-row flex-row">
            <div className="bid-list-cell bid-list-divider">
                Ineligible for Stage 2 bidding
            </div>
        </div>;

    const createBidComponent = (index: number, bidGroup: Bid[], stage2Participants?: { [companyIdentifier: string]: boolean }) =>
        <BidGroup
            key={`b${index}`}
            level={bidGroup.some(b => b.pass) ? undefined : index}
            positionId={position.id}
            allBids={position.bids}
            levelBids={bidGroup}
            bidAttributesVisible={bidAttributesEnabled}
            feedbackVisible={feedbackEnabled}
            process={process}
            stage2Participants={stage2Participants}
            trade={position.trade}
            bwicStatus={bwicStatus}
        />;

    const renderBids = () => {
        const emptyBids =
            showAllBidders
                ? participants
                    .filter(p => !position.bids.some(b => b.company.id === p.id && !b.buyerPseudoOrderNumber))
                    .map((p, i) =>
                        <EmptyBid
                            key={`e${i}`}
                            positionId={position.id}
                            company={p}
                            feedbackVisible={feedbackEnabled}
                            bidAttributesEnabled={bidAttributesEnabled}
                            process={process}
                            feedbackDisabled={isAllToAll}
                        />
                    )
                : [];

        let bids = [];

        if (hasStage2 && process.stagedBiddingStatus !== OpenBiddingStatus.improvementRoundEnded) {
            const stage2Participants = calculateStage2Participants(positionWithAllBids?.bids ?? [], process);
            const passedBids = [];
            const notPassedBids = [];

            for (let i = 0; i < bidsByLevel.length; i++) {
                const bidComponent = createBidComponent(i, bidsByLevel[i], stage2Participants);
                if (bidsByLevel[i].some(b => stage2Participants[biddingUtils.getBidCompanyIdentifier(b)])) {
                    passedBids.push(bidComponent);
                } else {
                    notPassedBids.push(bidComponent);
                }
            }

            bids = passedBids;
            if (notPassedBids.length || emptyBids.length) {
                bids.push(renderStage2GroupSplitter(), ...notPassedBids)
            }
        } else {
            bids = bidsByLevel.map((bidGroup, i) => createBidComponent(i, bidGroup));
        }

        const isHidden = (isBidding || isFinished) && !showAllBidders && !bids.length;

        return (
            <>
                {(isBidding || isFinished) && !isAllToAll && renderBiddingSubHeader()}
                {
                    !isHidden &&
                    <div className="bid-list-columns-name flex-row">
                        <div className="bid-list-cell bid-list-rank">Rank</div>
                        <div className="bid-list-cell bid-list-dealer">Dealer</div>
                        <div className="bid-list-cell bid-list-bid">Bid</div>
                        {
                            bidAttributesEnabled &&
                            <>
                                <div className="bid-list-cell bid-list-axed">
                                    <OnHoverTooltip overlay="Axed">
                                        <span>
                                            <IconSVG name="axed" width={16} height={16} />
                                        </span>
                                    </OnHoverTooltip>
                                </div>
                                <div className="bid-list-cell bid-list-final">
                                    <OnHoverTooltip overlay="Final">
                                        <span>
                                            <IconSVG name="final" width={16} height={16} />
                                        </span>
                                    </OnHoverTooltip>
                                </div>
                            </>
                        }
                        <div className="bid-list-cell bid-list-bid-on-behalf">&nbsp;</div>
                        <div className="bid-list-cell bid-list-feedback">&nbsp;</div>
                    </div>
                }
                <div className="bid-list-content">
                    {bids}
                    {emptyBids}
                    {
                        isHidden &&
                        <div className="bid-list-empty">
                            <EmptyPlaceholder textView={true} text="No one has placed bids yet." />
                        </div>
                    }
                </div>
            </>
        );
    };

    return <div className="bid-list">{renderBids()}</div>;
}
