import { SecurityListHeaderColumn, SecurityListColumn } from '../../../../../common/security-list';
import { PositionTrade } from '../../../../../../types/trades/PositionTrade';
import { OnHoverTooltip } from '../../../../../common/OnHoverTooltip';
import { constants } from '../../../../../../constants/constants';
import { formatUtils } from '../../../../../../utils/format.utils';

export const tradeCommission = {
    columnName: 'tradeCommission',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xs text-right">
                Comm, %
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (positionTrade: PositionTrade) {
        const commission = positionTrade.trade.commission
            ? formatUtils?.formatDecimal(positionTrade.trade.commission, constants.commissionDecimalPlaces)
            : constants.emptyPlaceholder

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xs text-right">
                <OnHoverTooltip overlay={commission}>
                    <span className="text-ellipsis">{commission}</span>
                </OnHoverTooltip>
            </SecurityListColumn>
        );
    }
};
