import * as React from 'react';
import moment from "moment";
import { constants } from "../../../constants";

interface CompletedAtProps {
    completedDateUtc: Date;
}

export function CompletionTime({ completedDateUtc }: CompletedAtProps) {
    let completionDate = moment.utc(completedDateUtc).local();
    const time = completionDate.format(constants.timeFormat);

    return (
        <div className="bwic-completed finished">
            <span className="text-sm">BWIC completed at: <span className="text-medium">{time}</span></span>
        </div>
    );
}
