import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { FeatureButton } from '../../../access/FeatureButton';
import Confirm from '../../../alerts/Confirm';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import { RequestState } from '../../../../constants/request-state';
import { bwicService } from '../../../../services/bwic.service';
import { errorActions } from '../../../../actions/error.actions';
import { isRequestSuccess, isRequesting } from '../../../../utils/request-state.utils';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';

interface Props {
    bwicReferenceName: string;
    process: Process;
}

export function SwitchStageButton({ bwicReferenceName, process }: Props) {
    if (process.type !== BwicProcessType.JumpBall && process.type !== BwicProcessType.TopX) return null;

    const automaticStage1Deadline = process.type === BwicProcessType.JumpBall
        ? process.jumpBall?.automaticStage1Deadline
        : process.topX?.automaticStage1Deadline;

    if (process.stagedBiddingStatus == null && !automaticStage1Deadline) {
        return (
            <>
                <Button
                    key="stage-1" // use uniqie key to reset request state
                    bwicReferenceName={bwicReferenceName}
                    text="Start Stage 2"
                    confirmText={<>Would you like to finish <span className="text-bold">Stage 1</span> and start <span className="text-bold">Stage 2</span>?</>}
                />
                <span className="vertical-divider margin-r-8 margin-l-8" />
            </>
        )
    }

    const improvementRoud = process.type === BwicProcessType.JumpBall
        ? process.jumpBall?.improvementRound
        : process.topX?.improvementRound;

    if (process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended && !improvementRoud) {
        // Seller controls stage 2 end
        return (
            <>
                <Button
                    key="stage-2" // use uniqie key to reset request state
                    bwicReferenceName={bwicReferenceName}
                    text="Complete Stage 2"
                    tooltip="Click to complete Stage 2 of BWIC. All bidders will no longer be able to bid, however, the Seller will be able to trade and send color."
                    confirmTitle="BWIC Completing"
                    confirmText={<>Are you sure you want to <span className="text-bold">complete Stage 2</span>? Bidders will no longer be able to improve their bids, however, you will be able to trade and send color.</>}
                />
                <span className="vertical-divider margin-r-8 margin-l-8" />
            </>
        )
    }

    return null;
}

interface ButtonProps {
    bwicReferenceName: string;
    text: string;
    confirmTitle?: string;
    confirmText: React.ReactNode;
    tooltip?: string;
}

function Button({ bwicReferenceName, text, tooltip, confirmText, confirmTitle }: ButtonProps) {
    const [confirm, setConfirm] = useState(false);
    const { requestState, finishStage } = useFinishStageService(bwicReferenceName);

    return (
        <>
            <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                <OnHoverTooltip
                    overlay={tooltip}
                >
                    <FeatureButton
                        className="btn btn-main"
                        disabled={isRequesting(requestState) || isRequestSuccess(requestState)}
                        onClick={() => setConfirm(true)}
                    >
                        {text}
                    </FeatureButton>
                </OnHoverTooltip>
            </RoleActionBlocker>
            {
                confirm &&
                <Confirm
                    renderInBody
                    title={confirmTitle}
                    text={confirmText}
                    onConfirm={() => {
                        finishStage();
                        setConfirm(false);
                    }}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    onCancel={() => setConfirm(false)}
                />
            }
        </>
    );
}

function useFinishStageService(bwicReferenceName: string) {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useState(RequestState.none);

    const finishStage = async () => {
        setRequestState(RequestState.request);
        try {
            await bwicService.finishStage(bwicReferenceName);
            setRequestState(RequestState.success);
        } catch (e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { requestState, finishStage };
}
