import * as React from "react";
import classNames from 'classnames';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BwicPosition } from "../../../../types/bwic/BwicPosition";
import { best } from "../../common/table/columns/best";
import { cover } from "../../common/table/columns/cover";
import { third } from "../../common/table/columns/third";
import { fourth } from "../../common/table/columns/fourth";
import { fifth } from "../../common/table/columns/fifth";
import { ColumnBuilder } from "../../common/table/columns/column-builder/ColumnBuilder";
import { events } from "../../common/table/columns/events";
import { pnl } from "../../common/table/columns/pnl";
import { pxTalk } from "../../common/table/columns/pxTalk";
import { rating } from "../../common/table/columns/rating";
import { bidListButton } from "../../common/table/columns/seller-actions/bid-list-button";
import { tradeButton } from "../../common/table/columns/seller-actions/trade-button";
import { size } from "../../common/table/columns/size";
import { targetLevel } from "../../common/table/columns/target.level";
import { tickerButton } from "../../common/table/columns/ticker-button";
import { liveBiddingStage2Timer } from "../../common/table/columns/live-bidding-stage2-timer";
import { IColumnDefinition } from "../../common/table/types/ColumnDefinition";
import { CompanyStatus } from "../../../../types/company/CompanyStatus";
import { CompanyBid } from '../biddingSecurities/CompanyBid';
import { EditBidOnBehalf } from '../bid-on-behalf/EditBidOnBehalf';
import { color } from "../../common/table/columns/color";
import { DealerColumnHeaderContentMemoized } from "./DealerColumnHeaderContent";
import { isActiveTrade } from "../../../../types/trades/TradeStatus";
import { ListBuilder } from "../../../../utils/ListBuilder";
import { SettlementAgentAgreement } from "../../../../types/bid-as-dealer/SettlementAgentAgreement";
import { tradeStatus } from "../../common/table/columns/trade-affirm/trade.status";
import { BwicProcessType, Process } from "../../../../types/models/Process";
import { OpenBiddingStatus } from "../../../../types/enums/OpenBiddingStatus";
import { biddingUtils } from "../../../../utils";
import { jumpBallUtils } from "../../../../utils/jump-ball.utils";
import { topXUtils } from "../../../../utils/top-x.utils";
import { biddersCounter } from "../../common/table/columns/biddersCounter";
import { BidderCompanySlim } from "../../../../types/company/BidderCompanySlim";

export function getSellerBiddingColumns(
    shortView: boolean,
    process: Process,
    companies: BidderCompanySlim[],
    settlementAgentAgreements: SettlementAgentAgreement[],
    isStage2ParticipantsOnly: boolean,
    isAllToAll: boolean) {
    const improverCount =
        (process.type === BwicProcessType.JumpBall && process.jumpBall?.improverCount) ||
        (process.type === BwicProcessType.TopX && process.topX?.improverCount) ||
        (process.type === BwicProcessType.Live && process.liveBidding?.openBiddingImproverCount);

    return new ListBuilder<unknown>()
        .add(freeze(tickerButton))
        .addWhen(() =>
            process.type === BwicProcessType.JumpBall,
            freeze(biddersCounter))
        .addWhen(() =>
            process.type === BwicProcessType.Live &&
            (
                process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended ||
                process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded
            ),
            freeze(liveBiddingStage2Timer))
        .add(freeze(bidListButton), freeze(tradeButton), size, rating)
        .addWhen(() => !shortView, pxTalk, targetLevel, pnl)
        .addWhen(() => !isStage2ParticipantsOnly, best, cover, third)
        .addWhen(() => isStage2ParticipantsOnly, ...[best, cover, third, fourth, fifth].slice(0, Number(improverCount)))
        .addWhen(
            () => !!companies.length,
            ...companies.map(c =>
                createCompanyColumn(
                    c,
                    isAllToAll,
                    false,
                    process.type !== BwicProcessType.Live,
                    process.type === BwicProcessType.Standard || process.type === BwicProcessType.BestFootForward,
                    settlementAgentAgreements.find(a => a.settlementAgent.id === c.id),
                    process
                )))
        .add(events)
        .result();
}

export function getSellerFinishedColumns(
    shortView: boolean,
    companies: BidderCompanySlim[],
    feedbackVisible: boolean,
    settlementAgentAgreements: SettlementAgentAgreement[],
    isAllToAll: boolean) {
    return new ListBuilder<unknown>()
        .add(freeze(tickerButton), freeze(bidListButton), freeze({ ...tradeStatus, title: '' }), size, rating)
        .addWhen(() => !shortView, pxTalk, targetLevel, pnl)
        .add(best, cover, third)
        .addWhen(
            () => !!companies.length,
            ...companies.map(c => createCompanyColumn(
                c,
                isAllToAll,
                true,
                feedbackVisible,
                false,
                settlementAgentAgreements.find(a => a.settlementAgent.id === c.id)
            )))
        .add(color, events)
        .result();
}

function freeze(columnDefinition: object) {
    return { ...columnDefinition, column: { stickLeft: true } };
}

function createCompanyColumn(
    company: BidderCompanySlim,
    isAllToAll: boolean,
    readonly = false,
    feedbackVisible = false,
    bidAttributesVisible = true,
    agreement?: SettlementAgentAgreement,
    process?: Process) {
    const isOnPlatform = company.status === CompanyStatus.active;
    const definition: IColumnDefinition<BwicPosition> = {
        columnKey: `company-${company.identifier}`,
        renderColumnContent: (position, context: { stage2Participants?: { [companyIdentifier: string]: boolean }; columnAnimationEnabled?: boolean }) =>
            <CompanyCell
                stage2Participants={context.stage2Participants ?? {}}
                position={position}
                company={company}
                process={process}
                readonly={readonly}
                bidAttributesVisible={bidAttributesVisible}
                feedbackVisible={feedbackVisible}
                columnAnimationEnabled={context.columnAnimationEnabled}
            />,
        renderColumnHeaderContent: (context: { columnScrollEnabled?: boolean }) =>
            <DealerColumnHeaderContentMemoized
                columnScrollEnabled={context.columnScrollEnabled}
                company={company}
                canHide={!readonly}
                agreement={agreement} />,
        headerClassName: classNames('data-list-cell-xl cell-bob', { 'cell-on-platform': isOnPlatform }),
        bodyClassName: classNames('data-list-cell-xl cell-bob overflow-visible', { 'cell-on-platform': isOnPlatform })
    };

    return new ColumnBuilder(definition);
}

interface Props {
    company: BidderCompanySlim;
    position: BwicPosition;
    process?: Process;
    stage2Participants?: { [companyIdentifier: string]: boolean };
    readonly?: boolean;
    feedbackVisible?: boolean;
    bidAttributesVisible?: boolean;
    columnAnimationEnabled?: boolean;
}

function CompanyCell({ company, position, process, stage2Participants = {}, readonly = false, feedbackVisible = false, bidAttributesVisible = false, columnAnimationEnabled = false }: Props) {
    const isOnPlatform = company.status === CompanyStatus.active;
    const activeTrade = isActiveTrade(position.trade);
    const isJumpBall = process?.type === BwicProcessType.JumpBall;
    const isTopX = process?.type === BwicProcessType.TopX;
    const isBFF = process?.type === BwicProcessType.BestFootForward;
    const isBuyerCompanyWithoutBids = Boolean(company.buyerPseudoOrderNumber && !position.bids?.some(b => biddingUtils.getBidCompanyIdentifier(b) === company.identifier));
    const lastSelectedCompanyIdentifier = useAppSelector(s => s.dealerListPanel.lastSelectedCompanyIdentifier);

    const animationEnabled = columnAnimationEnabled && !!lastSelectedCompanyIdentifier && lastSelectedCompanyIdentifier === company.identifier;

    const canBidJumpBall = isJumpBall
        ? jumpBallUtils.canBid(
            process?.stagedBiddingStatus,
            position.bids?.find(b => biddingUtils.getBidCompanyIdentifier(b) === company.identifier),
            stage2Participants[company.identifier])
        : true;
    const canBidTopX = isTopX
        ? topXUtils.canBid(process?.stagedBiddingStatus, stage2Participants[company.identifier])
        : true;
    const canBidFF = isBFF
        ? process.stagedBiddingStatus == null
        : true;

    return isOnPlatform || readonly || activeTrade || !canBidJumpBall || !canBidTopX || !canBidFF || !process
        ? <CompanyBid
            company={company}
            position={position}
            feedbackVisible={feedbackVisible}
            feedbackDisabled={readonly || activeTrade || !canBidJumpBall || !canBidTopX || !canBidFF || isBuyerCompanyWithoutBids}
            showEmptyDash={readonly || activeTrade}
            process={process}
        />
        : <EditBidOnBehalf
            company={company}
            position={position}
            bidAttributesVisibe={bidAttributesVisible}
            process={process}
            columnAnimationEnabled={animationEnabled}
        />

}
