import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { TopPanel, ActionBar, ExportButton } from '../common';
import { RequestInvitationPanel } from './invitation';
import { BwicCreator } from '.';
import { brokerDealerAccessState } from '../../../constants/bidding';
import { Table } from '../common/table';
import { brokerDealerScheduledColumns } from '../common/table/columns';
import { ControlPanel } from '../common/ControlPanel';
import { user } from '../../../user';
import { CompanyDetailsPanel } from '../../company-details/CompanyDetailsPanel';
import { RequestSettlementAgentAgreementBanner } from '../../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { AppState } from '../../../types/state/AppState';
import { BwicPosition } from '../../../types/bwic/BwicPosition';
import { roles } from '../../../constants';

interface ScheduledProps {
    activePositionId?: number;
}

export const Scheduled = ({ activePositionId }: ScheduledProps) => {
    const bwic = useSelector((s: AppState) => s.bidding.bwic);
    const securities = useSelector((s: AppState) => s.brokerDealerBidding.securities);
    const accessState = useSelector((s: AppState) => s.brokerDealerBidding.accessState);

    const createSecurityCustomClassName = (security: BwicPosition) => {
        return classNames({
            active: security.id === activePositionId
        });
    }

    if (!securities) {
        return null;
    }

    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    {bwic.seller && <BwicCreator seller={bwic.seller} />}
                </TopPanel>
                <div className="sub-header-row type03 flex-row">
                    {!bwic.isParsed && <RequestInvitationPanel />}
                    <ActionBar>
                        {accessState === brokerDealerAccessState.approved && <ExportButton bwicReferenceName={bwic.referenceName} />}
                    </ActionBar>

                </div>
            </div>
            {bwic.isAllToAll && <RequestSettlementAgentAgreementBanner />}
            <div className="flex-row flex-row-full-size">
                <Table
                    className="data-list-striped data-list-bd-scheduled"
                    dataItems={securities}
                    columns={brokerDealerScheduledColumns(user.hasRoles(roles.BrokerDealerTrader))}
                    createSecurityCustomClassName={createSecurityCustomClassName}
                    createSecurityCustomArgs={() => ({ bwic })}
                />
                <ControlPanel
                    bwic={bwic}
                    positions={securities}
                />
                <CompanyDetailsPanel />
            </div>
        </div>
    );
}
