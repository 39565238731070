import React from 'react';
import { constants } from '../../../../constants/constants';
import { Bid } from '../../../../types/bidding/Bid';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { InfoTooltip } from '../../../common/InfoTooltip';
import { FeedbackLabel } from '../../common/feedback/FeedbackLabel';

interface CurrentBid {
    pass: boolean;
    stagedBiddingStatus?: OpenBiddingStatus;
    feedback?: string;
}

export interface BiddingInfoProps {
    isColorDistribution: boolean;
    canBidOnStage2?: boolean;
    currentBid?: CurrentBid | Bid;
    bwicStatus?: BwicStatus;
    process: Process;
    expired?: boolean;
}

type ApplicableFunc = () => boolean;

type BiddingInfoRule = {
    isApplicable: boolean | ApplicableFunc;
    content: () => React.ReactNode;
}

export function BiddingInfo({
    isColorDistribution,
    canBidOnStage2 = false,
    currentBid,
    bwicStatus,
    expired = false,
    process,
}: BiddingInfoProps) {
    const hasBid = currentBid != null && !currentBid.pass;

    const biddingFinished = () => ({
        isApplicable: bwicStatus === BwicStatus.finished,
        content: () => <Content text={constants.emptyPlaceholder} />
    })

    const liveBiddingStage2TimeEnded = () => {
        const isApplicable =
            bwicStatus === BwicStatus.bidding &&
            process.type === BwicProcessType.Live && (
                process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded || (
                    process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
                    expired
                )
            );

        const content = () => <BiddingCompleted />;

        return { isApplicable, content };
    }

    const liveBiddingStage2OutOfTop = () => {
        const isApplicable =
            process.type === BwicProcessType.Live &&
            process.liveBidding != null &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            !canBidOnStage2 &&
            hasBid;

        const content = () =>
            <Stage2OutOfTop
                improverCountText={`Top ${process.liveBidding?.openBiddingImproverCount}`}
            />

        return { isApplicable, content };
    }

    function liveBiddingNoStage1Bids() {
        const isApplicable =
            process.type === BwicProcessType.Live &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            !canBidOnStage2 &&
            !hasBid;

        const content = () => <NoStage1Bids />

        return { isApplicable, content };
    }

    function liveBiddingStage2StartedCanImprove() {
        const isApplicable = (
            process.type === BwicProcessType.Live &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            canBidOnStage2
        );

        const content = () => <Content text="Stage 2 started" tooltip="Stage 2 started. Please improve your bids." />

        return { isApplicable, content };
    }

    function liveBiddingStage2Started() {
        const isApplicable = (
            process.type === BwicProcessType.Live &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended
        );

        const content = () => <Content text="Stage 2 started" tooltip="Stage 2 started" />

        return { isApplicable, content };
    }

    function jumpBallTopXStage2OutOfTop() {
        const isApplicable =
            (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            hasBid &&
            !canBidOnStage2 &&
            currentBid?.stagedBiddingStatus !== OpenBiddingStatus.stage1Ended;

        let improverCount = 0;
        if (process.type === BwicProcessType.JumpBall && process.jumpBall) {
            improverCount = process.jumpBall?.improverCount
        }
        if (process.type === BwicProcessType.TopX && process.topX) {
            improverCount = process.topX?.improverCount
        }

        const content = () => <Stage2OutOfTop improverCountText={String('top ' + improverCount)} />

        return { isApplicable, content }
    }

    function jumpBallTopXNoStage1BidsOnStage2() {
        const isApplicable =
            (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            !hasBid;

        return { isApplicable, content: () => <NoStage1Bids /> }
    }

    function jumpBallStage2Started() {
        const isApplicable =
            process.type === BwicProcessType.JumpBall &&
            bwicStatus === BwicStatus.bidding &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            canBidOnStage2 &&
            hasBid &&
            currentBid?.stagedBiddingStatus == null

        const content = () => <Content
            text="Stage 2 started"
            tooltip={Boolean(currentBid?.feedback) 
                ? `Your status from Stage 1 - ${currentBid?.feedback}. You have the opportunity to improve your bid only once.`
                : "Stage 2 started"
            }
        />;

        return { isApplicable, content };
    }

    function topXStage2Started() {
        const isApplicable =
            process.type === BwicProcessType.TopX &&
            bwicStatus === BwicStatus.bidding &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            canBidOnStage2 &&
            hasBid &&
            currentBid?.stagedBiddingStatus == null

        const content = () => <Content
            text="Stage 2 started"
            tooltip={Boolean(currentBid?.feedback) 
                ? `Your status from Stage 1 - ${currentBid?.feedback}. You can improve your bid, but the seller can optionally provide bid feedback during Stage 2.`
                : "Stage 2 started"
            }
        />;

        return { isApplicable, content };
    }

    function topXStage2ImprovedBid() {
        const isApplicable =
            process.type === BwicProcessType.TopX &&
            bwicStatus === BwicStatus.bidding &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            canBidOnStage2 &&
            hasBid &&
            currentBid?.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

        const content = () => <Content
            text="Stage 2. Bid improved"
            tooltip="The seller can optionally provide bid feedback during Stage 2."
        />;

        return { isApplicable, content };
    }

    function jumpBallStage2BidAlreadySent() {
        const isApplicable =
            bwicStatus === BwicStatus.bidding &&
            process.type === BwicProcessType.JumpBall &&
            process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            hasBid &&
            currentBid?.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

        const content = () => <Content
            text="Stage 2. Bid improved"
            tooltip="We will let you know your status as soon as all participants submit their levels"
        />

        return { isApplicable, content };
    }

    function jumpBallTopXBiddingCompleted() {
        const isApplicable =
            bwicStatus === BwicStatus.bidding &&
            (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
            process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded && (
                !hasBid || !canBidOnStage2
            )
        const content = () => <BiddingCompleted />;

        return { isApplicable, content };
    }

    function jumpBallTopXBiddingCompletedHasBids() {
        return {
            isApplicable:
                bwicStatus === BwicStatus.bidding &&
                (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
                process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded &&
                canBidOnStage2 &&
                hasBid &&
                Boolean(currentBid?.feedback),
            content: () => <Content
                text="Bidding is completed"
                tooltip={`Bidding is completed. ${currentBid?.feedback}`}
            />
        }
    }

    function bestFFBiddingEndedHasBids() {
        return {
            isApplicable:
                process.type === BwicProcessType.BestFootForward &&
                bwicStatus === BwicStatus.bidding &&
                process.stagedBiddingStatus != null &&
                hasBid &&
                Boolean(currentBid?.feedback),
            content: () => <Content
                text="Bidding is completed"
                tooltip={`Bidding is completed. ${currentBid?.feedback}`}
            />
        };
    }

    function bestFFBiddingEndedNoBids() {
        return {
            isApplicable:
                process.type === BwicProcessType.BestFootForward &&
                bwicStatus === BwicStatus.bidding &&
                process.stagedBiddingStatus != null &&
                !hasBid,
            content: () => <BiddingCompleted />
        };
    }

    function stage1Started() {
        return {
            isApplicable: bwicStatus === BwicStatus.bidding && process.stagedBiddingStatus == null,
            content: () => <Stage1Started processType={process.type} />
        };
    }

    const feedbackRules: BiddingInfoRule[] = [
        biddingFinished(),
        { isApplicable: isColorDistribution && bwicStatus === BwicStatus.bidding, content: () => <Content text="Awaiting color" tooltip="Awaiting color" /> },
        liveBiddingStage2TimeEnded(),
        liveBiddingStage2OutOfTop(),
        liveBiddingNoStage1Bids(),
        liveBiddingStage2StartedCanImprove(),
        liveBiddingStage2Started(),
        jumpBallTopXStage2OutOfTop(),
        jumpBallTopXNoStage1BidsOnStage2(),
        jumpBallStage2BidAlreadySent(),
        jumpBallStage2Started(),
        topXStage2Started(),
        topXStage2ImprovedBid(),
        jumpBallTopXBiddingCompleted(),
        jumpBallTopXBiddingCompletedHasBids(),
        stage1Started(),
        bestFFBiddingEndedHasBids(),
        bestFFBiddingEndedNoBids(),
        { isApplicable: true, content: () => <FeedbackLabel text={constants.emptyPlaceholder} /> }
    ];

    const rule = feedbackRules.find(r => typeof r.isApplicable === 'function' ? r.isApplicable() : r.isApplicable);

    return (rule?.content() ?? null) as React.ReactElement;
}

function BiddingCompleted() {
    return <Content
        text="Bidding is completed"
        tooltip="Bidding is completed. No bids are accepted"
    />;
}

function Stage1Started({ processType }: { processType: BwicProcessType }) {
    let text;
    let tooltip;

    switch (processType) {
        case BwicProcessType.TopX:
        case BwicProcessType.JumpBall:
        case BwicProcessType.Live:
            text = "Stage 1 started";
            tooltip = "BWIC is opened, initial bid placement started";
            break;
        default:
            text = "Bidding started";
            tooltip = "Bidding is started. Please submit your bid";
    }

    return <Content text={text} tooltip={tooltip} />;
}

interface Stage2OutOfTopProps {
    improverCountText: string;
}

function Stage2OutOfTop({ improverCountText }: Stage2OutOfTopProps) {
    return <Content
        text="Cannot bid in Stage 2"
        tooltip={`You cannot bid in Stage 2 because you are not in ${improverCountText} bidders`}
    />;
}

function NoStage1Bids() {
    return <Content
        text="Cannot bid in Stage 2"
        tooltip="You cannot bid in Stage 2 because you didn't bid in Stage 1"
    />;
}

interface ContentProps {
    text: string;
    tooltip?: string;
}
function Content({ text, tooltip }: ContentProps) {
    return (
        <div className="flex-row bidding-info-content">
            <FeedbackLabel text={text!} />
            {
                tooltip &&
                <InfoTooltip text={tooltip} />
            }
        </div>
    );
}
