import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { Events } from '../../../seller/biddingSecurities';

export const events = {
    columnName: 'events',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-xxxl-flexible cell-flexible-text">
                Events
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-xxxl-flexible cell-flexible-text">
                <Events position={position} />
            </SecurityListColumn>
        );
    }
};
