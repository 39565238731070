import cn from 'classnames';
import { useState } from 'react';
import IconSVG from '../../../../styles/svg-icons';
import { OnHoverTooltip } from '../../../common';
import { IColumnDefinition } from './types/ColumnDefinition';

interface Props {
    onCollapsed: (status: boolean) => void;
    defaultCollapseStatus?: boolean;
}

const ExpandOptionsButton = ({ onCollapsed, defaultCollapseStatus = false}: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapseStatus);

    const handleSetCollapsed = () => {
        setIsCollapsed((isCollapsed) => {
            onCollapsed(!isCollapsed);
            return !isCollapsed;
        });
    }

    return (
        <OnHoverTooltip overlay={isCollapsed ? 'Expand Actions' : 'Collapse Actions'} wrapperClass={cn({ 'box-collapsed': isCollapsed })}>
            <button onClick={handleSetCollapsed} className="btn-link">
                <IconSVG name="collapse-right" width={16} height={16} />
            </button>
        </OnHoverTooltip>
    )
}

export const collapseStickRightColumn: IColumnDefinition<any> = {
    columnKey: 'collapse-stick-right-column',
    renderColumnHeaderContent: (ctx: { onCollapseRightColumn: (status: boolean) => void, isOptionsColumnCollapsed: boolean}) =>
        <ExpandOptionsButton
            onCollapsed={ctx?.onCollapseRightColumn}
            defaultCollapseStatus={ctx?.isOptionsColumnCollapsed}
        />,
    renderColumnContent: () => '',
    className: 'data-list-cell-xxxs data-list-cell-icon-collapsed visible-collapse-icon padding-l-0 keep-while-collapsed',
    stickRight: true
}
