import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { blastMessageActions } from '../../../../actions/blast-message.actions';
import { AppState } from '../../../../types/state/AppState';
import { RightSidePanel } from '../../../controls/RightSidePanel';
import { BlastMessageType } from './BlastMessageType';
import { BlastMessageRecipients } from './BlastMessageRecipients';
import { BlastMessageButtons } from './BlastMessageButtons';
import { Process } from '../../../../types/models/Process';
import { BlastMessageStatusMessage } from './BlastMessageStatusMessage';

interface Props {
    isAllToAll: boolean;
    bwicProcess: Process;
}
export function BlastMessagePanel({ bwicProcess, isAllToAll }: Props) {
    const dispatch = useDispatch();

    const visible = useSelector((s: AppState) => s.blastMessage.panelVisible);
    const isSending = useSelector((s: AppState) => s.blastMessage.isSending);

    const handleClose = () => {
        if (!isSending) {
            dispatch(blastMessageActions.hide());
        }
    }

    return (
        <BlastMessagePanelContent
            visible={visible}
            disabled={isSending}
            bwicProcess={bwicProcess}
            isAllToAll={isAllToAll}
            onClose={handleClose}
        />
    );
}

interface ContentProps {
    bwicProcess: Process;
    isAllToAll: boolean;
    visible: boolean;
    disabled: boolean;
    onClose: () => void;
}

export function BlastMessagePanelContent({ visible, isAllToAll, disabled, bwicProcess, onClose }: ContentProps) {
    if (!visible) {
        return null;
    }

    return (
        <RightSidePanel
            className={cn("right-panel-blast-message", {"right-panel-blast-message-all-to-all": isAllToAll})}
            title="Blast Message"
            onClose={onClose}
        >
            <div className="blast-message-settings">
                <div className="blast-message-settings-content">
                    <BlastMessageStatusMessage bwicProcess={bwicProcess} isAllToAll={isAllToAll} />
                    <BlastMessageType disabled={disabled} bwicProcess={bwicProcess} isAllToAll={isAllToAll} />
                    <BlastMessageRecipients disabled={disabled} bwicProcess={bwicProcess} isAllToAll={isAllToAll} />
                </div>
                <BlastMessageButtons disabled={disabled} />
            </div>
        </RightSidePanel>
    );
}
