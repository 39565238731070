import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { TopPanel, CompletionTime, ActionBar, ExportButton } from '../common';
import { brokerDealerAccessState } from '../../../constants/bidding';
import { BwicCreator } from ".";
import { ControlPanel } from '../common/ControlPanel';
import { user } from '../../../user';
import { CompanyDetailsPanel } from '../../company-details/CompanyDetailsPanel';
import { isActiveTrade, TradeStatus } from '../../../types/trades/TradeStatus';
import { RequestSettlementAgentAgreementBanner } from '../../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { FinishedTable } from './FinishedTable';
import { AppState } from '../../../types/state/AppState';
import { BwicPosition } from '../../../types/bwic/BwicPosition';
import { roles } from '../../../constants/roles';

interface FinishedProps {
    activePositionId?: number;
}

export const Finished = ({ activePositionId }: FinishedProps) => {
    const bwic = useSelector((s: AppState) => s.bidding.bwic);
    const securities = useSelector((s: AppState) => s.brokerDealerBidding.securities);
    const accessState = useSelector((s: AppState) => s.brokerDealerBidding.accessState);
    const completedDateUtc = bwic.completedDateUtc || bwic.goodUntilUtc;

    const createSecurityCustomArgs = () => {
        return {
            bwic: bwic,
            brokerDealerAccessState: accessState,
            bwicStatus: bwic.status
        }
    }

    const createSecurityCustomClassName = (security: BwicPosition) => {
        const canSubmitBid =
            accessState === brokerDealerAccessState.approved &&
            user.hasRoles(roles.BrokerDealerTrader);

        return classNames('pointer', {
            active: security.id === activePositionId,
            tradedaway: canSubmitBid && (security.isTradedAway || !isActiveTrade(security.trade)),
            affirmed: canSubmitBid && security.trade && security.trade.status === TradeStatus.affirmed
        });
    }


    return (
        <div className="container bidding-cnt">
            <div className="sub-header">
                <TopPanel bwic={bwic}>
                    {bwic.seller && <BwicCreator seller={bwic.seller} />}
                    <ActionBar>
                        {completedDateUtc && <CompletionTime completedDateUtc={completedDateUtc} />}
                        {accessState === brokerDealerAccessState.approved && <ExportButton bwicReferenceName={bwic.referenceName} />}
                    </ActionBar>
                </TopPanel>
            </div>
            {bwic.isAllToAll && <RequestSettlementAgentAgreementBanner />}
            <div className="flex-row flex-row-full-size">
                <FinishedTable
                    bwic={bwic}
                    accessState={accessState}
                    securities={securities}
                    createSecurityCustomArgs={createSecurityCustomArgs}
                    createSecurityCustomClassName={createSecurityCustomClassName}
                />
                <ControlPanel
                    bwic={bwic}
                    positions={securities}
                />
                <CompanyDetailsPanel />
            </div>
        </div>
    );
}
