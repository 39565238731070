import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { blastMessageActions } from "../../../../actions/blast-message.actions";
import { constants } from "../../../../constants/constants";
import { errorMessages } from "../../../../constants/error.messages";
import { AppState } from "../../../../types/state/AppState";
import { BlastMessageRecipientLevelState, BlastMessageTypes } from "../../../../types/state/BlastMessageState";
import dateTimeUtils from "../../../../utils/dateTime.utils";
import { Relative } from "../../../common/Relative";
import { FormError } from "../../../controls/FormError";
import cn from 'classnames';
import { RadioButton } from "../../../controls/RadioButton";

export function Stage1AboutToEndTimeoutRadioButton({
    isStage1AboutToEndTimeout,
    disabled,
    handleOnChange,
    visible
}: {
    isStage1AboutToEndTimeout: boolean,
    disabled: boolean,
    handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    visible: boolean
}) {

    const dispatch = useDispatch()

    const stage1AboutToEndTimeout = useSelector((s: AppState) => s.blastMessage.stage1AboutToEndTimeout);
    const error = useSelector((s: AppState) => s.blastMessage.error);
    const stage1AboutToEndTimeoutMinutes = dateTimeUtils.parseTimeSpan(stage1AboutToEndTimeout).totalMinutes

    const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            dispatch(blastMessageActions.setError(errorMessages.empty))
        }
        else if (+e.target.value < constants.stage1AboutToEndTimeoutMinutesRange.min ||
            +e.target.value > constants.stage1AboutToEndTimeoutMinutesRange.max) {
            dispatch(blastMessageActions.setError(
                errorMessages.invalidRange(
                    constants.stage1AboutToEndTimeoutMinutesRange.min,
                    constants.stage1AboutToEndTimeoutMinutesRange.max
                )))
        } else {
            dispatch(blastMessageActions.setError(''))
        }
    };

    const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        validate(e)
        dispatch(blastMessageActions.editMinutesAboutToEnd(dateTimeUtils.timeSpan(0, +e.target.value)))
    }

    useEffect(() => {
        if (!isStage1AboutToEndTimeout) {
            dispatch(blastMessageActions.editMinutesAboutToEnd(dateTimeUtils.timeSpan(0, 2)))
            dispatch(blastMessageActions.setError(''))
        }
    }, [isStage1AboutToEndTimeout, dispatch])

    useEffect(() => {
        return () => {
            dispatch(blastMessageActions.messageTypeChange(BlastMessageTypes.submitFinal));
            dispatch(blastMessageActions.recipientLevelChange(BlastMessageRecipientLevelState.All));
        }
    }, [dispatch])

    const Stage1AboutToEndTimeoutLabel = () => {
        return (
            <>
                "Please note Stage 1 will be completed in
                <Relative inline={true} className="input-wrapper">
                    <MaskedInput
                        className={cn(
                            "form-control form-control-sm text-right",
                            { 'is-invalid': !!error }
                        )}
                        value={String(stage1AboutToEndTimeoutMinutes || '')}
                        mask={createNumberMask({
                            prefix: "",
                            suffix: "",
                            allowDecimal: false,
                            decimalLimit: 3,
                            integerLimit: 3,
                            includeThousandsSeparator: false,
                        })}
                        onChange={handleMinutesChange}
                        disabled={!isStage1AboutToEndTimeout}
                        data-testid="Stage1AboutToEndTimeoutMinutesInput"
                    />
                    <FormError message={error} right />
                </Relative>
                minutes.
                <br /> Please improve your bids."
            </>
        )
    }

    if (!visible) {
        return null
    }
    return (
        <RadioButton
            className="radio-with-input"
            dataTestId='stage1AboutToEndTimeout'
            label={Stage1AboutToEndTimeoutLabel()}
            name="messageType"
            checked={isStage1AboutToEndTimeout}
            disabled={disabled}
            value={BlastMessageTypes.stage1AboutToEndTimeout}
            onChange={handleOnChange}
        />
    )
}

