import React from 'react';
import { formatUtils } from '../../../../utils/format.utils';
import { FeedbackMenu } from '../FeedbackMenu';
import { OnHoverTooltip } from "../../../common/OnHoverTooltip";
import { Bid } from '../../../../types/bidding/Bid';
import { BidOnBehalfIcon } from '../bid-on-behalf/BidOnBehalfIcon';
import { BwicProcessType, Process } from '../../../../types/models/Process';
import { jumpBallUtils } from '../../../../utils/jump-ball.utils';
import { topXUtils } from '../../../../utils/top-x.utils';
import { constants } from '../../../../constants';
import { BidOverThemSelf } from '../biddingSecurities/BidOverThemSelf';
import { BwicStatus } from '../../../../types/enums/BwicStatus';
import { Trade } from '../../../../types/trades/Trade';
import { AxedIcon } from '../../common/AxedIcon';
import { FinalIcon } from '../../common/FinalIcon';
import { BidLevel } from '../../../../types/bidding/BidLevel';
import { biddingUtils } from '../../../../utils';

interface Props {
    level?: number;
    positionId: number;
    allBids: Bid[];
    levelBids: Bid[];
    feedbackVisible: boolean;
    bidAttributesVisible: boolean;
    process: Process;
    stage2Participants?: { [companyIdentifier: string]: boolean };
    trade?: Trade;
    bwicStatus?: BwicStatus;
}

export function BidGroup({ level, positionId, allBids, levelBids, feedbackVisible, bidAttributesVisible, process, stage2Participants, trade, bwicStatus }: Props) {
    const isFeedbackVisible = (bid: Bid) => {
        if (!feedbackVisible) return false;
        if (process.type === BwicProcessType.JumpBall) {
            return jumpBallUtils.canSendFeedback(stage2Participants ?? {}, process.stagedBiddingStatus, [bid]);
        } else if (process.type === BwicProcessType.TopX) {
            return topXUtils.canSendFeedback(stage2Participants ?? {}, process.stagedBiddingStatus, [bid]);
        } else if (process.type === BwicProcessType.BestFootForward) {
            return process.stagedBiddingStatus == null;
        }

        return true;
    }

    const renderBids = () =>
        levelBids.map((bid) => {
            return (
                <div className="flex-row" key={biddingUtils.getUniqueBidId(bid)}>
                    <OnHoverTooltip overlay={bid.buyerPseudoOrderNumber ? `${bid.company.name}-${bid.buyerPseudoOrderNumber}` : bid.company.name}>
                        <div className="bid-list-cell bid-list-dealer">
                            {bid.buyerPseudoOrderNumber ? `${bid.company.code}-${bid.buyerPseudoOrderNumber}` : bid.company.code}
                        </div>
                    </OnHoverTooltip>
                    {
                        !bid.pass &&
                        <OnHoverTooltip overlay={formatUtils.formatBid(bid.value)}>
                            <div className="bid-list-cell bid-list-bid">{formatUtils.formatBid(bid.value)}</div>
                        </OnHoverTooltip>
                    }
                    {
                        !!bid.pass &&
                        <OnHoverTooltip overlay="PASS">
                            <div className="bid-list-cell bid-list-bid">PASS</div>
                        </OnHoverTooltip>
                    }
                    {
                        bidAttributesVisible &&
                        <>
                            <div className="bid-list-cell bid-list-axed">
                                {bid.axed && <AxedIcon />}
                            </div>
                            <div className="bid-list-cell bid-list-final">
                                {bid.final && <FinalIcon />}
                            </div>
                        </>
                    }
                    <div className="bid-list-cell bid-list-bid-on-behalf margin-l-a">
                        {!!bid.onBehalf && <BidOnBehalfIcon />}
                    </div>
                    <div className="bid-list-cell bid-list-bid-themself">
                        {level === BidLevel.Best && (
                            <BidOverThemSelf
                                bwicStatus={bwicStatus}
                                trade={trade}
                                positionId={positionId}
                            />
                        )}
                    </div>
                    <div className="bid-list-cell bid-list-feedback">
                        {
                            isFeedbackVisible(bid) &&
                            <FeedbackMenu
                                level={level}
                                positionId={positionId}
                                bids={allBids}
                                levelBids={[bid]}
                                hasManyBids={levelBids.length > 1}
                                process={process}
                            />
                        }
                    </div>
                </div>
            );
        })

    return (
        <div className="bid-list-row flex-row">
            <div className="bid-list-cell bid-list-rank">{level == null ? constants.emptyPlaceholder : level + 1}</div>
            <div className="bid-list-cell bid-list-wrap">
                {renderBids()}
            </div>
        </div>
    );

}
