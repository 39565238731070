import React, { Component } from 'react';
import { Popup } from '../../../controls';
import { Form } from '../../../forms';

export default class RequestInvitationDialog extends Component {
    state = {
        comment: ''
    }

    handleSubmit = () => this.props.onSend(this.state.comment);

    render = () => {
        return (
            <Popup title="Request Invite" onClose={this.props.onClose.bind(this)}>
                <Form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <label className="form-label">Comment (optional)</label>
                        <div className="form-control-wrapper">
                            <textarea
                                className="form-control"
                                id="comment"
                                type="text"
                                maxLength="512"
                                cols="60"
                                rows="4"
                                placeholder=""
                                value={this.state.comment}
                                onChange={e => this.setState({ comment: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="modal-footer right-padding">
                        <button type="button" className="btn btn-ghost" onClick={this.props.onClose.bind(this)}>cancel</button>
                        <button type="submit" className="btn btn-main">send</button>
                    </div>
                </Form>
            </Popup>
        );
    }
}
