import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { sellerBiddingActions } from '../../../../actions';
import { AppState } from '../../../../types/state/AppState';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { arrayUtils, biddingUtils } from '../../../../utils';
import { Bid } from '../../../../types/bidding/Bid';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    position: BwicPosition;
}

export function BidListButton({ position }: Props) {
    const dispatch = useAppDispatch();
    const bidList = useSelector((s: AppState) => s.sellerBidding.bidList);
    const isActive = bidList.visible && bidList.positionId === position.id;
    const bids = position.bids;
    const biddersCount = React.useMemo(() => bids?.length
        ? arrayUtils.groupBy(bids, (bid: Bid) => biddingUtils.getBidCompanyIdentifier(bid)).size
        : 0, [bids]);

    const handleClick = (e: React.MouseEvent) => {
        dispatch(sellerBiddingActions.toggleBidList(position.id));
        e.stopPropagation();
    };

    return (
        <button
            className={classNames("btn btn-ghost btn-sm btn-bidlist", { 'btn-link-active': isActive })}
            onClick={handleClick}
        >
            bids{!!biddersCount && ` (${biddersCount})`}
        </button>
    );
}
