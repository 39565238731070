import * as React from 'react';
import { constants } from '../../../../constants/constants';
import { Bid } from '../../../../types/bidding/Bid';
import { LevelSpecificationType } from '../../../../types/enums/LevelSpecificationType';
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { formatUtils } from '../../../../utils/format.utils';
import { AnimatedValueUpdate } from '../../../controls/AnimatedValueUpdate';

interface Props {
    stagedBiddingStatus?: OpenBiddingStatus | null;
    currentBid?: Bid;
    stage2Level?: number;
    levelSpecificationType: LevelSpecificationType;
    tiedToBest?: boolean;
    animated?: boolean;
}

export function LiveBiddingStage2Level({
    stagedBiddingStatus,
    currentBid,
    stage2Level,
    levelSpecificationType,
    tiedToBest,
    animated = true }: Props) {
    const render = (text: string, level: number) => {
        let content;
        if (animated) {
            const animatedLevel = <AnimatedValueUpdate
                value={formatUtils.formatBid(level)}
                backgroundClassName="open-bidding-feedback-updated-bg"
            />
            content = <>{text} {animatedLevel}</>;
        } else {
            content = `${text} ${formatUtils.formatBid(level)}`;
        }

        return <div className="flex-row">{content}</div>
    }

    if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended && currentBid != null && stage2Level != null) {
        if (levelSpecificationType === LevelSpecificationType.best) {
            if (currentBid.value === stage2Level && tiedToBest) return render("Tied to best. Best level is", stage2Level);
            if (currentBid.value === stage2Level) return render("Best. Best level is", stage2Level);
            return render("Best level is", stage2Level);
        } 
    }

    return <>{constants.emptyPlaceholder}</>;
}