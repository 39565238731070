import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../../common';
import { ColorType } from '../../../../seller/colorDistribution';
import { isActiveTrade } from '../../../../../../types/trades/TradeStatus';
import { RoleActionBlocker } from '../../../../../access/RoleActionBlocker';
import { roles } from '../../../../../../constants';

export const colorDistributionType = {
    columnName: 'colorDistributionType',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-lg cell-input">
                Type
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position) {
        const isTraded = isActiveTrade(position.trade);

        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-lg cell-input">
                <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    <ColorType position={position} traded={isTraded} />
                </RoleActionBlocker>
            </SecurityListColumn>
        );
    }
};
