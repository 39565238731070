import React from 'react';
import {
    SecurityListHeaderColumn,
    SecurityListColumn
} from '../../../common';
import { PxTalkListButton } from '../../../../common/PxTalk/PxTalkListButton';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

export const pxTalk = {
    columnName: 'pxTalk',
    renderHeader: function () {
        return (
            <SecurityListHeaderColumn key={this.columnName} className="data-list-cell-sm text-right">
                Px Talk
            </SecurityListHeaderColumn>
        );
    },
    renderBody: function (position, context) {
        return (
            <SecurityListColumn key={this.columnName} className="data-list-cell-sm text-right">
                <PxTalkListButton 
                    className="pseudo-link" 
                    positionId={position.id} 
                    pxTalks={position.pxTalks} 
                    requiredFeature={context.bwic?.isParsed && SubscriptionFeature.BwicMonitorBwicsData}
                />
            </SecurityListColumn>
        );
    }
};
