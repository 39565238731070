import { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../../history';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { tradeConfirmActions as actions } from '../../../../actions';
import { tradeStatuses } from '../../../../constants/bidding';
import { directTradeAffirmColmns } from '../../common/table/columns';
import { Table } from '../../common/table';
import { user } from '../../../../user';
import { FeatureButton } from '../../../access/FeatureButton';
import { DirectBiddingDisclaimerActionBlocker } from '../../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';

class AffirmTradePopup extends Component {
    prevLocation = null;

    isPathChanged = nextLocation =>
        !this.prevLocation ||
        !nextLocation ||
        this.prevLocation.pathname !== nextLocation.pathname;

    componentDidMount = () => {
        if (user.isAuthenticated()) {
            this.props.dispatch(actions.checkAwaitingTrades());

            this.unlisten = history.listen(location => {
                const { visible, positions } = this.props;
                if (!visible &&
                    positions &&
                    positions.length &&
                    this.isPathChanged(location) &&
                    positions.some(p => p.trade && p.trade.status === tradeStatuses.pending.key)) {
                    this.props.dispatch(actions.visibility(true));
                }

                this.prevLocation = location;
            });
        }
    };

    componentWillUnmount = () => {
        this.unlisten();
        this.props.dispatch(actions.reset());
    };

    handleClose = () => this.props.dispatch(actions.visibility(false));

    render = () => {
        const { visible, positions, dispatch } = this.props;

        if (!visible || !positions || !positions.length) {
            return null;
        }

        // Add settleDate for table sorting
        const positionsWithTradeSettleDate = positions.map(p =>
            p.trade ? { ...p, settleDate: p.trade.settlementDate } : p
        );

        return (
            <Popup
                title="Pending Trades"
                modalClass="trade-modal trade-modal-affirm trade-modal-affirm-direct"
                onClose={this.handleClose}
            >
                <>
                    <PopupBody>
                        <Table
                            className="data-list-striped"
                            dataItems={positionsWithTradeSettleDate}
                            columns={directTradeAffirmColmns}
                        />
                    </PopupBody>
                    <PopupFooter>
                        <DirectBiddingDisclaimerActionBlocker onConfirm={() => dispatch(actions.affirmAll())}>
                            <FeatureButton
                                className="btn btn-main btn-affirm-all"
                                onClick={() => dispatch(actions.affirmAll())}
                                disabled={!positions.some(p => p.trade.status === tradeStatuses.pending.key)}
                            >
                                Affirm all
                            </FeatureButton>
                        </DirectBiddingDisclaimerActionBlocker>
                    </PopupFooter>
                </>
            </Popup>
        );
    }
}

const mapStateToProps = ({ tradeConfirm }) => ({
    visible: tradeConfirm.visible,
    positions: tradeConfirm.tradedPositions
});

const connectedAffirmTradePopup = connect(mapStateToProps)(AffirmTradePopup);
export { connectedAffirmTradePopup as AffirmTradePopup };
