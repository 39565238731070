import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { constants, errorMessages } from "../../../constants";
import { Relative } from '../../common';
import { BlockedFeatureContext } from '../../access/BlockedFeatureContext';
import { numericUtils } from '../../../utils';
import { FormError } from '../../controls';
import { RequestState } from '../../../constants/request-state';
import { sellerBiddingActions } from '../../../actions';
import { AppState } from '../../../types/state/AppState';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    positionId: number;
    targetLevel?: number;
}

export function TargetLevel({ positionId, targetLevel }: Props) {
    const dispatch = useAppDispatch();
    const editState = useSelector((state: AppState) => state.sellerBidding.editTargetLevel[positionId]);
    const isBlocked = useContext(BlockedFeatureContext);
    const [error, setError] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!error &&
            targetLevel &&
            (targetLevel < constants.targetLevelRange.min || targetLevel > constants.targetLevelRange.max)) {
            setError(errorMessages.targetLevelRange);
        }
    }, [error, targetLevel]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (constants.targetLevel.test(value)) {
            if (!value || (+value < constants.targetLevelRange.min || +value > constants.targetLevelRange.max)) {
                setError(errorMessages.targetLevelRange)
            } else if (error) {
                setError('');
            }

            dispatch(sellerBiddingActions.setTargetLevel(positionId, value))
        }
    }

    const handleBlur = () => {
        dispatch(sellerBiddingActions.sendTargetLevel(positionId));
        setError('');
    }

    const getValue = () => {
        if (editState && editState.targetLevel != null) {
            return editState.targetLevel;
        }

        return targetLevel != null && numericUtils.isNumber(targetLevel)
            ? targetLevel.toFixed(4)
            : '';
    };

    const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputRef && inputRef.current) {
            inputRef.current.select();
        }
    };

    return (
        <Relative>
            <input
                ref={inputRef}
                type="text"
                className={classNames('form-control', { 'is-invalid': error })}
                disabled={isBlocked}
                readOnly={editState && editState.targetLevelStatus === RequestState.request}
                value={getValue()}
                placeholder="0.0000"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onMouseUp={() => false}
                onClick={e => e.stopPropagation()}
            />
            <FormError message={error} />
        </Relative>
    );
}
