import * as React from 'react';
import { useDispatch } from 'react-redux';
import { blastMessageActions } from '../../../../actions/blast-message.actions';
import { BidderCompanySlim } from '../../../../types/company/BidderCompanySlim';
import { FavoriteButton } from '../../../common/FavoriteButton';
import { Pluralize } from '../../../common/Pluralize';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import { CompanyStatus } from "../../../../types/company/CompanyStatus";
import IconSVG from '../../../../styles/svg-icons';
import { Checkbox } from '../../../controls/Checkbox';

interface Props {
    company: BidderCompanySlim;
    selected: boolean;
    bidCount: number;
    disabled: boolean;
}

export function BlastMessageRecipient(props: Props) {
    const dispatch = useDispatch();

    return <BlastMessageRecipientContent
        {...props}
        onRecipientSelectionChange={(identifier: string) => dispatch(blastMessageActions.recipientSelectedChange(identifier))}
    />
}

interface ContentProps extends Props {
    onRecipientSelectionChange: (companyIdentifier: string) => void;
}

export function BlastMessageRecipientContent({ company, selected, bidCount, disabled, onRecipientSelectionChange }: ContentProps) {
    const isOnPlatform = company.status === CompanyStatus.active;

    let title = '';
    let tooltip = '';

    if (!company.code) {
        title = tooltip = company.name;
    } else if (company.buyerPseudoOrderNumber) {
        title = `${company.code}-${company.buyerPseudoOrderNumber}`;
        tooltip = `${company.name}-${company.buyerPseudoOrderNumber}`;
    } else {
        title = company.name;
        tooltip = `${company.name} (${company.code})`;
    }

    return (
        <div className="recipient-checkbox-row">
            <Checkbox
                checked={selected}
                disabled={disabled}
                onChange={() => onRecipientSelectionChange(company.identifier)}
            >
                <>
                    <div className="icon-wrap">
                        {
                            isOnPlatform && !company.buyerPseudoOrderNumber &&
                            <OnHoverTooltip overlay="Platform broker-dealer. Bids will come automatically">
                                <IconSVG name="on-platform" width={16} height={16} />
                            </OnHoverTooltip>
                        }
                    </div>
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={tooltip}>
                        {title}
                    </OnHoverTooltip>
                </>
            </Checkbox>
            {
                !!bidCount &&
                <div className="bids-counter">
                    <Pluralize singular="bid" count={bidCount} />
                </div>
            }
            <div className="favorite-action-button">
                {
                    !company.buyerPseudoOrderNumber &&
                    <FavoriteButton companyId={company.id} disabled={true} />
                }
            </div>
        </div>
    );
}
